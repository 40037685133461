/* eslint-env es6 */

angular.module('genius').component('profileAdminPane', {
  bindings: {
    user: '<',
    view_errors_link: '<viewErrorsLink',
  },

  templateUrl: 'profile_admin_pane.html',

  controller: ['Session', function(Session) {
    this.$onInit = () => {
      this.managing_accomplishments = false;
    };

    this.$onChanges = (changes) => {
      if (changes.user) {
        this.can_moderate = this.can('toggle_penalty_status')
          || this.can('mark_spam')
          || this.can('mark_ham')
          || this.can('mass_delete_unreviewed_activity')
          || this.can('mass_delete_votes');
      }
    };

    this.can = action_name => Session.has_permission(action_name, this.user);
  }],
});
