/* eslint-env es6 */

angular.module('genius').component('statisticsPanel', {
  bindings: {
    user: '<',
    on_select: '&onSelect',
  },

  templateUrl: 'statistics_panel.html',
});
