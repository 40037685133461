/* eslint-env es6 */

angular.module('genius').factory('ImageTransform', ['AppConfig', 'initial_image_urls', function(AppConfig, initial_image_urls) {
  const RESIZED_REGEXP = new RegExp(`^${AppConfig.sizies_base_url}`);
  const TRANSFORM_DOMAINS = ['images.genius.com', 'images.rapgenius.com'];
  const DIMENSION_REGEX = /\.(\d+).(\d+).\d+\.\w*$/;

  const resized_srcs = _(initial_image_urls).toArray().
    filter(url => RESIZED_REGEXP.test(url)).
    map((url) => {
      const [size_string, encoded_src] = _.takeRight(url.split('/'), 2);
      const [width, height] = size_string.split('x').map(px => Number(px));
      const src = decodeURIComponent(encoded_src);
      return {src, width, height, url};
    }).
    sortBy(({width, height}) => width * height).
    value();

  const find_reusable_resized_url = _.memoize((target) => {
    const image_ratio = extract_aspect_ratio(target.original_url);
    if (image_ratio && target.width && !target.height) target.height = Math.round(target.width / image_ratio);
    if (image_ratio && target.height && !target.width) target.width = Math.round(target.height * image_ratio);
    const target_ratio = target.width / target.height;
    return _.find(resized_srcs, ({src, width, height}) =>
      src === target.original_url &&
        width >= target.width &&
        height >= target.height &&
        Math.abs((width / height) - target_ratio) < 0.05
    );
  });

  const extract_aspect_ratio = (original_url) => {
    const [match, width, height] = original_url.match(DIMENSION_REGEX) || [];
    if (match) return Number(width) / Number(height);
  };

  return {
    resize(original_url, params) {
      if (!original_url) return;

      if (initial_image_urls.has(original_url)) return original_url;

      const parsed_url = new URL(original_url);
      if (!_.includes(TRANSFORM_DOMAINS, parsed_url.host)) return original_url;

      const width = parseInt(params.width || 0);
      const height = parseInt(params.height || 0);

      const resusable = find_reusable_resized_url({original_url, width, height});

      if (resusable) return resusable.url;

      const url = `${AppConfig.sizies_base_url}/${width}x${height}/${encodeURIComponent(original_url)}`;

      resized_srcs.push({src: original_url, width, height, url});

      return url;
    },
  };
}]);
