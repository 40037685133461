/* eslint-env es6 */

angular.module('genius').directive('leaderboard', [function() {
  return {
    restrict: 'E',

    templateUrl: 'leaderboard.html',

    scope: {
      leaderboard_rows: '<collection',
    },
  };
}]);
