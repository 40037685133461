/* eslint-env es6 */

angular.module('genius').component('squareItemAndIq', {
  bindings: {
    event_type: '@eventType',
    iq_params: '<iqParams',
    show_pill_initial: '<showPill',
    variants: '<',
  },

  templateUrl: 'square_item_and_iq.html',

  transclude: true,

  controller: ['Iq', 'Session', function(Iq, Session) {
    this.show_pill = () => {
      if (!Session.user_signed_in) return false;
      if (_.isUndefined(this.show_pill_initial)) return true;
      return this.show_pill_initial;
    };

    this.iq = () => {
      if (this.iq_params) {
        return _.sum(_.map(_.filter(this.iq_params, 'applicable'), reason => reason.multiplier * reason.base));
      } else {
        return Iq.for(this.event_type);
      }
    };
  }],
});
