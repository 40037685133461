/* eslint-env es6 */

angular.module('genius').directive('handleIntraPageLinks', ['ReferentRegistry', 'ModelRouter', '$location', function(ReferentRegistry, ModelRouter, $location) {
  return {
    restrict: 'A',

    link(scope, element) {
      element.on('click', 'a', function() {
        const path = $(this).attr('data-api_path');
        if (!path) return;
        const found = ModelRouter.lookup(path);

        if (found && found.type === 'referent') {
          const referent = ReferentRegistry.peek(found.id);
          if (referent) {
            scope.$apply(() => $location.url(`/${referent.id}`));
            return false;
          }
        }
      });
    },
  };
}]);
