/* eslint-env es6 */

angular.module('genius').component('questionForm', {
  bindings: {
    questionable: '<',
    on_new_question: '&onNewQuestion',
  },

  templateUrl: 'question_form.html',

  controller: ['Session', '$scope', 'QuestionClient', function(Session, $scope, QuestionClient) {
    this.user_signed_in = Session.user_signed_in;

    const reset_question = (question) => {
      this.question = {
        _new: true,
        author: Session.current_user,
        author_name: question && question.author_name,
        author_email: question && question.author_email,
        body: '',
      };
    };

    this.save = () => {
      if (this.saving) return;
      this.saving = true;

      if (!Session.user_signed_in) {
        this.question.anonymous_user = {name: this.question.name};
      }

      const saved_question = this.question;
      reset_question();
      $scope.question_form.$setPristine();

      QuestionClient.create(this.questionable._type, this.questionable.id, saved_question).then(() => {
        this.saving = false;
        $scope.question_form.$setPristine();
        this.thank_you = true;

        this.on_new_question({question: saved_question});
        reset_question(saved_question);
        this.error = {};
      }).catch((error) => {
        this.error = error;
        this.question = saved_question;
        this.saving = false;
      });
    };
  }],
});
