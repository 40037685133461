/* eslint-env es6 */

angular.module('genius').component('expandableContainer', {
  templateUrl: 'expandable_container.html',

  transclude: {
    expandable_container_title: '?expandableContainerTitle',
  },

  bindings: {
    start_expanded: '<startExpanded',
    start_initialized: '<startInitialized',
    title: '@',
    variants: '<',
    yields_api: '&yieldsApi',
  },

  controller: [function() {
    this.$onInit = function() {
      this.expanded = this.start_expanded;
      this.yields_api({api: this.api});
    };

    this.$onChanges = function(changes) {
      if (changes.start_expanded) {
        this.expanded = this.start_expanded;
      }
    };

    this.toggle_expand = () => {
      this.expanded = !this.expanded;
    };

    this.api = () => ({expanded: () => this.expanded});
  }],
});
