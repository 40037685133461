/* eslint-env es6 */

angular.module('genius').directive('mediaControls', [function() {
  return {
    restrict: 'E',

    scope: {
      media: '<mediaPlayer',
      hide_playpause: '<?hidePlaypause',
      variants: '<?',
    },

    templateUrl: 'media_controls.html',
  };
}]);
