/* eslint-env es6 */

angular.module('genius').component('songTopVerifiedArtist', {
  bindings: {
    contributors: '<verifiedContributors',
    on_clicked_verified_annotator: '&onClickedVerifiedAnnotator',
    on_clicked_verified_answer: '&onClickedVerifiedAnswer',
  },

  templateUrl: 'song_top_verified_artist.html',

  controller() {
    this.$onChanges = (changes) => {
      if (changes.contributors) {
        const sorted = _.sortBy(this.contributors, contributor => contributor.contributions.length);
        this.contributor = _.last(sorted);
      }
    };

    this.on_clicked = (e) => {
      if (_.includes(this.contributor.contributions, 'annotations')) {
        this.on_clicked_verified_annotator({user: this.contributor.user});
        e.preventDefault();
      } else if (_.includes(this.contributor.contributions, 'answers')) {
        this.on_clicked_verified_answer({artist: this.contributor.artist});
        e.preventDefault();
      }
    };
  },
});
