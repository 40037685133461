/* eslint-env es6 */

angular.module('genius').directive('placeholderSpinner', [function() {
  return {
    restrict: 'E',

    scope: {
      placeholderClasses: '@',
      variants: '<variants',
    },

    templateUrl: 'placeholder_spinner.html',
  };
}]);
