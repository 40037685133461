/* eslint-env es6 */

angular.module('genius').filter('human_readable_datetime', ['$filter', function($filter) {
  return (datetime, options) => {
    const t = $filter('cast_to_date')(datetime);

    options = options || {
      weekday: 'long', year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', timeZoneName: 'short',
    };

    return t.toLocaleTimeString('en-US', options);
  };
}]);
