/* eslint-env es6 */

angular.module('genius').directive('annotationHistory', [function() {
  return {
    restrict: 'E',

    templateUrl: 'annotation_history.html',

    scope: {
      annotation: '<',
      onRevert: '&',
    },

    controllerAs: 'ctrl',

    controller: ['$scope', 'AnnotationClient', 'Session', function($scope, AnnotationClient, Session) {
      this.can_revert = Session.has_permission('edit', $scope.annotation);

      AnnotationClient.load_versions($scope.annotation.id).then((versions) => {
        $scope.versions = versions;
      });

      this.revert_to = function(version_id) {
        $scope.reverting_to = version_id;
        AnnotationClient.revert_to_version($scope.annotation.id, version_id).then((updated_annotation) => {
          _.assign($scope.annotation, updated_annotation);
          $scope.onRevert();
        }).finally(() => {
          Reflect.deleteProperty($scope, 'reverting_to');
        });
      };
    }],
  };
}]);
