/* eslint-env es6 */

angular.module('genius').component('question', {
  bindings: {
    question: '<',
    variants: '<',
    move_question: '&?moveQuestion',
    featured: '<',
    on_pending_event: '&onPendingEvent',
    yields_iq: '&yieldsIq',
    yields_api: '&yieldsApi',
  },

  templateUrl: 'question.html',

  controller: ['Reversible', 'QuestionClient', 'Session', function(Reversible, QuestionClient, Session) {
    this.$onInit = () => {
      this.yields_api({api: {
        answer: () => {
          this.answerApi.start_editing();
        },
        archive: this.archive,
      }});
    };

    this.can = (permission, object) => Session.has_permission(permission, object);

    this.is_pinned = () => this.question.state === 'pinned';

    this.is_verified = () => this.question.answer && this.question.answer.answer_source;

    this.archive = () => {
      if (!confirm('Are you sure?')) return false;

      this.question._deleted = true;

      const promise = this.change_state('archive');
      this.on_pending_event({event: 'UserArchiveQuestion', result: promise});
      return promise.catch(() => {
        Reflect.deleteProperty(this.question, '_deleted');
      });
    };

    this.pin = () => {
      const unpin = Reversible.modify(this.question, {'state': 'pinned'});

      return this.change_state('pin').catch(unpin);
    };

    this.unpin = () => {
      const pin = Reversible.modify(this.question, {'state': 'pending'});

      return this.change_state('unpin').catch(pin);
    };

    this.change_state = event =>
      QuestionClient.create_if_not_exists(this.question).
        then(() => QuestionClient.change_state(this.question, event));

    this.start_editing = () => {
      this.question._editing = _.defaults(this.question._editing || {}, {
        _form_visible: true,
        body: this.question.body,
      });
    };

    this.move = function(direction) {
      this.move_question({direction, question: this.question});
    };

    this.cancel_editing = () => {
      Reflect.deleteProperty(this.question, '_editing');
    };

    this.finish_editing = () => {
      if (this.disable_saving_with) return;
      this.disable_saving_with = 'Saving';

      const restart_editing = Reversible.modify(this.question, {'_editing._form_visible': false});

      QuestionClient.update(this.question, {question: this.question._editing}).
        then(this.cancel_editing.bind(this)).
        catch(restart_editing).
        finally(() => {
          this.disable_saving_with = false;
        });
    };
  }],
});
