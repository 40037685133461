/* eslint-env es6 */

angular.module('genius').factory('application_bootstrap', ['$q', function($q) {
  let complete = false;
  const deferred = $q.defer();
  const queue = [];

  return {
    before(fn) {
      if (!complete) fn();
    },

    after(fn) {
      if (complete) return fn();
      queue.push(deferred.promise.then(fn));
    },

    done() {
      complete = true;
      deferred.resolve();
      return $q.all(queue);
    },
  };
}]);
