/* eslint-env es6 */

angular.module('genius').component('profileUserDescriptionPreview', {
  templateUrl: 'profile_user_description_preview.html',

  bindings: {
    user: '<',
    top_accomplishment: '<topAccomplishment',
  },
});
