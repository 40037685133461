/* eslint-env es5 */
/* eslint-disable arrow-body-style, arrow-parens, arrow-spacing, constructor-super, generator-star-spacing, no-class-assign, no-confusing-arrow, no-const-assign, no-dupe-class-members, no-duplicate-imports, no-extra-bind, no-new-symbol, no-restricted-imports, no-this-before-super, no-useless-computed-key, no-useless-constructor, no-useless-rename, no-var, object-shorthand, prefer-arrow-callback, prefer-const, prefer-numeric-literals, prefer-reflect, prefer-rest-params, prefer-spread, prefer-template, require-yield, rest-spread-spacing, sort-imports, symbol-description, template-curly-spacing, yield-star-spacing, dot-location, key-spacing, max-len, new-parens, no-lonely-if, no-mixed-operators, no-trailing-spaces, no-whitespace-before-property, padded-blocks, quotes, space-infix-ops */

angular.module('genius').component('textCorrector', {
  bindings: {
    text: '<',
    proposing_edit: '<proposingEdit',
    cancel: '&',
    submit: '&',
    yields_text_corrector: '&yieldsTextCorrector',
  },

  templateUrl: 'text_corrector.html',

  controller: ['$q', '$window', 'I18n', function($q, $window, I18n) {
    this.submit_form = function() {
      this.saving = true;
      return $q.when(this.submit({corrected: this.text}))
        .finally(function() {
          delete this.saving;
          this.correction_form.$setPristine();
        }.bind(this));
    };

    this.cancel_correction = function() {
      if (!this.is_dirty() || $window.confirm(I18n.t('editing.dirty_unload_confirm_box'))) {
        this.correction_form.$rollbackViewValue();
        this.correction_form.$setPristine();
        this.cancel();
      }
    };

    this.yields_text_corrector({text_corrector: {
      cancel: this.cancel_correction.bind(this),
    }});
  }],
});
