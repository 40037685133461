/* eslint-env es6 */

angular.module('genius').directive('hideSongForm', [function() {
  return {
    restrict: 'E',

    scope: {
      song: '<',
      on_close: '&onClose',
    },

    templateUrl: 'hide_song_form.html',

    controllerAs: 'ctrl',
    controller: ['$scope', 'SongClient', function($scope, SongClient) {
      this.save = function(viewable_by_roles) {
        this.saving = true;
        SongClient.perform_action($scope.song.id, 'hide', {viewable_by_roles}).then((song) => {
          _.assign($scope.song, song);
          $scope.error = null;
          $scope.on_close();
        }).
          catch(error => $scope.error = error.message).
          finally(() => this.saving = false);
      };
    }],
  };
}]);
