/* eslint-env es6 */

angular.module('genius').filter('cast_to_date', [function() {
  return (datetime) => {
    if (_.isNumber(datetime)) {
      return new Date(datetime * 1000);
    } else if (_.isString(datetime)) {
      return new Date(datetime);
    } else if (_.isDate(datetime)) {
      return datetime;
    } else {
      return null;
    }
  };
}]);
