/* eslint-env es5 */
/* eslint-disable arrow-body-style, arrow-parens, arrow-spacing, constructor-super, generator-star-spacing, no-class-assign, no-confusing-arrow, no-const-assign, no-dupe-class-members, no-duplicate-imports, no-extra-bind, no-new-symbol, no-restricted-imports, no-this-before-super, no-useless-computed-key, no-useless-constructor, no-useless-rename, no-var, object-shorthand, prefer-arrow-callback, prefer-const, prefer-numeric-literals, prefer-reflect, prefer-rest-params, prefer-spread, prefer-template, require-yield, rest-spread-spacing, sort-imports, symbol-description, template-curly-spacing, yield-star-spacing, dot-location, key-spacing, max-len, new-parens, no-lonely-if, no-mixed-operators, no-trailing-spaces, no-whitespace-before-property, padded-blocks, quotes, space-infix-ops */

angular.module('genius').directive('roleSelectionDialog', ['AppConfig', function(AppConfig) {
  return {
    restrict: 'E',

    templateUrl: 'role_selection_dialog.html',

    scope: {
      prompt: '@',
      save: '&',
      saving: '<',
      error: '<',
    },

    link: function(scope) {
      scope.available_roles = AppConfig.available_roles;

      scope.backing = {};

      scope.submit = function() {
        var selected_roles = _(scope.backing).map(function(v, k) {
          if (v) return k;
        }).compact().value();
        scope.save({selected_roles: selected_roles});
      };
    },
  };
}]);
