/* eslint-env es6 */

angular.module('genius').directive('annotatableImage', ['$document', function($document) {
  return {
    templateUrl: 'annotatable_image.html',
    restrict: 'E',

    scope: {
      src: '@',
      song: '<',
      on_image_referent_creation_started: '&onImageReferentCreationStarted',
      on_image_referent_created: '&onImageReferentCreated',
      on_image_referent_creation_failed: '&onImageReferentCreationFailed',
      can_perform: '<canPerform',
      lyrics_anchorer: '<lyricsAnchorer',
    },

    controller: ['$scope', function($scope) {
      this.create_image_referent = (options) => {
        const img = this.getImage();
        const selection_range = $document[0].createRange();
        selection_range.selectNodeContents(img);
        selection_range.precomputed_client_rect = selection_range.getBoundingClientRect();

        $scope.creating_image_referent = true;

        $scope.on_image_referent_creation_started({selection_range});

        $scope.image_referent_creation_failed = false;

        $scope.lyrics_anchorer.create_image_referent(img, selection_range, options).
          then((referent) => {
            $scope.creating_image_referent = false;
            $scope.on_image_referent_created({referent, selection_range});
            $scope.image_referent_created = true;
          }, (error) => {
            $scope.creating_image_referent = false;
            $scope.on_image_referent_creation_failed({error});
            $scope.image_referent_creation_failed = true;
          });
      };

      this.set_current_hover_and_digest = (hover) => {
        $scope.hovering_over_image = hover;
        $scope.$digest();
      };
    }],

    controllerAs: 'ctrl',

    link: {
      pre(_scope, element, _attrs, ctrl) {
        ctrl.getImage = () => element.find('img')[0];
      },
    },
  };
}]);
