/* eslint-env es6 */

angular.module('genius').directive('commentForm', [function() {
  return {
    restrict: 'E',
    templateUrl: 'comment_form.html',

    scope: {
      commentable: '<',
      onSave: '&',
      allowReasons: '<',
      saveText: '@',
      savingText: '@',
      textareaPlaceholder: '@',
      showFullFormByDefault: '<',
      showReasonsByDefault: '<',
      on_dirty: '&onDirty',
      on_clean: '&onClean',
    },

    controllerAs: 'ctrl',

    controller: ['Session', '$scope', 'Reversible', function(Session, $scope, Reversible) {
      this.user_signed_in = Session.user_signed_in;
      this.show_full_form_by_default = $scope.showFullFormByDefault;
      this.show_reasons_by_default = $scope.showReasonsByDefault;
      this.showing_full_form = this.show_full_form_by_default || false;
      this.showing_authentication_prompt = false;
      this.current_user = Session.current_user;

      this.show_reasons_form = () => this.showing_full_form || this.show_reasons_by_default;

      const reset_comment = () => {
        $scope.comment = {
          _new: true,
          author: Session.current_user,
          body: {},
        };
      };

      reset_comment();

      this.body_is_required = () => !$scope.comment.reason || $scope.comment.reason.requires_body;

      this.save = () => {
        if (this.saving) return;

        if (!Session.user_signed_in) {
          $scope.comment.anonymous_user = {name: $scope.comment.name};
        }

        const save_comment = $scope.comment;
        const revert = Reversible.modify(this, {showing_full_form: false, saving: false});
        reset_comment();
        $scope.form.$setPristine();
        $scope.onSave({comment: save_comment}).catch(() => {
          $scope.comment = save_comment;
          revert();
        });
      };

      $scope.$watch('comment.body.markdown', (body) => {
        if (_.isEmpty(body)) {
          $scope.on_clean();
        } else {
          $scope.on_dirty();
        }
      });

      this.show_full_form_or_prompt_auth = () => {
        if (!Session.user_signed_in) {
          this.showing_authentication_prompt = true;
        } else if (Session.has_permission('create_comment', $scope.commentable)) {
          this.showing_full_form = true;
        }
      };
    }],

    link(scope, element) {
      scope.$on('focus_comment_textarea', (_e, commentable) => {
        if (commentable === scope.commentable) {
          element.find('textarea[ng-model="comment.body.markdown"]').focus();
        }
      });
    },
  };
}]);
