/* eslint-env es6 */

angular.module('genius').directive('setPathOnModal', ['$location', function($location) {
  return {
    restrict: 'A',

    link(_scope, element, attrs) {
      if (!attrs.setPathOnModal) return;
      const path_origin = $location.path();
      history.replaceState(null, null, attrs.setPathOnModal);

      element.on('click', (event) => {
        if (!$(event.target).closest('a').length) return;
        history.replaceState(null, null, path_origin);
      });

      _scope.$on('$destroy', () => history.replaceState(null, null, path_origin));
    },
  };
}]);
