/* eslint-env es6 */

angular.module('genius').component('inboxLineItemDetailLyricsDiff', {
  bindings: {
    before: '<',
    after: '<',
    state: '<',
  },

  templateUrl: 'inbox_line_item_detail_lyrics_diff.html',

  controller: ['LyricsEditProposal', function(LyricsEditProposal) {
    this.$onChanges = (changes) => {
      if (changes.before || changes.after) {
        const patched_diff = LyricsEditProposal.diff(this.before, this.after);
        this.diff_html = LyricsEditProposal.pretty_diff(patched_diff);
      }
    };
  }],
});
