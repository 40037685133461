/* eslint-env es6 */

angular.module('genius').component('editableTracklist', {
  bindings: {
    album: '<',
    album_appearances: '<albumAppearances',
    editing: '<',
    on_update: '&onUpdate',
    close: '&',
  },

  templateUrl: 'editable_tracklist.html',

  controller: ['AlbumClient', '$timeout', 'I18n', function(AlbumClient, $timeout, I18n) {
    this.$onInit = () => {
      this.error = null;
      const album_appearance_by_track_number = _.groupBy(this.album_appearances, 'track_number');
      const largest_track_number = _.max(_.compact(_.map(_.keys(album_appearance_by_track_number), key => Number(key))));
      this.numbered_slots = [];
      for (let i = 1; i <= largest_track_number; i++) {
        if (album_appearance_by_track_number[i]) {
          const album_appearance = album_appearance_by_track_number[i][0];
          this.numbered_slots.push({
            track_number: i,
            song_id: album_appearance.song.id,
            title: album_appearance.song.title_with_featured,
          });
        } else {
          this.numbered_slots.push({track_number: i});
        }
      }
      this.unnumbered_slots = _.map(
        album_appearance_by_track_number.null,
        album_appearance => ({
          song_id: album_appearance.song.id,
          title: album_appearance.song.title_with_featured,
        })
      );
    };

    this.remove_trailing_empty_slots = () => {
      const largest_track_number = _.max(_.map(_.filter(this.numbered_slots, 'song_id'), 'track_number')) || 0;
      this.numbered_slots = this.numbered_slots.slice(0, largest_track_number);
    };

    this.make_unnumbered = (slot) => {
      Reflect.deleteProperty(slot, 'track_number');
      this.unnumbered_slots.push(slot);
    };

    this.clear_slot = (slot) => {
      if (slot.track_number) {
        this.numbered_slots[slot.track_number - 1] = {track_number: slot.track_number};
      } else {
        _.remove(this.unnumbered_slots, {song_id: slot.song_id});
      }
    };

    this.place_song_into_slot = (slot_to_place, slot_to_clear) => {
      const track_number = slot_to_clear.track_number;
      if (slot_to_clear.song_id) {
        this.clear_slot(slot_to_clear);
        this.make_unnumbered(slot_to_clear);
      }
      this.clear_slot(slot_to_place);
      slot_to_place.track_number = track_number;
      this.numbered_slots[track_number - 1] = slot_to_place;
      this.remove_trailing_empty_slots();
    };

    this.update_slot_number = (slot, input) => {
      if (input === '') {
        this.clear_slot(slot);
        this.make_unnumbered(slot);
        this.remove_trailing_empty_slots();
        return;
      }
      const track_number = _.toNumber(input);
      if (_.isNaN(track_number) || track_number <= 0) return;
      while (track_number > this.numbered_slots.length) {
        this.numbered_slots.push({track_number: this.numbered_slots.length + 1});
      }
      this.place_song_into_slot(slot, this.numbered_slots[track_number - 1]);
    };

    let add_slot_timeout;
    this.add_slot_after_delay = () => {
      add_slot_timeout = $timeout(() => this.numbered_slots.push({track_number: this.numbered_slots.length + 1}), 500);
    };

    this.cancel_add_slot = () => {
      $timeout.cancel(add_slot_timeout);
    };

    this.add_slot_and_fill = (slot) => {
      this.cancel_add_slot();
      const new_slot = {track_number: this.numbered_slots.length + 1};
      this.numbered_slots.push(new_slot);
      this.place_song_into_slot(slot, new_slot);
    };

    this.add_song = (song) => {
      const new_slot = {song_id: song.id, title: song.title_with_featured};
      if (song.new) {
        new_slot.lyrics_state = 'incomplete';
      }
      this.unnumbered_slots.push(new_slot);
    };

    this.create_result = term =>
      ({
        id: _.uniqueId('new_'),
        title: term,
        title_with_featured: term,
        primary_artist_names: this.album.primary_artist_names,
        new: true,
      });

    this.toggle_unreleased = (slot) => {
      if (slot.lyrics_state === 'incomplete' && confirm(I18n.t('song.lyrics_state.unreleased.confirm'))) {
        slot.lyrics_state = 'unreleased';
      } else {
        slot.lyrics_state = 'incomplete';
      }
    };

    this.save = () => {
      this.saving = true;
      this.error = null;
      const tracklist = _.map(
        _.filter([...this.numbered_slots, ...this.unnumbered_slots], 'song_id'),
        slot => _.pick(slot, ['track_number', 'song_id', 'title', 'lyrics_state'])
      );
      AlbumClient.update_tracklist(this.album.id, tracklist).
        then((updated_album_appearances) => {
          this.on_update({album_appearances: updated_album_appearances});
          this.close();
        }).catch((error) => {
          this.error = `${error.message}: ${_.join(error.response.errors, ', ')}`;
        }).finally(() => {
          this.saving = false;
        });
    };
  }],
});
