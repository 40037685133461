/* eslint-env es6 */

angular.module('genius').factory('MixedDataFeed', ['$q', function($q) {
  return class {
    constructor(scrollable_data_objects) {
      this.scrollable_data_objects = scrollable_data_objects;
      this.reset = () => {
        _.each(this.scrollable_data_objects, (scrollable_data) => {
          scrollable_data.src.reset();
        });
      };

      this.next = () => $q.all(
        _.map(this.scrollable_data_objects, scrollable_data =>
          scrollable_data.src.next().then(models => ({
            type: scrollable_data.type,
            models,
          }))
        )
      );

      this.has_next = () =>
        _.some(this.scrollable_data_objects, scrollable_data => scrollable_data.src.has_next());
    }
  };
}]);
