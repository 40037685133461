/* eslint-env es5 */
/* eslint-disable arrow-body-style, arrow-parens, arrow-spacing, constructor-super, generator-star-spacing, no-class-assign, no-confusing-arrow, no-const-assign, no-dupe-class-members, no-duplicate-imports, no-extra-bind, no-new-symbol, no-restricted-imports, no-this-before-super, no-useless-computed-key, no-useless-constructor, no-useless-rename, no-var, object-shorthand, prefer-arrow-callback, prefer-const, prefer-numeric-literals, prefer-reflect, prefer-rest-params, prefer-spread, prefer-template, require-yield, rest-spread-spacing, sort-imports, symbol-description, template-curly-spacing, yield-star-spacing, dot-location, key-spacing, max-len, new-parens, no-lonely-if, no-mixed-operators, no-trailing-spaces, no-whitespace-before-property, padded-blocks, quotes, space-infix-ops */

angular.module('genius').directive('userBadge', function() {
  return {
    restrict: 'E',

    templateUrl: 'user_badge.html',

    scope: {
      user: '<object',
      size: '@',
      pinned_role: '<pinnedRole',
      anonymous_user: '<anonymousUser',
      sponsor: '<',
      variants: '<',
    },

    require: '^?standaloneAnnotation',

    link: function(scope, _element, _attrs, standalone_annotation_ctrl) {
      scope.disables_user_tooltips = standalone_annotation_ctrl && standalone_annotation_ctrl.disables_user_tooltips;

      var avatar_is_large = !_.includes(['small', 'x_small'], scope.size);

      scope.is_two_lines = avatar_is_large;

      if (scope.user) {
        scope.avatar_url = avatar_is_large ? scope.user.avatar.small.url : scope.user.avatar.thumb.url;
      }
    },
  };
});
