/* eslint-env es6 */

angular.module('genius').component('artistSongsAndAlbums', {
  templateUrl: 'artist_songs_and_albums.html',

  bindings: {
    artist: '<',
    artist_songs: '<artistSongs',
    artist_albums: '<artistAlbums',
    artist_has_more_songs: '<artistHasMoreSongs',
    open_all_songs_pane: '&openAllSongsPane',
    songs_discography_path: '@songsDiscographyPath',
  },
});
