/* eslint-env es6 */

angular.module('genius').directive('modalWindow', ['$compile', '$document', '$templateCache', 'Bowser', function($compile, $document, $templateCache, Bowser) {
  const template = $templateCache.get('modal_window.html');

  return {
    scope: {
      on_close: '&onClose',
      variants: '<',
      hidden: '<',
      media: '<',
      content_dimensions: '<contentDimensions',
    },

    transclude: true,

    link(scope, _element, _attributes, _controller, transcludeFn) {
      const link = $compile(template);

      const body = $document.prop('body');

      scope.is_ios = Bowser.ios;

      link(scope, (cloned_element) => {
        $(body).append(cloned_element);

        scope.close = (reason) => {
          scope.on_close({reason});
        };

        scope.$on('$destroy', () => cloned_element.remove());
      }, {
        parentBoundTranscludeFn: transcludeFn,
        futureParentElement: body,
      });
    },
  };
}]);
