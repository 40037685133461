/* eslint-env es6 */

angular.module('genius').component('lyricsEditProposalModeration', {
  bindings: {
    song: '<',
    lyrics_html: '<lyricsHtml',
    on_lyrics_update: '&onLyricsUpdate',
  },

  templateUrl: 'lyrics_edit_proposal_moderation.html',

  controller: [
    'LyricsEditProposalClient',
    'LyricsEditing',
    'SongClient',
    'Tracking',
    'Pusher',
    'Session',
    function(
      LyricsEditProposalClient,
      LyricsEditing,
      SongClient,
      Tracking,
      Pusher,
      Session
    ) {
      this.$onInit = () => {
        this.load();

        Pusher.subscribe(this.song.pusher_channel, 'lyrics-edits-updated', (data) => {
          if (data.updater_id !== Session.current_user.id) {
            this.load();
          }
        });
      };

      this.$onChanges = (changes) => {
        if (changes.lyrics_html) {
          this.editable = LyricsEditing.prepare_lyrics_for_editing(this.lyrics_html);
        }
      };

      this.show = () => {
        Tracking.track('lyrics_edit_proposals:view_proposals');
        this.showing = true;
      };

      this.close = () => {
        this.showing = false;
        if (_.some(this.lyrics_edit_proposals, '_moderated')) this.load();
      };

      this.load = () => {
        LyricsEditProposalClient.pending({song_id: this.song.id}).
          then(lyrics_edit_proposals => this.lyrics_edit_proposals = lyrics_edit_proposals);
      };

      this.accept = ({editable, lyrics_edit_id}) => {
        const lyrics = LyricsEditing.convert_editable_for_saving(editable);
        return SongClient.save_lyrics(this.song, lyrics, {lyrics_edit_id}).
          then((lyrics_data) => {
            this.on_lyrics_update({lyrics_data});
          });
      };

      this.pending_count = () => (this.lyrics_edit_proposals || []).filter(c => !c._moderated).length;
    },
  ],
});
