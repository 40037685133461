/* eslint-env es6 */

angular.module('genius').directive('onHoverWithNoDigest', [function() {
  return {
    restrict: 'A',

    link(scope, element, attrs) {
      element.hover(() => {
        scope.$eval(attrs.onHoverWithNoDigest, {hover: true});
      }, () => {
        scope.$eval(attrs.onHoverWithNoDigest, {hover: false});
      });
    },
  };
}]);
