/* eslint-env es6 */

angular.module('genius').component('miniArtistCard', {
  bindings: {
    artist: '<object',
    variants: '<',
  },

  templateUrl: 'mini_artist_card.html',
});
