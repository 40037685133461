/* eslint-env es6 */

angular.module('genius').component('profileUserPane', {
  bindings: {
    user: '<',
    on_statistics_select: '&onStatisticsSelect',
  },

  templateUrl: 'profile_user_pane.html',

  controller: ['Tracking', function(Tracking) {
    this.change_state = (state) => {
      this.state = state;
      Tracking.track('Profile Tab Open', {tab_name: state, display_as_artist: false});
    };
  }],
});
