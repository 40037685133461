import 'embedly-player-0.0.10.min';
/* eslint-env es6 */


angular.module('genius').directive('embedlyPlayer', ['$sce', '$immediate', '$q', 'Tracking', 'PlayerJS', function($sce, $immediate, $q, Tracking, PlayerJS) {
  return {
    restrict: 'E',
    templateUrl: 'embedly_player.html',
    transclude: true,

    scope: {
      yieldsApi: '&',
      yieldsState: '&',
      media: '<',
      controls: '<?',
      playerClass: '@',
      preload: '<',
      allowFullScreen: '<?',
    },

    link(scope, element) {
      const fail = () => {
        state.has_errored = true;
      };

      let deferred_player;
      let promised_player;
      let state;

      const init_embed = () => {
        const player = PlayerJS.Player(element.find('iframe')[0]);

        player.on('ready', () => {
          player.setVolume(100);

          deferred_player.resolve(player);

          scope.$apply(() => {
            state.is_loaded = true;
            state.is_loading = false;
          });
        });

        player.on('play', () => {
          Tracking.track('play_song_media', {playing: true, first_interaction: false});
          scope.$apply(() => { state.is_playing = true; });
        });

        player.on('pause', () => {
          scope.$apply(() => { state.is_playing = false; });
        });

        player.on('ended', () => {
          scope.$apply(() => { state.is_playing = false; });
        });

        player.on('timeupdate', _.throttle((data) => {
          scope.$apply(() => {
            state.percentage = data.seconds / data.duration;
            state.time_remaining = (data.duration - data.seconds) * 1000;
          });
        }, 1000));

        player.on('error', () => { scope.$apply(fail); });
      };

      scope.load_media = () => {
        if (state.is_loading || state.is_loaded) return;
        Tracking.track('play_song_media', {playing: false, first_interaction: true});
        state.is_loading = true;
        scope.is_initialized = true;
        $immediate(init_embed, !'invoke_apply');
      };

      const api = {
        load_media: scope.load_media,

        playpause() {
          if (state.is_playing) this.pause();
          else this.play();
        },

        play() {
          promised_player.then((player) => { player.play(); });
        },

        pause() {
          promised_player.then((player) => { player.pause(); });
        },

        seek_to(percentage) {
          promised_player.then((player) => {
            player.getDuration((duration) => {
              player.setCurrentTime(duration * percentage);
            });
          });
        },
      };

      scope.yieldsApi({api});

      const is_private = response => scope.media.provider === 'youtube' && !response.author_name;

      const has_errored = response => response.invalid || response.type === 'error' || response.type === 'link';

      const player_parameters = {
        youtube: {
          youtube_controls: scope.controls ? 2 : 0,
          youtube_modestbranding: 1,
          youtube_showinfo: 0,
          youtube_rel: 0,
          youtube_iv_load_policy: 3,
          youtube_start: scope.media ? scope.media.start : undefined,
          youtube_fs: scope.allowFullScreen ? 1 : 0,
        },
      }[scope.media ? scope.media.provider : undefined];

      const fetch_embed = (options) => {
        const query = _.assign({}, player_parameters, options);

        return $q.resolve($.embedly.oembed(scope.media.url, {query})).then((responses) => {
          const response = responses[0];

          if (is_private(response) || has_errored(response)) {
            if (query.force) {
              return $q.reject(Error('No embed found'));
            } else {
              return fetch_embed({force: true});
            }
          }

          return response;
        });
      };

      scope.$watch('state.is_playing', (is_playing) => {
        if (is_playing) scope.state.has_played = true;
      });

      scope.$watch('media.url', (media) => {
        scope.is_initialized = false;
        deferred_player = $q.defer();
        promised_player = deferred_player.promise;
        state = scope.state = {};
        scope.yieldsState({state});

        if (!media) {
          state.is_ready = true;
          return;
        }

        scope.media_html = null;
        fetch_embed().then((response) => {
          state.placeholder = response.thumbnail_url;
          state.is_ready = true;
          scope.media_html = $sce.trustAsHtml(response.html);
          if (scope.preload) scope.load_media();
        }).catch(fail);
      });

      scope.$on('$destroy', () => {
        scope.yieldsState({state: undefined});
      });
    },
  };
}]);
