/* eslint-env es6 */

angular.module('genius').directive('metadataCustomPerformanceRoleInput', [function() {
  return {
    restrict: 'E',

    templateUrl: 'metadata_custom_performance_role_input.html',

    scope: {
      role_field_placeholder: '@roleFieldPlaceholder',
      artist_field_placeholder: '@artistFieldPlaceholder',
      customPerformanceRoles: '<',
      isDisabled: '<',
      validation_errors: '<validationErrors',
      applicable_to: '@applicableTo',
    },

    controllerAs: 'ctrl',

    controller: ['$scope', function($scope) {
      this.remove_custom_performance = (custom_performance_role) => {
        _.pull($scope.customPerformanceRoles, custom_performance_role);
      };

      this.add_new_custom_performance = () => {
        $scope.customPerformanceRoles.push({label: '', artists: []});
      };

      this.errors_for_label = label => _.get(
        $scope.validation_errors,
        `custom_performances:${label.toLowerCase()}`
      );

      this.autocomplete_options = {
        extraParams: {
          type: $scope.applicable_to,
        },
      };
    }],
  };
}]);
