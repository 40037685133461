/* eslint-env es6 */

angular.module('genius').directive('conversations', ['ConversationClient', 'Attempt', function(ConversationClient, Attempt) {
  return {
    restrict: 'E',

    scope: {
      visible: '<',
      refresh_at: '<refreshAt',
      on_conversation_read: '&onConversationRead',
      on_modal_open: '&onModalOpen',
      on_modal_close: '&onModalClose',
      embed: '<',
      modal_dimensions: '<modalDimensions',
    },

    templateUrl: 'conversations.html',

    controller: ['$scope', function($scope) {
      $scope.attempt_close = Attempt.close_form;

      let last_refreshed, next_page, promised_loading_page;

      const refresh = () => {
        last_refreshed = Date.now();
        $scope.conversations = [];
        this.all_pages_loaded = false;
        next_page = 1;
      };

      refresh();

      this.should_paginate = () =>
        !!$scope.visible &&
        !this.all_pages_loaded &&
        !promised_loading_page;

      this.load_page = () => {
        if (promised_loading_page) return;

        promised_loading_page = ConversationClient.latest({page: next_page}).then((page) => {
          $scope.conversations.push(...page);
          this.all_pages_loaded = !page.length;
          ++next_page;
          promised_loading_page = null;
        });
      };

      $scope.$watchGroup(['visible', 'refresh_at'], () => {
        if (!$scope.visible && $scope.refresh_at && $scope.refresh_at > last_refreshed) refresh();
      });

      this.open = (conversation_id, event) => {
        event.preventDefault();
        $scope.open_conversation_id = conversation_id;
        const conversation = _.find($scope.conversations, {id: conversation_id});
        const was_unread = conversation.unread;
        const unread_messages_count_was = conversation.unread_messages_count;
        conversation.unread = false;
        conversation.unread_messages_count = 0;
        $scope.on_modal_open();
        if (was_unread) {
          $scope.on_conversation_read({
            conversation,
            unread_messages_count: unread_messages_count_was,
          });
        }
      };

      this.close = () => {
        $scope.on_modal_close();
        $scope.open_conversation_id = null;
      };
    }],

    controllerAs: 'ctrl',
  };
}]);
