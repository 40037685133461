/* eslint-env es6 */

angular.module('genius').component('reportAbuse', {
  templateUrl: 'report_abuse.html',

  bindings: {
    type: '@',
    reportable: '<object',
    inline: '<',
    onClick: '&onClick',
    buttonClass: '@',
    buttonText: '@',
  },

  controllerAs: 'ctrl',

  controller: ['AppConfig', 'ReportAbuseClient', 'gRecaptcha', 'Session',
    function(AppConfig, ReportAbuseClient, gRecaptcha, Session) {
      this.loggedIn = Session.user_signed_in;

      if (!this.loggedIn) {
        gRecaptcha.initialize({key: AppConfig.recaptcha_v3_site_key});
      }

      this.reasons = [
        {value: 'Harmful or abusive content', label: 'harmful'},
        {value: 'Hate speech or discrimination', label: 'discrimination'},
        {value: 'Misleading information', label: 'misleading'},
        {value: 'Suspicious or Spam', label: 'suspicious'},
        {value: 'Other', label: 'other'},
      ];

      this.submitForm = (recaptchaToken = null) => new Promise((resolve) => {
        ReportAbuseClient.reportAsAbusive(this.type, this.reportable.id, this.reason, this.details, recaptchaToken).
          then(() => {
            this.show_form = false;
            this.reported_abuse = true;
          }).
          catch(() => {
            this.has_error = true;
          }).
          finally(resolve);
      });

      this.submit = () => {
        if (this.report_abuse_form.$invalid) {
          return;
        }
        this.submitting = true;
        if (this.loggedIn) {
          this.submitForm().
            then(() => {
              this.submitting = false;
            });
        } else {
          gRecaptcha.execute({action: 'report_abuse'}).
            then(this.submitForm).
            then(() => {
              this.submitting = false;
            }).
            catch(() => {
              this.has_error = true;
            });
        }
      };
    }],
});
