/* eslint-env es6 */

angular.module('genius').factory('TagClient', ['$http', 'AppConfig', function($http, AppConfig) {
  return {
    autocomplete(query) {
      return $http.get(`${AppConfig.api_root_url}/tags/autocomplete`, {params: {q: query}}).
        then(response => response.data.response.tags);
    },

    primary() {
      return $http.get(`${AppConfig.api_root_url}/tags/home`).
        then(response => response.data.response.tags);
    },
  };
}]);
