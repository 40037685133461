/* eslint-env es6 */

angular.module('genius').directive('confirm', ['$window', function($window) {
  return {
    priority: -1,

    restrict: 'A',

    scope: {
      message: '@confirm',
    },

    link(scope, element) {
      element.on('click', (e) => {
        if (!scope.message) return;
        if ($window.confirm(scope.message)) return;
        e.stopImmediatePropagation();
        e.preventDefault();
      });
    },
  };
}]);
