/* eslint-env es6 */

angular.module('genius').factory('Filepicker', ['$window', 'AppConfig', '$q', function($window, AppConfig, $q) {
  const filepicker = $window.filepicker;
  filepicker.setKey(AppConfig.filepicker_api_key);

  const DEFAULT_SUPPORTED_MIME_TYPES = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];

  const DEFAULT_OPTIONS = {
    policy: AppConfig.filepicker_policy,
    signature: AppConfig.filepicker_signature,
  };

  const DEFAULT_SERVICES = ['IMAGE_SEARCH', 'COMPUTER', 'URL'];
  const DEFAULT_OPEN_TO = 'IMAGE_SEARCH';

  class Filepicker {
    constructor(options = {}) {
      this.allowed_mime_types = options.allowed_mime_types || DEFAULT_SUPPORTED_MIME_TYPES;
      this.services = options.services || DEFAULT_SERVICES;
      this.open_to = options.open_to || DEFAULT_OPEN_TO;
    }

    allows_mime_type(type) {
      return this.allowed_mime_types.includes((type || '').toLowerCase());
    }

    _store(to_store, options) {
      const filepicker_options = angular.extend(angular.copy(DEFAULT_OPTIONS), options);
      filepicker_options.container = to_store.container;
      const deferred = $q.defer();

      filepicker.store(to_store, filepicker_options,
        blob => deferred.resolve(this._construct_s3_url(blob.key)),
        FPError => deferred.reject(FPError),
        progress => deferred.notify(progress)
      );

      return deferred.promise;
    }

    _read_blob(blob) {
      const deferred = $q.defer();

      const reader = new FileReader();
      reader.onloadend = () => deferred.resolve(reader.result);
      reader.readAsBinaryString(blob);

      return deferred.promise;
    }

    _construct_s3_url(key) {
      if (AppConfig.filepicker_cdn_domain) {
        return `https://${AppConfig.filepicker_cdn_domain}/${encodeURIComponent(key)}`;
      } else {
        return `https://s3.amazonaws.com/${AppConfig.filepicker_s3_image_bucket}/${encodeURIComponent(key)}`;
      }
    }

    store(file, options) {
      if (file instanceof Blob) {
        return this._read_blob(file).
          then(blob_string => this._store(btoa(blob_string), angular.extend(options, {base64decode: true})));
      } else {
        return this._store(file, options);
      }
    }

    pick(options) {
      const deferred = $q.defer();
      const fp_options = angular.extend(
        angular.copy(DEFAULT_OPTIONS),
        {mimetypes: this.allowed_mime_types, services: this.services, openTo: this.open_to},
        options
      );

      filepicker.pick(fp_options, (blob) => {
        filepicker.stat(
          blob.url,
          angular.extend(angular.copy(DEFAULT_OPTIONS), {width: true, height: true}),
          dimensions => deferred.resolve({blob, dimensions})
        );
      });

      return deferred.promise;
    }

    pick_and_store(options, store_options = {}) {
      const deferred = $q.defer();
      const fp_options = angular.extend(
        DEFAULT_OPTIONS,
        {mimetypes: this.allowed_mime_types, services: this.services, openTo: this.open_to},
        options
      );

      store_options.path = [
        AppConfig.filepicker_path,
        store_options.path || Math.random().toString(36).slice(2),
      ].join('/');

      filepicker.pickAndStore(
        fp_options,
        store_options,
        FPFiles => deferred.resolve(this._construct_s3_url(FPFiles[0].key)),
        e => deferred.reject(e)
      );

      return deferred.promise;
    }
  }

  return Filepicker;
}]);
