/* eslint-env es6 */

angular.module('genius').component('hangInitialQuote', {
  templateUrl: 'hang_initial_quote.html',

  bindings: {
    content: '<',
  },

  controller() {
    this.hang_initial_double_quote = this.content[0] === '“';
    this.hang_initial_single_quote = this.content[0] === '‘';
  },
});
