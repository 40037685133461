/* eslint-env es6 */

angular.module('genius').component('albumPage', {
  templateUrl: 'album_page.html',

  controller: Object.assign(['VideoRegistry', 'mobile_device', 'Tracking', 'AlbumClient', 'Session', 'Attempt', 'Reversible', 'SmartRouting', '$q', '$location', '$immediate', function(VideoRegistry, mobile_device, Tracking, AlbumClient, Session, Attempt, Reversible, SmartRouting, $q, $location, $immediate) {
    this.has_permission = action_name => Session.has_permission(action_name, this.page_data.album);
    this.user_signed_in = Session.user_signed_in;
    this.attempt_close = Attempt.close_form;
    this.show_report_abuse = Session.has_feature('report_abuse');

    this.show_question_list = () => this.has_permission('create_question') ||
      this.page_data.pinned_questions.length ||
      (this.page_data.featured_question && this.page_data.featured_question.length) ||
      this.page_data.pending_question_count;

    this.show_qa_section = () => this.show_question_list() ||
      this.page_data.album_metadata_questions.length;

    this.is_released = () => {
      const date_components = this.page_data.album.release_date_components;
      const today = new Date();
      if (today.getFullYear() === date_components.year && date_components.month) {
        if (today.getMonth() + 1 === date_components.month && date_components.day) {
          return today.getDate() >= date_components.day;
        } else {
          return today.getMonth() + 1 >= date_components.month;
        }
      } else {
        return today.getFullYear() >= date_components.year;
      }
    };

    this.close_form = () => {
      this.editing = false;
      this.current_edit_panel = null;
    };

    this.save = (album_update) => {
      this.saving = true;
      const rollback = Reversible.modify(this.page_data.album, album_update);

      return AlbumClient.
        update(this.page_data.album.id, album_update).
        then((updated_album) => {
          Object.assign(this.page_data.album, updated_album);
          this.close_form();
        }).catch((error) => {
          rollback();
          return $q.reject(error);
        }).finally(() => {
          this.saving = false;
        });
    };

    this.$routerCanReuse = (next) => {
      if (next.routeData.data.type === 'album') {
        return `/${next.urlPath}` === this.page_data.path;
      } else if (next.routeData.data.type === 'album_cover_art') {
        return Boolean(cover_art_by_id(next.params.cover_art_id));
      } else {
        return false;
      }
    };

    this.$routerOnReuse = (next) => {
      if (next.routeData.data.type === 'album_cover_art') {
        this.focused_cover_art_id = parseInt(next.params.cover_art_id);
      } else {
        this.focused_cover_art_id = null;
      }
    };

    this.$routerOnActivate = (next) => {
      this.page_data = next.routeData.page_data;
      this.get_leaderboard_page = params => AlbumClient.load_leaderboard(this.page_data.album.id, params);
      VideoRegistry.register_page(this.page_data.album, mobile_device ? 'mobile_album_page' : 'desktop_album_page');
      Tracking.delayed_track('Album Page Load', {
        'Album ID': this.page_data.album.id,
        'counts_as_page_load': true,
      });

      if (next.params.cover_art_id) {
        const cover_art = cover_art_by_id(next.params.cover_art_id);
        if (cover_art) {
          $immediate(() => SmartRouting.navigate_to_absolute_url(cover_art.url));
        }
      }
    };

    this.unfocus_cover_art = () => $location.url(this.page_data.path);

    const cover_art_by_id = (maybe_string_id) => {
      const id = parseInt(maybe_string_id);
      const cover_arts = _.get(this, ['page_data', 'album', 'cover_arts'], []);
      return _.find(cover_arts, ca => ca.id === id);
    };

    this.track_question_link = (clickEvent, metadataQuestion) => {
      const [related_question_slug, related_questionable_type, related_questionable_id] = metadataQuestion.id.split(':');
      Tracking.track_link_click(clickEvent,
        'album:click_question_page',
        {
          related_question_slug,
          related_questionable_type,
          related_questionable_id,
        });
    };
  }], {
    $canActivate: ['$nextInstruction', '$prevInstruction', 'PageTransition', function(
      $nextInstruction,
      $prevInstruction,
      PageTransition
    ) {
      return PageTransition.by_router_instruction($nextInstruction, $prevInstruction);
    }],
  }),
});
