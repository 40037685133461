/* eslint-env es6 */

angular.module('genius').directive('handleRoutableLinks', ['LinkEvents', 'SmartRouting', function(LinkEvents, SmartRouting) {
  return {
    restrict: 'A',

    link(_scope, element) {
      element.on('click', 'a', (event) => {
        const $anchor = $(event.target).closest('a');
        const href = $anchor.attr('href');

        if (event.isDefaultPrevented()) return;
        if (LinkEvents.should_open_in_new_window_or_tab(event, $anchor[0])) return;
        if (!href || href === '#') return;

        event.preventDefault();
        SmartRouting.navigate_to_absolute_url($anchor[0].href);
      });
    },
  };
}]);
