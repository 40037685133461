/* eslint-env es5 */

angular.module('genius').factory('DateTools', [function() {
  return {
    days_to_ms(days) {
      const date = new Date();
      date.setTime(date.getTime() + (1000 * 60 * 60 * 24 * days));
      return date;
    },
  };
}]);
