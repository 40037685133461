/* eslint-env es6 */

angular.module('genius').directive('headerMenuItem', [function() {
  return {
    restrict: 'E',

    transclude: true,

    scope: {
      name: '@',
      url: '@',
    },

    templateUrl: 'header_menu_item.html',
  };
}]);
