/* eslint-env es6 */

angular.module('genius').directive('clippedBackgroundImage', ['$window', 'ImageTransform', function($window, ImageTransform) {
  return {
    link(scope, element, attrs) {
      const snap_to_increment = (num, increment) => Math.ceil(num / increment) * increment;

      scope.$watch(attrs.clippedBackgroundImage, (original) => {
        scope.$applyAsync(() => {
          element.addClass('clipped_background_image');

          let background_image_url = '';
          let size_increment = parseInt(attrs.clippedBackgroundImageWidthIncrement);
          if (!_.isFinite(size_increment)) size_increment = 1;

          if (original) {
            const resolution_multiplier = $window.devicePixelRatio || 1;
            const container_width = snap_to_increment(element.outerWidth(), size_increment) * resolution_multiplier;
            const container_height = snap_to_increment(element.outerHeight(), size_increment) * resolution_multiplier;

            let url;

            if (_.has(attrs, 'clippedBackgroundImageFitHeight') && container_width > container_height) {
              url = ImageTransform.resize(original, {height: container_height});
              element.addClass('clipped_background_image--tiled_horizontally');
            } else {
              url = ImageTransform.resize(original, {
                width: container_width,
                height: element.height() * resolution_multiplier,
              });
            }

            background_image_url = `url('${url}')`;
          }

          element.css('background-image', background_image_url);
        });
      });
    },
  };
}]);
