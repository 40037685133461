/* eslint-env es6 */

angular.module('genius').factory('CommentClient', ['$http', '$q', 'AppConfig', 'Markdown', 'signed_out_created_content', function($http, $q, AppConfig, Markdown, signed_out_created_content) {
  return {
    load(type, id, options) {
      return $http.get(`${AppConfig.api_root_url}/${type}/${id}/comments`, {params: options}).
        then(response => response.data.response);
    },

    destroy(id) {
      return $http.delete(`${AppConfig.api_root_url}/comments/${id}`);
    },

    create(type, id, new_comment) {
      new_comment._deleted = false;

      const save_comment = _.assign({}, new_comment, {
        body: new_comment.body.markdown,
        reason: new_comment.reason ? new_comment.reason.raw_name : undefined,
      });

      new_comment.body.html = Markdown.to_html(save_comment.body);

      return $http.post(`${AppConfig.api_root_url}/${type}/${id}/comments?text_format=html`, {comment: save_comment}).
        then((response) => {
          _.assign(new_comment, response.data.response.comment);
          signed_out_created_content.add('comments', new_comment.id);
          if (new_comment.anonymous_author) {
            new_comment.anonymous_author.email = new_comment.email;
          }
          return new_comment;
        }).catch(() => {
          new_comment._deleted = true;
          return $q.reject();
        });
    },

    change_state(id, state) {
      const data = {state_event: state};
      return $http.put(`${AppConfig.api_root_url}/comments/${id}/change_state`, data);
    },
  };
}]);
