/* eslint-env es6 */

angular.module('genius').directive('songMetadataPreview', [function() {
  return {
    templateUrl: 'song_metadata_preview.html',

    restrict: 'E',

    scope: {
      song: '<',
    },

    controllerAs: 'ctrl',
  };
}]);
