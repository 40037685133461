/* eslint-env es6 */

angular.module('genius').directive('searchForm', ['Tracking', '$location', 'SearchClient', '$immediate', 'PageTransition', function(Tracking, $location, SearchClient, $immediate, PageTransition) {
  return {
    restrict: 'E',

    templateUrl: 'search_form.html',

    scope: {
      style: '@searchStyle',
    },

    link: (scope, element) => {
      const $input = element.find('input.quick_search');

      scope.unfocus = () => {
        if (!scope.focused) return;

        Tracking.track('Search Cancel');
        scope.focused = false;
        scope.search_text = '';
      };

      scope.focus = () => {
        if (scope.focused) return;

        Tracking.track('Search Page View', {'counts_as_page_load': true});
        scope.focused = true;
        $input.focus();
      };

      PageTransition.on_client_transition_complete(() => {
        scope.focused = false;
        scope.search_text = '';
      });

      Rx.Observable.$watch(scope, 'search_text').
        auditTime(500).
        switchMap(search_text => (
          search_text
            ? Rx.Observable.fromPromise(SearchClient.multi(search_text))
            : Rx.Observable.of(null))
        ).subscribe((sections) => {
          $immediate(() => scope.sections = sections);
        });

      scope.submit_form = () => {
        if (!scope.focused || !scope.search_text) return;
        element.find('form').submit();
      };

      if ($location.path() === '/gastly') {
        scope.search_text = $location.search().q;
      }
    },
  };
}]);
