/* eslint-env es6 */

angular.module('genius').directive('factTrackSimulator', [function() {
  return {
    restrict: 'E',
    templateUrl: 'fact_track_simulator.html',

    scope: {
      annotations: '<',
      track_uuid: '<trackUuid',
      time: '=?',
      spotify_song: '<song',
      yields_api: '&yieldsApi',
      variants: '<',
      current_annotation_index: '=?currentAnnotationIndex',
      annotation_clicked: '&annotationClicked',
      autoplay: '<',
    },

    controllerAs: 'ctrl',

    controller: ['$scope', 'SpotifyPlayer', 'WebAudioPlayer', function($scope, SpotifyPlayer, WebAudioPlayer) {
      let current_player_type = $scope.spotify_song.track_url ? 'web_audio' : 'spotify';

      const track_uri = `spotify:track:${$scope.track_uuid}`;
      const players = {};
      const current_player = () => players[current_player_type];

      players.spotify = new SpotifyPlayer((state) => {
        if (current_player_type !== 'spotify') return;
        $scope.time = state.position;
        $scope.playing = state.is_playing;
        $scope.track_uri = state.track_uri;
      });

      if ($scope.spotify_song.track_url) {
        players.web_audio = new WebAudioPlayer((state) => {
          if (current_player_type !== 'web_audio') return;
          $scope.time = state.position;
          $scope.playing = state.is_playing;
          $scope.duration = state.duration;
        });
      }

      this.annotation_percentage = () => {
        if (!$scope.current_annotation) return 0;
        const this_annotation_time = $scope.current_annotation.timestamp_in_ms;
        const next_annotation = $scope.annotations[$scope.current_annotation_index + 1];
        const next_annotation_time = next_annotation ? next_annotation.timestamp_in_ms : $scope.duration;
        return ((next_annotation_time - $scope.time) / (next_annotation_time - this_annotation_time));
      };

      this.seek_to = (time) => {
        current_player().seek(time);
      };

      this.seek_to_percentage = (percentage) => {
        current_player().seek(Math.round(percentage * $scope.duration));
      };

      this.toggle_play_pause = () => {
        if ($scope.playing) {
          current_player().pause();
        } else if (current_player_type === 'spotify') {
          players.spotify.play(track_uri);
        } else {
          players.web_audio.play($scope.spotify_song.track_url);
        }
      };

      this.card_clicked = () => {
        $scope.annotation_clicked({current_annotation: $scope.current_annotation});
      };

      const api = {
        playback_type() {
          return current_player().playback_type;
        },

        playback_label() {
          return current_player().playback_label;
        },

        set_playback_options({type}) {
          current_player_type = type;
        },

        other_track_playing() {
        },

        use_current_track() {
        },

        pause() {
          if ($scope.playing) current_player().pause();
        },

        seek_to(time, options) {
          current_player().seek(time, options);
        },

        print_player_log() {
          current_player().print_log_entries();
        },

        download_player_log() {
          current_player().download_log_entries();
        },
      };

      $scope.time = 0;
      $scope.duration = _.get($scope.spotify_song, ['metadata', 'duration_ms'], 0);
      $scope.album = _.get($scope.spotify_song, ['metadata', 'album'], {});
      $scope.yields_api({api});

      const default_annotation = {content_type: 'intro', timestamp_in_ms: 0};
      $scope.$watch('time', () => {
        const filtered_annotations = $scope.annotations.filter(annotation => annotation.timestamp_in_ms <= $scope.time);
        $scope.current_annotation_index = filtered_annotations.length - 1;
        $scope.current_annotation = $scope.annotations[$scope.current_annotation_index] || default_annotation;
      });
    }],
  };
}]);
