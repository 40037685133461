/* eslint-env es6 */

angular.module('genius').factory('PagedDataFeed', [function() {
  return class {
    constructor(get_data, model_key, params = {}, starting_page_number = 1, callback = _.noop) {
      this.next_page = starting_page_number;
      this.params = _.clone(params);

      this.next = () => get_data(_.assign({page: this.next_page}, this.params)).
        then((data) => {
          this.next_page = data.next_page;
          this.total_entries = data.total_entries;
          callback(data[model_key]);
          return data[model_key];
        });

      this.has_next = () => this.next_page;

      this.reset = () => {
        this.next_page = 1;
      };
    }
  };
}]);
