/* eslint-env es6 */

angular.module('genius').component('videoEditForm', {
  templateUrl: 'video_edit_form.html',

  bindings: {
    video: '<object',
  },

  controller: ['Session', 'VideoSourcesClient', function(Session, VideoSourcesClient) {
    this.$onChanges = (changes) => {
      if (changes.video) {
        this.loaded = false;
        VideoSourcesClient.index(this.video.id).then((video_sources) => {
          this.video_sources = video_sources;
          this.loaded = true;
        });
      }
    };

    this.add_video_source = () => {
      this.error = null;
      VideoSourcesClient.create(this.video.id, this.new_source).then((video_source) => {
        this.video_sources.push(video_source);
        this.new_source = {};
      }).catch((error) => {
        this.error = error;
      });
    };

    this.remove_video_source = (video_source) => {
      this.error = null;
      VideoSourcesClient.destroy(video_source.id).then(() => {
        _.remove(this.video_sources, video_source);
      }).catch((error) => {
        this.error = error;
      });
    };

    this.has_permission = Session.has_permission;
  }],
});
