/* eslint-env es6 */

angular.module('genius').component('inboxIcon', {
  bindings: {
    inbox_name: '@inboxName',
    opened: '<',
    sync_count_to_title: '<syncCountToTitle',
    abbreviate_after: '<abbreviateAfter',
    yields_api: '&yieldsApi',
  },

  templateUrl: 'inbox_icon.html',

  controller: ['Session', 'TopBarIndicatorsLoader', 'WindowTitle', 'InboxClient', function(Session, TopBarIndicatorsLoader, WindowTitle, InboxClient) {
    const update_unread_count = (count) => {
      this.unread_count = count;
      if (this.sync_count_to_title) {
        WindowTitle.update_count(this.unread_count);
      }
    };

    this.$onInit = () => {
      update_unread_count(Session.current_user[`unread_${this.inbox_name}_count`]);

      TopBarIndicatorsLoader.listen_for(this.inbox_name, update_unread_count);

      InboxClient.on_update(this.inbox_name, TopBarIndicatorsLoader.refresh);

      this.yields_api({api: {
        decrement: (count) => {
          update_unread_count(this.unread_count - count);
        },
        clear() {
          update_unread_count(0);
        },
      }});
    };
  }],
});
