/* eslint-env es6 */

angular.module('genius').factory('HtmlConstants', ['$window', function($window) {
  const html = {
    top_level_block_containers: $window.TOP_LEVEL_BLOCK_CONTAINERS,
    top_level_stand_alone_blocks: $window.TOP_LEVEL_STANDALONE_BLOCKS,
  };

  html.top_level_block_containers_regexp = new RegExp(`</(${html.top_level_block_containers.join('|')})>\\s*`, 'gi');
  html.top_level_stand_alone_blocks_regexp = new RegExp(`</(${html.top_level_stand_alone_blocks.join('|')})>\\s*`, 'gi');

  return html;
}]);
