/* eslint-env es6 */

angular.module('genius').component('manageChartItem', {
  bindings: {
    type: '<',
    id: '<',
  },

  templateUrl: 'manage_chart_item.html',

  controller: ['ChartClient', function(ChartClient) {
    this.$onInit = () => {
      this.loading = true;
      ChartClient.state(this.type, this.id).then((response) => {
        this.excluded = response.excluded;
        this.loading = false;
      });
    };

    this.toggle_excluded = () => {
      this.loading = true;
      const toggle_promise = this.excluded ?
        ChartClient.unexclude(this.type, this.id).then(() => this.excluded = false) :
        ChartClient.exclude(this.type, this.id).then(() => this.excluded = true);
      toggle_promise.finally(() => this.loading = false);
    };
  }],
});
