/* eslint-env es6 */

angular.module('genius').directive('conversationWithUser', ['ConversationClient', function(ConversationClient) {
  return {
    restrict: 'E',

    scope: {
      user: '<',
      yields_is_dirty: '&yieldsIsDirty',
    },

    template: `
      <conversation-messages
        conversation="conversation"
        ng-if="conversation"
        yields-is-dirty="yields_is_dirty({is_dirty: is_dirty})"
      ></conversation-messages>
    `,

    controller: ['$scope', function($scope) {
      ConversationClient.with_user($scope.user.id).then((conversation) => {
        $scope.conversation = conversation;
      });
    }],
  };
}]);
