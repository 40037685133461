/* eslint-env es6 */

angular.module('genius').directive('insertableAtCursor', ['$window', function($window) {
  return {
    restrict: 'A',

    link(scope, element, attrs) {
      const insert = ({before = '', after = '', default_body = '', each_line = false}) => {
        _.toArray(element).forEach((input) => {
          const bodyScrollTop = pageYOffset;
          const startPos = input.selectionStart;
          const endPos = input.selectionEnd;
          const scrollTop = input.scrollTop;
          const selection = input.value.substring(startPos, endPos);
          let wrapped_selection;

          if (selection && each_line) {
            wrapped_selection = _.map(selection.split('\n'), line => before + line + after).join('\n');
          } else if (selection) {
            wrapped_selection = before + selection + after;
          } else {
            wrapped_selection = before + default_body + after;
          }

          input.value =
            input.value.substring(0, startPos) +
            wrapped_selection +
            input.value.substring(endPos, input.value.length);
          input.focus();
          input.selectionStart = startPos;
          input.selectionEnd = input.selectionStart + wrapped_selection.length;
          input.scrollTop = scrollTop;

          $window.scrollTo(0, bodyScrollTop);
          $(input).trigger('input');
        });
      };

      scope.$eval(attrs.insertableAtCursor, {insertFn: insert});
    },
  };
}]);
