/* eslint-env es6 */

angular.module('genius').directive('scrollableData', ['Bindings', function(Bindings) {
  const resolve_bindings = Bindings.resolver({
    src: '<',
    preload: '&',
    empty_message: '@emptyMessage',
    scroll_mechanism: '@scrollMechanism',
    repeat_with: '@repeatWith',
    button_classes: '@buttonClasses',
    container_classes: '@containerClasses',
    item_classes: '@itemClasses',
    unique_by: '@uniqueBy',
    start_collapsed: '<startCollapsed',
    on_show_more: '&onShowMore',
    on_scroll_complete: '&onScrollComplete',
    on_empty: '&onEmpty',
  });

  return {
    restrict: 'E',
    templateUrl: 'scrollable_data.html',

    transclude: {
      empty: '?empty',
      show_more: '?showMore',
    },

    scope: true,

    controller: ['$scope', '$attrs', function($scope, $attrs) {
      const bindings = resolve_bindings($scope, $attrs);

      bindings.assign_to(this);

      $scope.$watch('$scrollable_data_ctrl.src', () => {
        if (this.auto_reset) {
          this.src.reset();
        }
        this.reset();
      });

      this.get_preloaded_data = () => this.preload() || [];

      this.reset = () => {
        const preloaded_data = this.get_preloaded_data();
        this.models = _.clone(preloaded_data);
        this.current_loading_page = null;
        this.loading = false;
        if (this.has_next() && !preloaded_data.length && !this.start_collapsed) {
          this.load_next();
        }
      };

      this.has_next = () => {
        if (typeof(this.src.next_page) === 'undefined') {
          return this.src.has_next();
        } else {
          return this.src.next_page > this.current_loading_page;
        }
      };

      this.load_next = () => {
        if (this.loading || !this.has_next()) return;

        this.loading = true;
        this.current_loading_page = this.src.next_page;
        this.on_show_more();
        const current_models = this.models;
        return this.src.next().then((models) => {
          current_models.push(...models);
        }).then(() => {
          if (this.unique_by) {
            this.models = _.uniqBy(this.models, this.unique_by);
          }
          this.loading = false;
          if (this.models.length === 0 && !this.has_next()) {
            this.on_empty();
          }

          if (this.more_loaded_callback) {
            this.more_loaded_callback();
          }
        });
      };

      $scope.$watch('$scrollable_data_ctrl.src.has_next()', (new_value) => {
        if (!new_value) {
          this.on_scroll_complete();
        }
      });
    }],

    controllerAs: '$scrollable_data_ctrl',
  };
}]);
