/* eslint-env es6 */

angular.module('genius').component('searchResultSection', {
  bindings: {
    section: '<',
    on_show_more: '&onShowMore',
    limit_to: '<limitTo',
    variants: '<',
  },

  templateUrl: 'search_result_section.html',
});
