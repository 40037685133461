/* eslint-env es6 */

const PADDING_AMOUNT = 3;
const EXPAND_AMOUNT = 10;

angular.module('genius').component('expandableDiff', {
  bindings: {
    html: '<',
  },

  templateUrl: 'expandable_diff.html',

  controller() {
    this.$onInit = () => {
      this.expand_amount = EXPAND_AMOUNT;
    };

    this.$onChanges = (changes) => {
      if (changes.html) {
        this.lines = _.split(this.html, '<br>');
        calculate_affected_ranges();
        pad_ranges();
        consolidate_ranges();
      }
    };

    const calculate_affected_ranges = () => {
      this.ranges = [];
      const stack = [];
      let range_start = null;
      this.lines.forEach((line, i) => {
        const tags = line.match(/(<ins|<del|<\/ins>|<\/del>)/g) || [];

        const has_diff = (stack.length || tags.length) &&
          !_.startsWith(line, '</ins>') &&
          !_.startsWith(line, '</del>');
        if (has_diff && _.isNull(range_start)) {
          range_start = i;
        } else if (!has_diff && !_.isNull(range_start)) {
          this.ranges.push({start: range_start, end: i});
          range_start = null;
        }

        tags.forEach((tag) => {
          if (tag === '<ins' || tag === '<del') {
            stack.push(tag);
          } else {
            stack.pop();
          }
        });
      });
      if (!_.isNull(range_start)) {
        this.ranges.push({start: range_start, end: this.lines.length});
      }
    };

    const pad_ranges = (padding = PADDING_AMOUNT) => {
      this.ranges.forEach((range) => {
        range.start = Math.max(range.start - padding, 0);
        range.end = Math.min(range.end + padding, this.lines.length);
      });
    };

    const consolidate_ranges = () => {
      let i = 0;
      while (i < this.ranges.length - 1) {
        if (this.ranges[i].end >= this.ranges[i + 1].start) {
          this.ranges[i].end = this.ranges[i + 1].end;
          _.pullAt(this.ranges, i + 1);
        } else {
          i++;
        }
      }
    };

    this.expand_range_start = (index, expand = EXPAND_AMOUNT) => {
      this.ranges[index].start = Math.max(this.ranges[index].start - expand, 0);
      consolidate_ranges();
    };

    this.expand_range_end = (index, expand = EXPAND_AMOUNT) => {
      this.ranges[index].end = Math.min(this.ranges[index].end + expand, this.lines.length);
      consolidate_ranges();
    };

    this.render_lines = (start, end) => _.join(_.slice(this.lines, start, end), '<br>');
  },
});
