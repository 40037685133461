/* eslint-env es6 */

angular.module('genius').component('songStoryGalleryPage', {
  templateUrl: 'song_story_gallery_page.html',

  controller: Object.assign(['Tracking', 'Session', function(Tracking) {
    this.$routerOnActivate = (next) => {
      this.page_data = next.routeData.page_data;
      Tracking.track('song_stories:gallery_page_view');
    };
  }], {
    $canActivate: ['$nextInstruction', '$prevInstruction', 'PageTransition', function(
      $nextInstruction,
      $prevInstruction,
      PageTransition
    ) {
      return PageTransition.by_router_instruction($nextInstruction, $prevInstruction);
    }],
  }),
});
