/* eslint-env es6 */

angular.module('genius').component('searchResultPaginatedSection', {
  bindings: {
    section_type: '@sectionType',
    search_text: '@searchText',
    on_load_next: '&onLoadNext',
  },

  templateUrl: 'search_result_paginated_section.html',

  controller: ['PagedDataFeed', 'SearchClient', function(PagedDataFeed, SearchClient) {
    this.$onChanges = (changes) => {
      if (changes.section_type || changes.search_text) {
        this.infinite_scroll_data = new PagedDataFeed(
          _.partial(SearchClient.section, this.section_type, this.search_text),
          'hits',
          {},
          1,
          this.on_load_next
        );
      }
    };
  }],
});
