/* eslint-env es6 */

angular.module('genius').component('contactButton', {
  templateUrl: 'contact_button.html',

  controller: ['FeedbackClient', function(FeedbackClient) {
    this.submit = () => {
      this.sending = true;
      FeedbackClient.post_feedback(this.feedback_body).
        then(() => this.success = true).
        finally(() => this.sending = false);
    };

    this.close = () => {
      this.open_contact_form = false;
      this.success = false;
    };
  }],
});
