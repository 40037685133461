/* eslint-env es6 */

angular.module('genius').factory('TopBarIndicatorsLoader', ['EagerLoader', '$http', 'Session', function(EagerLoader, $http, Session) {
  const loader = new EagerLoader();

  const refresh = () => {
    if (!Session.user_signed_in) return;

    $http.get('/top_bar_indicators', {retries: 2}).then((response) => {
      for (const key in response.data) {
        loader.loaded(key, response.data[key]);
      }
    });
  };

  refresh();

  return {
    listen_for: loader.listen_for.bind(loader),
    refresh,
  };
}]);
