/* eslint-env es5 */
/* eslint-disable arrow-body-style, arrow-parens, arrow-spacing, constructor-super, generator-star-spacing, no-class-assign, no-confusing-arrow, no-const-assign, no-dupe-class-members, no-duplicate-imports, no-extra-bind, no-new-symbol, no-restricted-imports, no-this-before-super, no-useless-computed-key, no-useless-constructor, no-useless-rename, no-var, object-shorthand, prefer-arrow-callback, prefer-const, prefer-numeric-literals, prefer-reflect, prefer-rest-params, prefer-spread, prefer-template, require-yield, rest-spread-spacing, sort-imports, symbol-description, template-curly-spacing, yield-star-spacing, dot-location, key-spacing, max-len, new-parens, no-lonely-if, no-mixed-operators, no-trailing-spaces, no-whitespace-before-property, padded-blocks, quotes, space-infix-ops */

angular.module('genius').directive('upwardsInfiniteScroll', ['$throttle', function($throttle) {
  return {
    restrict: 'A',

    link: function(scope, element, attrs) {
      var expr = attrs.upwardsInfiniteScroll;

      var pagination_disabled = false;

      if (attrs.infiniteScrollDisabled) {
        scope.$watch(attrs.infiniteScrollDisabled, function(disabled) {
          pagination_disabled = disabled;
          check_position_and_paginate();
        });
      }

      var check_position_and_paginate = function() {
        if (pagination_disabled) return;

        if (element.scrollTop() < element.height() * 0.1) {
          scope.$eval(expr);
        }
      };

      check_position_and_paginate();
      element.on(
        'scroll',
        $throttle(check_position_and_paginate, 250, {leading: false})
      );
    },
  };
}]);
