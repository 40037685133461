window.JSONStore = {
  get_object: function(key) {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (_) {
      return null;
    }
  },

  set_object: function(key, object) {
    var data = JSON.stringify(object);
    try {
      localStorage.setItem(key, data);
    } catch (_) {}
  },

  delete: function(key) {
    try {
      localStorage.removeItem(key);
    } catch (_) {}
  },

  modify_store: function(key, update) {
    this.set_object(key, update(this.get_object(key)));
  },
};
