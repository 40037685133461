/* eslint-env es6 */

angular.module('genius').component('articleIndexArrangeable', {
  bindings: {
    articles: '<',
  },

  templateUrl: 'article_index_arrangeable.html',

  controller: ['ArticleClient', 'BeforeUnloadCheck', '$scope', function(ArticleClient, BeforeUnloadCheck, $scope) {
    const top_slots = [
      'home_top_hero',
      'home_mobile_desktop_first',
      'home_promo_unit',
      'home_desktop_second',
      'home_desktop_third',
    ];
    const video_slots = ['home_video_hero'];
    const arrange_modes = {
      locked: 'locked',
      active: 'active',
      saving: 'saving',
    };

    this.$onInit = () => {
      this.slot_sections = {
        top: {
          name: 'Top Section',
          slots: top_slots,
          error: null,
        },
        video: {
          name: 'Video Section',
          slots: video_slots,
          error: null,
        },
      };

      this.arrange_mode = arrange_modes.locked;
      this.dirty = false;
    };

    const check = () => this.dirty;

    BeforeUnloadCheck.add(check);

    $scope.$on('$destroy', () => BeforeUnloadCheck.remove(check));

    const section_for_slot = slot => _.find(this.slot_sections, section => section.slots.includes(slot));

    const next_slot_and_article = (current_slot, slots) => {
      let next_slot_index = slots.indexOf(current_slot) + 1;

      if (next_slot_index === slots.indexOf('home_promo_unit')) {
        next_slot_index++;
      }

      const next_slot = slots[next_slot_index] || null;

      return {
        next_slot,
        next_article: this.article_for_slot(next_slot),
      };
    };

    const shift_articles_down = (slot) => {
      let shifting_article = this.article_for_slot(slot);
      const {slots} = section_for_slot(slot);

      while (shifting_article) {
        const {next_slot, next_article} = next_slot_and_article(slot, slots);
        shifting_article.featured_slot = next_slot;
        shifting_article = next_article;
        slot = next_slot;
      }
    };

    const unfeature = article => article.featured_slot = null;

    this.arrange_mode_locked = () => this.arrange_mode === arrange_modes.locked;

    this.arrange_mode_active = () => this.arrange_mode === arrange_modes.active;

    this.arrange_mode_saving = () => this.arrange_mode === arrange_modes.saving;

    const clear_errors = () => {
      this.error = null;
      this.slot_sections.video.error = null;
      this.slot_sections.top.error = null;
    };

    const validate_article_placement = (article, slot) => {
      clear_errors();
      if (slot === 'home_video_hero' && article.article_type !== 'video') {
        this.slot_sections.video.error = 'only video articles can be placed in this slot';
        return false;
      }

      return true;
    };

    this.place_article_into_slot = (article_id, slot) => {
      const article = this.articles.find(a => a.id === article_id);

      if (!validate_article_placement(article, slot)) {
        return;
      }

      unfeature(article);

      const article_in_slot = this.article_for_slot(slot);

      if (article_in_slot && article_in_slot.id !== article.id) {
        shift_articles_down(slot);
      }

      article.featured_slot = slot;
      this.dirty = true;
    };

    this.clear_slot = (slot) => {
      unfeature(this.article_for_slot(slot));
      this.dirty = true;
    };

    this.article_for_slot = slot => this.articles.find(a => a.featured_slot && a.featured_slot === slot);

    this.unfeatured_articles = () => this.articles.filter(a => !a.featured_slot);

    this.slot_holder_text = (position, slot) => (slot === 'home_promo_unit') ?
      'home promotional unit' : `slot ${position}, ${section_for_slot(slot).name}`;

    this.enable_rearranging = () => {
      this.previous_arrangement = _.cloneDeep(this.articles);

      this.arrange_mode = arrange_modes.active;
    };

    this.cancel_rearrange = () => {
      clear_errors();
      this.articles = this.previous_arrangement;
      this.arrange_mode = arrange_modes.locked;
      this.dirty = false;
    };

    const featured_slot_by_article_id = () => this.articles.reduce((result, article) => {
      if (article.featured_slot) {
        result[article.id] = article.featured_slot;
      }
      return result;
    }, {});

    const arrangement_has_gaps = () => {
      let seen_blank_slot = false;

      for (let i = 0; i < top_slots.length; i++) {
        const slot = top_slots[i];
        const slot_occupied = slot === 'home_promo_unit' || Boolean(this.article_for_slot(slot));

        if (seen_blank_slot && slot_occupied) {
          return true;
        } else if (!slot_occupied) {
          seen_blank_slot = true;
        }
      }

      return false;
    };

    this.save_arrangement = () => {
      this.arrange_mode = arrange_modes.saving;
      clear_errors();

      if (arrangement_has_gaps()) {
        this.error = 'Saving cannot complete due to gaps in arrangement order';
        this.arrange_mode = arrange_modes.active;
        return;
      }

      ArticleClient.update_featured_slots(featured_slot_by_article_id()).
        then(() => {
          this.arrange_mode = arrange_modes.locked;
          this.dirty = false;
        }).catch((error) => {
          this.arrange_mode = arrange_modes.active;
          this.error = `${error.message}: ${_.join(error.response.errors, ', ')}`;
        });
    };
  }],
});
