/* eslint-env es6 */

angular.module('genius').factory('Librato', ['$http', '$q', 'AnalyticsConfig', 'SessionConfig', function($http, $q, AnalyticsConfig, SessionConfig) {
  return {
    set_page_type(page_type) {
      this.page_type = page_type;
    },

    measure(name, value, {log} = {log: true}) {
      if (log && _.get(SessionConfig, 'log_client_metrics')) {
        console.log(`LIBRATO METRIC: ${name} = ${value}`); // eslint-disable-line no-console
      }

      if (!_.get(AnalyticsConfig, 'enabled')) return $q.when();

      return $http.post(
        `https://${AnalyticsConfig.librato_web_client_host}/v1/metrics`,
        {gauges: [{name, value, source: `${this.page_type}_page`}]}
      );
    },
  };
}]);
