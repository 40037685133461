/* eslint-env es6 */

angular.module('genius').component('artistVerification', {
  templateUrl: 'artist_verification.html',

  bindings: {
    user: '<',
    artist: '<',
    close: '&',
  },

  controller: ['UserClient', 'AppConfig', function(UserClient, AppConfig) {
    this.name = _.get(this.artist, 'name');
    this.role = this.user.verification_role;
    this.user_verification_roles = AppConfig.user_verification_roles;

    this.updating = () => this.verifying || this.unverifying;

    this.verify = () => {
      if (this.updating()) return;
      this.verifying = true;
      this.errors = null;

      UserClient.associate_with_artist(this.user.id, {
        name: this.name,
        role: this.role,
      }).then((data) => {
        _.assign(this.user, data.user);
        this.form.$setPristine();
        window.location.href = this.user.url;
      }).catch((error) => {
        this.errors = error.errors;
        this.verifying = false;
      });
    };

    this.unverify = () => {
      if (this.updating()) return;
      this.unverifying = true;
      this.errors = null;

      UserClient.dissociate_from_artist(this.user.id).then((data) => {
        _.assign(this.user, data.user);
        this.form.$setPristine();
        window.location.href = this.user.url;
      }).catch((error) => {
        this.errors = error.errors;
        this.unverifying = false;
      });
    };
  }],
});
