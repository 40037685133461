/* eslint-env es6 */

angular.module('genius').component('profileContributionsAnnotations', {
  templateUrl: 'profile_contributions_annotations.html',

  bindings: {
    user: '<',
    contributions_group: '<contributionsGroup',
  },

  controller() {
    this.$onInit = () => {
      this.limit = 1;
    };

    this.$onChanges = (changes) => {
      if (changes.contributions_group) {
        this.annotations = this.contributions_group.contributions;
      }
    };
  },
});
