/* eslint-env es6 */

angular.module('genius').directive('songActivity', ['SongClient', function(SongClient) {
  return {
    templateUrl: 'song_activity.html',
    restrict: 'E',

    scope: {
      song: '<',
    },

    controllerAs: 'ctrl',

    controller: ['$scope', function($scope) {
      let page = 0;
      let total = 0;

      this.loading_contributors = true;
      this.loading_iq_earners = true;

      this.load_contributors = () => {
        SongClient.load_contributor_data($scope.song.id).then((contributors) => {
          total += contributors.iq_earners.length;
          $scope.contributors = contributors;
          $scope.has_more_iq_earners = total < contributors.iq_earners_total;
          page += 1;
        }).finally(() => {
          $scope.ctrl.loading_contributors = false;
          $scope.ctrl.loading_iq_earners = false;
        });
      };

      this.load_more_iq_earners = () => {
        if ($scope.loading_contributors) return;
        this.loading_iq_earners = true;
        SongClient.load_iq_earners($scope.song.id, {page}).then((contributors) => {
          total += contributors.iq_earners.length;
          $scope.contributors.iq_earners = _.uniqBy($scope.contributors.iq_earners.concat(contributors.iq_earners), 'user.id');
          $scope.has_more_iq_earners = total < contributors.iq_earners_total;
          page += 1;
        }).finally(() => {
          $scope.ctrl.loading_iq_earners = false;
        });
      };

      this.load_contributors();
    }],
  };
}]);
