/* eslint-env es6 */

angular.module('genius').component('searchResultItems', {
  bindings: {
    results: '<',
    limit_to: '<limitTo',
  },

  templateUrl: 'search_result_items.html',
});
