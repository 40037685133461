/* eslint-env es5 */
/* eslint-disable arrow-body-style, arrow-parens, arrow-spacing, constructor-super, generator-star-spacing, no-class-assign, no-confusing-arrow, no-const-assign, no-dupe-class-members, no-duplicate-imports, no-extra-bind, no-new-symbol, no-restricted-imports, no-this-before-super, no-useless-computed-key, no-useless-constructor, no-useless-rename, no-var, object-shorthand, prefer-arrow-callback, prefer-const, prefer-numeric-literals, prefer-reflect, prefer-rest-params, prefer-spread, prefer-template, require-yield, rest-spread-spacing, sort-imports, symbol-description, template-curly-spacing, yield-star-spacing, dot-location, key-spacing, max-len, new-parens, no-lonely-if, no-mixed-operators, no-trailing-spaces, no-whitespace-before-property, padded-blocks, quotes, space-infix-ops */

angular.module('genius').component('songVerifiedArtists', {
  bindings: {
    verified_contributors: '<verifiedContributors',
  },

  templateUrl: 'song_verified_artists.html',

  controller: ['$scope', function($scope) {
    $scope.$watch('$ctrl.verified_contributors', function(verified_contributors) {
      if (verified_contributors.length === 1) {
        this.featured = true;
      }

      this.commentary_and_lyrics_by = _.filter(verified_contributors, function(contributor) {
        return _.includes(contributor.contributions, 'lyrics') &&
          (_.includes(contributor.contributions, 'annotations') || _.includes(contributor.contributions, 'answers'));
      });
      this.commentary_by = _.filter(verified_contributors, function(contributor) {
        return !_.includes(contributor.contributions, 'lyrics') &&
          (_.includes(contributor.contributions, 'annotations') || _.includes(contributor.contributions, 'answers'));
      });
      this.lyrics_by = _.filter(verified_contributors, function(contributor) {
        return _.includes(contributor.contributions, 'lyrics') &&
          !(_.includes(contributor.contributions, 'annotations') || _.includes(contributor.contributions, 'answers'));
      });

      this.lyrics_only = this.lyrics_by.length && !(this.commentary_and_lyrics_by.length || this.commentary_by.length);
    }.bind(this));
  }],
});
