/* eslint-env es6 */

angular.module('genius').component('inboxContainer', {
  bindings: {
    inbox_name: '@inboxName',
    open_inboxes: '<openInboxes',
    sync_count_to_title: '<syncCountToTitle',
    abbreviate_after: '<abbreviateAfter',
    use_messages_inbox: '<useMessagesInbox',
    use_iq_inbox: '<useIqInbox',
  },

  templateUrl: 'inbox_container.html',
});
