/* eslint-env es6 */

angular.module('genius').directive('webAnnotatorRoleRow', [function() {
  return {
    templateUrl: 'web_annotator_role_row.html',

    scope: {
      on_create: '&onCreate',
      on_error: '&onError',
      user_role: '<userRole',
    },

    bindToController: true,

    controllerAs: '$ctrl',

    controller: ['WebAnnotatorClient', '$q', function(WebAnnotatorClient, $q) {
      const user_role_params = () => _.pick(this.user_role, [
        'user_login', 'role', 'page_moderator', 'auto_feature',
      ]);

      this.$onInit = function() {
        this.editing = !this.user_role.id;
      };

      this.create = () => {
        this.saving = true;
        $q.when(this.on_create({
          params: user_role_params(),
        })).then(() => {
          this.user_role = {auto_feature: true};
        }).catch((error) => {
          this.on_error({error});
        }).finally(() => {
          this.saving = false;
        });
      };

      this.save = () => {
        this.saving = true;
        WebAnnotatorClient.update_user_role(
          this.user_role.id,
          user_role_params()
        ).then((user_role) => {
          this.user_role = user_role;
          this.editing = false;
        }).catch((error) => {
          this.on_error({error});
        }).finally(() => {
          this.saving = false;
        });
      };

      this.destroy = () => {
        this.user_role._deleted = true;
        WebAnnotatorClient.destroy_user_role(
          this.user_role.id
        ).catch((error) => {
          this.on_error({error});
          this.user_role._deleted = false;
        });
      };
    }],
  };
}]);
