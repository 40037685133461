/* eslint-env es6 */

angular.module('genius').component('annotationPreview', {
  bindings: {
    annotation_preview: '<annotationPreview',
    click_handler: '&clickHandler',
    dismissal_handler: '&dismissalHandler',
    reactivation_handler: '&reactivationHandler',
    is_dismissed: '<isDismissed',
  },

  templateUrl: 'annotation_preview.html',

  controller: ['$scope', 'Tracking', function($scope, Tracking) {
    $scope.$watch('$ctrl.annotation_preview', () => {
      if (this.annotation_preview) {
        this.annotation_content_switch = !this.annotation_content_switch;
      }
    });

    this.on_click = () => {
      Tracking.track('Tap Annotation Teaser', this.tracking_params());
      this.click_handler();
    };

    this.tracking_params = () => ({
      'Annotation ID': _.get(this.annotation_preview, 'id'),
    });
  }],
});
