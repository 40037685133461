/* eslint-env es5 */
/* eslint-disable arrow-body-style, arrow-parens, arrow-spacing, constructor-super, generator-star-spacing, no-class-assign, no-confusing-arrow, no-const-assign, no-dupe-class-members, no-duplicate-imports, no-extra-bind, no-new-symbol, no-restricted-imports, no-this-before-super, no-useless-computed-key, no-useless-constructor, no-useless-rename, no-var, object-shorthand, prefer-arrow-callback, prefer-const, prefer-numeric-literals, prefer-reflect, prefer-rest-params, prefer-spread, prefer-template, require-yield, rest-spread-spacing, sort-imports, symbol-description, template-curly-spacing, yield-star-spacing, dot-location, key-spacing, max-len, new-parens, no-lonely-if, no-mixed-operators, no-trailing-spaces, no-whitespace-before-property, padded-blocks, quotes, space-infix-ops */

angular.module('genius').component('userIdentities', {
  templateUrl: 'user_identities.html',

  bindings: {
    user: '<',
    editable: '<',
  },

  controller: ['IdentityClient', 'Session', function(IdentityClient, Session) {
    this.can = function(permission) {
      return Session.has_permission(permission, this.user);
    };
    this.can_edit = function() {
      return this.editable && this.can('manage_identities');
    };

    this.saving_identity = {};
    this.unlink_identity = function(identity) {
      if (this.saving_identity[identity.provider]) return;
      this.saving_identity[identity.provider] = true;
      delete this.errors;

      IdentityClient.destroy(identity.id).then(function(data) {
        _.assign(this.user, data.user);
        delete this.errors;
      }.bind(this)).catch(function(error) {
        this.errors = error.errors;
      }.bind(this)).finally(function() {
        delete this.saving_identity[identity.provider];
      }.bind(this));
    };

    this.identity_auth_path = function(identity) {
      return '/auth/' + identity;
    };
  }],
});
