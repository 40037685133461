/* eslint-env es6 */

angular.module('genius').directive('openLinksInNewWindow', [function() {
  return {
    restrict: 'A',

    link(_scope, element) {
      element.on('click', 'a:not([target])', (e) => {
        e.target.target = '_blank';
      });
    },
  };
}]);
