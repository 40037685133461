/* eslint-env es6 */

angular.module('genius').factory('SongClient', ['$q', '$http', 'AppConfig', 'Session', 'querystring', function($q, $http, AppConfig, Session, querystring) {
  const raw_update_data_to_update_payload = raw_data => _(raw_data).
    pick(['id', 'title', 'updated_by_human_at', 'spotify_uuid', 'youtube_url', 'soundcloud_url',
      'release_date_components', 'albums', 'tags', 'featured_video', 'youtube_start', 'custom_song_art_image_url',
      'recording_location', 'custom_performances', 'song_art_primary_color', 'song_art_secondary_color', 'song_art_text_color',
      'valid_song_art_contrast', ..._.map(AppConfig.external_song_match_purposes, purpose => `apple_song_for_${purpose}`),
    ]).
    merge(['primary_artists', 'producer_artists', 'featured_artists', 'writer_artists'].
      reduce((memo, key) => {
        if (raw_data[key]) {
          memo[key] = _.map(raw_data[key], artist => _.pick(artist, 'name'));
        }
        return memo;
      }, {})
    ).tap((payload) => {
      if (raw_data.primary_tag) payload.primary_tag_id = raw_data.primary_tag.id;

      if (raw_data.song_relationships) {
        payload.song_relationships_by_id = [];
        _.each(raw_data.song_relationships, (relationship) => {
          payload.song_relationships_by_id.push({type: relationship.type, song_ids: _.compact(_.map(relationship.songs, 'id'))});
        });
      }
    }).omit(_.isUndefined).value();

  return {
    autocomplete_api_endpoint: `${AppConfig.api_root_url}/search`,

    autocomplete_options: {
      parse(data) {
        return _.map(data.response.hits, 'result').map((song) => {
          const label = `${song.title} | ${song.primary_artist_names}`;
          return {data: [label, song], value: song, result: song};
        });
      },

      formatItem(row) {
        const display = `${row[1].title}<br>${row[1].primary_artist_names}`;
        return row[1].new ? `Create new: ${display}` : display;
      },
    },

    autocomplete(query) {
      return $http.get(`${AppConfig.api_root_url}/search/song`, {params: {q: query}}).
        then(response => _.map(_.get(response, 'data.response.sections[0].hits', []), 'result'));
    },

    count_view(song_id) {
      return $http.post(`${AppConfig.api_root_url}/songs/${song_id}/count_view`);
    },

    load_contributor_data(song_id) {
      return $http.get(`${AppConfig.api_root_url}/songs/${song_id}/contributors`).
        then(response => response.data.response.contributors);
    },

    load_iq_earners(song_id, params) {
      const query = querystring.stringify(params);
      return $http.get(`${AppConfig.api_root_url}/songs/${song_id}/iq_earners?${query}`).
        then(response => response.data.response.contributors);
    },

    load_annotation_open_data(song_id) {
      return $http.get(`${AppConfig.api_root_url}/songs/${song_id}/annotation_open_data`).
        then(response => response.data.response.annotation_open_data);
    },

    load_song(song_id) {
      return $http.get(`${AppConfig.api_root_url}/songs/${song_id}`, {params: {text_format: 'html,markdown'}}).
        then(response => response.data.response.song);
    },

    load_lyrics(song_id) {
      return $http.get(`${AppConfig.api_root_url}/songs/${song_id}/lyrics`).then(response => response.data.response.song_lyrics);
    },

    perform_action(song_id, action_name, data) {
      const text_format = Session.user_signed_in ? 'html,markdown' : 'html';
      return $http.put(`${AppConfig.api_root_url}/songs/${song_id}/${action_name}`, data, {params: {text_format}}).
        then(
          response => response.data.response.song,
          response => $q.reject(new Error(
            _.get(response, ['data', 'meta', 'message'], 'Error performing admin action')
          ))
        );
    },

    delete(song_id) {
      return $http.delete(`${AppConfig.api_root_url}/songs/${song_id}`);
    },

    update(id, raw_update_data) {
      const update_payload = raw_update_data_to_update_payload(raw_update_data);
      return $http.put(`${AppConfig.api_root_url}/songs/${id}`, {song: update_payload}, {params: {text_format: 'html,markdown'}}).
        then(response => response.data.response.song,
          response => $q.reject(_.set(response.data.response, 'message', response.data.meta.message)));
    },

    save_lyrics(song, lyrics, params = {}) {
      return $http.put(`${AppConfig.api_root_url}/songs/${song.id}/lyrics`, _.merge({
        lyrics: {body: {html: lyrics}},
        client_timestamps: _.pick(song, ['updated_by_human_at', 'lyrics_updated_at']),
      }, params)).then(response => response.data.response.song_lyrics, response => $q.reject(response.data));
    },

    line_items(inbox_name, page) {
      const url = `${AppConfig.api_root_url}/songs/${inbox_name}/activity_stream/line_items`;
      const params = {
        text_format: 'html',
        page,
      };

      return $http.get(url, {params}).then(response => response.data.response);
    },

    for_artist(artist_id, params) {
      return $http.get(`${AppConfig.api_root_url}/artists/${artist_id}/songs`, {params}).then(response => response.data.response);
    },

    search_with_artist(artist_id, params) {
      return $http.get(`${AppConfig.api_root_url}/artists/${artist_id}/songs/search`, {params: params || {}}).
        then(response => response.data.response);
    },

    hot(params) {
      return $http.get(`${AppConfig.api_root_url}/songs/hot`, {params: params || {}}).then(response => response.data.response);
    },

    attribution_visualization(id) {
      return $http.get(`${AppConfig.api_root_url}/songs/${id}/attribution_visualization`).
        then(response => response.data.response.attribution_visualization);
    },

    preview_lyrics_for_export(song_id) {
      return $http.get(`${AppConfig.api_root_url}/songs/${song_id}/preview_lyrics_for_export`).
        then(response => response.data.response.preview_lyrics_for_export.lyrics);
    },
  };
}]);
