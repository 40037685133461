/* eslint-env es6 */

angular.module('genius').factory('SpotifyPlayerClient', ['$http', '$q', 'IdentityClient', function($http, $q, IdentityClient) {
  const fresh_token = (() => {
    let credentials;

    return () => {
      if (_.get(credentials, 'expires_at', 0) > Date.now() / 1000) {
        return $q.when(credentials.token);
      } else {
        return IdentityClient.spotify_credentials().then((new_credentials) => {
          credentials = new_credentials;
          return credentials.token;
        });
      }
    };
  })();

  const request = (method, path, {params, data} = {}) => fresh_token().then(token => $http({
    method, params, data,
    url: `https://api.spotify.com/v1${path}`,
    headers: {'Authorization': `Bearer ${token}`},
  }));

  return {
    fresh_token,

    play(track_uri) {
      const data = {};
      if (track_uri) data.uris = [track_uri];
      return request('put', '/me/player/play', {data});
    },

    pause() {
      return request('put', '/me/player/pause');
    },

    seek(position_ms) {
      return request('put', '/me/player/seek', {params: {position_ms}});
    },

    subscribe(connection_id) {
      return request('put', '/me/notifications/player', {params: {connection_id}});
    },

    shuffle(state = true) {
      return request('put', '/me/player/shuffle', {params: {state}});
    },
  };
}]);
