/* eslint-env es5 */
/* eslint-disable arrow-body-style, arrow-parens, arrow-spacing, constructor-super, generator-star-spacing, no-class-assign, no-confusing-arrow, no-const-assign, no-dupe-class-members, no-duplicate-imports, no-extra-bind, no-new-symbol, no-restricted-imports, no-this-before-super, no-useless-computed-key, no-useless-constructor, no-useless-rename, no-var, object-shorthand, prefer-arrow-callback, prefer-const, prefer-numeric-literals, prefer-reflect, prefer-rest-params, prefer-spread, prefer-template, require-yield, rest-spread-spacing, sort-imports, symbol-description, template-curly-spacing, yield-star-spacing, dot-location, key-spacing, max-len, new-parens, no-lonely-if, no-mixed-operators, no-trailing-spaces, no-whitespace-before-property, padded-blocks, quotes, space-infix-ops */

angular.module('genius').controller('FactTrackAnnotationEditCtrl', ['$scope', function($scope) {
  $scope.set_timestamp_to_now = function() {
    $scope.annotation.timestamp_in_ms = $scope.ctrl.time;
  };

  $scope.annotation_duration = function() {
    var annotation_index = $scope.annotations.indexOf($scope.annotation);
    var next_annotation = $scope.annotations[annotation_index + 1];
    var next_annotation_time = next_annotation ? next_annotation.timestamp_in_ms : $scope.duration;
    return next_annotation_time - $scope.annotation.timestamp_in_ms;
  };
}]);
