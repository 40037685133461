/* eslint-env es6 */

angular.module('genius').factory('SmartRouting', ['$q', '$routerRootComponent', '$document', '$injector', '$location', 'mobile_device', function($q, $routerRootComponent, $document, $injector, $location, mobile_device) {
  const has_root_routing_component = () =>
    angular.element($routerRootComponent).empty();

  const with_lazy_router = callback =>
    $injector.invoke(['$rootRouter', callback]);

  const recognize = routing_url =>
    with_lazy_router($rootRouter => $rootRouter.recognize(routing_url));

  const navigate_if_allowed = (routing_url) => {
    if (!has_root_routing_component()) {
      return $q.reject(Error('No root routing component'));
    }

    return recognize(routing_url).then((component) => {
      const deviceType = mobile_device ? 'mobile' : 'desktop';
      const clientRoutableOn = _.get(component, 'component.routeData.data.clientRoutableOn');
      const clientRoutableOnCurrentDeviceType = clientRoutableOn && clientRoutableOn.indexOf(deviceType) !== -1;

      return clientRoutableOnCurrentDeviceType
        ? $location.url(routing_url)
        : $q.reject(Error('Cannot route with client'));
    }
    );
  };

  const redirect_to = (url) => {
    $document[0].location.href = url;
  };

  return {
    navigate_to_absolute_url(url) {
      const parsed_url = new URL(url);
      if (parsed_url.hostname !== $document[0].location.hostname) {
        return redirect_to(url);
      }

      const routing_url = parsed_url.pathname + parsed_url.search + parsed_url.hash;
      navigate_if_allowed(routing_url).
        catch(_err => redirect_to(url));
    },
  };
}]);
