/* eslint-env es5 */
/* eslint-disable arrow-body-style, arrow-parens, arrow-spacing, constructor-super, generator-star-spacing, no-class-assign, no-confusing-arrow, no-const-assign, no-dupe-class-members, no-duplicate-imports, no-extra-bind, no-new-symbol, no-restricted-imports, no-this-before-super, no-useless-computed-key, no-useless-constructor, no-useless-rename, no-var, object-shorthand, prefer-arrow-callback, prefer-const, prefer-numeric-literals, prefer-reflect, prefer-rest-params, prefer-spread, prefer-template, require-yield, rest-spread-spacing, sort-imports, symbol-description, template-curly-spacing, yield-star-spacing, dot-location, key-spacing, max-len, new-parens, no-lonely-if, no-mixed-operators, no-trailing-spaces, no-whitespace-before-property, padded-blocks, quotes, space-infix-ops */

angular.module('genius').directive('textSelectionChanged', ['TextSelection', function(TextSelection) {

  var get_range_element_ancestor = function(range) {
    var ancestor = range.commonAncestorContainer;
    while (ancestor.nodeType !== Node.ELEMENT_NODE) ancestor = ancestor.parentNode;
    return ancestor;
  };

  return {
    restrict: 'A',

    scope: {
      textSelectionChanged: '&',
    },

    link: function(scope, element) {
      TextSelection.$on('selection', function(_event, data) {
        var ranges = data.ranges.filter(function(range) {
          return element[0].contains(get_range_element_ancestor(range));
        });
        scope.textSelectionChanged({ranges: ranges});
      });
    },
  };
}]);
