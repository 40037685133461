/* eslint-env es6 */

angular.module('genius').component('headerAdmin', {
  bindings: {
    open_header_menus: '<openHeaderMenus',
  },

  templateUrl: 'header_admin.html',

  controller: ['Session', 'UserClient', 'AssetClient', '$window', function(Session, UserClient, AssetClient, $window) {
    this.$onInit = () => {
      this.user = Session.current_user;
      this.can = permission => Session.has_permission(permission, this.user);
    };

    this.logout = () => {
      this.loading = true;
      UserClient.logout().then(() => {
        $window.location.reload();
      });
    };

    this.impersonate_user = () => {
      const to_impersonate = prompt('Enter the login of the user you want to sign in as');
      if (_.trim(to_impersonate) === '') return;

      this.impersonating = true;
      UserClient.impersonate(to_impersonate).then(() => {
        $window.location.reload();
      });
    };

    this.search_user_aliases = () => {
      const alias = prompt('Find all users who\'ve ever had this login');
      if (_.trim(alias) === '') return;

      $window.location.href =
        `/users/aliases?alias=${encodeURIComponent(alias)}`;
    };

    this.remove_asset = () =>  {
      const url = prompt('Enter the url to remove');
      if (_.trim(url) === '') return;

      AssetClient.remove(url);
    };
  }],
});
