/* eslint-env es6 */

angular.module('genius').component('breadcrumbs', {
  templateUrl: 'breadcrumbs.html',

  bindings: {
    order: '<',
  },

  controller: [function() {
    this.rebuildHierarchy = () => {
      this.hierarchy = [{url: '/', title: 'Home'}];
      this.order.forEach((item) => {
        if (item._type === 'artist') {
          this.hierarchy.push({
            url: `/artists-index/${item.index_character}`,
            title: item.index_character.toUpperCase(),
          });
          this.hierarchy.push({
            url: item.url,
            title: item.name,
          });
        } else if (item._type === 'album') {
          this.hierarchy.push({
            url: item.url,
            title: item.name,
          });
        } else if (item._type === 'song') {
          this.hierarchy.push({
            url: item.url,
            title: item.title + (item.is_music ? ' Lyrics' : ''),
          });
        }
      });
    };

    this.$onChanges = (changes) => {
      if (changes.order) {
        this.rebuildHierarchy();
      }
    };
  }],
});
