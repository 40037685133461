/* eslint-env es6 */

angular.module('genius').directive('timeAgo', [function() {
  return {
    restrict: 'E',

    templateUrl: 'time_ago.html',

    scope: {
      time: '<',
    },
  };
}]);
