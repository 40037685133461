/* eslint-env es6 */

angular.module('genius').directive('voting', [function() {
  const scores = {down: -1, up: 1};

  return {
    restrict: 'E',

    templateUrl: 'voting.html',

    scope: {
      voteTotalClicked: '&',
      voteable: '<object',
      type: '@',
      onUnauthorized: '&',
      variants: '<',
    },

    controllerAs: 'ctrl',
    controller: ['$scope', 'Session', 'Tracking', 'VoteClient', 'Reversible', function($scope, Session, Tracking, VoteClient, Reversible) {
      const save_vote = (vote, vote_was) => {
        const diff = (scores[vote] || 0) - (scores[vote_was] || 0);
        const changes = {votes_total: $scope.voteable.votes_total + diff};
        if (!$scope.voteable.has_voters) {
          changes.has_voters = true;
        }
        const revert = Reversible.modify($scope.voteable, changes);
        VoteClient.submit_vote($scope.type, $scope.voteable.id, vote).then(() => {
          $scope.$emit('vote_saved');
        }).catch(() => {
          revert();
          $scope.voteable.current_user_metadata.interactions.vote = vote_was;
        });
      };

      this.submit_vote = (vote) => {
        const vote_was = $scope.voteable.current_user_metadata.interactions.vote;
        if (vote === vote_was) vote = undefined;

        if (!Session.user_signed_in) {
          Tracking.track('Logged out vote attempt');
          return $scope.onUnauthorized({vote});
        }

        if (!Session.has_permission('vote', $scope.voteable)) return;
        $scope.voteable.current_user_metadata.interactions.vote = vote;
        save_vote(vote, vote_was);

        $scope.$emit('vote_submitted', $scope.type, vote);
      };
    }],
  };
}]);
