/* eslint-env es6 */

angular.module('genius').component('inboxLineItemDetailObject', {
  templateUrl: 'inbox_line_item_detail_object.html',

  bindings: {
    object: '<',
    variants: '<',
  },
});
