/* eslint-env es6 */

angular.module('genius').component('userModeration', {
  templateUrl: 'user_moderation.html',

  bindings: {
    user: '<',
  },

  controller: ['Session', 'UserClient', 'Attempt', 'AppConfig', function(Session, UserClient, Attempt, AppConfig) {
    this.can = permission => Session.has_permission(permission, this.user);

    this.clear = () => {
      this.errors = null;
      this.messages = null;
    };

    this.toggle_penalty_status = () => {
      if (this.updating) return;
      this.clear();
      this.updating = true;

      let action;
      if (this.user.penalty_box_until) {
        action = UserClient.unpenalty_box(this.user.id);
      } else {
        action = UserClient.penalty_box(this.user.id);
      }
      action.then((data) => {
        _.assign(this.user, data.user);
      }).catch((data) => {
        this.errors = data.errors;
      }).finally(() => {
        this.updating = false;
      });
    };

    this.update_spam_state = (state) => {
      if (this.updating) return;

      Attempt.admin_action(() => {
        this.clear();
        this.updating = true;

        UserClient.update_spam_state(this.user.id, {state}).then((data) => {
          _.assign(this.user, data.user);
          this.messages = ['Done! (But it\'ll take a few minutes to finish)'];
        }).catch((data) => {
          this.errors = data.errors;
        }).finally(() => {
          this.updating = false;
        });
      }, I18n.t(`mark_${state}.confirm`));
    };

    this.mass_delete_unreviewed_activity = () => {
      if (this.updating) return;

      Attempt.admin_action(() => {
        this.clear();
        this.updating = true;

        UserClient.mass_delete_unreviewed_activity(this.user.id).then((data) => {
          _.assign(this.user, data.user);
          this.messages = ['Done! (But it\'ll take a few minutes to finish)'];
        }).catch((data) => {
          this.errors = data.errors;
        }).finally(() => {
          this.updating = false;
        });
      }, I18n.t('mass_delete_unreviewed_activity.confirm'));
    };

    this.vote_types = AppConfig.user_vote_types_for_delete;
    this.mass_delete_votes = (type) => {
      if (this.updating) return;

      Attempt.admin_action(() => {
        this.clear();
        this.updating = true;

        UserClient.delete_votes(this.user.id, type).then((data) => {
          _.assign(this.user, data.user);
          this.messages = ['Done! (But it\'ll take a few minutes to finish)'];
        }).catch((data) => {
          this.errors = data.errors;
        }).finally(() => {
          this.updating = false;
        });
      }, I18n.t(`mass_delete_votes.${type}.confirm`));
    };
  }],
});
