/* eslint-env es6 */

angular.module('genius').filter('minimum_readable_time', ['$window', function($window) {
  let average_adult_wpm = 245;
  const wpm_from_query_match = $window.location.search.match(/wpm=(\d+)/);
  if (wpm_from_query_match) average_adult_wpm = Number(wpm_from_query_match[1]);
  const average_adult_millis_per_word = (60 * 1000) / average_adult_wpm;

  return (input) => {
    const number_of_words = input ? input.split(/\s+/).length : 0;
    return number_of_words * average_adult_millis_per_word;
  };
}]);
