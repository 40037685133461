/* eslint-env es6 */

angular.module('genius').factory('Twitter', ['$window', 'querystring', function($window, querystring) {
  return {
    share(options) {
      const qs = querystring.stringify({url: options.url, text: options.message});
      const url = `https://twitter.com/intent/tweet?${qs}`;
      $window.open(url);
    },
  };
}]);
