/* eslint-env es6 */

angular.module('genius').component('activeReferent', {
  bindings: {
    referent: '<',
    on_navigate: '&onNavigate',
    on_close: '&onClose',
  },

  templateUrl: 'active_referent.html',

  controller: ['Cookies', function(Cookies) {
    const count_cookie_name = 'referent_open_count_for_interstitial';
    const show_interstitial_on_referent_number = 2;
    const six_hours_in_days = 0.25;

    this.$onInit = function() {
      this.session_referent_open_count = Number(Cookies.get(count_cookie_name) || 0);

      this.increment_session_referent_open_count = function() {
        this.session_referent_open_count += 1;
        if (this.session_referent_open_count <= show_interstitial_on_referent_number) {
          Cookies.put(count_cookie_name, this.session_referent_open_count, {expires: six_hours_in_days});
        }
      };

      this.should_show_interstitial = () => this.session_referent_open_count === show_interstitial_on_referent_number;
    };

    this.$onChanges = function(changes) {
      if (changes.referent && changes.referent.currentValue) {
        this.increment_session_referent_open_count();
      }
    };
  }],
});
