/* eslint-env es6 */

angular.module('genius').directive('onIframeLoad', ['Bindings', function(Bindings) {
  const resolve_bindings = Bindings.resolver({
    on_iframe_load: '&onIframeLoad',
  });

  return {
    restrict: 'A',

    link(scope, element, attrs) {
      const bindings = resolve_bindings(scope, attrs);
      element.get(0).addEventListener('load', (event) => {
        const target = event.target;
        scope.$applyAsync(() => bindings.on_iframe_load({target}));
      });
    },
  };
}]);
