/* eslint-env es6 */

angular.module('genius').factory('UserClient', ['ApiClient', '$http', 'AppConfig', '$q', function(ApiClient, $http, AppConfig, $q) {
  const return_error = (response) => {
    const error = new Error(response.data.meta.message);
    _.assign(error, _.pick(response, 'status', 'statusText'));
    return $q.reject(_.assign(error, response.data.response));
  };

  const UserClient = {
    autocomplete_api_endpoint: `${AppConfig.api_root_url}/users/autocomplete`,

    transform_autocomplete_results(data) {
      return data.response.users;
    },

    contribution_opportunity_assignments(user_id) {
      return ApiClient.get('/users/:id/contribution_opportunity_assignments', {id: user_id, text_format: 'html,markdown'});
    },

    mark_contribution_opportunity_assignments_read(user_id) {
      return ApiClient.post('/users/:id/contribution_opportunity_assignments/mark_read', {id: user_id});
    },

    dismiss_contribution_opportunities_explanation(user_id) {
      return ApiClient.delete('/users/:id/contribution_opportunities_explanation', {id: user_id});
    },

    accomplishments(user_id, params) {
      return $http.get(`${AppConfig.api_root_url}/users/${user_id}/accomplishments`, {params}).
        then(response => response.data.response);
    },

    autocomplete(query) {
      return $http.get(this.autocomplete_api_endpoint, {params: {q: query}}).
        then(response => this.transform_autocomplete_results(response.data));
    },

    load(user_id) {
      return $http.get(`${AppConfig.api_root_url}/users/${user_id}`).
        then(response => response.data.response.user);
    },

    impersonate(user_to_impersonate) {
      return $http.post('/users/impersonate', {login: user_to_impersonate});
    },

    autocomplete_options: {
      parse(response) {
        const users = UserClient.transform_autocomplete_results(response);
        return _.map(users, user => ({
          data: [user.login],
          value: user.login,
          result: user.login,
        }));
      },
    },

    load_followers_by_artist(artist_id, params) {
      return $http.get(`${AppConfig.api_root_url}/artists/${artist_id}/followers`, {params}).
        then(response => response.data.response);
    },

    load_followers_by_user(user_id, params) {
      return $http.get(`${AppConfig.api_root_url}/users/${user_id}/followers`, {params}).
        then(response => response.data.response);
    },

    load_followed_users(user_id, params) {
      return $http.get(`${AppConfig.api_root_url}/users/${user_id}/followed_users`, {params}).
        then(response => response.data.response);
    },

    load_contributions(user_id, contribution_type, params) {
      const contribution_type_url_component = contribution_type ? `/${contribution_type}` : '';
      return $http.get(`${AppConfig.api_root_url}/users/${user_id}/contributions${contribution_type_url_component}`, {params}).
        then(response => response.data.response);
    },

    update(user_id, data) {
      const params = {params: {text_format: 'html,markdown'}};
      return $http.put(`${AppConfig.api_root_url}/users/${user_id}`, data, params).
        then(response => response.data.response).
        catch(response => return_error(response));
    },

    load_recent_signups(spam_state, params) {
      params.spam_state = spam_state;
      return $http.get(`${AppConfig.api_root_url}/users/recent_signups`, {params}).
        then(response => response.data.response);
    },

    add_role(user_id, role) {
      return $http.put(`${AppConfig.api_root_url}/users/${user_id}/roles/${role}`).
        then(response => response.data.response).
        catch(response => return_error(response));
    },

    remove_role(user_id, role) {
      return $http.delete(`${AppConfig.api_root_url}/users/${user_id}/roles/${role}`).
        then(response => response.data.response).
        catch(response => return_error(response));
    },

    associate_with_artist(user_id, params) {
      return $http.put(`${AppConfig.api_root_url}/users/${user_id}/artist`, params).
        then(response => response.data.response).
        catch(response => return_error(response));
    },

    dissociate_from_artist(user_id) {
      return $http.delete(`${AppConfig.api_root_url}/users/${user_id}/artist`).
        then(response => response.data.response).
        catch(response => return_error(response));
    },

    penalty_box(user_id) {
      return $http.put(`${AppConfig.api_root_url}/users/${user_id}/penalty_box`).
        then(response => response.data.response).
        catch(response => return_error(response));
    },

    unpenalty_box(user_id) {
      return $http.delete(`${AppConfig.api_root_url}/users/${user_id}/penalty_box`).
        then(response => response.data.response).
        catch(response => return_error(response));
    },

    update_spam_state(user_id, params) {
      return $http.put(`${AppConfig.api_root_url}/users/${user_id}/spam`, params).
        then(response => response.data.response).
        catch(response => return_error(response));
    },

    mass_delete_unreviewed_activity(user_id) {
      return $http.delete(`${AppConfig.api_root_url}/users/${user_id}/unreviewed_activity`).
        then(response => response.data.response).
        catch(response => return_error(response));
    },

    delete_votes(user_id, type) {
      return $http.delete(`${AppConfig.api_root_url}/users/${user_id}/${type}`).
        then(response => response.data.response).
        catch(response => return_error(response));
    },

    other_users_with_ip(user_id, params) {
      return $http.get(`${AppConfig.api_root_url}/users/${user_id}/other_users_with_ip`, {params}).
        then(response => response.data.response);
    },

    get_slug(user) {
      return _(this.get_path(user).split('/')).last();
    },

    get_path(user) {
      return new URL(user.url).pathname;
    },

    activity_line_items(user_id, params) {
      return $http.get(`${AppConfig.api_root_url}/users/${user_id}/activity_stream/line_items`, {params}).
        then(response => response.data.response);
    },

    spam_status(user_id) {
      return $http.get(`${AppConfig.api_root_url}/users/${user_id}/spam_status`).
        then(response => response.data.response);
    },

    logout() {
      return $http.delete('/logout');
    },
  };

  return UserClient;
}]);
