/* eslint-env es6 */

angular.module('genius').component('iqInbox', {
  bindings: {
    opened: '<',
    clear_unread_count: '&clearUnreadCount',
  },

  templateUrl: 'iq_inbox.html',

  controller: ['Session', 'UserClient', 'Pusher', 'Tracking', function(Session, UserClient, Pusher, Tracking) {
    this.load = () => {
      UserClient.contribution_opportunity_assignments(this.user.id).then((data) => {
        this.loaded = true;
        const existing_ids = _.map(this.assignments, 'id');
        const new_opps = _.reject(data.contribution_opportunity_assignments, opp => _.includes(existing_ids, opp.id));
        this.assignments = this.assignments.concat(new_opps);
        if (this.opened) {
          UserClient.mark_contribution_opportunity_assignments_read(this.user.id);
          this.clear_unread_count();
        }
      });
    };

    this.remove = (assignment) => {
      this.assignments = _.reject(this.assignments, {id: assignment.id});
      this.load();
    };

    this.dismiss_explanation = () => {
      this.show_explanation = false;
      UserClient.dismiss_contribution_opportunities_explanation(this.user.id);
    };

    this.$onInit = () => {
      this.assignments = [];
      this.user = Session.current_user;
      this.show_explanation = !this.user.dismissed_contribution_opportunities_explanation;

      this.load();

      Pusher.subscribe(
        Session.current_user.pusher_channel,
        'contribution_opportunity_completion',
        (id) => {
          if (_.find(
            this.assignments,
            assignment => assignment.contribution_opportunity.id === id
          )) {
            this.load();
            this.assignments = _.reject(
              this.assignments,
              assignment => assignment.contribution_opportunity.id === id
            );
          }
        }
      );

      Pusher.subscribe(
        Session.current_user.pusher_channel,
        'contribution_opportunities_refreshed',
        this.load
      );
    };

    this.$onChanges = (changes) => {
      if (changes.opened) {
        const is_open = changes.opened.currentValue;
        const was_open = changes.opened.previousValue;
        if (is_open && (!was_open || changes.opened.isFirstChange())) {
          Tracking.track('Inbox Open', {inbox_name: 'iq_inbox'});
          this.clear_unread_count();
        }
      }
    };
  }],
});
