import 'jquery-deparam/jquery-deparam';
/* eslint-env es6 */


angular.module('genius').factory('querystring', [function() {
  return {
    stringify(params) {
      return $.param(params);
    },

    parse(string) {
      return $.deparam(string);
    },
  };
}]);
