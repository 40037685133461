import 'jquery.hotkeys.04e5435';
/* eslint-env es6 */


angular.module('genius').directive('kb', ['$window', function($window) {
  return {
    restrict: 'E',

    link(scope, _element, attrs) {
      const $target = $($window);

      const handler = (event) => {
        scope.$apply(() => scope.$eval(attrs.action, {$event: event}));
        return false;
      };

      $target.bind('keydown', attrs.shortcut, handler);

      scope.$on('$destroy', () => $target.unbind('keydown', handler));
    },
  };
}]);
