/* eslint-env es6 */

angular.module('genius').factory('SpotifyAnnotationClient', ['$http', function($http) {
  return {
    save_song(song_id, spotify_song, annotations) {
      return $http.post('/spotify_annotations/bulk_update', {
        song_id,
        spotify_annotations: annotations,
        spotify_song: {
          note: spotify_song.note,
          lock_version: spotify_song.lock_version,
          finalized: spotify_song.finalized,
        },
      }).then(response => response.data);
    },

    load_song(song_id) {
      return $http.get(`/songs/${song_id}/spotify_annotations`).then(response => response.data);
    },
  };
}]);
