/* eslint-env es6 */

angular.module('genius').directive('filepickerInput', ['Filepicker', function(Filepicker) {
  return {
    link(scope, element, attrs) {
      const filepicker = new Filepicker({services: 'COMPUTER'});
      const min_width = scope.$eval(attrs.minWidth);

      element.on('click', () => {
        element.attr('value', '');
        scope.$eval(attrs.onFilepickerError, {error: null});
        scope.$eval(attrs.onFilepickerProgress, {uploading: true});
        filepicker.pick_and_store().then((url) => {
          if (min_width) {
            const img = new Image();
            img.onload = () => {
              if (img.width < min_width) {
                scope.$applyAsync(() => scope.$eval(attrs.onFilepickerError, {error: `must be wider than ${min_width}px`}));
              } else {
                element.attr('value', url);
              }
            };
            img.src = url;
          } else {
            element.attr('value', url);
          }
        }).catch((e) => {
          if (e.code !== 101) {
            scope.$eval(attrs.onFilepickerError, {error: e.toString()});
          }
        }).finally(() => scope.$eval(attrs.onFilepickerProgress, {uploading: null}));

        return false;
      });
    },
  };
}]);
