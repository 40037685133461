/* eslint-env es6 */

angular.module('genius').factory('AppleMatchEvaluationsClient', ['ApiClient', function(ApiClient) {
  return {
    submit(id, evaluation) {
      return ApiClient.put(`/apple_match_evaluations/${id}`, {evaluation});
    },
    skip(id) {
      return ApiClient.put(`/apple_match_evaluations/${id}`, {evaluation: {checked_out_at: null}});
    },
  };
}]);
