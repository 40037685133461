/* eslint-env es6 */

angular.module('genius').component('verticalSearchResultItems', {
  bindings: {
    results: '<',
    limit_to: '<limitTo',
  },

  templateUrl: 'vertical_search_result_items.html',
});
