/* eslint-env es6 */

angular.module('genius').factory('wait_until', ['$q', function($q) {
  return function(condition, options = {}) {
    return $q((resolve, reject) => {
      const timeout = setTimeout(() => {
        clearInterval(wait);
        reject(new Error('timed out waiting for condition'));
      }, options.timeout || 10000);

      const wait = setInterval(() => {
        if (!condition()) return;
        clearInterval(wait);
        clearTimeout(timeout);
        resolve();
      }, 50);
    });
  };
}]);
