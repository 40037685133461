/* eslint-env es6 */

angular.module('genius').filter('boundedCount', [function() {
  return (value, bound) => {
    if (!value) return;
    if (!bound) return value.toString();

    if (Number(value) >= Number(bound)) {
      return `${bound}+`;
    } else {
      return value.toString();
    }
  };
}]);
