/* eslint-env es6 */

angular.module('genius').factory('WebAnnotatorClient', ['ApiClient', function(ApiClient) {
  return {
    load_web_page(id) {
      return ApiClient.
        returning('web_page').
        get('/web_pages/:id', {id});
    },

    protect_web_page(id) {
      return ApiClient.
        returning('web_page').
        post('/web_pages/:id/protect', {id});
    },

    unprotect_web_page(id) {
      return ApiClient.
        returning('web_page').
        post('/web_pages/:id/unprotect', {id});
    },

    publish_web_page_drafts(id) {
      return ApiClient.
        returning('web_page').
        post('/web_pages/:id/publish_drafts', {id});
    },

    load_user_roles(type, id) {
      return ApiClient.
        returning('user_web_page_roles').
        get('/:type/:id/user_roles', {type, id});
    },

    create_user_role(type, id, params) {
      return ApiClient.
        returning('user_web_page_role').
        post('/:type/:id/user_roles', {type, id, user_web_page_role: params});
    },

    update_user_role(id, params) {
      return ApiClient.
        returning('user_web_page_role').
        put('/user_web_page_roles/:id', {id, user_web_page_role: params});
    },

    destroy_user_role(id) {
      return ApiClient.delete('/user_web_page_roles/:id', {id});
    },
  };
}]);
