/* eslint-env es6 */

angular.module('genius').component('coverArtForm', {
  templateUrl: 'cover_art_form.html',

  bindings: {
    cover_artable: '<coverArtable',
    cover_arts: '<coverArts',
    type: '@',
    close: '&',
    yields_is_dirty: '&yieldsIsDirty',
  },

  controller: ['CoverArtClient', 'Reversible', 'Session', function(CoverArtClient, Reversible, Session) {
    this.can = (action, object) => Session.has_permission(action, object);

    this.visible_cover_arts = () => _.reject(this.cover_arts, '_deleted');

    this.create = (image_url) => {
      if (_.some(this.cover_arts, {image_url})) {
        this.error = {response: {errors: ['Image already exists']}};
        return;
      }
      this.error = null;
      this.autosaving = true;
      const cover_art = {image_url};
      this.cover_arts.push(cover_art);
      const revert = () => _.remove(this.cover_arts, {image_url});
      CoverArtClient.
        create(this.type, this.cover_artable.id, {image_url}).
        then(data => Object.assign(this.cover_artable, data.cover_artable)).
        catch((error) => {
          this.error = error;
          revert();
        }).finally(() => this.autosaving = false);
    };

    this.update = (cover_art, image_url) => {
      if (_.some(this.cover_arts, {image_url})) {
        this.error = {response: {errors: ['Image already exists']}};
        return;
      }
      this.error = null;
      this.autosaving = true;
      const revert = Reversible.modify(cover_art, {image_url});
      CoverArtClient.
        update(cover_art.id, {image_url}).
        then(data => Object.assign(this.cover_artable, data.cover_artable)).
        catch((error) => {
          this.error = error;
          revert();
        }).finally(() => this.autosaving = false);
    };

    this.destroy = (cover_art) => {
      this.error = null;
      this.autosaving = true;
      const revert = Reversible.modify(cover_art, {_deleted: true});
      CoverArtClient.
        destroy(cover_art.id).
        then(data => Object.assign(this.cover_artable, data.cover_artable)).
        catch((error) => {
          this.error = error;
          revert();
        }).finally(() => this.autosaving = false);
    };

    this.cant_drop = (cover_art) => {
      const cover_art_ids = _.map(this.visible_cover_arts(), 'id');
      const index_of_draggable = _.indexOf(cover_art_ids, this.cover_art_being_dragged.id);
      const index_of_droppable = _.indexOf(cover_art_ids, cover_art.id);
      return index_of_draggable === index_of_droppable || index_of_draggable === index_of_droppable - 1;
    };

    this.move_before = (to_shift, to_move) => {
      this.error = null;
      this.saving = true;
      const cover_art_ids = _.map(this.cover_arts, 'id');
      const old_index = _.indexOf(cover_art_ids, to_move.id);
      const new_index = to_shift ? _.indexOf(cover_art_ids, to_shift.id) : this.cover_arts.length - 1;
      _.remove(this.cover_arts, {id: to_move.id});
      this.cover_arts.splice(new_index, 0, to_move);

      const revert = () => {
        _.remove(this.cover_arts, {id: to_move.id});
        this.cover_arts.splice(old_index, 0, to_move);
      };

      CoverArtClient.
        move_between(to_move.id, {above_id: _.get(this.cover_arts[new_index - 1], 'id'), below_id: _.get(to_shift, 'id')}).
        then(data => Object.assign(this.cover_artable, data.cover_artable)).
        catch((error) => {
          this.error = error;
          revert();
        }).finally(() => this.saving = false);
    };
  }],
});
