/* eslint-env es6 */

angular.module('genius').factory('AnswerClient', ['$http', '$q', 'AppConfig', 'Session', 'Reversible', 'Markdown', function($http, $q, AppConfig, Session, Reversible, Markdown) {
  const return_error = (response) => {
    const error = new Error(response.data.meta.message);
    _.assign(error, _.pick(response, 'status', 'statusText'));
    return $q.reject(_.assign(error, response.data.response));
  };

  const update = (answer_id, data) => {
    const formats = Session.user_signed_in ? 'html,markdown' : 'html';
    const params = {text_format: formats};
    return $http.put(`${AppConfig.api_root_url}/answers/${answer_id}`, data, {params}).
      then(response => response.data.response).
      catch(response => return_error(response));
  };

  const create_with_new_question = (question, data) => {
    const formats = Session.user_signed_in ? 'html,markdown' : 'html';
    const params = {text_format: formats};
    data = _.assign({}, data, {
      question: _.pick(question, [
        'body',
        'questionable_id',
        'questionable_type',
        'default_key',
        'state',
      ]),
    });

    return $http.post(`${AppConfig.api_root_url}/answers`, data, {params}).
      then(response => response.data.response).
      catch(response => return_error(response));
  };

  const create_with_existing_question = (question_id, data) => {
    const formats = Session.user_signed_in ? 'html,markdown' : 'html';
    const params = {text_format: formats};
    return $http.post(`${AppConfig.api_root_url}/questions/${question_id}/answers`, data, {params}).
      then(response => response.data.response).
      catch(response => return_error(response));
  };

  return {
    save(answer, args) {
      const savable_answer = {
        body: {markdown: answer._editing.markdown},
        pin_question: answer._editing.pin_question,
      };

      if (Session.has_permission('update_answer_source', args.question)) {
        savable_answer.answer_source = answer.answer_source;
      }

      let action;
      if (answer.id) {
        action = update(answer.id, {answer: savable_answer});
      } else if (args.question.id) {
        action = create_with_existing_question(args.question.id, {answer: savable_answer});
      } else {
        action = create_with_new_question(args.question, {answer: savable_answer});
      }

      const revert_answer = Reversible.modify(answer, {'body.html': Markdown.to_html(answer._editing.markdown), new: false});
      const question_changes = {};
      if (savable_answer.pin_question) {
        question_changes.state = 'pinned';
      }
      const revert_question = Reversible.modify(args.question, question_changes);
      return action.then((payload) => {
        _.assign(args.question, _.omit(payload.question, 'answer'));
        return _.assign(answer, payload.answer);
      }).catch((error) => {
        revert_answer();
        revert_question();
        return $q.reject(error);
      });
    },

    destroy(answer, args) {
      const answer_id = answer.id;
      if (!answer_id) {
        return $q.reject(new Error("Answer hasn't been saved yet."));
      }
      const revert = Reversible.modify(
        args.question.current_user_metadata,
        {permissions: args.question.current_user_metadata.permissions.concat('add_answer')}
      );

      return $http.delete(`${AppConfig.api_root_url}/answers/${answer_id}`).then((response) => {
        _.assign(args.question, _.omit(response.question, 'answer'));
        return response.data.response;
      }).catch((response) => {
        revert();
        return return_error(response);
      });
    },
  };
}]);
