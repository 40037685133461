angular.module('genius').component('songPageSidebarMultiPlacement', {
  templateUrl: 'song_page_sidebar_multi_placement.html',

  bindings: {
    song: '<',
    show_song_story: '&showSongStory',
  },

  controller() {
    let has_relevant_video, no_video, no_ad;

    this.video_did_fill = (videos) => {
      const relevance = _.get(videos, '0.relevance');
      has_relevant_video = relevance === 'high' || relevance === 'medium';
    };

    this.no_video = () => no_video = true;

    this.no_ad = () => no_ad = true;

    this.is_empty = () => !this.song.song_story && no_video && no_ad;

    this.should_render_video_component = () =>
      !this.song.song_story && (has_relevant_video || no_ad);

    this.should_show_ad = () =>
      !this.song.song_story && (!has_relevant_video || _.isUndefined(has_relevant_video));
  },
});
