/* eslint-env es6 */

angular.module('genius').factory('ApiClient', ['$http', 'AppConfig', 'PromiseQueue', 'BeforeUnloadCheck', function($http, AppConfig, PromiseQueue, BeforeUnloadCheck) {
  const request_queue = new PromiseQueue();
  BeforeUnloadCheck.add(() => !request_queue.is_empty());

  const is_valid_api_response = (response) => {
    const is_structured_response = _.isObject(response)
      && _.isObject(response.data)
      && _.has(response.data, 'meta')
      && _.has(response.data, 'response');

    const is_empty_response = _.isObject(response)
      && response.status === 204
      && response.data === '';

    return is_structured_response || is_empty_response;
  };

  class ApiResponse {
    constructor(response) {
      _.assign(this, response.data.response);
    }
  }

  class ApiError extends Error {
    constructor(message, response) {
      super(message);
      this.status = response.status;
      this.response = response.data;
      this.stack = Error().stack;
    }

    get name() { return 'ApiError'; }
  }

  const handle_json_error = (response) => {
    if (is_valid_api_response(response)) {
      const message = response.data.meta.message || response.statusText;
      const error = new ApiError(message, response);

      return _.assign(error, {response: response.data.response});
    } else {
      return new ApiError(response.statusText, response);
    }
  };

  const format_path = (path, params) => {
    const parts = path.split('/');

    return parts.map(part => part.replace(/^:(\w+)$/, (_match, key) => {
      if (!_.has(params, key) || params[key] == null) {
        throw Error(`Missing param '${key}' for API path '${path}'`);
      } else {
        const value = params[key];
        Reflect.deleteProperty(params, key);
        return value;
      }
    })).join('/');
  };

  const sending_method = method => function(path, params = {}) {
    if (!_.startsWith(path, '/')) throw Error('Path must start with /');

    params = _.clone(params);
    path = format_path(AppConfig.api_root_url + path, params);

    const param_config = method === 'get' || method === 'delete'
      ? {params}
      : {data: params};

    return this.
      extend({method, url: path}).
      merge(param_config).
      send();
  };

  class ApiRequest {
    constructor(config, transform) {
      this._config = config;
      this._transform = transform;
    }

    static empty() {
      return new ApiRequest({}, _.identity);
    }

    _update(attributes) {
      const record = _.assign({
        config: this._config,
        transform: this._transform,
      }, attributes);

      return new ApiRequest(record.config, record.transform);
    }

    _is_get() {
      return this._config.method === 'get';
    }

    extend(partial_config) {
      return this._update({
        config: _.assign({}, this._config, partial_config),
      });
    }

    merge(partial_config) {
      return this._update({
        config: _.merge({}, this._config, partial_config),
      });
    }

    use(f) {
      return _.tap(f(this), (result) => {
        if (!(result instanceof ApiRequest)) {
          throw TypeError('Result of ApiRequest#use() must be an ApiRequest');
        }
      });
    }

    set_param(param, value) {
      return this.merge({params: {[param]: value}});
    }

    transform(transform) {
      return this._update({
        transform: _.flow(this._transform, transform),
      });
    }

    returning(property) {
      return this.transform(_.property(property));
    }

    send() {
      const promise = this._is_get() ? $http(this._config) : request_queue.add(() => $http(this._config));
      return promise.
        catch((response) => {
          const error = _.isObject(response.data)
            ? handle_json_error(response)
            : new ApiError(response.statusText, response);

          return Promise.reject(error);
        }).
        then(response =>
          is_valid_api_response(response)
            ? Promise.resolve(new ApiResponse(response))
            : Promise.reject(new ApiError('Invalid response', response))
        ).
        then(this._transform);
    }

    then(on_resolve, on_reject) {
      this._response = this._response || this.send();

      return this._response.then(on_resolve, on_reject);
    }

    get get() { return sending_method('get'); }
    get post() { return sending_method('post'); }
    get put() { return sending_method('put'); }
    get delete() { return sending_method('delete'); }
  }

  return ApiRequest.empty();
}]);
