/* eslint-env es6 */

angular.module('genius').component('songActivityStream', {
  bindings: {
    songId: '<',
  },

  templateUrl: 'song_activity_stream.html',

  controllerAs: 'ctrl',

  controller: Object.assign(['$scope', 'SongClient', function($scope, SongClient) {
    let next_page;
    let currently_loading_page;

    const reset_line_items = () => {
      $scope.line_items = [];
      $scope.done_loading = false;
      next_page = 1;
      this.load_next_page();
    };

    this.load_next_page = () => {
      if (currently_loading_page) return;

      currently_loading_page = SongClient.line_items(this.songId, next_page).then((response) => {
        const line_items = response.line_items;
        $scope.line_items.push(...line_items);
        currently_loading_page = undefined;
        if (line_items.length) {
          next_page = response.next_page;
          if (!next_page) {
            $scope.done_loading = true;
          }
        } else {
          $scope.done_loading = true;
        }
      });
    };

    this.should_paginate = () => !currently_loading_page && !$scope.done_loading;

    reset_line_items();
  }]),
});
