/* eslint-env es6 */

angular.module('genius').directive('authenticationForm', ['$window', 'AuthenticationClient', 'AbTest', 'AppConfig', '$q', 'FingerprintJS', function($window, AuthenticationClient, AbTest, AppConfig, $q, FingerprintJS) {
  return {
    scope: {
      variants: '<?',
      on_cancel: '&onCancel',
      user_session: '=?userSession',
      include_sign_in: '<?signIn',
      include_social: '<?social',
      show_email_fields: '=?showEmail',
      header_text: '<headerText',
      preamble_text: '<preambleText',
      init_signing_in: '<signingIn',
    },

    templateUrl: 'authentication_form.html',
    controllerAs: 'ctrl',

    controller: ['$scope', function($scope) {
      this.show_email_fields = $scope.show_email_fields;
      this.signing_in = $scope.init_signing_in;
      this.login_requires_2fa = false;
      $scope.user_session = $scope.user_session || {};

      this.submit = () => {
        if (this.saving) return;
        this.saving = true;
        this.errors = null;
        const action = this.signing_in ? 'sign_in' : 'sign_up';

        let fingerprint_promise = $q.resolve();
        if (!this.signing_in) {
          fingerprint_promise = $q.when(FingerprintJS.load({
            endpoint: 'https://fp.genius.com',
            apiKey: AppConfig.fingerprint_api_key,
          })).
            then(fp => fp.get()).
            then((deviceFingerprintData) => {
              $scope.user_session.device_fingerprint = deviceFingerprintData.visitorId;
            }).
            catch(() => {
              $scope.user_session.device_fingerprint = 'errored-bagon';
            });
        }

        fingerprint_promise.
          then(() => AuthenticationClient[action]($scope.user_session)).
          then((response) => {
            AbTest.clear();
            if (action === 'sign_up' && $window.location.pathname === '/') {
              $window.location = response.user.url;
            } else {
              $window.location.reload();
            }
          }).
          catch((api_error) => {
            this.errors = api_error.response.errors;
            this.error_type = api_error.response.error_type;
            if (api_error.response.validation_errors && api_error.response.validation_errors.second_factor_credential) {
              this.login_requires_2fa = true;
            }
            if (api_error.response.allowed_2fa_types) {
              $scope.user_session.second_factor_type = api_error.response.allowed_2fa_types[0];
            }
          }).
          finally(() => {
            this.saving = false;
          });
      };

      this.toggle_signup_signin = () => {
        this.show_email_fields = true;
        this.signing_in = !this.signing_in;
        this.errors = null;
      };
    }],
  };
}]);
