/* eslint-env es6 */

angular.module('genius').directive('expandableAccordionSection', [function() {
  return {
    restrict: 'E',

    scope: {
      label: '@',
      expanded: '=',
      shortcut: '@',
    },

    templateUrl: 'expandable_accordion_section.html',

    replace: true,

    transclude: true,

    link(scope) {
      scope.expand_content = function() {
        scope.expanded = !scope.expanded;
      };
    },
  };
}]);
