/* eslint-env es6 */

angular.module('genius').factory('AccomplishmentClient', ['ApiClient', function(ApiClient) {
  return {
    update(accomplishment_id, data) {
      return ApiClient.
        put('/accomplishments/:accomplishment_id', _.merge({accomplishment_id}, data));
    },
  };
}]);
