/* eslint-env es6 */

angular.module('genius').controller('SongCtrl', ['$scope', '$window', '$q', '$location', '$immediate', 'ReferentRegistry', 'ReferentElementMap', 'ReferentClient', 'SongClient', 'Tracking', 'Session', 'currentFlashMessages', 'Reversible', 'Attempt', 'Pusher', 'I18n', 'ScrollPoint', 'only_changes', 'VideoRegistry', 'mobile_device', 'ReactSongPageService', 'AppConfig', function($scope, $window, $q, $location, $immediate, ReferentRegistry, ReferentElementMap, ReferentClient, SongClient, Tracking, Session, currentFlashMessages, Reversible, Attempt, Pusher, I18n, ScrollPoint, only_changes, VideoRegistry, mobile_device, ReactSongPageService, AppConfig) {
  if ($scope.$ctrl) $scope.$ctrl.song_ctrl = this;

  $scope.attempt_close = Attempt.close_form;

  $scope.transcriber_guide_url = AppConfig.transcriber_guide_url;

  $scope.bagon_eol_survey_url = AppConfig.bagon_eol_survey_url;

  $scope.$watch('song.id', (id) => {
    if (id) {
      VideoRegistry.register_page($scope.song, mobile_device ? 'mobile_song_page' : 'desktop_song_page');
      Tracking.delayed_track('Song Page Load', {'counts_as_page_load': true});
    }
  });

  const from_question_email = $location.search().from_question_email;
  if (from_question_email) {
    Tracking.track('Question email question clicked', {question_id: from_question_email});
  }

  const preload_from_start_count = 5;

  $scope.user_signed_in = Session.user_signed_in;

  ReferentElementMap.clear();

  const annotation_preview_for_referent = referent_id =>
    _.find($scope.annotation_previews, {referent_id});

  const annotation_preview_for_anchor = (referent_anchor) => {
    const referent_id = Number($(referent_anchor).data('id'));
    return annotation_preview_for_referent(referent_id);
  };

  this.update_focused_referent_anchor = (referent_anchor) => {
    this.focused_fragement_element_id = $(referent_anchor).data('fragment_element_id');
    $scope.annotation_preview = annotation_preview_for_anchor(referent_anchor);
    $scope.$digest();
  };

  this.is_active_element = fragment_element_id =>
    this.focused_fragement_element_id === fragment_element_id;

  this.dismiss_annotation_preview = () => {
    $scope.annotation_preview_dismissed = true;
    Tracking.track('Dismiss Annotation Teaser');
  };

  this.reactivate_annotation_preview = () => {
    $scope.annotation_preview_dismissed = false;
    Tracking.track('Reactivate Annotation Teaser');
  };

  this.referent_has_preview = referent_id => !!annotation_preview_for_referent(referent_id);

  this.highlight_preview_referent = fragment_element_id =>
    this.is_active_element(fragment_element_id) && !$scope.annotation_preview_dismissed;

  this.show_preview_referent_indicator = fragment_element_id =>
    this.is_active_element(fragment_element_id) && $scope.annotation_preview_dismissed;

  ReferentRegistry.register($scope.song.description_annotation);
  if ($scope.preloaded_referents) {
    _.each($scope.preloaded_referents, (referent) => {
      ReferentRegistry.register(_.assign({fragments: []}, referent));
    });
  }

  $immediate(() => {
    SongClient.count_view($scope.song.id);
    ReferentRegistry.preload_from_start(preload_from_start_count);
  }, !'invoke_apply');

  $scope.sidebar = {signing_up: false};
  $scope.initial_sidebar_content_showing = () =>
    !$scope.current_referent &&
      !$scope.$ctrl.lyrics_selection_range &&
      !$scope.sidebar.signing_up &&
      !$scope.$ctrl.creating_referent;

  $scope.delete_song = () => {
    SongClient.delete($scope.song.id).then(() => {
      currentFlashMessages.push({type: 'notice', message: 'Successfully deleted Song'});
      $window.location.href = '/';
    });
  };

  $scope.next_track = () => {
    let current_found = false;
    return _.find($scope.primary_album_tracks, (track) => {
      if (current_found) return true;
      current_found = track.song.id === $scope.song.id;
    });
  };

  $scope.open_first_verified_annotation = (user) => {
    Tracking.track('Tap Verified Callout');

    const referent = ReferentRegistry.first(
      r => r.classification === 'verified' && _.includes(r.verified_annotator_ids, user.id)
    );

    this.open_referent_by_id(referent.id);
  };

  $scope.open_next_referent = (referent_id) => {
    Tracking.track('Tap Next Verified');

    this.open_referent_by_id(referent_id);
  };

  $scope.$watch('next_up_clicked', (value) => {
    if (value) Tracking.track('Next Song Tap');
  });

  this.update_url_for_open_referent = (id) => {
    if ($location.url() !== `/${id}`) $location.url(`/${id}`);
  };

  const perform_song_action_and_update = (action_name, data) =>
    SongClient.
      perform_action($scope.song.id, action_name, data).
      then((song) => {
        $scope.song = song;
      }).
      catch(({message}) => {
        currentFlashMessages.push({type: 'error', message});
      });

  $scope.can_perform = action_name => Session.has_permission(action_name, $scope.song);

  const custom_admin_actions = {
    hide: () => {
      this.hiding_prompt_visible = true;
    },

    destroy() {
      $scope.delete_song();
    },

    view_annotation_engagement_data() {
      $scope.toggle_engagement_data();
    },

    edit_spotify_annotations_for() {
      $window.open(`/songs/${$scope.song.id}/edit-fact-track`);
    },

    view_lyrics_synchronization() {
      $window.open(`/songs/${$scope.song.id}/lyrics_sync`);
    },
  };

  const non_confirmable_admin_actions = {
    view_annotation_engagement_data: true,
    edit_spotify_annotations_for: true,
    view_lyrics_synchronization: true,
  };

  $scope.attempt_admin_action = (action_name, data) => {
    if (non_confirmable_admin_actions[action_name]) return $scope.perform_admin_action(action_name, data);
    Attempt.admin_action(
      _.partial($scope.perform_admin_action, action_name, data),
      I18n.lookup(`song.action_confirmations.${action_name}`) || I18n.t('song.action_confirmations.admin_default')
    );
  };

  $scope.perform_admin_action = (action_name, data) => {
    if (custom_admin_actions[action_name]) { return custom_admin_actions[action_name](); }

    perform_song_action_and_update(action_name, data);
  };

  $scope.$on('editorial_action_count_changed', (_e, data) => {
    if (!data.commentable.referent_id) return;

    const referent = ReferentRegistry.get(data.commentable.referent_id);
    if (!referent) return;

    const count = _.chain(referent.annotations).
      filter(annotation => !annotation.verified).
      sumBy(annotation => annotation.comment_count + annotation.proposed_edit_count);

    referent.pending_editorial_actions_count = count;
  });

  $scope.$on('annotation_deleted', (_e, removed_annotation, deletion_promise) => {
    $scope.$ctrl.lyrics_compiled.promise.then(() => {
      const referent = ReferentRegistry.get(removed_annotation.referent_id);
      $scope.$ctrl.lyrics_interface.block_edit(true);
      referent._deleted = _(referent.annotations).every(annotation => annotation._deleted || annotation.new);
      deletion_promise.then(() => {
        if (referent._deleted) {
          $scope.$ctrl.lyrics_interface.remove_referent(removed_annotation.referent_id);
        } else {
          Reflect.deleteProperty(referent, '_deleted');
          ReferentClient.refresh(referent);
        }
      }).catch(() => {
        Reflect.deleteProperty(referent, '_deleted');
      }).finally(() => {
        $scope.$ctrl.lyrics_interface.block_edit(false);
      });
    });
  });

  $scope.$watch('current_referent._deleted', (deleted) => {
    if (deleted) $scope.$ctrl.clear_current_referent();
  });

  const load_annotation_open_data = _.once(() => {
    SongClient.
      load_annotation_open_data($scope.song.id).
      then((annotation_open_data) => {
        $scope.open_data = annotation_open_data;
      });
  });

  $scope.toggle_engagement_data = () => {
    $scope.showing_annotation_engagement_data = !$scope.showing_annotation_engagement_data;
    load_annotation_open_data();
  };

  $scope.set_current_hover_and_digest = (fragment_id) => {
    $scope.hover_referent_id = fragment_id;
    $scope.$digest();
  };

  $scope.$on('referent_created', (_event, referent) => {
    ReferentRegistry.register(referent);
    _.assign($scope.song, referent.annotatable.client_timestamps);
    this.update_url_for_open_referent(referent.id);
  });

  this.save = (song_update) => {
    this.saving = true;
    const changes = only_changes(song_update, $scope.song, {except: ['valid_song_art_contrast']});
    const rollback = Reversible.modify($scope.song, song_update);

    return SongClient.
      update($scope.song.id, changes).
      then(updated_song => angular.extend($scope.song, updated_song)).
      catch((error) => {
        rollback();
        return $q.reject(error);
      }).finally(() => {
        this.saving = false;
      });
  };

  this.show_metadata_form = (section_name, from_keyboard_shortcut) => {
    this.show_metadata_form_sections = {[section_name]: true};
    Tracking.track('Song Page Open Edit Form', {
      section: section_name,
      keyboard_shortcut: !!from_keyboard_shortcut,
    });
  };

  this.has_track_info = (...types) => {
    const song = $scope.song;
    return _(types).filter(type => _.isNumber(song[type]) || _.some(song[type])).some();
  };

  this.has_song_relationships = () =>
    _($scope.song.song_relationships).
      map(song_relationship => song_relationship.songs).
      flatten().
      some();

  this.close = () => {
    this.show_metadata_form_sections = false;
  };

  this.open_referent_by_id = (id) => {
    $location.path(`/${id}`);
  };

  this.featured_annotation_in_view = _.once((in_view) => {
    if (in_view) Tracking.track('Featured Annotation In View');
  });

  this.reload_song = () => {
    this.edit_blocked = true;
    return SongClient.load_song($scope.song.id).then((song) => {
      this.edit_blocked = false;
      _.assign($scope.song, _.omit(song, 'description', 'description_annotation'));
    });
  };

  this.is_apple_music_player_enabled = () => Session.has_permission('view_apple_music_player', $scope.song);

  this.should_load_apple_music_player = () => this.is_apple_music_player_enabled() && $scope.song.is_music && (
    $scope.song.apple_music_id || mobile_device
  );

  this.react_song_page_link = ReactSongPageService.reactSongPageRedirect;

  if (Session.user_signed_in) {
    Pusher.subscribe($scope.song.pusher_channel, 'song-updated', (data) => {
      if ($scope.song.updated_by_human_at < data.updated_by_human_at) this.reload_song();
      if ($scope.song.lyrics_updated_at < data.lyrics_updated_at) $scope.$ctrl.lyrics_interface.refresh_lyrics();
    });
  }

  $scope.open_first_verified_answer = (artist) => {
    const first = _.find($scope.pinned_questions, question => _.get(question, 'answer.answer_source.artist.id') === artist.id);
    if (first) {
      ScrollPoint.scroll_to(`question_${first.id}`);
    } else {
      ScrollPoint.scroll_to('questions');
    }
  };

  $immediate(() => {
    if ($('.song_media_controls').offset()) {
      this.media_player_sticky_offset = -($('.song_body').offset().top - $('.song_media_controls').offset().top);
    }
  });
}]);
