/* eslint-env es5 */
/* eslint-disable arrow-body-style, arrow-parens, arrow-spacing, constructor-super, generator-star-spacing, no-class-assign, no-confusing-arrow, no-const-assign, no-dupe-class-members, no-duplicate-imports, no-extra-bind, no-new-symbol, no-restricted-imports, no-this-before-super, no-useless-computed-key, no-useless-constructor, no-useless-rename, no-var, object-shorthand, prefer-arrow-callback, prefer-const, prefer-numeric-literals, prefer-reflect, prefer-rest-params, prefer-spread, prefer-template, require-yield, rest-spread-spacing, sort-imports, symbol-description, template-curly-spacing, yield-star-spacing, dot-location, key-spacing, max-len, new-parens, no-lonely-if, no-mixed-operators, no-trailing-spaces, no-whitespace-before-property, padded-blocks, quotes, space-infix-ops */

angular.module('genius').directive('keepInView', ['$document', '$window', function($document, $window) {
  var PADDING_RATIO = 0.05;
  var DEFAULT_SCROLL_DURATION = 300;
  var FAST_SCROLL_DURATION = 100;
  var FAST_SCROLL_INTERVAL_THRESHOLD = 200;

  return {
    link: function(scope, elem, attrs) {
      var last_animated = 0;

      scope.$watch(attrs.keepInView, function(keep_is) {
        if (!keep_is) return;

        var rect = elem[0].getBoundingClientRect();
        var windowHeight = $window.innerHeight;
        if (rect.bottom > windowHeight * (1 - PADDING_RATIO) ||
            rect.top < windowHeight * PADDING_RATIO) {
          var inteval = last_animated - Date.now();
          var duration = inteval < FAST_SCROLL_INTERVAL_THRESHOLD ?
            FAST_SCROLL_DURATION :
            DEFAULT_SCROLL_DURATION;
          last_animated = Date.now();
          $($document.prop('body')).animate({
            scrollTop: $(elem).offset().top - windowHeight * PADDING_RATIO,
          }, duration);
        }
      });
    },
  };
}]);
