/* eslint-env es6 */

angular.module('genius').directive('mutuallyExclusiveSwitches', [function() {
  return {
    restrict: 'E',

    scope: true,

    link(scope, _element, attributes) {
      const property = attributes.property;

      scope[property] = {};

      scope.$watch(property, (active_switches_are, active_switches_were) => {
        if (_(active_switches_are).values().compact().size() <= 1) return;

        _.forEach(active_switches_are, (is_open, switch_name) => {
          const was_open = active_switches_were[switch_name];

          if (is_open && !was_open) {
            scope[property] = _.pick(scope[property], switch_name);
          }
        });
      }, true);
    },
  };
}]);
