/* eslint-env es6 */

angular.module('genius').component('notificationInbox', {
  bindings: {
    inbox_name: '@inboxName',
    abbreviate_after: '@abbreviateAfter',
    sync_count_to_title: '<syncCountToTitle',
    open_inboxes: '<openInboxes',
  },

  templateUrl: 'notification_inbox.html',

  controller: ['$sce', 'Pusher', 'Session', '$scope', '$window', 'AppConfig', 'mobile_device', function($sce, Pusher, Session, $scope, $window, AppConfig, mobile_device) {
    this.$onInit = () => {
      this.src = $sce.trustAsResourceUrl(`/inboxes/${this.inbox_name}?embed=true`);

      Pusher.subscribe(Session.current_user.pusher_channel, 'inbox-marked-read', (data) => {
        if (data.inbox === this.inbox_name && this.inbox_name !== 'messages_inbox') {
          this.unread_count_api.clear();
        }
      });

      $window.addEventListener('message', (message) => {
        if (
          message.origin === `https://${AppConfig.canonical_domain}` &&
            _.get(message.data, 'type') === 'decrement_unread_messages_count' &&
            this.inbox_name === 'messages_inbox'
        ) {
          $scope.$applyAsync(() => this.unread_count_api.decrement(message.data.decrement));
        }
      });
    };

    this.toggle = ($event) => {
      if (mobile_device) return;
      $event.preventDefault();
      this.open_inboxes[this.inbox_name] = !this.open_inboxes[this.inbox_name];
      if (this.open_inboxes[this.inbox_name]) {
        this.loading = true;
      }
    };
  }],
});
