/* eslint-env es6 */

angular.module('genius').factory('ReferentClient', ['$http', 'AppConfig', 'Session', 'querystring', function($http, AppConfig, Session, querystring) {
  return {
    refresh(referent) {
      return this.get(referent.id).then((updated_referent) => {
        _.assign(referent, _.omit(updated_referent, 'annotations'));
        _.each(referent.annotations, (existing_annotation) => {
          _.each(updated_referent.annotations, (updated_annotation) => {
            if (updated_annotation.id === existing_annotation.id) {
              _.assign(existing_annotation, updated_annotation);
            }
          });
        });

        return referent;
      });
    },

    get(id) {
      const formats = Session.user_signed_in ? 'html,markdown,plain' : 'html,plain';
      const params = {text_format: formats};
      return $http.get(`${AppConfig.api_root_url}/referents/${id}`, {params}).
        then(response => response.data.response.referent);
    },

    bulk_load(ids) {
      const formats = Session.user_signed_in ? 'html,markdown,plain' : 'html,plain';
      const query = querystring.stringify({text_format: formats, ids});
      return $http.get(`${AppConfig.api_root_url}/referents/multi?${query}`).
        then(response => response.data.response.referents);
    },

    create_referent(data) {
      const query = querystring.stringify({text_format: 'html'});
      return $http.post(`${AppConfig.api_root_url}/songs/${data.song.id}/referents?${query}`, data).
        then(response => response.data.response.referent);
    },
  };
}]);
