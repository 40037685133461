/* eslint-env es6 */

angular.module('genius').component('webAnnotatorManager', {
  bindings: {
    web_page_id: '@webPageId',
  },

  templateUrl: 'web_annotator_manager.html',

  controller: ['WebAnnotatorClient', function(WebAnnotatorClient) {
    this.$onInit = () => {
      this.errors = [];
      WebAnnotatorClient.load_web_page(this.web_page_id).
        then(web_page => this.web_page = web_page);
    };

    this.protect = (protect) => {
      const action = protect ? 'protect' : 'unprotect';
      WebAnnotatorClient[`${action}_web_page`](this.web_page_id).
        then(web_page => this.web_page = web_page).
        catch(this.on_error);
    };

    this.publish_drafts = () => {
      WebAnnotatorClient.publish_web_page_drafts(this.web_page_id).
        then(web_page => this.web_page = web_page).
        catch(this.on_error);
    };
  }],
});
