/* eslint-env es6 */

angular.module('genius').controller('ArticleFormCtrl', ['Filepicker', '$element', '$immediate', function(Filepicker, $element, $immediate) {
  let filepicker;

  this.$onInit = () => {
    this.snippets = {
      'Lyrics': {before: '<g-lyrics>', after: '</g-lyrics>'},
      'Outlined Title': {before: '<g-outlined-title>', after: '</g-outlined-title>'},
      'Pullquote': {
        before:
`<g-pullquote>
  `,
        after:
`
  <cite>Attribution</cite>
</g-pullquote>
`,
      },
      'Image With Caption': {
        before: '<g-image src="https://example.com/image.png">',
        after: '</g-image>',
        default_body:
`
  Caption
`,
      },
      'Fullbleed Image With Caption': {
        before: '<g-fullbleed-image src="https://example.com/image.png">',
        after: '</g-fullbleed-image>',
        default_body:
`
  Caption
`,
      },
      'List': {
        before:
`<g-list>
  <g-list-item>
    <g-list-item-title>`,
        after:
`</g-list-item-title>
    <g-list-item-media src=""></g-list-item-media>
    <g-list-item-body></g-list-item-body>
  </g-list-item>
</g-list>`,
      },
      'Knotch': {before: '<g-knotch>', after: '</g-knotch>', default_body: 'knotch_class_here'},
    };

    this.formatting = {
      'Header (Primary)': {before: '# '},
      'Header (Secondary)': {before: '## '},
      'Header (Tertiary)': {before: '### '},
      'Link': {before: '[', after: '](url)'},
      'Italic': {before: '*', after: '*'},
      'Bold': {before: '**', after: '**'},
      'Strikethrough': {before: '~~', after: '~~'},
      'Superscript': {before: '<sup>', after: '</sup>'},
      'Subscript': {before: '<sub>', after: '</sub>'},
      'Small': {before: '<small>', after: '</small>'},
      'Center': {before: '<center>', after: '</center>'},
      'Bulleted List': {before: '- ', each_line: true},
      'Numbered List': {before: '1. ', each_line: true},
      'Blockquote': {before: '> '},
      'En Dash': {before: '--'},
      'Em Dash': {before: '---'},
      'Horizontal Line': {before: '***'},
    };

    filepicker = new Filepicker({services: 'COMPUTER'});
  };

  this.pick_image = () => filepicker.pick_and_store().then((url) => {
    this.insert_at_body_markdown_cursor({
      before: `<g-image src="${url}">`,
      after: '</g-image>',
      default_body: 'Caption',
    });
  });

  this.preview = () => {
    this.previewing = true;
    $immediate(() => {
      const $form = $element.find('form.edit_article');
      $form.find('input[name="_method"]').attr('value', 'post');
      $form.submit();
    });
  };
}]);
