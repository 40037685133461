/* eslint-env es6 */

angular.module('genius').directive('globalErrorMessage', [function() {
  return {
    scope: {},

    templateUrl: 'global_error_message.html',

    controllerAs: 'ctrl',

    controller: ['GlobalErrorBus', '$sce', function(GlobalErrorBus, $sce) {
      GlobalErrorBus.$on('global_error', (_event, message_html) => {
        this.message_html = $sce.trustAsHtml(message_html);
      });
    }],
  };
}]);
