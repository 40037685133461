/* eslint-env es6 */

angular.module('genius').factory('LocalStorage', ['$window', function($window) {
  return {
    is_available() {
      try {
        const x = '__storage_test__';
        $window.localStorage.setItem(x, x);
        $window.localStorage.removeItem(x);
        return true;
      } catch (e) {
        return false;
      }
    },

    set_item(key, value) {
      $window.localStorage.setItem(key, JSON.stringify(value));
    },

    get_item(key) {
      return JSON.parse($window.localStorage.getItem(key));
    },

    remove_item: $window.localStorage.removeItem.bind($window.localStorage),

    safe_set_item(key, value) {
      if (this.is_available()) { this.set_item(key, value); }
    },

    safe_get_item(key, default_value) {
      return this.is_available() ? this.get_item(key) : default_value;
    },

    safe_remove_item(key) {
      if (this.is_available()) { this.remove_item(key); }
    },
  };
}]);
