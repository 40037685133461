/* eslint-env es6 */

angular.module('genius').component('miniAlbumCard', {
  bindings: {
    album: '<object',
    variants: '<',
  },

  templateUrl: 'mini_album_card.html',
});
