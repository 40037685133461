/* eslint-env es6 */

angular.module('genius').factory('FeedbackClient', ['ApiClient', function(ApiClient) {
  return {
    post_feedback(feedback_body) {
      return ApiClient.post(
        '/feedback',
        {
          feedback: {
            body: feedback_body,
          },
        }
      );
    },
  };
}]);
