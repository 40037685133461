/* eslint-env es6 */

angular.module('genius').directive('songMetadataForm', ['$window', 'Session', 'TagClient', 'I18n', function($window, Session, TagClient, I18n) {
  return {
    scope: {
      save: '&onSave',
      song_was: '<object',
      expanded_sections: '=expandedSections',
      yields_is_dirty: '&yieldsIsDirty',
    },

    restrict: 'E',
    templateUrl: 'song_metadata_form.html',
    controllerAs: 'ctrl',

    controller: ['Tracking', '$scope', 'AppConfig', function(Tracking, $scope, AppConfig) {
      this.apple_purposes = AppConfig.external_song_match_purposes;

      this.close = () => {
        if (!$scope.expanded_sections ||
          (this.song_form.$dirty && !$window.confirm(I18n.t('song.action_confirmations.dismiss_dirty')))) {
          return;
        }
        $scope.expanded_sections = null;
      };

      this.has_permission = permission => Session.has_permission(permission, $scope.song);

      this.submit = () => {
        $scope.save({song: $scope.song}).then(() => {
          Tracking.track('Metadata Edit', {'Source': 'Edit'});
          $scope.expanded_sections = null;
        }).catch((error) => {
          this.error = error;
        });
      };

      $scope.song = angular.copy($scope.song_was);

      $scope.$watch('song_was.updated_by_human_at', (new_value, old_value) => {
        if (new_value > old_value) {
          this.editable_metadata_stale = true;
        }
      });

      $scope.available_primary_tags = [];

      TagClient.primary().then((tags) => {
        $scope.available_primary_tags = tags.map(tag => tag.id === $scope.song.primary_tag.id ? $scope.song.primary_tag : tag);
      });
    }],
  };
}]);
