/* eslint-env es5 */
/* eslint-disable arrow-body-style, arrow-parens, arrow-spacing, constructor-super, generator-star-spacing, no-class-assign, no-confusing-arrow, no-const-assign, no-dupe-class-members, no-duplicate-imports, no-extra-bind, no-new-symbol, no-restricted-imports, no-this-before-super, no-useless-computed-key, no-useless-constructor, no-useless-rename, no-var, object-shorthand, prefer-arrow-callback, prefer-const, prefer-numeric-literals, prefer-reflect, prefer-rest-params, prefer-spread, prefer-template, require-yield, rest-spread-spacing, sort-imports, symbol-description, template-curly-spacing, yield-star-spacing, dot-location, key-spacing, max-len, new-parens, no-lonely-if, no-mixed-operators, no-trailing-spaces, no-whitespace-before-property, padded-blocks, quotes, space-infix-ops */

angular.module('genius').factory('Preloader', ['$q', function($q) {

  var Preloader = function(bulk_load_function) {
    this.bulk_load_function = bulk_load_function;
  };

  Preloader.prototype = {
    cache: {},

    insert: function(id, object) {
      this.cache[id] = $q.when(object);
    },

    load: function(id) {
      this.bulk_load([id]);
      return this.cache[id];
    },

    filter_unloaded: function(ids) {
      var cache = this.cache;
      return ids.filter(function(id) { return !cache[id]; });
    },

    bulk_load: function(ids) {
      var cache = this.cache;
      var ids_to_load = this.filter_unloaded(ids);
      var load_promise = ids_to_load.length ? this.bulk_load_function(ids_to_load) : undefined;

      ids_to_load.forEach(function(id) {
        cache[id] = load_promise.then(function(objects_by_id) {
          return objects_by_id[id];
        });
      });
    },
  };

  return Preloader;

}]);
