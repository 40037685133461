/* eslint-env es6 */

angular.module('genius').directive('clickToLoad', [function() {
  return {
    scope: {
      message: '@',
    },

    restrict: 'E',

    transclude: true,

    templateUrl: 'click_to_load.html',

    link(scope) {
      scope.load = () => {
        scope.loading = true;
      };
    },
  };
}]);
