import 'jquery.embedly-3.1.2';
import 'embedly_re';
import 'vidyard';
/* eslint-env es6 */


angular.module('genius').directive('embedly', ['getScript', 'querystring', '$compile', function(getScript, querystring, $compile) {
  const links_regex = new RegExp('^https?://', 'i');

  const links_in_container = $container => $container.find('a').filter(function() {
    return links_regex.test($.trim($(this).text()));
  });

  const twitter_embed = _.template(`
    <blockquote class="twitter-tweet">
      <a href="<%- tweet_url %>"></a>
    </blockquote>
  `);

  const annotation_embed = _.template(`
    <blockquote
      class="rg_standalone_container"
      data-src="//${CANONICAL_DOMAIN}/annotations/<%- annotation_id %>/standalone_embed"
    ></blockquote>
  `);

  const embedly_embed = _.template(`
    <embedly-embed
      embed=":: <%- JSON.stringify(embed) %>"
    ></embedly-embed>
  `);

  const youtube_embed = _.template(`
    <embedly-youtube
      video-id="<%- video_id %>"
      start="<%- start %>"
    ></embedly-youtube>
  `);

  const tidal_regexp = /^https?:\/\/tidal\.com\/(track|album|video)\/(\d+)$/;

  const tidal_embed = _.template(`
    <div class="tidal-embed" data-type="<%- type %>" data-id="<%- id %>"></div>
  `);

  const setup_embedly = (container, scope) => {
    const $container = $(container || '.embedly:visible');

    links_in_container($container).each(function() {
      const $this = $(this);

      if (app.youtubeURLre.test(this.href)) {
        const url = new URL(this.href);
        const query_params = querystring.parse(url.search.slice(1));
        const hash_params = querystring.parse(url.hash.slice(1));
        let start = query_params.t || hash_params.t || 0;
        if (/\d+m|\d+s|\d+m\d+s/.test(start)) {
          start = _(start.match(/^(\d+m)?(\d+s)?$/)).
            slice(1, 3).
            values().
            map(v => (v ? parseInt(v) : 0)).
            thru(time => (time[0] * 60) + time[1]).value();
        }
        const video_id = query_params.v || url.pathname.slice(1);
        $this.replaceWith($compile(youtube_embed({video_id, start}))(scope));
      } else if (app.twitterURLre.test(this.href)) {
        $this.replaceWith(twitter_embed({tweet_url: this.href}));
        getScript('//platform.twitter.com/widgets.js', {cache: true});
      } else if (app.rgURLre.test(this.href) &&
          this.href === $this.text() &&
          !$this.closest('.cont-annotations.act-standalone_embed').length) {
        const annotation_id = app.rgURLre.exec(this.href).pop();
        $this.replaceWith(annotation_embed({annotation_id}));
        getScript(`//${CANONICAL_DOMAIN}/annotations/load_standalone_embeds.js`, {cache: true});
      } else if (this.href.match(/https?:\/\/embed\.vidyard\.com\/(landingpage|share)\/(.*)/i)) {
        $this.embedly({
          addImageStyles: false,
          className: 'embedly_preview',
          secure: true,
          query: {secure: 'true'},
        });
      } else if (tidal_regexp.test(this.href)) {
        const [embed_type, tidal_id] = this.href.match(tidal_regexp).slice(1);
        $this.replaceWith(tidal_embed({type: embed_type[0], id: tidal_id}));
        getScript('//embed.tidal.com/tidal-embed.js', {cache: true});
      } else if (app.embedlyURLre.test(this.href)) {
        const options = {
          addImageStyles: false,
          className: 'embedly_preview',
          secure: true,
          query: {luxe: !MOBILE_DEVICE, secure: 'true'},
          display(embed) {
            const html = embed.html, soundcloud_height = 165;
            if (/soundcloud\.com/.test(html)) {
              embed.html = html.replace('visual%3Dtrue', 'visual%3Dfalse').
                replace(/height="\d+"/, `height="${soundcloud_height}"`);
              embed.height = soundcloud_height;
            } else if (embed.type === 'video' || (embed.type !== 'photo' && embed.type !== 'rich')) {
              $this.replaceWith($compile(embedly_embed({embed}))(scope));
            }
            embed.display();
          },
        };
        $this.embedly(options);
      } else {
        return;
      }

      $this.text('Loading embed...');
    });

    setup_embedly_pro(container, scope);
  };

  const setup_embedly_pro = (container, scope) => {
    const $container = $(container || '.embedly_pro:visible');

    links_in_container($container).each(function() {
      if (app.embedlyURLre.test(this.href)) return true;
      const $this = $(this);

      $this.embedly({
        secure: true,
        query: {secure: 'true'},
        display(oembed) {
          if (!oembed.description || _.trim($this.parent().text()) !== _.trim($this.text())) {
            $this.text(oembed.title);
            return;
          }

          if (oembed.provider_name === 'Wikipedia') oembed.html = null;

          $this.replaceWith($compile(embedly_embed({embed: oembed}))(scope));
        },
      });

      $this.text('Loading embed...');
    });
  };

  return {
    restrict: 'A',
    link(scope, element, attrs) {
      scope.$watch(attrs.embedly || attrs.ngBindHtml, (html) => {
        if (!html) return;
        setup_embedly(element, scope);
      });
    },
  };
}]);
