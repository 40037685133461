/* eslint-env es6 */

angular.module('genius').directive('positionBeside', ['$window', function($window) {
  const calculate_optimal_position = (element, rect, options) => {
    if (!rect) return 0;

    const bounding_container = element.offsetParent();
    const bounding_rect = bounding_container[0].getBoundingClientRect();
    const height = element.height();
    const top_of_container = bounding_rect.top;
    const bottom_of_container = bounding_rect.bottom;
    let min_top = top_of_container;
    let max_bottom = bottom_of_container;

    if (options.maximum_visibility) {
      min_top = find_unobstructed_offset_for(
        bounding_container,
        Math.max(min_top, 0)
      );

      max_bottom = find_unobstructed_offset_for(
        bounding_container,
        Math.min(max_bottom, $window.innerHeight - 1),
        !!'move_up'
      );
    }

    let optimal_top = rect.top + ((rect.height - height) / 2);

    const overhang = optimal_top + height - max_bottom;
    if (overhang > 0) optimal_top -= overhang;

    const underhang = min_top - optimal_top;
    if (underhang > 0) optimal_top += underhang;

    return optimal_top;
  };

  const find_unobstructed_offset_for = (element, offset, move_up) => {
    const element_center = element[0].getBoundingClientRect().left + (element.width() / 2);
    const obstructing_element = $(document.elementFromPoint(element_center, offset));
    if (!obstructing_element.length || element.is(obstructing_element) || element.find(obstructing_element).length) {
      return offset;
    }

    const obstructing_rect = obstructing_element[0].getBoundingClientRect();
    let next_offset;
    if (move_up) {
      next_offset = obstructing_rect.top - 1;
    } else {
      next_offset = obstructing_rect.bottom + 1;
    }

    return find_unobstructed_offset_for(
      element,
      next_offset,
      move_up
    );
  };

  return {
    restrict: 'A',

    link(scope, element, attrs) {
      scope.$watch(attrs.positionBeside, (target_rect) => {
        const position = calculate_optimal_position(
          element,
          target_rect,
          {maximum_visibility: !!scope.$eval(attrs.positionForMaximumVisibility)}
        );
        element.offset({top: position + $($window).scrollTop()});
      });
    },
  };
}]);
