/* eslint-env es6 */

angular.module('genius').component('missingMetadataForm', {
  templateUrl: 'missing_metadata_form.html',

  bindings: {
    missing_params: '<missingParams',
    object: '<',
    type: '@',
    yields_api: '&yieldsApi',
    yields_iq: '&yieldsIq',
    on_pending_event: '&onPendingEvent',
    placeholder: '@',
    source: '@',
  },

  controller: ['$scope', 'SongClient', 'Tracking', 'Reversible', function($scope, SongClient, Tracking, Reversible) {
    const LIST_TYPES = ['autocomplete_artists', 'autocomplete_albums'];

    const param_object = (type, key) => {
      if (LIST_TYPES.includes(type)) {
        return {
          reset: () => this.changes[key] = [],
          changes_count: () => this.changes[key].length,
        };
      } else {
        return {
          reset: () => this.changes[key] = null,
          changes_count: () => Number(!_.isEmpty(this.changes[key])),
        };
      }
    };

    this.$onInit = () => {
      this.params = _.map(this.missing_params, param_object);
      this.changes = {};
      this.reset_changes();

      this.iq_api = _.cloneDeep(this.object.current_user_metadata.iq_by_action);
      this.yields_iq({iq_api: this.iq_api});

      $scope.$watch(
        () => _.sum(_.invokeMap(this.params, 'changes_count')),
        changes_count => this.iq_api.edit_metadata.primary.multiplier = _.max([changes_count, 1])
      );
      this.yields_api({
        api: {
          edit: () => this.show_full_form = true,
        },
      });
    };

    this.submit = () => {
      this.errors = [];
      this.saving = true;

      const rollback = Reversible.modify(this.object, this.changes);

      let promise;
      switch (this.type) {
      case 'song':
        promise = SongClient.update(this.object.id, this.changes);
        this.on_pending_event({event: 'UserEditMetadataSong', result: promise});
        break;
      }
      promise.then(() => {
        Tracking.track('Metadata Edit', {'Source': this.source});
      }).catch((response) => {
        rollback();
        this.reset_changes();
        this.errors = response.errors;
      }).finally(() => {
        this.saving = false;
      });
    };

    this.cancel = () => {
      this.reset_changes();
      this.show_full_form = false;
      this.errors = [];
    };

    this.reset_changes = () => {
      _.invokeMap(this.params, 'reset');
    };
  }],
});
