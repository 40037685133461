/* eslint-env es5 */
/* eslint-disable arrow-body-style, arrow-parens, arrow-spacing, constructor-super, generator-star-spacing, no-class-assign, no-confusing-arrow, no-const-assign, no-dupe-class-members, no-duplicate-imports, no-extra-bind, no-new-symbol, no-restricted-imports, no-this-before-super, no-useless-computed-key, no-useless-constructor, no-useless-rename, no-var, object-shorthand, prefer-arrow-callback, prefer-const, prefer-numeric-literals, prefer-reflect, prefer-rest-params, prefer-spread, prefer-template, require-yield, rest-spread-spacing, sort-imports, symbol-description, template-curly-spacing, yield-star-spacing, dot-location, key-spacing, max-len, new-parens, no-lonely-if, no-mixed-operators, no-trailing-spaces, no-whitespace-before-property, padded-blocks, quotes, space-infix-ops */

angular.module('genius').component('selectDropdown', {
  templateUrl: 'select_dropdown.html',

  require: {
    ngModel: '?ngModel',
  },

  bindings: {
    options: '<',
    on_select: '&onSelect',
    variants: '<',
    selection: '<',
  },

  controller: ['$scope', function($scope) {
    $scope.$watch('$ctrl.options', function() {
      this.selected = this.options[0];
    }.bind(this));

    $scope.$watch('$ctrl.selected', function() {
      if (this.ngModel) {
        this.ngModel.$setViewValue(arguments[0]);
      }
    }.bind(this));

    $scope.$watch('$ctrl.selection', function(new_value) {
      if (new_value) {
        this.selected = new_value;
      }
    }.bind(this));

    this.is_clickable = function() {
      return this.options.length > 1;
    };
  }],
});
