/* eslint-env es5 */
/* eslint-disable arrow-body-style, arrow-parens, arrow-spacing, constructor-super, generator-star-spacing, no-class-assign, no-confusing-arrow, no-const-assign, no-dupe-class-members, no-duplicate-imports, no-extra-bind, no-new-symbol, no-restricted-imports, no-this-before-super, no-useless-computed-key, no-useless-constructor, no-useless-rename, no-var, object-shorthand, prefer-arrow-callback, prefer-const, prefer-numeric-literals, prefer-reflect, prefer-rest-params, prefer-spread, prefer-template, require-yield, rest-spread-spacing, sort-imports, symbol-description, template-curly-spacing, yield-star-spacing, dot-location, key-spacing, max-len, new-parens, no-lonely-if, no-mixed-operators, no-trailing-spaces, no-whitespace-before-property, padded-blocks, quotes, space-infix-ops */

angular.module('genius').controller('DevCtrl', ['$window', '$scope', '$http', 'AppConfig', function($window, $scope, $http, AppConfig) {

  $scope.search = {query: ''};

  $scope.saving = false;
  $scope.build_scenario = function() {
    if ($scope.saving) return;
    $scope.saving = true;
    var enabled_trait_names = $scope.current_scenario.traits.filter(function(trait) {
      return trait.enabled;
    }).map(function(trait) { return trait.name; });
    $http.post(AppConfig.api_root_url + '/scenarios?id=' + $scope.current_scenario.id, {scenario: {traits: enabled_trait_names}}).then(function(response) {
      $window.location.href = response.data.url;
      $scope.saving = false;
    });
  };

  $scope.configure_scenario = function(scenario) {
    $scope.current_scenario = scenario;
  };

  $scope.clear_scenario = function() {
    $scope.current_scenario = undefined;
  };

  var load_scenarios = _.once(function() {
    $http.get(AppConfig.api_root_url + '/scenarios').then(function(response) {
      $scope.results = response.data.scenarios;
    });
  });

  $scope.open_search = function() {
    load_scenarios();
    $scope.open = true;
  };

  $scope.close_search = function() {
    $scope.open = false;
  };

}]);
