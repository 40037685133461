/* eslint-env es6 */

angular.module('genius').component('videoPage', {
  templateUrl: 'video_page.html',

  controller: Object.assign(['Tracking', '$interval', 'SmartRouting', 'Session', '$location', 'VideoRegistry', 'ArticleClient', 'Attempt', 'currentFlashMessages', '$window', function(Tracking, $interval, SmartRouting, Session, $location, VideoRegistry, ArticleClient, Attempt, currentFlashMessages, $window) {
    const next_video_spinner_time = 5;
    let next_video_interval;

    this.navigate_to_next_video = () => {
      SmartRouting.navigate_to_absolute_url(`${this.next_video.url}?utm_source=autoplay`);
    };

    this.countdown_to_next_video = () => {
      if (!this.next_video) return;
      this.seconds_until_next_video = next_video_spinner_time;
      next_video_interval = $interval(() => {
        this.seconds_until_next_video -= 1;
        if (this.seconds_until_next_video === 0) {
          this.stop_countdown_to_next_video();
          this.navigate_to_next_video();
        }
      }, 1000);
    };

    this.stop_countdown_to_next_video = () => {
      this.seconds_until_next_video = undefined;
      $interval.cancel(next_video_interval);
    };

    this.delete_article = () => {
      Attempt.admin_action(
        () => {
          ArticleClient.delete(this.page_data.article.id).then(() => {
            currentFlashMessages.push({type: 'notice', message: 'Successfully deleted article'});
            $window.location.href = this.page_data.video.url;
          });
        },
        'Are you sure you want to delete this article?'
      );
    };

    this.$routerOnActivate = (next) => {
      this.page_data = next.routeData.page_data;

      VideoRegistry.register_page(this.page_data.video, 'desktop_video_page');

      this.tracking_data = {
        'Page Type': 'desktop_video_page',
        'Placement': 'main',
        'Position': 1,
        'Video ID': this.page_data.video.id,
        'Object Type': 'video',
        'Object ID': this.page_data.video.id,
      };

      Tracking.track('Video Page Load', {
        'Video ID': this.page_data.video.id,
        'Source': $location.search().utm_source,
        'counts_as_page_load': true,
      });
    };

    this.can = action_name => Session.has_permission(action_name, this.page_data.video);
  }], {
    $canActivate: ['$nextInstruction', '$prevInstruction', 'PageTransition', function(
      $nextInstruction,
      $prevInstruction,
      PageTransition
    ) {
      return PageTransition.by_router_instruction($nextInstruction, $prevInstruction);
    }],
  }),
});
