/* eslint-env es6 */

angular.module('genius').factory('SpotifyPlayer', ['$interval', '$q', '$timeout', 'SpotifyPlayerClient', 'SpotifySocket', function(
  $interval,
  $q,
  $timeout,
  SpotifyPlayerClient,
  SpotifySocket
) {
  const CALLBACK_INTERVAL = 50;
  const ACTION_TIMEOUT = 500;
  const SEEK_TOLERENCE = 1000;
  const UPDATE_INTERVAL = 3500;
  const SEEK_THRESHOLD = 5000;

  const debounce_non_playing = (fn) => {
    let pending;
    let last_is_playing;

    return (state) => {
      clearTimeout(pending);
      if (state.is_playing || last_is_playing === false) {
        fn(state);
      } else {
        pending = setTimeout(() => fn(state), 500);
      }
      last_is_playing = state.is_playing;
    };
  };

  return class SpotifyPlayer {
    constructor(callback) {
      this._callback = callback;
      this._position_offset = 0;
      this._last_player_state_update_at = 0;
      this._pending_actions = [];
      this._log_entries = [];

      this._socket = new SpotifySocket();

      const on_player_state_changed =
        debounce_non_playing(this._on_player_state_changed.bind(this));

      this._socket.on('player_state_changed', (raw_state) => {
        this._log({label: 'player_state_changed', raw_state});
        on_player_state_changed(raw_state);
      });

      $interval(() => this._callback(this.state), CALLBACK_INTERVAL);
      $interval(() => this._force_update(), UPDATE_INTERVAL);
      this._force_update();
    }

    pause() {
      const action_name = 'pause';
      this._log({label: 'action_start', action_name});

      return this._request_and_expect({
        action_name,
        request: () => SpotifyPlayerClient.pause(),
        expect: () => !this.is_playing,
      });
    }

    play(track_uri) {
      const action_name = 'play';
      this._log({label: 'action_start', action_name, action_arguments: {track_uri}});

      return this._request_and_expect({
        action_name,
        request: () => SpotifyPlayerClient.play(track_uri === this.track_uri ? null : track_uri),
        expect: () => this.is_playing && (!track_uri || track_uri === this.track_uri),
      });
    }

    seek(position, {play} = {play: false}) {
      const action_name = 'seek';
      position = Number(position);
      this._log({label: 'action_start', action_name, action_arguments: {position, play}});

      return this._request_and_expect({
        action_name,
        request: () => SpotifyPlayerClient.seek(position),
        expect: () => Math.abs(this._player_state_message.state.progress_ms - position) < SEEK_TOLERENCE,
      }).then(() => play ? this.play() : this.state);
    }

    print_log_entries() {
      console.log(this._log_entries); // eslint-disable-line no-console
    }

    download_log_entries() {
      const blob = new Blob([JSON.stringify(this._log_entries)], {type: 'text/csv'});
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = `spotify_player_log_${Date.now()}.json`;
      a.click();
    }

    get is_playing() {
      return _.get(this._player_state_message, ['state', 'is_playing'], false);
    }

    get position() {
      if (!this._player_state_message) return 0;
      return Math.max(this._position_without_offset - this._position_offset, 0);
    }

    get track() {
      return _.get(this._player_state_message, ['state', 'item'], null);
    }

    get track_uri() {
      return _.get(this.track, ['linked_from', 'uri']) || _.get(this.track, 'uri') || null;
    }

    get state() {
      return _.pick(this, ['is_playing', 'position', 'track', 'track_uri']);
    }

    get playback_type() {
      return 'spotify';
    }

    get playback_label() {
      return 'Spotify Websocket';
    }

    _force_update() {
      SpotifyPlayerClient.shuffle(false);
    }

    _on_player_state_changed(raw_state) {
      if (_.get(this._player_state_message, 'event_id', 0) >= raw_state.event_id) {
        alert(
          'An irregular event has occurred. Please download the player log and ' +
            'forward it to engineering, along with a description of the network ' +
            'connection of your player device (wifi, ethernet, etc)'
        );

        return;
      }

      this._last_player_state_update_at = Date.now();

      if (this._new_state_should_update_position_offset(raw_state)) {
        this._position_offset = this._last_player_state_update_at - raw_state.state.timestamp;
      }

      this._player_state_message = raw_state;

      this._pending_actions.forEach((pending_action) => {
        if (pending_action.expect()) pending_action.deferred.resolve(this.state);
      });
    }

    _new_state_should_update_position_offset({state}) {
      if (!this._player_state_message) return false;
      if (this.is_playing !== state.is_playing) return true;
      if (Math.abs(this._position_without_offset - state.progress_ms) > SEEK_THRESHOLD) return true;
      return false;
    }

    _request_and_expect({request, expect, action_name}) {
      if (expect()) return $q.when(this.state);

      const pending_action = {expect, deferred: $q.defer()};
      let timeout;

      this._pending_actions.push(pending_action);

      pending_action.deferred.promise.finally(() => {
        $timeout.cancel(timeout);
        _.pull(this._pending_actions, pending_action);
      });

      request().then(() => {
        timeout = $timeout(() => {
          this._log({label: 'action_timeout', action_name});
          pending_action.deferred.reject(new Error('Timeout'));
        }, ACTION_TIMEOUT);
      }).catch((error) => {
        this._log({label: 'action_error', action_name, error});
        pending_action.deferred.reject(error);
      });

      return pending_action.deferred.promise.then((result) => {
        this._log({label: 'action_success', action_name, result});
        return result;
      });
    }

    _log(entry) {
      this._log_entries = [
        ..._.takeRight(this._log_entries, 5000),
        _.extend(entry, {
          state: this.state,
          timestamp: Date.now(),
          pending_action_count: this._pending_actions.length,
        }),
      ];
    }

    get _position_without_offset() {
      if (!this._player_state_message) return 0;
      const progress_since_update = this.is_playing ? Date.now() - this._last_player_state_update_at : 0;
      return this._player_state_message.state.progress_ms + progress_since_update;
    }
  };
}]);
