/* eslint-env es6 */

angular.module('genius').factory('PromiseQueue', ['$q', function($q) {
  return class {
    constructor(options = {}) {
      this.pending_count = 0;
      this.queue =
        new Rx.Subject().
          do(() => this.pending_count++).
          concatMap(({f, deferred}) => {
            deferred.resolve(f());
            let observable = Rx.Observable.fromPromise(deferred.promise);
            if (!options.fail_fast) {
              observable = observable.catch(error => Rx.Observable.of(error));
            }
            return observable;
          });
      this.queue.subscribe(() => this.pending_count--, _.noop);
    }

    is_empty() {
      return this.pending_count === 0;
    }

    add(f) {
      const deferred = $q.defer();
      this.queue.next({f, deferred});
      return deferred.promise;
    }
  };
}]);
