/* eslint-env es6 */

angular.module('genius').component('videoCarousel', {
  bindings: {
    object: '<',
    placement: '@',
    variants: '<',
    title: '@',
    active_video: '<activeVideo',
    on_load: '&onLoad',
    video_list: '<videoList',
  },

  templateUrl: 'video_carousel.html',

  controller: ['VideoPlacement', '$templateCache', function(VideoPlacement, $templateCache) {
    this.$onChanges = (changes) => {
      if (changes.object) {
        this.video_placement = new VideoPlacement(this.object, this.placement, (videos) => {
          this.loaded = true;
          this.on_load({videos});
        });
      }

      this.logo_name = () =>
        this.video_list ?
          `${this.video_list.slug}.svg` : null;
      this.has_logo = () => !!$templateCache.get(this.logo_name());
      this.logo_variants = () => {
        let variants;
        switch (this.logo_name()) {
        case 'news.svg':
          variants = ['medium'];
          break;
        case 'bts.svg':
          variants = ['large'];
          break;
        case 'for-the-record.svg':
          variants = ['large'];
          break;
        case 'irl.svg':
          variants = ['medium'];
          break;
        default:
          variants = ['small'];
        }
        return _.map(variants, variant => `video_carousel-video-card-logo--${variant}`).join(' ');
      };
    };
  }],
});
