/* eslint-env es6 */

angular.module('genius').factory('Lyrics', ['mobile_device', function(mobile_device) {
  const lazy_load_unit_when_within = mobile_device ? '75vh' : '50vh';

  const DEFERRED_IN_READ_AD_TAG = slot_name => `
    <defer-compile
      class="u-display_block"
      timing-label="deferred-inread-ad-loaded-${slot_name}"
      within-visibility-range="${lazy_load_unit_when_within}">
      ${IN_READ_AD_TAG(slot_name)}
    </defer-compile>
  `;

  const IN_READ_AD_TAG = slot_name => `
    <span avoid-selection class="u-noselect">
      <dfp-ad
        name="${slot_name}"
        class="u-display_block"
        ad-classes="{'dfp_unit--in_read': $height > 1, 'u-xx_large_top_margin': $height > 0}"
      ></dfp-ad>
    </span>
  `;

  const INREAD_INJECTIONS =
      (mobile_device ? ['mobile_song_medium1', 'mobile_song_medium2', 'mobile_song_medium3']
        : ['desktop_song_inread', 'desktop_song_inread2', 'desktop_song_inread3']).
        map(slot_name => DEFERRED_IN_READ_AD_TAG(slot_name));

  const inject_multi = (lyrics_html, injections, lines_between_injections = 25, ensure_html = '') => {
    const minimal_dom = $('<div>').html(lyrics_html.replace(/\s*\n/g, ''));
    let all_line_breaks = minimal_dom.find('br');

    injections.forEach((injection) => {
      all_line_breaks = _.drop(all_line_breaks, lines_between_injections);
      all_line_breaks = _.dropWhile(all_line_breaks, br => _.get(br, 'previousSibling.nodeName', '').toLowerCase() !== 'br');
      const element_to_inject = $(injection);
      const inject_before_node = all_line_breaks.shift();

      if (inject_before_node) {
        $(inject_before_node).before(element_to_inject);
      } else if (ensure_html === injection) {
        minimal_dom.append(element_to_inject);
      }
    });

    return minimal_dom.html();
  };

  const escape_interpolation = html => html.replace(/}}/g, '}<!-- literal brace -->}');

  return {
    prepare_for_display(lyrics_html) {
      return escape_interpolation(inject_multi(lyrics_html, INREAD_INJECTIONS, 25));
    },
  };
}]);
