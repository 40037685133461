/* eslint-env es6 */

angular.module('genius').factory('Reversible', ['$parse', function($parse) {
  return {
    modify(scope, changes) {
      let key;
      const parsers = {};
      const originals = {};
      for (key in changes) {
        parsers[key] = $parse(key);
        if (_.has(scope, key)) {
          originals[key] = parsers[key](scope);
        }
        parsers[key].assign(scope, changes[key]);
      }

      return function() {
        for (key in changes) {
          if (_.has(originals, key)) {
            parsers[key].assign(scope, originals[key]);
          } else {
            key.split('.').reduce((memo, k, index, all) => {
              if (memo) return index === all.length - 1 ? Reflect.deleteProperty(memo, k) : memo[k];
            }, scope);
          }
        }
      };
    },
  };
}]);
