/* eslint-env es6 */

angular.module('genius').factory('CustomPerformanceRoleClient', ['$http', 'AppConfig', function($http, AppConfig) {
  return {
    autocomplete_api_endpoint: `${AppConfig.api_root_url}/custom_performance_roles/autocomplete`,

    autocomplete(query) {
      return $http.get(this.autocomplete_api_endpoint, {params: {q: query}}).
        then(response => response.data.response);
    },

    autocomplete_options: {
      minChars: 2,
      parse(response) {
        return _.map(response.response.custom_performance_roles, (customPerformanceRole) => {
          const label = customPerformanceRole.label;
          return {data: [label], value: label, result: label};
        });
      },
    },
  };
}]);
