import 'dragster';
/* eslint-env es6 */
/* global Dragster */


angular.module('genius').directive('dragAndDrop', ['Bindings', function(Bindings) {
  const resolve_bindings = Bindings.resolver({
    data: '<dragAndDrop',
    draggable: '<',
    on_dragstart: '&onDragstart',
    on_dragend: '&onDragend',
    on_dragenter: '&onDragenter',
    on_dragleave: '&onDragleave',
    on_drop: '&?onDrop',
    cant_drop: '<cantDrop',
  });

  return {
    restrict: 'A',

    link(scope, element, attrs) {
      const bindings = resolve_bindings(scope, attrs);

      if (bindings.draggable) {
        element.addClass('draggable_item');

        element[0].addEventListener('dragstart', (event) => {
          event.dataTransfer.setData('text', JSON.stringify(bindings.data));
          element.addClass('draggable_item--dragging');
          scope.$apply(bindings.on_dragstart);
        });

        element[0].addEventListener('dragend', () => {
          element.removeClass('draggable_item--dragging');
          scope.$apply(bindings.on_dragend);
        });
      }

      if (bindings.on_drop) {
        new Dragster(element[0]);

        element[0].addEventListener('dragster:enter', () => {
          if (bindings.cant_drop) return;
          element.addClass('droppable_item');
          scope.$apply(bindings.on_dragenter);
        });

        element[0].addEventListener('dragster:leave', () => {
          if (bindings.cant_drop) return;
          element.removeClass('droppable_item');
          scope.$apply(bindings.on_dragleave);
        });

        element[0].addEventListener('dragover', (event) => {
          event.preventDefault();
        });

        element[0].addEventListener('drop', (event) => {
          event.stopPropagation();
          event.preventDefault();
          element.removeClass('droppable_item');
          if (bindings.cant_drop) return;
          const data = JSON.parse(event.dataTransfer.getData('text'));
          scope.$apply(() => bindings.on_drop({data}));
          return false;
        });
      }
    },
  };
}]);
