/* eslint-env es6 */

angular.module('genius').component('lyricsSyncHome', {
  bindings: {
    instructions_url: '@instructionsUrl',
  },

  templateUrl: 'lyrics_sync_home.html',

  controller: ['$rootScope', '$location', '$window', 'LyricsSynchronizationClient', function($rootScope, $location, $window, LyricsSynchronizationClient) {
    $rootScope.$on('$locationChangeStart', () => {
      this.id = null;
      this.tasks = null;
      this.checked_out = null;
      this.navigating = true;
    });

    $rootScope.$on('$locationChangeSuccess', () => {
      if ($location.path() === '/lyrics_synchronizations/home') {
        this.load();
      } else {
        this.id = parseInt($location.path().replace(/^\/lyrics_synchronizations\//, ''));
        if (!this.id) return $location.path('/lyrics_synchronizations/home');
        this.navigating = null;
      }
    });

    this.load = function() {
      LyricsSynchronizationClient.home().then((home) => {
        _.assign(this, home);
        this.navigating = null;
      });
    };

    this.start = (verb) => {
      LyricsSynchronizationClient.start(verb).
        then(display_lyrics_synchronization);
    };

    this.check_out = (lyrics_synchronization) => {
      LyricsSynchronizationClient.submit('check_out', lyrics_synchronization).
        then(display_lyrics_synchronization);
    };

    this.check_in = function(lyrics_synchronization) {
      LyricsSynchronizationClient.submit('check_in', lyrics_synchronization).
        then(this.load.bind(this));
    };

    this.navigate_to = (path) => {
      $location.path(`/lyrics_synchronizations/${path}`);
    };

    const display_lyrics_synchronization = (lyrics_synchronization) => {
      if (lyrics_synchronization.state === 'pristine') {
        $window.open(`/songs/${lyrics_synchronization.song_id}`);
      }
      $location.path(`/lyrics_synchronization/${lyrics_synchronization.id}`);
    };
  }],
});
