import 'jquery-mousewheel/jquery.mousewheel.js';
/* eslint-env es6 */


angular.module('genius').directive('infiniteScroll', ['$window', '$document', '$immediate', function($window, $document, $immediate) {
  return {
    link(scope, elem, attrs) {
      let scrollDisabled = false;
      const loadTrigger = attrs.infiniteScrollLoadTrigger || 'scroll';

      const $window_element = $($window);
      const observedElement = document.createElement('div');
      let observer;

      if (loadTrigger === 'intersection') {
        observedElement.style.height = '1px';
        elem.append(observedElement);
      }

      if ('infiniteScrollDisabled' in attrs) {
        scope.$watch(attrs.infiniteScrollDisabled, (value) => {
          scrollDisabled = value;
          if (!scrollDisabled) $immediate(handleScroll, !'invoke_apply');
        });
      }

      const run_infinite_scroll_function = () => {
        scope.$apply(() => {
          scope.$eval(attrs.infiniteScroll);
        });
      };

      const handleScroll = (event) => {
        if (scrollDisabled) return;

        let window_bottom = $document[0].documentElement.clientHeight;
        if (event) {
          const target = event.target;
          if (target.getBoundingClientRect) {
            window_bottom = target.getBoundingClientRect().top + target.offsetHeight;
          }
        }

        const element_bottom = elem[0].getBoundingClientRect().top + elem[0].offsetHeight;

        if (window_bottom >= element_bottom - 1) run_infinite_scroll_function();
      };

      const handleIntersection = ([entry]) => {
        if (entry.isIntersecting) run_infinite_scroll_function();
      };

      if (loadTrigger === 'intersection') {
        observer = new IntersectionObserver(handleIntersection, {
          root: null,
          rootMargin: '100px',
          threshold: 0,
        });

        observer.observe(observedElement);
        scope.$on('$destroy', () => observer.unobserve(observedElement));
      } else {
        $window_element.on('scroll', handleScroll);
        $window_element.on('resize', handleScroll);
        elem.parents().on('scroll', handleScroll);

        scope.$on('$destroy', () => {
          elem.parents().off('scroll', handleScroll);
          return $window_element.off('scroll', handleScroll);
        });
      }

      if (attrs.yieldsOnLoadMore) {
        scope.$eval(attrs.yieldsOnLoadMore, {callback: () => $immediate(handleScroll, !'invoke_apply')});
      }
    },
  };
}]);
