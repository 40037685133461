/* eslint-env es6 */

angular.module('genius').directive('inboxLineItem', ['Tracking', function(Tracking) {
  const formations = [
    {
      formation: ['targets', 'actors'],
      strategies: [
        'user_upvoted_annotation', 'user_downvoted_annotation',
        'user_accepted_annotation',
        'user_created_annotation', 'user_deleted_annotation',
        'user_pyonged_annotation', 'user_pyonged_song',
        'user_pyonged_video', 'user_pyonged_album',
        'user_pyonged_article',
        'user_upvoted_forum_post', 'user_downvoted_forum_post',
        'user_upvoted_video', 'user_downvoted_video',
        'user_hit_pageview_threshold_song',
        'user_created_cover_art', 'user_edited_cover_art', 'user_deleted_cover_art',
        'user_locked_album', 'user_unlocked_album',
        'user_recognized_song',
        'user_upvoted_article', 'user_downvoted_article',
        'user_moved_discussion', 'user_followed_discussion',
        'user_followed_song', 'user_followed_artist',
        'user_hid_song', 'user_unhid_song',
        'user_cosigned_annotation', 'user_verified_the_lyrics_of_song',
        'user_unverified_the_lyrics_of_song',
        'user_pinned_discussion', 'user_unpinned_discussion',
        'user_deleted_discussion', 'user_deleted_song',
        'user_created_song', 'user_wrote_article',
        'user_locked_song', 'user_unlocked_song',
        'user_followed_group', 'user_followed_web_page',
        'user_marked_complete_song', 'user_unmarked_complete_song',
        'user_marked_as_high_transcription_priority_song',
      ],
    },
    {
      formation: ['targets', 'context', 'actors'],
      strategies: [
        'user_created_question', 'user_archived_question', 'user_edited_question',
        'user_pinned_question', 'user_unpinned_question',
        'user_created_answer', 'user_deleted_answer', 'user_edited_answer',
        'user_upvoted_question', 'user_downvoted_question',
        'user_upvoted_answer', 'user_downvoted_answer',
      ],
    },
    {
      formation: ['targets', 'actions'],
      strategies: [
        'user_edited_annotation', 'user_proposed_an_edit_to_annotation',
        'user_added_a_suggestion_to_song',
        'user_marked_annotation', 'user_commented_on_album',
        'user_commented_on_article', 'user_commented_on_video',
        'user_replied_to_annotation',
      ],
    },
    {
      formation: ['context', 'targets', 'actors'],
      strategies: [
        'user_upvoted_comment', 'user_downvoted_comment',
        'user_merged_annotation_edit', 'user_rejected_annotation_edit',
        'user_integrated_comment', 'user_archived_comment',
        'user_accepted_comment', 'user_rejected_comment',
      ],
    },
    {
      formation: ['context', 'actors'],
      strategies: [
        'user_invited_user',
      ],
    },
    {
      formation: ['user_acted_on_user'],
      strategies: [
        'user_followed_user', 'user_de_modded_user', 'user_marked_as_spam_user',
        'user_made_an_editor_out_of_user', 'user_made_a_moderator_out_of_user', 'user_mediatored_user',
        'user_transcribered_user',
      ],
    },
    {
      formation: ['context_commentable', 'context'],
      strategies: [
        'user_mentioned_user', 'user_mentioned_group',
      ],
    },
    {
      formation: ['actors'],
      strategies: [
        'user_created_identity', 'user_added_a_photo_user',
      ],
    },
    {
      formation: ['merged_artist', 'context', 'actors'],
      strategies: [
        'user_merged_artist',
      ],
    },
    {
      formation: ['metadata_changes', 'targets', 'actors'],
      strategies: [
        'user_edited_the_metadata_of_song',
        'user_edited_the_metadata_of_artist',
        'user_edited_the_metadata_of_album',
      ],
    },
    {
      formation: ['lyrics_diffs', 'targets', 'actors', 'lyrics_edit_acceptor'],
      strategies: ['user_edited_the_lyrics_of_song'],
    },
    {
      formation: ['lyrics_diffs', 'context', 'actors'],
      strategies: [
        'user_created_lyrics_edit',
        'user_accepted_lyrics_edit',
        'user_rejected_lyrics_edit',
      ],
    },
    {
      formation: ['lyrics_edit_archive_info', 'lyrics_diffs', 'context', 'actors'],
      strategies: ['user_archived_lyrics_edit'],
    },
    {
      formation: ['annotations_with_comments'],
      strategies: ['user_added_a_suggestion_to_annotation'],
    },
    {
      formation: ['annotations_with_rejection_comments'],
      strategies: ['user_rejected_annotation'],
    },
    {
      formation: ['targets'],
      strategies: ['user_posted_in_discussion'],
    },
  ];
  const formation_by_strategy = {};
  for (const formation of formations) {
    for (const strategy of formation.strategies) {
      formation_by_strategy[strategy] = formation.formation;
    }
  }

  const default_line_item_template = 'inbox_line_items/generic.html';
  const line_item_templates = {
    'user.Pyong.song': 'inbox_line_items/pyong.html',
    'user.Pyong.annotation': 'inbox_line_items/pyong.html',
    'user.Pyong.video': 'inbox_line_items/pyong.html',
    'user.Pyong.album': 'inbox_line_items/pyong.html',
    'user.Pyong.article': 'inbox_line_items/pyong.html',
  };

  return {
    scope: {
      inbox_name: '<inboxName',
      line_item: '<object',
      eager_load_details: '<eagerLoadDetails',
      variants: '<',
    },

    templateUrl: 'inbox_line_item.html',

    controller: ['$scope', 'querystring', 'InboxClient', 'AppConfig', function($scope, querystring, InboxClient, AppConfig) {
      this.state = {
        load_details: false,
        show_details: false,
      };

      $scope.details_url = `/activity_stream/show_details?${querystring.stringify({id: $scope.line_item.event_ids})}`;
      this.formation = formation_by_strategy[$scope.line_item.strategy];

      $scope.$watch('ctrl.state.load_details', (should_load, previous_state) => {
        if (this.formation && should_load && !previous_state) {
          InboxClient.get_line_item($scope.inbox_name, $scope.line_item.event_ids.join(',')).then((detail) => {
            this.line_item_detail = detail;
          });
        }
      });

      $scope.$watch('eager_load_details', (eager_load_details) => {
        this.state.load_details = !!(this.state.load_details || eager_load_details);
      });

      this.message_components = $scope.line_item.message_components;
      this.message_template = line_item_templates[this.message_components.key] || default_line_item_template;

      const track_open = _.once(() => {
        Tracking.track('Line Item Open', {
          action_name: $scope.line_item.action_name,
          inbox_name: $scope.inbox_name,
          strategy_name: $scope.line_item.strategy,
          bagon: true,
          iframe: !this.formation,
        });
      });

      this.toggle_visible = () => {
        this.state.load_details = true;
        this.state.show_details = !this.state.show_details;

        if (this.state.show_details) track_open();

        if (this.state.show_details && $scope.line_item.unread) {
          $scope.line_item.unread = false;
          InboxClient.mark_read(
            $scope.inbox_name,
            [$scope.line_item.event_ids]
          );
        }
      };

      this.truncate_line_item_event_count = $scope.line_item.strategy === 'user_posted_in_discussion';
      this.max_line_item_event_count = AppConfig.max_line_item_event_count;
    }],

    controllerAs: 'ctrl',
  };
}]);
