/* eslint-env es6 */

angular.module('genius').filter('genericLineItemMessage', ['i18nFilter', function(i18nFilter) {
  return (key, params) => {
    const options = Object.assign({
      scope: 'activemodel.activity_stream_line_item_message.message',
      defaultKey: 'default',
    }, params);

    return i18nFilter(key, options);
  };
}]);
