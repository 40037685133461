import 'jquery.urlinternal';
/* eslint-env es6 */


angular.module('genius').factory('LyricsEditing', ['AppConfig', 'HtmlConstants', 'MarkdownHelpers', 'MarkdownPlaintextFinderRegex', 'ReferentRegistry', 'string_has_unmatched_parentheses', 'I18n', function(AppConfig, HtmlConstants, MarkdownHelpers, MarkdownPlaintextFinderRegex, ReferentRegistry, string_has_unmatched_parentheses, I18n) {
  const ALLOWED_HTML_TAGS_FOR_MUSIC = ['p', 'br', 'b', 'strong', 'em', 'i', 'a'];

  const is_real_link = (href = '') => {
    const link = href.trim();
    return link[0] === '/' ||
           link.indexOf('http') === 0 ||
           link.includes(AppConfig.canonical_domain);
  };

  const LyricsEditing = {
    lyrics_html_without_referent(lyrics_html, referent_id) {
      const lyrics = $('<div>').append($(lyrics_html));
      lyrics.find(`[data-id='${referent_id}']`).each((_index, referent) => {
        const $referent = $(referent);
        const content = $referent.find('img')[0] || $referent.text();
        $referent.replaceWith(content);
      });
      return lyrics.html();
    },

    prepare_lyrics_for_editing(lyrics) {
      return this.lyrics_html_to_pseudo_markdown__deprecated(this.clean_up_lyrics__deprecated(lyrics));
    },

    convert_editable_for_saving(editable) {
      return this.clean_up_lyrics__deprecated(
        this.lyrics_pseudo_markdown_to_html__deprecated(editable.markdown, editable.attr_hash)
      );
    },

    prepare_lyrics_with_correction(lyrics, correction) {
      if (correction.referent.id) {
        const $lyrics = $(lyrics);
        const $referent = $($lyrics.
          find(`[data-id='${correction.referent.id}']`)[correction.referent.occurrence - 1]);
        $referent.html(correction.replacement.html);
        return this.prepare_lyrics_for_editing($lyrics[0].outerHTML);
      } else {
        let index = -1;
        const occurrence = correction.referent.occurrence_in_markdown;
        const editable = this.prepare_lyrics_for_editing(lyrics);
        const replacement_matcher = MarkdownPlaintextFinderRegex(correction.original.markdown);
        editable.markdown = editable.markdown.replace(replacement_matcher, () => {
          ++index;
          return index === occurrence ? correction.replacement.markdown :  correction.original.markdown;
        });
        return editable;
      }
    },

    clean_up_lyrics__deprecated(lyrics) {
      return $.trim($('<div>').append(lyrics).find('a').each(function() {
        const $a = $(this);
        if (!$a.attr('data-id') && !$a.data('real-link') && !$a.hasClass('referent')) {
          const err = new Error(`Error processing lyrics for editing: detected non-referent link (${$a[0].outerHTML})`);
          err.human_readable_message = "Your browser's copy of the lyrics is out of sync. Try reloading the page";
          throw err;
        }
        if ($a.html().match(/<br[\s\/]?>$/i)) {
          $a.find('br:last').remove();
          $a.after('<br>');
        }
      }).end().
        find('#annotation_prompt').remove().end().
        html().
        replace(/<p( [^>]*)?>/gi, '').
        replace(/<\/p>\s*/gi, '\n\n').
        replace(HtmlConstants.top_level_block_containers_regexp, '$&\n\n').
        replace(HtmlConstants.top_level_stand_alone_blocks_regexp, '$&\n\n').
        replace(/\s*<br[\s\/]?>([ \t]*)\s*/gi, '\n$1'));
    },

    lyrics_html_to_pseudo_markdown__deprecated(lyrics) {
      const result = {attr_hash: {}};
      const markdown = $('<div>').append(lyrics).find('a').each(function() {
        const $element =  $(this);

        $element.find('.referent-vote_total').remove();
        const annotation_id = $element.attr('data-id');
        if (!_.isUndefined(annotation_id)) {
          _.set(result.attr_hash, annotation_id, {
            'class': $element.attr('class'),
            'data-editorial-state': $element.attr('data-editorial-state'),
          });
          $element.replaceWith(`[${$element.html()}](${annotation_id})`);
        } else if ($element.data('real-link')) {
          $element.replaceWith(`[${$element.html()}](${$element.attr('href')})`);
        } else {
          $element.replaceWith($element.html());
        }
      }).end().find('lyrics').removeAttr('ng-non-bindable').end();

      result.markdown = _.unescape(markdown.find('annotatable-image').each(function() {
        const $element =  $(this);

        const img_url = $element.attr('src');
        $element.replaceWith($('<img>').attr('src', img_url));
      }).end().html());

      return result;
    },

    markdown_has_block_level_markup(markdown) {
      return Boolean($('<div>').append(markdown).find([
        '*',
        ...ALLOWED_HTML_TAGS_FOR_MUSIC.map(tag => `:not(${tag})`),
      ].join('')).length);
    },

    html_has_block_level_markup: _.memoize((html) => {
      const {markdown} = LyricsEditing.
        lyrics_html_to_pseudo_markdown__deprecated(html);
      return LyricsEditing.
        markdown_has_block_level_markup(markdown);
    }),

    lyrics_pseudo_markdown_to_html__deprecated(lyrics, attrs) {
      attrs || (attrs = {});
      lyrics = lyrics.replace(/\n/gi, '<br>');
      const $lyrics = $('<div>').
        append(MarkdownHelpers.convert_markdown_anchors(lyrics));

      const converted_html = $lyrics.find('a').each(function() {
        const $a = $(this);
        $a.attr('href', $.trim($a.attr('href')));
        if ($a.attr('href').match(/^\d+$/)) {
          $a.
            attr('data-id', $a.attr('href')).
            attr('href', `/${$a.attr('data-id')}`).
            attr(_.get(attrs, $a.attr('data-id'), {}));
        } else if (is_real_link($a.attr('href'))) {
          $a.attr('target', $.isUrlInternal($a.attr('href')) ? '_self' : '_blank').attr('data-real-link', true);
        } else { // the "[Hook](2x)" case
          $a.replaceWith(`[${$a.html()}](${$a.attr('href')})`);
        }
      }).end().find('lyrics').attr('ng-non-bindable', true).end().html();

      return MarkdownHelpers.
        unescape_special_characters_escaped_by_markdown_anchor_converter(converted_html);
    },

    backfill_angular_link_properties(html) {
      const $container = $('<div>').append(html).find('a[data-id]').each(function() {
        const $a = $(this);
        const id = Number($a.attr('data-id'));
        const referent = ReferentRegistry.peek(id);

        if (referent) {
          $a.attr('annotation-fragment', id);
          $a.attr('on-hover-with-no-digest', 'set_current_hover_and_digest(hover ? fragment_id : undefined)');
          $a.attr('ng-click', 'open()');
          $a.attr('prevent-default-click', '');
          $a.attr('classification', referent.classification);
          $a.attr('image', referent.is_image);
          $a.attr('pending-editorial-actions-count', referent.pending_editorial_actions_count);
        }
      }).end();
      return $('<div>').append($container).html();
    },

    validate_pseudo_markdown_lyrics(original_html, markdown, {is_music = true}) {
      const errors = [];
      const lyrics_text = $('<div>').append(markdown).text();

      if (string_has_unmatched_parentheses(lyrics_text)) {
        errors.push(I18n.t('lyrics.unmatched_parens_error'));
      }

      if (is_music) {
        const had_block_level_markup = this.html_has_block_level_markup(original_html);
        const has_block_level_markup = this.markdown_has_block_level_markup(markdown);
        if (had_block_level_markup && has_block_level_markup) {
          errors.push(I18n.t('lyrics.didnt_remove_block_level_html_from_music_error'));
        } else if (!had_block_level_markup && has_block_level_markup) {
          errors.push(I18n.t('lyrics.added_block_level_html_to_music_error'));
        }
      }

      return errors;
    },
  };

  return LyricsEditing;
}]);
