angular.module('genius').component('albumAdminMenu', {
  templateUrl: 'album_admin_menu.html',

  bindings: {
    album: '<',
    album_appearances: '<albumAppearances',
  },

  controller: ['Session', 'Attempt', 'AlbumClient', 'currentFlashMessages', '$window', 'array_to_sentence', function(Session, Attempt, AlbumClient, currentFlashMessages, $window, array_to_sentence) {
    this.actions = ['destroy', 'unlock', 'lock', 'super_lock', 'sort_songs'];
    const non_confirmable_admin_actions = ['sort_songs'];

    this.can_perform = action_name => Session.has_permission(action_name, this.album);

    const change_lock_state = (state_event) => {
      AlbumClient.change_lock_state(this.album.id, state_event).then((updated_album) => {
        currentFlashMessages.push({type: 'notice', message: `Successfully ${state_event}ed album`});
        Object.assign(this.album, updated_album);
      });
    };

    const admin_actions = {
      destroy: () => {
        AlbumClient.delete(this.album.id).then(() => {
          currentFlashMessages.push({type: 'notice', message: 'Successfully deleted album'});
          $window.location.href = '/';
        });
      },

      lock: () => change_lock_state('lock'),

      super_lock: () => change_lock_state('super_lock'),

      unlock: () => change_lock_state('unlock'),

      sort_songs: () => this.show_sorted_modal = true,
    };

    this.attempt_admin_action = (action_name) => {
      if (_.includes(non_confirmable_admin_actions, action_name)) {
        return admin_actions[action_name]();
      }

      Attempt.admin_action(
        admin_actions[action_name],
        I18n.lookup(`album.action_confirmations.${action_name}`) || I18n.t('album.action_confirmations.admin_default')
      );
    };

    this.$onChanges = () => {
      this.has_admin_items = _(this.actions).some(action => this.can_perform(action));
    };

    this.tracklist_markup = () => {
      const primary_artists_links = array_to_sentence(
        this.album.primary_artists.map(artist => `[${artist.name}](${artist.url})`)
      );
      const album_link = `[*${this.album.name}*](${this.album.url})`;
      const header = `<b>${primary_artists_links} - ${album_link} Lyrics and Tracklist</b>\n\n`;
      const body = this.album_appearances.map((appearance) => {
        const ft = appearance.song.featured_artists.map(artist => artist.name).join(', ');
        return `${appearance.track_number || '?'}. [${appearance.song.title}](${appearance.song.url})${ft ? ` ft. ${ft}` : ''}`;
      }).join('\n');
      return header + body;
    };
  }],
});
