/* eslint-env es5 */
/* eslint-disable arrow-body-style, arrow-parens, arrow-spacing, constructor-super, generator-star-spacing, no-class-assign, no-confusing-arrow, no-const-assign, no-dupe-class-members, no-duplicate-imports, no-extra-bind, no-new-symbol, no-restricted-imports, no-this-before-super, no-useless-computed-key, no-useless-constructor, no-useless-rename, no-var, object-shorthand, prefer-arrow-callback, prefer-const, prefer-numeric-literals, prefer-reflect, prefer-rest-params, prefer-spread, prefer-template, require-yield, rest-spread-spacing, sort-imports, symbol-description, template-curly-spacing, yield-star-spacing, dot-location, key-spacing, max-len, new-parens, no-lonely-if, no-mixed-operators, no-trailing-spaces, no-whitespace-before-property, padded-blocks, quotes, space-infix-ops */

angular.module('genius').component('profileAnnotation', {
  bindings: {
    annotation: '<',
    referent: '<',
    variants: '<',
  },

  templateUrl: 'profile_annotation.html',

  controller: ['$scope', function($scope) {
    $scope.$watchGroup(['$ctrl.annotation.community', '$ctrl.annotation.cosigned_by'], function() {
      this.referent_display_mode = this.annotation.community && this.annotation.cosigned_by.length === 0 ? 'default' : 'green';
    }.bind(this));

    $scope.$on('annotation_deleted', function(_scope, annotation, promise) {
      if (annotation === this.annotation) {
        promise.then(function() {
          this.deleted = true;
        }.bind(this));
      }
    }.bind(this));
  }],
});
