/* eslint-env es6 */

angular.module('genius').directive('youtubePlayer', ['$document', 'Youtube', function($document, Youtube) {
  const YOUTUBE_ID_REGEX = /http:\/\/www\.youtube\.com\/watch\?v=(.*)/i;

  return {
    restrict: 'E',

    scope: {
      src: '<?',
      start: '<?',
      controls: '<?',
      fullscreen: '<?',
      yields_api: '&yieldsApi',
      yields_state: '&yieldsState',
      eager_load: '<?eagerLoad',
      autoplay: '<?',
      playsinline: '<?',
      on_playing: '&onPlaying',
    },

    link(scope, element) {
      scope.state = {};

      const container = $document[0].createElement('div');
      element[0].appendChild(container);

      const youtube = new Youtube(container, {
        controls: scope.controls,
        fullscreen: scope.fullscreen,
        playsinline: scope.playsinline,
      });

      scope.$watch('src', (src) => {
        const id = src.match(YOUTUBE_ID_REGEX)[1];
        youtube.cue_video_by_id(id, scope.start || 0);

        if (scope.eager_load) {
          youtube.api.load_media();
          if (scope.autoplay) {
            youtube.api.mute();
            youtube.api.playpause();
          }
        }

        scope.yields_api({api: youtube.api});
        scope.yields_state({state: youtube.state});
      });

      youtube.state.$watch('is_playing', (is_playing) => {
        if (is_playing) scope.on_playing();
      });
    },
  };
}]);
