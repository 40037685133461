/* eslint-env es6 */

angular.module('genius').component('videoListPage', {
  templateUrl: 'video_list_page.html',

  controller: Object.assign(['Tracking', 'Session', 'PagedDataFeed', 'VideoListClient', function(Tracking, Session, PagedDataFeed, VideoListClient) {
    this.$routerOnActivate = (next) => {
      this.page_data = next.routeData.page_data;
      Tracking.track('Video List Page Load', {
        'Video List ID': this.page_data.video_list.id,
        'counts_as_page_load': true,
      });
      this.scrollable_videos_data = new PagedDataFeed(
        _.partial(VideoListClient.videos, this.page_data.video_list.slug),
        'videos',
        {per_page: 60},
        this.page_data.next_page,
      );
    };

    this.can_perform = action_name => Session.has_permission(action_name, this.page_data.video_list);
  }], {
    $canActivate: ['$nextInstruction', '$prevInstruction', 'PageTransition', function(
      $nextInstruction,
      $prevInstruction,
      PageTransition
    ) {
      return PageTransition.by_router_instruction($nextInstruction, $prevInstruction);
    }],
  }),
});
