/* eslint-env es6 */

angular.module('genius').factory('LinkEvents', () => {
  const RIGHT_CLICK_EVENT = 2;

  const base_target_is_parent = () => {
    const base_tags = document.getElementsByTagName('base');
    return base_tags.length > 0 && base_tags[0].target === '_parent';
  };

  return {
    should_open_in_new_window_or_tab(event, anchor) {
      anchor = anchor || $(event.target).closest('a')[0];
      return event.which === RIGHT_CLICK_EVENT ||
        event.metaKey ||
        event.ctrlKey ||
        event.shiftKey ||
        anchor.target === '_blank' ||
        (base_target_is_parent() && !anchor.target);
    },
  };
});
