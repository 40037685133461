/* eslint-env es6 */

angular.module('genius').factory('Cookies', [function() {
  return {
    get(key) {
      return $.cookie(key);
    },

    getObject(key) {
      const value = $.cookie(key);
      if (value) return JSON.parse(value);
    },

    getAll() {
      return $.cookie();
    },

    put(key, value, options) {
      return $.cookie(key, value, options);
    },

    putObject(key, value, options) {
      return $.cookie(key, JSON.stringify(value), options);
    },

    remove(key, options) {
      return $.removeCookie(key, options);
    },
  };
}]);
