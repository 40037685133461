/* eslint-env es6 */

angular.module('genius').component('appleMusicPlayer', {
  templateUrl: 'apple_music_player.html',

  bindings: {
    url: '<',
    apple_music_id: '<appleMusicId',
    position_from_top: '<positionFromTop',
    on_in_apple_music_deal: '&onInAppleMusicDeal',
    yields_api: '&yieldsApi',
    on_play_state_change: '&onPlayStateChange',
  },

  controller: ['AppConfig', '$window', '$sce', '$element', '$scope', 'Tracking', '$timeout', 'Channel', 'LocalStorage', 'Session', function(AppConfig, $window, $sce, $element, $scope, Tracking, $timeout, Channel, LocalStorage, Session) {
    const INNER_IFRAME_MARGIN = 16;
    const PLAYER_DEFAULT_HEIGHT = 91;

    const SAFARI_TOOLBAR_HEIGHTS_BY_HEIGHT_DIFFERENCE = [{
      model: 'iPhone 8',
      min_expanded_viewport_height_difference: 75,
      max_expanded_viewport_height_difference: 85,
      toolbar_height: 45,
    }, {
      model: 'iPhone X',
      min_expanded_viewport_height_difference: 113,
      max_expanded_viewport_height_difference: 123,
      toolbar_height: 84,
    }];

    this.$onInit = () => {
      if (this.position_from_top) {
        this.update_player_top_value();
        $window.addEventListener('resize', () => this.update_player_top_value());
      }

      $window.addEventListener('message', (message) => {
        if (message.origin === `https://${AppConfig.canonical_domain}` && message.data === 'in_apple_music_deal') {
          $scope.$applyAsync(() => {
            this.in_apple_music_deal = true;
            this.on_in_apple_music_deal({in_apple_music_deal: true});
            if (this.position_from_top) $timeout(() => this.update_player_top_value(), 0);
          });
        }
      });

      if (!this.apple_music_id && LocalStorage.safe_get_item('apple_musickit_authorized', false)) {
        this.dismissed = true;
      }

      if (LocalStorage.safe_get_item('apple_music_player_dismissed', false) && Session.user_signed_in) {
        this.dismissed = true;
      }
    };

    this.$onChanges = (changes) => {
      if (changes.url) {
        this.src = $sce.trustAsUrl(this.url);
      }
    };

    this.iframe_loaded = (iframe) => {
      $scope.$applyAsync(() => this.loaded = true);

      const channel = Channel.build({
        window: iframe.contentWindow,
        origin: '*',
        scope: 'com.genius.Genius',
      });

      const toggle_play = () => {
        this.dismissed = false;
        channel.notify({
          method: 'header_play_pause',
          params: {},
        });
      };

      channel.bind('player_play_pause', (_transaction, play_state) =>
        $scope.$applyAsync(() => this.on_play_state_change({play_state})));

      channel.bind('player_auth', (_transaction, authorized) => {
        if (authorized && !this.apple_music_id) {
          this.dismiss();
        }
      });

      const api = {toggle_play};

      this.yields_api({api});

      this.dismiss = () => {
        Tracking.track('apple_player:player_dismissed', {
          provider: 'apple',
          provider_id: this.apple_music_id,
        });
        this.dismissed = true;
        channel.notify({
          method: 'dismiss',
          params: {},
        });
      };
    };

    const initial_viewport_height = window.innerHeight;

    const get_safari_toolbar_height_by_height_difference = (height_difference) => {
      const phone_properties = _.find(SAFARI_TOOLBAR_HEIGHTS_BY_HEIGHT_DIFFERENCE,
        ({min_expanded_viewport_height_difference: min, max_expanded_viewport_height_difference: max}) =>
          _.inRange(height_difference, min, max + 1));

      return _.get(phone_properties, ['toolbar_height'], 0);
    };

    this.update_player_top_value = (player_height_after_transition) => {
      if (!this.position_from_top) return false;
      const player_height = player_height_after_transition ||
        _.get($element.get(0), ['children', 0, 'clientHeight']) ||
        PLAYER_DEFAULT_HEIGHT;
      const current_viewport_height = $window.innerHeight;
      const expanded_viewport_height_difference = current_viewport_height - initial_viewport_height;
      const safari_toolbar_height = get_safari_toolbar_height_by_height_difference(expanded_viewport_height_difference);
      const safari_toolbar_clearance = safari_toolbar_height > 0 ? safari_toolbar_height - INNER_IFRAME_MARGIN : 0;
      const new_top = `${current_viewport_height - player_height - safari_toolbar_clearance}px`;

      if (this.top !== new_top) {
        $scope.$applyAsync(() => this.top = new_top);
      }
    };

    this.on_resize = ({width}) => {
      this.dismissed = width === 185;
      if (this.dismissed) {
        LocalStorage.safe_set_item('apple_music_player_dismissed', true);
      } else {
        LocalStorage.safe_set_item('apple_music_player_dismissed', false);
      }
    };
  }],
});
