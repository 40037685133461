/* eslint-env es6 */

angular.module('genius').directive('annotationEdit', ['Session', 'AnnotationClient', function(Session, AnnotationClient) {
  return {
    restrict: 'E',
    templateUrl: 'annotation_edit.html',

    scope: {
      edit: '<object',
      before_html: '<beforeHtml',
      on_merge: '&onMerge',
      on_resolution: '&onResolution',
    },

    controllerAs: 'ctrl',

    controller: ['$scope', function($scope) {
      this.can_moderate = Session.has_permission('moderate', $scope.edit);

      this.merge = () => {
        this.working = true;
        $scope.edit._merged = true;
        AnnotationClient.merge_edit($scope.edit.id).
          then((annotation_edit) => {
            _.assign($scope.edit, annotation_edit);
            $scope.on_merge({edit: $scope.edit});
            $scope.on_resolution();
          }).
          catch(() => {
            $scope.edit._merged = false;
          }).
          finally(() => {
            this.working = false;
          });
      };

      this.reject = () => {
        this.working = true;
        $scope.edit._deleted = true;
        AnnotationClient.reject_edit($scope.edit.id).
          then($scope.on_resolution).
          catch(() => {
            $scope.edit._deleted = false;
          }).
          finally(() => {
            this.working = false;
          });
      };
    }],
  };
}]);
