/* eslint-env es6 */

angular.module('genius').component('mediaPlayer', {
  templateUrl: 'media_player.html',

  bindings: {
    media_sources: '<mediaSources',
    sticky_offset: '<stickyOffset',
    on_edit: '&onEdit',
    can_edit: '<canEdit',
  },

  controller: ['Tracking', 'mobile_device', function(Tracking, mobile_device) {
    this.$onInit = () => { this.media_player = {}; };

    this.$onChanges = (changes) => {
      if (changes.media_sources) {
        let media;
        for (const provider of ['youtube', 'soundcloud']) {
          media = _.find(this.media_sources, {provider});
          if (media) break;
        }
        if (_.get(media, 'url') !== _.get(this.media, 'url')) {
          this.played_once = false;
          this.media = media;
          if (!media) this.media_player = {};
        }
      }
    };

    this.play = () => {
      if (!this.played_once) {
        this.media_player.api.load_media();
        if (!(this.media.provider === 'youtube' && mobile_device)) {
          this.media_player.api.playpause();
        }

        if (this.media.provider === 'youtube') {
          Tracking.track(
            'Play featured video',
            {properties: {'Video provider': this.media.provider}}
          );
        } else {
          Tracking.track(
            'Play audio',
            {properties: {'Audio provider': this.media.provider}}
          );
        }
        this.played_once = true;
      } else {
        this.media_player.api.playpause();
      }
    };
  }],
});
