/* eslint-env es6 */

angular.module('genius').directive('ngBodyClass', [function() {
  const $body = $('body');

  return {
    restrict: 'A',

    link(scope, _element, attrs) {
      scope.$watch(attrs.ngBodyClass, (class_map) => {
        for (const class_name in class_map) {
          $body.toggleClass(class_name, !!class_map[class_name]);
        }
      }, true);

      scope.$on('$destroy', () => {
        for (const class_name in scope.$eval(attrs.ngBodyClass)) {
          $body.removeClass(class_name);
        }
      });
    },
  };
}]);
