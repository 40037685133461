/* eslint-env es6 */

angular.module('genius').directive('alwaysDirty', () => ({
  restrict: 'A',

  controller: ['BeforeUnloadCheck', function(BeforeUnloadCheck) {
    const dirty_check = () => true;

    this.$onInit = () => BeforeUnloadCheck.add(dirty_check);
    this.$onDestroy = () => BeforeUnloadCheck.remove(dirty_check);
  }],
}));
