import 'loadjs/dist/loadjs';
/* eslint-env es6 */


angular.module('genius').factory('MusicKit', ['$q', '$window', function($q, $window) {
  const deferred = $q.defer();
  document.addEventListener('musickitloaded', deferred.resolve);

  $window.loadjs(['https://js-cdn.music.apple.com/musickit/v3/musickit.js'], {
    async: true,
    error: () => {
      deferred.reject(new Error('MusicKit failed to load'));
    },
  });

  return {
    load() {
      return deferred.promise.then(() => {
        const MusicKit = window.MusicKit;
        return MusicKit.configure({
          developerToken: window.MUSICKIT_TOKEN,
          debug: true,
          app: {name: 'Genius'},
          suppressErrorDialog: true,
          features: ['equivalencies'],
          bitrate: MusicKit.PlaybackBitrate.STANDARD,
        }).then(() => MusicKit);
      });
    },
  };
}]);
