/* eslint-env es6 */

angular.module('genius').component('annotationEmbed', {
  bindings: {
    referent: '<',
    simple: '<',
  },

  templateUrl: 'annotation_embed.html',

  controller: ['ReferentHelper', function(ReferentHelper) {
    this.$onChanges = (changes) => {
      if (changes.referent) {
        this.display_mode = ReferentHelper.display_mode_by_classification(this.referent.classification);
      }
    };
  }],
});
