/* eslint-env es6 */

angular.module('genius').component('profileContributionsComments', {
  templateUrl: 'profile_contributions_comments.html',

  bindings: {
    user: '<',
    contributions_group: '<contributionsGroup',
  },

  controller() {
    this.$onInit = () => {
      this.limit = 1;
    };

    this.$onChanges = (changes) => {
      if (changes.contributions_group) {
        this.comments = this.contributions_group.contributions;
        this.commentable = this.comments[0].commentable;
      }
    };
  },
});
