/* eslint-env es6 */

angular.module('genius').component('profileContributionsGroup', {
  templateUrl: 'profile_contributions_group.html',

  bindings: {
    user: '<',
    contributions_group: '<contributionsGroup',
  },
});
