/* eslint-env es6 */

angular.module('genius').directive('brightcove', ['$window', 'Ads', 'AppConfig', 'Bindings', 'Brightcove', 'Bowser', 'querystring', '$q', 'Session', '$timeout', 'mobile_device', function(
  $window,
  Ads,
  AppConfig,
  Bindings,
  Brightcove,
  Bowser,
  querystring,
  $q,
  Session,
  $timeout,
  mobile_device
) {
  const resolve_bindings = Bindings.resolver({
    video: '<object',
    on_ready: '&onReady',
    can_play: '&canPlay',
    on_time_change: '&onTimeChange',
    on_finish: '&onFinish',
    yields_api: '&yieldsApi',
    player_name: '@playerName',
    tracking_data: '<trackingData',
    autoplays_on_page_load: '<autoplaysOnPageLoad',
  });

  const correlator = Date.now();

  return {
    restrict: 'A',

    link(scope, element, attrs) {
      const video = element[0];
      if (video.constructor !== $window.HTMLVideoElement) {
        throw new Error("The 'brightcove' directive can only be used as an attribute on a <video> element");
      }

      const video_has_already_been_initialized_by_brightcove = () =>
        (/(?:^|\s)vjs-tech(?:\s|$)/).test(video.className);

      const should_play_fullscreen = () => Bowser.android;

      const bindings = resolve_bindings(scope, attrs);
      const brightcove_player_id = Brightcove[`${bindings.player_name}_player_id`];

      if (!brightcove_player_id) {
        throw new Error("The 'brightcove' slot is not configured!");
      }

      const destroyed = $q(resolve => scope.$on('$destroy', resolve));

      scope.player = Brightcove.load_api(brightcove_player_id).then(({bc, videojs}) => {
        const pause_all = () => {
          _.each(videojs.players, (p) => {
            if (p) p.pause();
          });
        };

        const tracking_data = Object.assign({
          'Logged In': Session.user_signed_in,
          'Mobile Site': mobile_device,
        }, bindings.tracking_data);
        element.attr({
          'data-video-id': bindings.video.provider_id,
          'data-account': Brightcove.account_id,
          'data-player': brightcove_player_id,
          'data-embed': 'default',
          'data-param-tracking-data': JSON.stringify(tracking_data),
          controls: true,
        });

        let player;
        if (!video_has_already_been_initialized_by_brightcove()) {
          player = bc(video);
        } else {
          player = videojs(video);
        }

        player.play = _.wrap(player.play, (play, ...args) => {
          pause_all();
          Reflect.apply(play, player, args);
          if (should_play_fullscreen()) {
            const requestFullScreen = video.requestFullScreen || video.webkitRequestFullScreen || video.mozRequestFullScreen;
            if (requestFullScreen) Reflect.apply(requestFullScreen, video, []);
          }
        });

        if (player.ima3) {
          player.ima3.adMacroReplacement =
            _.wrap(
              player.ima3.adMacroReplacement,
              (replace, ...args) => transformAdMacro(Reflect.apply(replace, player.ima3, args))
            );
        }

        player.ready(() => {
          scope.$apply(bindings.on_ready);
          bindings.yields_api({api: player});
          $(player.el_).find('.vjs-control').addClass('needsclick');
        });

        player.on('timeupdate', _.throttle(() => {
          const duration = player.duration();
          const current = player.currentTime();

          scope.$apply(() => {
            bindings.on_time_change({duration, current});
            if (duration && current >= duration) bindings.on_finish();
          });
        }), 250);

        element.on('canplay', () => {
          scope.$apply(bindings.can_play);
          if (bindings.autoplays_on_page_load) {
            $timeout(() => element.css('opacity', 1), 0);
          }
        });

        destroyed.then(() => player.dispose());
        if (player.ima3) {
          const ad_slot = _.last(
            querystring.parse(
              new URL(player.ima3.settings.serverUrl).search
            ).iu.split('/')
          );

          return Ads.ensure_targeting_for_placements([[ad_slot, ad_slot]]).
            then((result) => {
              this._ad_targeting = _.get(_.values(result), [0, 'targeting'], {});
              return player;
            });
        }

        return player;
      });

      const transformAdMacro = (urlString) => {
        if (!window['Genius.ads']) return null;

        const key_values_from_preflight = this._ad_targeting;
        const url = new URL(urlString);
        const params = querystring.parse(url.search.slice(1));

        _.assign(params, {
          url: $window.location.href,
          description_url: bindings.video.article_url,
          hl: ($window.navigator.language || 'en').split('-')[0],
          correlator,
        });

        if (bindings.autoplays_on_page_load) params.vpa = 1;

        const key_values_from_api = {};
        bindings.video.dfp_kv.forEach(({name, values}) => {
          key_values_from_api[name] = values.join(',');
        });

        let key_values_from_url;
        if (params.cust_params === 'null') {
          key_values_from_url = {};
        } else {
          key_values_from_url = querystring.parse(params.cust_params || '');
        }

        const keyValues = _.defaults({}, key_values_from_preflight, key_values_from_api, key_values_from_url, {
          platform: 'web',
          environment: AppConfig.env,
          campaign_id: querystring.parse($window.location.search.slice(1)).campaign,
        });

        params.cust_params = querystring.stringify(keyValues);
        url.search = querystring.stringify(params);
        return url.toString();
      };
    },
  };
}]);
