/* eslint-env es6 */

angular.module('genius').filter('highlight_braced', ['$sce', '$sanitize', function($sce, $sanitize) {
  const regex = /\[(.+?)\]/g;

  return (text, {class_name} = {}) => {
    const safe_text = $sanitize(text);
    const replacement = `<span class="${class_name}">$1</span>`;
    return $sce.trustAsHtml(safe_text.replace(regex, replacement));
  };
}]);
