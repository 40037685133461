/* eslint-env es6 */

angular.module('genius').component('dfpAd', {
  templateUrl: 'dfp_ad.html',

  bindings: {
    ad_classes: '@adClasses',
    name: '@',
    instance_id: '@?instanceId',
    on_filled: '&onFilled',
    on_no_ad: '&onNoAd',
  },

  controller: ['$element', '$immediate', '$scope', 'Ads', 'querystring', 'AdConfig', 'SessionConfig', 'classnames', function($element, $immediate, $scope, Ads, querystring, AdConfig, SessionConfig, classnames) {
    let instance_id;

    this.$onInit = () => {
      if (!SessionConfig.show_ads) {
        this.creative_size = [0, 0];
        this.no_ad = true;
        return this.on_no_ad();
      }

      instance_id = this.instance_id || this.name;

      this.creative_size = _.get(AdConfig.ad_placements[this.name], 'placeholder_size') || [0, 0];

      Ads.id_for(this.name, instance_id).
        then(id => this.id = id).
        then(() => $immediate(() => {
          Ads.render(this.name, instance_id).
            then((render_event) => {
              if (render_event.isEmpty) {
                this.no_ad = true;
                this.on_no_ad({render_event});
              } else {
                this.filled = true;
                this.creative_size = render_event.size;
                this.on_filled({
                  render_event,
                  custom_values: extract_custom_values(),
                });
              }

              $scope.$emit('layout_changed');
            });
        }));
    };

    this.$onDestroy = () => {
      Ads.dispose(this.name, instance_id);
    };

    this.compiled_ad_unit_classes = () => {
      const classes = $scope.$parent.$eval(
        this.ad_classes || '[]', {$width: this.creative_size[0], $height: this.creative_size[1]}
      );

      return classnames(classes);
    };

    const extract_custom_values = () =>
      querystring.parse($element.find('iframe').prop('contentWindow').customValues || '');
  }],
});
