/* eslint-env es5 */
/* eslint-disable arrow-body-style, arrow-parens, arrow-spacing, constructor-super, generator-star-spacing, no-class-assign, no-confusing-arrow, no-const-assign, no-dupe-class-members, no-duplicate-imports, no-extra-bind, no-new-symbol, no-restricted-imports, no-this-before-super, no-useless-computed-key, no-useless-constructor, no-useless-rename, no-var, object-shorthand, prefer-arrow-callback, prefer-const, prefer-numeric-literals, prefer-reflect, prefer-rest-params, prefer-spread, prefer-template, require-yield, rest-spread-spacing, sort-imports, symbol-description, template-curly-spacing, yield-star-spacing, dot-location, key-spacing, max-len, new-parens, no-lonely-if, no-mixed-operators, no-trailing-spaces, no-whitespace-before-property, padded-blocks, quotes, space-infix-ops */

angular.module('genius').controller('FactTrackSongEditCtrl', ['$scope', '$debounce', 'SpotifyAnnotationClient', 'orderByFilter', 'ScrollPoint', function($scope, $debounce, SpotifyAnnotationClient, orderByFilter, ScrollPoint) {
  $scope.scroll_to = ScrollPoint.scroll_to;
  $scope.song_id = $('[data-song-id]').attr('data-song-id');

  this.toggle_fullscreen = function() {
    this.alt_mode = false;
    this.fullscreen = !this.fullscreen;
  };

  this.toggle_alt_mode = function() {
    this.alt_mode = !this.alt_mode;
  };

  $scope.remove_annotation = function(annotation) {
    var idx = $scope.annotations.indexOf(annotation);
    $scope.annotations.splice(idx, 1);
  };

  $scope.add_annotation = function() {
    $scope.annotations.push({content_type: 'iq', timestamp_in_ms: this.time});
  }.bind(this);

  SpotifyAnnotationClient.load_song($scope.song_id).then(function(data) {
    $scope.spotify_song = data.spotify_song;
    $scope.annotations = data.spotify_annotations;

    if ($scope.spotify_song.track_url) $scope.is_ready = true;

    if (!_.some($scope.annotations, {content_type: 'credits'})) {
      var end_time = _.get($scope.spotify_song, ['metadata', 'duration_ms'], 180000);
      $scope.annotations.push({
        content_type: 'credits',
        content: 'Check out the full lyrics and annotations on Genius for contributors, sources, and even more knowledge.',
        timestamp_in_ms: end_time - 3000,
      });
    }

    setup_watches();
  });

  var resave_when_done = false;
  var save_annotations = function() {
    if ($scope.saving_disabled) {
      resave_when_done = true;
      return;
    }
    $scope.saving_disabled = true;
    SpotifyAnnotationClient.save_song($scope.song_id, $scope.spotify_song, $scope.annotations).then(function(data) {
      $scope.spotify_song.lock_version = data.spotify_song.lock_version;
      $scope.save_error = undefined;
    }).catch(function(response) {
      var data = response.data;
      $scope.save_error = data.message || 'Failed to save';
      $scope.conflicted = data.error === 'conflict';
      $.facebox("<div><img src='https://images.genius.com/5f38d92d0b29722104abd09aa01b1585.200x200x4.gif'></div><br><h1>Something went wrong!</h1><h2>Consider copy/pasting your work somewhere else before reloading the page</h2>");
    }).finally(function() {
      $scope.saving_disabled = false;
      if (resave_when_done) {
        resave_when_done = false;
        save_annotations();
      }
    });
  };

  var setup_watches = function() {
    $scope.$watch('annotations', function() {
      $scope.annotations = orderByFilter($scope.annotations, ['timestamp_in_ms', 'id']);
    }, true);

    var debounce_save = $debounce(save_annotations, 1000);
    $scope.$watchGroup(['annotations', 'spotify_song.note', 'spotify_song.finalized'], function() {
      debounce_save();
    }, true);
  };
}]);
