/* eslint-env es6 */

angular.module('genius').component('detailedSongSearch', {
  templateUrl: 'detailed_song_search.html',

  bindings: {
    mode: '<',
    cancel: '&onCancel',
    select: '&onSelect',
    confirm: '&onConfirm',
  },

  controller: ['$q', '$scope', 'LookupClient', 'SongClient', function($q, $scope, LookupClient, SongClient) {
    $scope.$watch('$ctrl.url', (url) => {
      this.song_data = null;
      this.error = null;
      if (!url) return;
      LookupClient.lookup(url).then((song) => {
        this.autocompleted_song = null;
        if (song._type !== 'song') {
          return $q.reject(new Error('URL is for a non-song page'));
        }
        this.song = song;
      }).catch((error) => {
        this.error = error;
      });
    });

    $scope.$watch('$ctrl.autocompleted_song.id', (song_id) => {
      this.url = null;
      this.song_data = null;
      this.error = null;
      if (!song_id) return;
      this.song_data = {
        id: this.autocompleted_song.id,
        title: this.autocompleted_song.title,
        artists: this.autocompleted_song.primary_artists,
        albums: [],
      };
      SongClient.load_song(song_id).then((song) => {
        this.song = song;
      });
    });

    $scope.$watch('$ctrl.song.id', (song_id) => {
      if (!song_id) return;
      this.song_data = {
        id: this.song.id,
        title: this.song.title,
        artists: this.song.primary_artists.concat(this.song.featured_artists),
        albums: this.song.albums,
      };
      this.select({song: this.song});
    });

    this.submit = function() {
      this.saving = true;
      $q.when(this.confirm({song: this.song})).finally(() => {
        this.saving = null;
      });
    };
  }],
});
