/* eslint-env es6 */

angular.module('genius').component('articlePage', {
  templateUrl: 'article_page.html',

  controller: Object.assign(['VideoRegistry', 'Tracking', 'MetaTagData', '$sce', function(VideoRegistry, Tracking, MetaTagData, $sce) {
    this.$routerOnActivate = (next) => {
      this.page_data = next.routeData.page_data;
      this.article_params = MetaTagData.get('article_params');
      this.article_update_url = $sce.trustAsUrl(`/articles/${this.page_data.article.id}`);
      this.article_edit_url = $sce.trustAsUrl(`/articles/${this.page_data.article.id}/edit_from_preview`);
      this.authenticity_token = MetaTagData.get('authenticity_token');

      VideoRegistry.register_page(this.page_data.article, 'desktop_article_page');

      Tracking.delayed_track('Article Page View', {
        'Article ID': this.page_data.article.id,
        'Title': this.page_data.article.title,
        'Author': this.page_data.article.author.name,
        'Author ID': this.page_data.article.author.id,
        'Article Type': this.page_data.article.article_type,
        'Published At': new Date(this.page_data.article.published_at * 1000).toUTCString(),
        'Generic Sponsorship': this.page_data.article.generic_sponsorship,
        'counts_as_page_load': true,
      });

      this.video = _(this.page_data.article.media).find(m => m.type === 'video' || m._type === 'video');
    };
  }], {
    $canActivate: ['$nextInstruction', '$prevInstruction', 'PageTransition', function(
      $nextInstruction,
      $prevInstruction,
      PageTransition
    ) {
      return PageTransition.by_router_instruction($nextInstruction, $prevInstruction);
    }],
  }),
});
