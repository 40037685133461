/* eslint-env es6 */

angular.module('genius').factory('Iq', ['PreloadCache', 'Session', function(PreloadCache, Session) {
  const iq_by_event_type = PreloadCache.iq_by_event_type;

  return {
    for(name) {
      const virtual_event_type_map = Session.current_user.virtual_iq_event_type_map;
      if (virtual_event_type_map && _.has(virtual_event_type_map, name)) {
        name = virtual_event_type_map[name];
      }

      if (_.has(iq_by_event_type, name)) {
        return iq_by_event_type[name];
      }
      return 0;
    },
  };
}]);
