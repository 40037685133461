/* eslint-env es6 */

angular.module('genius').component('embedlyEmbed', {
  templateUrl: 'embedly_embed.html',

  bindings: {
    embed: '<',
  },

  controller: ['$sce', function($sce) {
    this.trusted_html = () => $sce.trustAsHtml(this.embed.html);
  }],
});
