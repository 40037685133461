/* eslint-env es6 */

angular.module('genius').factory('array_to_sentence', () => (array) => {
  if (array.length === 0) return '';
  if (array.length === 1) return array[0];

  return [
    array.slice(0, -1).join(', '),
    array[array.length - 1],
  ].join(' & ');
});
