/* eslint-env es6 */

angular.module('genius').directive('releaseDate', [function() {
  return {
    templateUrl: 'release_date.html',

    restrict: 'E',

    scope: {
      date_components: '<dateComponents',
      variants: '<',
    },
  };
}]);
