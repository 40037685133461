/* eslint-env es6 */

angular.module('genius').directive('standardRichContent', () => ({
  restrict: 'E',

  scope: {
    html: '<',
    variants: '<',
  },

  templateUrl: 'standard_rich_content.html',

  controller: ['$element', '$scope', '$sce', 'Facebook', function($element, $scope, $sce, Facebook) {
    $scope.$watch('html', (html) => {
      if (html) {
        $scope.trusted_html = $sce.trustAsHtml(html);
        $scope.$applyAsync(() => Facebook.process_embeds($element[0]));
      } else {
        Reflect.deleteProperty($scope, 'trusted_html');
      }
    });
  }],
}));
