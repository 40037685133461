/* eslint-env es6 */

angular.module('genius').directive('standaloneReferent', () => ({
  templateUrl: 'standalone_referent.html',

  restrict: 'E',

  scope: {
    referent: '<object',
    variants: '<',
    yields_api: '&yieldsApi',
    on_pending_event: '&onPendingEvent',
    yields_iq: '&yieldsIq',
    focused_comments: '<focusedComments',
  },

  controller: ['$scope', '$window', '$immediate', function($scope, $window, $immediate) {
    $immediate(() => {
      $window.parent.postMessage({
        sentinel: 'amp',
        type: 'embed-size',
        height: $window.document.body.scrollHeight,
      }, '*');
    });

    this.disables_user_tooltips = _.get($scope, 'variants.disableTooltips');
  }],
}));
