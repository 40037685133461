window.app = window.app || {};

$.extend(window.app, {
  load_vidyard_players: function(container, load_invisible_players, callback) {
    var $container;
    var cb;

    if (typeof(container) === 'function') {
      cb = container;
      $container = $('body');
    } else {
      $container = $(container || 'body');
      cb = callback || $.noop;
    }

    var $elements = $container.find('.vidyard_container[data-vidyard-id]');
    if (!load_invisible_players) $elements = $elements.filter(':visible');

    $elements.each(function() {
      var $this = $(this);

      var vidyard_id = $this.attr('data-vidyard-id');
      var existing_already_loaded_player = $('#vidyard_' + vidyard_id + ':first');

      if (existing_already_loaded_player.length) {
        $this.replaceWith(existing_already_loaded_player.remove());
        cb();
      } else {
        $this.attr('id', 'vidyard_' + vidyard_id).removeAttr('data-vidyard-id');

        var width_param = (MOBILE_DEVICE || window.RG_EMBEDDED_PAGE) ? '?width=' + $this.parent().width() : '';

        $.getScript('//embed.vidyard.com/embed/' + vidyard_id + '/iframe/' + width_param, function() {
          $this.removeClass('loading_with_indicator');
          cb();
        });
      }
    });
  },
});
