/* eslint-env es6 */

angular.module('genius').component('inboxLineItemMessage', {
  bindings: {
    template: '<',
    key: '<',
    params: '<',
    state: '<',
  },

  template: '<ng-include src="$ctrl.template"></ng-include>',
});
