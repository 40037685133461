/* eslint-env es6 */

angular.module('genius').directive('lyricSyncTimestampInput', ['timestamp_for_displayFilter', 'parse_time_string', 'Mousetrap', '$immediate', function(timestamp_for_display, parse_time_string, Mousetrap, $immediate) {
  return {
    require: 'ngModel',

    link(scope, element, attrs, model) {
      let initial_timestring;

      const mousetrap = new Mousetrap(element[0], {
        stop_propagation: true,
        prevent_default: true,
      });

      mousetrap.$bind('up', () => {
        apply_new_timestamp(parse_time_string(model.$viewValue) - 50);
      });
      mousetrap.$bind('down', () => {
        apply_new_timestamp(parse_time_string(model.$viewValue) + 50);
      });
      mousetrap.$bind('esc', () => {
        apply_new_timestamp(parse_time_string(initial_timestring));
        element.blur();
      });

      $immediate(() => { initial_timestring = model.$viewValue; });

      const apply_new_timestamp = (new_timestamp) => {
        const old_timestring = model.$viewValue;
        const new_timestring = timestamp_for_display(
          Math.round(new_timestamp), {always_millis: true}
        );

        model.$setViewValue(new_timestring);

        if (model.$valid) {
          model.$render();
        } else {
          model.$setViewValue(old_timestring);
        }
      };

      element.bind('keyup', () => {
        model.$commitViewValue();
        scope.$apply();
      });

      model.$parsers.push(parse_time_string);

      model.$formatters.push((timestamp) => {
        if (!timestamp && timestamp !== 0) return;
        return timestamp_for_display(timestamp, {always_millis: true});
      });

      scope.$watch(attrs.ngModel, (new_value, old_value) => {
        if (_.isUndefined(new_value) && !_.isUndefined(old_value)) {
          if (model.$viewValue !== '') {
            model.$setViewValue(timestamp_for_display(old_value, {always_millis: true}));
          } else {
            model.$setViewValue(null);
          }
          model.$commitViewValue();
        }
      });

      if ('validators' in attrs) {
        _.assign(model.$validators, scope.$eval(attrs.validators));
      }
    },
  };
}]);
