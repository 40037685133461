/* eslint-env es6 */

angular.module('genius').factory('LeaderboardClient', ['ApiClient', function(ApiClient) {
  return {
    global(options) {
      return ApiClient.get('/leaderboard', options);
    },

    for_tag(tag, options) {
      return ApiClient.get('/tags/:tag_id/leaderboard', _.assign({tag_id: tag.id}, options));
    },
  };
}]);
