/* eslint-env es6 */

import {Observable} from 'rxjs5/Observable';
import {ConnectableObservable} from 'rxjs5/observable/ConnectableObservable';
import {Subject} from 'rxjs5/Subject';

import 'rxjs5/add/observable/combineLatest';
import 'rxjs5/add/observable/fromEvent';
import 'rxjs5/add/observable/fromPromise';
import 'rxjs5/add/observable/merge';
import 'rxjs5/add/observable/never';
import 'rxjs5/add/observable/of';

import 'rxjs5/add/operator/auditTime';
import 'rxjs5/add/operator/catch';
import 'rxjs5/add/operator/concatMap';
import 'rxjs5/add/operator/do';
import 'rxjs5/add/operator/first';
import 'rxjs5/add/operator/map';
import 'rxjs5/add/operator/mapTo';
import 'rxjs5/add/operator/publishBehavior';
import 'rxjs5/add/operator/scan';
import 'rxjs5/add/operator/skip';
import 'rxjs5/add/operator/startWith';
import 'rxjs5/add/operator/switchMap';
import 'rxjs5/add/operator/withLatestFrom';

Object.assign(Observable, {
  always(value) {
    return Observable.never().startWith(value);
  },

  $watch(scope, watchExpression, objectEquality) {
    return Observable.create((observer) => {
      scope.$on('$destroy', observer.complete.bind(observer));

      return scope.$watch(watchExpression, observer.next.bind(observer), objectEquality);
    });
  },

  fromPendingPromise(promise) {
    return Observable.create((observer) => {
      observer.next({pending: true, value: null, error: null});

      promise.then((value) => {
        observer.next({pending: false, value: value, error: null});
        observer.complete();
      }, (error) => {
        observer.next({pending: false, value: null, error: error});
        observer.complete();
      });
    });
  },
});

Object.assign(ConnectableObservable.prototype, {
  persist() {
    this.connect();
    return this;
  },
});

Object.assign(Observable.prototype, {
  accumulate() {
    return this.scan((array, item) => array.concat(item), []);
  },

  accumulateLatest() {
    return this.accumulate().switchMap((observables) =>
      Observable.combineLatest(observables));
  },
});

export {Observable, ConnectableObservable, Subject};
