/* eslint-env es6 */

angular.module('genius').component('hiddenFields', {
  bindings: {
    key: '@',
    value: '<',
  },

  templateUrl: 'hidden_fields.html',

  controller() {
    if (_.isArray(this.value)) {
      this.type = 'array';
    } else if (_.isObject(this.value)) {
      this.type = 'object';
    } else {
      this.type = 'constant';
    }
  },
});
