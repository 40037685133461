/* eslint-env es6 */

angular.module('genius').directive('pyongButton', [function() {
  return {
    restrict: 'E',

    scope: {
      pyongable: '<object',
      type: '@',
      onUnauthorized: '&',
      variants: '<',
    },

    templateUrl(_elem, attrs) {
      if ('forHeader' in attrs) {
        return 'pyong_button_for_header.html';
      } else {
        return 'pyong_button_square_button.html';
      }
    },

    controllerAs: 'ctrl',

    controller: ['$scope', 'PyongClient', 'Session', function($scope, PyongClient, Session) {
      const pyongable = $scope.pyongable;

      this.pyonged = () => {
        if (!Session.user_signed_in) return false;
        return pyongable.current_user_metadata.interactions.pyong;
      };

      this.pyong = () => {
        if (!Session.user_signed_in) return $scope.onUnauthorized();

        if (this.pyonged()) return;

        if (Session.has_permission('create_pyong_with_note', Session.current_user)) {
          this.show_pyong_form = true;
          pyongable.pyonged = this.clicked_pyong = true;
          pyongable.pyongs_count += 1;
          PyongClient.pyong($scope.type, pyongable.id).then((updated) => {
            pyongable.current_user_metadata.interactions.pyong = updated.current_user_metadata.interactions.pyong;
            pyongable.pyongs_count = updated.pyongs_count;
          }).catch(() => {
            pyongable.pyongs_count -= 1;
            pyongable.pyonged = false;
          });
        } else {
          pyongable.pyonged = this.clicked_pyong = true;
          pyongable.pyongs_count += 1;
          pyongable.current_user_metadata.interactions.pyong = true;
        }
      };
    }],
  };
}]);
