/* eslint-env es6 */

angular.module('genius').factory('AbTest', ['AbTestClient', 'LocalStorage', '$q', 'AppConfig', function(AbTestClient, LocalStorage, $q, AppConfig) {
  return {
    get_variant_for(ab_test) {
      return this.get_ab_tests().then(ab_tests => ab_tests[ab_test]);
    },

    get_ab_tests: _.once(() => {
      const ab_tests_version_stored = LocalStorage.safe_get_item('ab_tests_version', null);
      const ab_tests_from_local_storage = LocalStorage.safe_get_item('ab_tests', null);

      if (
        ab_tests_from_local_storage &&
        AppConfig.ab_tests_version === ab_tests_version_stored
      ) {
        return $q.resolve(ab_tests_from_local_storage);
      } else {
        return AbTestClient.get().then(({ab_tests}) => {
          LocalStorage.safe_set_item('ab_tests', ab_tests);
          LocalStorage.safe_set_item('ab_tests_version', AppConfig.ab_tests_version);
          return ab_tests;
        });
      }
    }),

    clear() {
      LocalStorage.safe_remove_item('ab_tests');
    },
  };
}]);
