/* eslint-env es6 */

angular.module('genius').component('profileIdentity', {
  templateUrl: 'profile_identity.html',

  bindings: {
    artist: '<',
    user: '<',
  },

  controller: [function() {
    this.alternate_name_limit = 2;

    this.user_has_more_than_one_role = () => this.user && this.user.roles_for_display.length > 1;

    this.more_alternate_names_to_show = () => this.artist.alternate_names.length > this.alternate_name_limit;

    this.show_all_alternate_names = () => {
      this.alternate_name_limit = Infinity;
    };
  }],
});
