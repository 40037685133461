/* eslint-env es6 */

angular.module('genius').component('leaderboardAd', {
  templateUrl: 'leaderboard_ad.html',

  bindings: {
    name: '@',
  },

  controller: ['$timeout', '$window', '$element', '$scope', 'wait_until', function($timeout, $window, $element, $scope, wait_until) {
    const ad_width = 970;
    const min_viewport_height = 600;
    const min_visible_ms = 3000;
    let ad, ad_size_stickable, viewport_large_enough, naturally_in_view, ad_state;

    this.$onInit = () => {
      $window.addEventListener('scroll', scroll_handler);
      viewport_large_enough = $window.innerHeight >= min_viewport_height;
      this.empty = true;
    };

    const scroll_handler = _.throttle(() => $scope.$applyAsync(() => set_bounding_client_rect_and_naturally_in_view()), 50);

    const set_bounding_client_rect_and_naturally_in_view = () => {
      this.bounding_client_rect = ad ? ad.getBoundingClientRect() : {height: 0, width: 0};
      naturally_in_view = $element[0].getBoundingClientRect().top >= 0;
    };

    const is_empty = ([width, height]) => width === 1 && height === 1;

    this.on_filled = (render_event) => {
      this.empty = is_empty(render_event.size);
      this.has_banner_ad = true;
      ad_size_stickable = render_event.size[0] === ad_width;
      ad_state = 'loading';
      ad = $element[0].querySelector('.dfp_unit--centered_billboard');

      wait_until(() => ad.getBoundingClientRect().height > 1).then(() => {
        ad_state = 'loaded';
        set_bounding_client_rect_and_naturally_in_view();
        this.loaded_when_not_naturally_in_view = !naturally_in_view;

        $timeout(() => {
          $window.removeEventListener('scroll', scroll_handler);
          ad_state = 'viewability_complete';
        }, min_visible_ms);
      }).catch(_.noop);
    };

    this.can_stick = () =>
      ad_size_stickable &&
      viewport_large_enough &&
      ad_state === 'loaded';

    this.should_stick = () => this.can_stick() && !naturally_in_view;
  }],
});
