/* eslint-env es6 */

angular.module('genius').factory('PageDataClient', ['MetaTagData', 'ApiClient', function(MetaTagData, ApiClient) {
  return {
    load(type, page_path) {
      return ApiClient.
        transform(_.property('page_data')).
        get('/page_data/:type', {type, page_path});
    },

    preloaded() {
      return MetaTagData.get('page_data');
    },
  };
}]);
