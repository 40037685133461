/* eslint-env es6 */

angular.module('genius').factory('CoverArtClient', ['ApiClient', 'Session', function(ApiClient, Session) {
  return {
    index(cover_artable_type, cover_artable_id) {
      const params = {text_format: Session.user_signed_in ? 'html,markdown' : 'html'};
      params[`${cover_artable_type}_id`] = cover_artable_id;
      return ApiClient.returning('cover_arts').get('/cover_arts', params);
    },

    create(cover_artable_type, cover_artable_id, data) {
      const params = {cover_art: data};
      params[`${cover_artable_type}_id`] = cover_artable_id;
      return ApiClient.post('/cover_arts', params);
    },

    update(cover_art_id, data) {
      return ApiClient.put(`/cover_arts/${cover_art_id}`, {cover_art: data});
    },

    destroy(cover_art_id) {
      return ApiClient.delete(`/cover_arts/${cover_art_id}`);
    },

    move_between(cover_art_id, data) {
      return ApiClient.put(`/cover_arts/${cover_art_id}/move_between`, data);
    },
  };
}]);
