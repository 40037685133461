/* eslint-env es6 */

angular.module('genius').component('songStoryStickyPlacement', {
  bindings: {
    story: '<',
    show: '<',
    song_id: '<songId',
  },

  templateUrl: 'song_story_sticky_placement.html',
});
