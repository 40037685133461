/* eslint-env es6 */

angular.module('genius').directive('descriptionReferent', [function() {
  return {
    scope: {
      referent: '<object',
      variants: '<',
    },

    templateUrl: 'description_annotation.html',
  };
}]);
