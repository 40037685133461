/* eslint-env es6 */

const PRIMIS_PLAYER_IDS = {
  desktop: '110117',
  mobile: '110118',
};

const load_primis_script = (node, primis_id) => {
  const script = document.createElement('script');
  script.async = true;
  script.src = `https://live.primis.tech/live/liveView.php?s=${primis_id}`;

  node.appendChild(script);
};

let primis_index = 0;

angular.module('genius').directive('primisPlayer', ['Ads', 'AdConfig', 'SessionConfig', function(Ads, AdConfig, SessionConfig) {
  return {
    restrict: 'E',

    scope: {
      name: '@',
      container_classes: '@containerClasses',
    },

    templateUrl: 'primis_player.html',

    link(scope, element) {
      scope.show_player = SessionConfig.show_ads;

      scope.$watch('$viewContentLoaded', () => {
        if (scope.show_player) {
          const player_element = element.find('.primis_player-player')[0];

          inject_primis(player_element);
        }
      });

      const inject_primis = async (node) => {
        if (node !== null) {
          const base_targeting = await Ads.get_base_targeting(scope.name);
          const fetch_new_targeting = () => {
            const result = {...base_targeting};

            primis_index++;
            Ads.get_async_targeting(scope.name, primis_index).then((targeting) => {
              Object.assign(result, targeting);
            });

            return result;
          };
          let next_bid = fetch_new_targeting();

          window.getPrimisTargeting = () => {
            const current_bid = next_bid;
            next_bid = fetch_new_targeting();
            return current_bid;
          };

          load_primis_script(node, PRIMIS_PLAYER_IDS[AdConfig.platform]);
        }
      };
    },
  };
}]);
