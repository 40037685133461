/* eslint-env es6 */

angular.module('genius').factory('TimestampedDataFeed', [function() {
  const unix_timestamp_now = () => Math.floor(new Date().getTime() / 1000);

  return class {
    constructor(get_data, model_key, params = {}, initial_timestamp = unix_timestamp_now()) {
      this.reset = () => {
        this.timestamp_for_next_page = initial_timestamp;
      };
      this.reset();
      this.params = _.clone(params);

      this.next = () => get_data(_.assign({timestamp_for_next_page: this.timestamp_for_next_page}, this.params)).
        then((data) => {
          this.timestamp_for_next_page = data.timestamp_for_next_page;
          return data[model_key];
        });

      this.has_next = () => this.timestamp_for_next_page;
    }
  };
}]);
