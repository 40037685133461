/* eslint-env es6 */

angular.module('genius').component('searchResultsPage', {
  bindings: {
    $router: '<',
  },

  templateUrl: 'search_results_page.html',

  controller: ['mobile_device', 'SearchClient', 'Tracking', function(mobile_device, SearchClient, Tracking) {
    this.$routerOnActivate = (next) => {
      Tracking.track('Search Standalone Page View');
      if (_.isEmpty(next.params.q)) {
        this.sections = [];
        return;
      }
      this.q = decodeURIComponent(next.params.q);
      const per_page = mobile_device ? 3 : 5;
      return SearchClient.multi(this.q, {per_page}).then((sections) => {
        this.sections = sections;
        this.has_results = _.flatMap(this.sections, 'hits').length > 0;
      });
    };
  }],
});
