/* eslint-env es6 */

angular.module('genius').directive('annotationFragment', ['ReferentHelper', 'ReferentRegistry', 'ReferentElementMap', '$compile', '$templateCache', '$location', function(ReferentHelper, ReferentRegistry, ReferentElementMap, $compile, $templateCache, $location) {
  return {
    restrict: 'A',

    scope: true,

    link(scope, element, attrs) {
      scope.fragment_id = Number(scope.$eval(attrs.annotationFragment));
      scope.fragment_element_id = scope.$id;
      scope.image = scope.$eval(attrs.image);

      element.data('fragment_element_id', scope.fragment_element_id);

      const update_classification = () => {
        scope.referent.display_mode = ReferentHelper.display_mode_by_classification(
          scope.referent.temporary_classification || scope.referent.classification
        );
      };

      scope.$watch('referent.classification', update_classification);
      scope.$watch('referent.temporary_classification', update_classification);

      scope.referent = ReferentRegistry.register({
        id: scope.fragment_id,
        classification: scope.classification,
        stub: true,
        fragments: [],
        is_image: scope.image,
        pending_editorial_actions_count: Number(attrs.pendingEditorialActionsCount),
      });

      if (!ReferentElementMap.has(scope.referent.id)) {
        ReferentElementMap.set(scope.referent.id, element);
      }

      if (attrs.verifiedAnnotatorIds) {
        scope.referent.verified_annotator_ids = _.map(attrs.verifiedAnnotatorIds.split(' '), Number);
      }
      scope.referent.classification = attrs.classification;

      scope.referent.fragments.push(element[0].innerText || element.text());
      scope.referent.fragment = _.uniq(scope.referent.fragments).join('\n');
      scope.referent.fragment_from_lyrics = scope.referent.fragment;

      scope.open = () => {
        ReferentElementMap.set(scope.fragment_id, element);
        $location.url(`/${scope.fragment_id}`);
      };

      scope.$watch('referent.id', (new_id) => {
        scope.fragment_id = new_id || scope.fragment_id;
      });

      let $deleted_placeholder;
      scope.$watch('referent._deleted', (deleted) => {
        if (deleted && !$deleted_placeholder) {
          const img = element.find('img')[0];
          if (img) {
            $deleted_placeholder = $('<annotatable-image>').attr({
              src: img.src,
              song: 'song',
              'on-image-referent-created': 'lyrics_ctrl.on_image_referent_created(selection_range, referent);',
              'on-image-referent-creation-failed': 'lyrics_ctrl.on_image_referent_creation_failed(error)',
              'on-image-referent-creation-started': 'lyrics_ctrl.on_image_referent_creation_started(selection_range)',
              'can-perform': 'can_perform',
              'lyrics_anchorer': 'lyrics_ctrl.lyrics_anchorer',
            });
          } else {
            $deleted_placeholder = $('<span>').html(element.html());
          }

          element.before($deleted_placeholder).detach();
          $compile($deleted_placeholder.parent())(scope);
        } else if (!deleted && $deleted_placeholder) {
          $deleted_placeholder.replaceWith(element);
          $deleted_placeholder = undefined;
        }
      });

      scope.$watch('referent.display_mode', (display_mode_is, display_mode_was) => {
        element.removeClass(`referent--${display_mode_was}` || 'default');
        element.addClass(`referent--${display_mode_is}` || 'default');
      });

      scope.$watchGroup(['highlighted_fragment_id', 'hover_referent_id'], () => {
        element.toggleClass(
          'referent--highlighted',
          scope.highlighted_fragment_id === scope.fragment_id || scope.hover_referent_id === scope.fragment_id
        );
      });

      scope.$watchGroup(['showing_annotation_engagement_data', 'open_data'], () => {
        if (scope.showing_annotation_engagement_data && scope.open_data) {
          element.attr('data-annotation-views', scope.open_data[scope.fragment_id] ? scope.open_data[scope.fragment_id] : 'N/A');
        } else {
          element.removeAttr('data-annotation-views');
        }
      });

      if (scope.can_perform('see_editorial_indicators')) {
        const indicators = $compile($templateCache.get('referent_editorial_indicator.html'));
        indicators(scope).insertBefore(element);
      }
    },
  };
}]);
