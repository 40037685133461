/* eslint-env es5 */
/* eslint-disable arrow-body-style, arrow-parens, arrow-spacing, constructor-super, generator-star-spacing, no-class-assign, no-confusing-arrow, no-const-assign, no-dupe-class-members, no-duplicate-imports, no-extra-bind, no-new-symbol, no-restricted-imports, no-this-before-super, no-useless-computed-key, no-useless-constructor, no-useless-rename, no-var, object-shorthand, prefer-arrow-callback, prefer-const, prefer-numeric-literals, prefer-reflect, prefer-rest-params, prefer-spread, prefer-template, require-yield, rest-spread-spacing, sort-imports, symbol-description, template-curly-spacing, yield-star-spacing, dot-location, key-spacing, max-len, new-parens, no-lonely-if, no-mixed-operators, no-trailing-spaces, no-whitespace-before-property, padded-blocks, quotes, space-infix-ops */

angular.module('genius').component('mergeArtistForm', {
  templateUrl: 'merge_artist_form.html',

  bindings: {
    artist: '<',
  },

  controller: ['Session', 'ArtistClient', function(Session, ArtistClient) {
    this.artist_name_to_merge_with = '';

    this.can = function(permission, object) {
      return Session.has_permission(permission, object);
    };

    this.merge_artist = function() {
      if (!this.show_warning) {
        this.show_warning = true;
      } else {
        delete this.errors;
        delete this.artist_merge_complete;
        if (this.saving) return;
        this.saving = true;

        ArtistClient.merge(this.artist.id, this.artist_name_to_merge_with).then(function() {
          this.artist_merge_complete = true;
        }.bind(this)).catch(function(error) {
          this.errors = error.data.response.errors;
        }.bind(this)).finally(function() {
          delete this.saving;
        }.bind(this));
      }
    }.bind(this);
  }],
});
