/* eslint-env es6 */

angular.module('genius').filter('richContentPreview', ['truncateToBoundaryFilter', function(truncateToBoundaryFilter) {
  return (input) => {
    if (!input) return;

    const text = input.replace(/<[^>]+>/gm, '').trim();

    return text
      ? truncateToBoundaryFilter(text, 75, {with_ellipsis: true})
      : '[…]';
  };
}]);
