/* eslint-env es6 */

angular.module('genius').component('inbox', {
  bindings: {
    inbox_name: '@inboxName',
    opened: '<opened',
    clear_unread_count: '&clearUnreadCount',
  },

  templateUrl: 'inbox.html',

  controller: ['InboxClient', 'Tracking', function(InboxClient, Tracking) {
    let next_timestamp;
    let stale_at;
    let currently_loading_page;

    this.should_paginate = () => this.opened &&
      !currently_loading_page &&
      !this.done_loading;

    this.load_next_page = () => {
      if (currently_loading_page) return;

      currently_loading_page = InboxClient.
        line_items(this.inbox_name, next_timestamp).
        then((line_items) => {
          this.line_items.push(...line_items);
          currently_loading_page = null;
          if (line_items.length) {
            next_timestamp = line_items.timestamp_for_next_page;
          } else {
            this.done_loading = true;
          }

          if (this.opened) mark_line_items_read(line_items);
        });
    };

    const reset_line_items = () => {
      this.line_items = [];
      this.done_loading = false;
      next_timestamp = undefined;
      this.load_next_page();
    };

    const refresh = () => {
      if (this.opened) {
        stale_at = Date.now();
      } else {
        reset_line_items();
      }
    };

    const mark_line_items_read = (line_items) => {
      InboxClient.mark_read(
        this.inbox_name,
        _(line_items).filter('unread').map('event_ids').value()
      );
      this.clear_unread_count();
    };

    this.$onInit = () => {
      reset_line_items();

      InboxClient.on_update(this.inbox_name, refresh);
    };

    this.$onChanges = (changes) => {
      if (changes.opened) {
        const is_open = changes.opened.currentValue;
        const was_open = changes.opened.previousValue;
        if (is_open && (!was_open || changes.opened.isFirstChange())) {
          mark_line_items_read(this.line_items);
          Tracking.track('Inbox Open', {inbox_name: this.inbox_name});
        } else if (was_open && !is_open) {
          if (stale_at && stale_at < Date.now()) {
            reset_line_items();
          }
        }
      }
    };
  }],
});
