/* eslint-env es6 */

angular.module('genius').factory('wait_for_global', ['$q', '$window', function($q, $window) {
  return function(name, options) {
    if (_.get($window, name)) return $q.when(_.get($window, name));

    options = _.defaults(options || {}, {timeout: 1000});

    return $q((resolve, reject) => {
      setTimeout(() => {
        clearInterval(wait);
        reject(new Error(`timed out waiting for '${name}' to load`));
      }, options.timeout);

      const wait = setInterval(() => {
        const value = _.get($window, name);
        if (!value) return;
        clearInterval(wait);
        resolve(value);
      }, 50);
    });
  };
}]);
