/* eslint-env es6 */
angular.module('genius').component('videoListEditForm', {
  templateUrl: 'video_list_edit_form.html',

  bindings: {
    video_list: '<object',
    on_updated: '&onUpdated',
  },

  controller: ['VideoListClient', function(VideoListClient) {
    this.save = () => {
      Reflect.deleteProperty(this, 'errors');
      VideoListClient.update(this.editable_video_list.id, {
        title_image_url: this.editable_video_list.title_image_url,
        mobile_title_image_url: this.editable_video_list.mobile_title_image_url,
      }).then((video_list) => {
        this.video_list = video_list;
        this.on_updated({video_list: this.video_list});
      }).catch((error) => {
        this.errors = error.response.errors;
      });
    };

    this.$onChanges = (changes) => {
      if (changes.video_list) {
        this.editable_video_list = _.cloneDeep(this.video_list);
      }
    };
  }],
});
