import 'lodash/lodash.js';


_.mixin({
  eachCons: function(array, size, f) {
    return _.zipWith.apply(null, _.times(size, function(i) {
      return _.slice(array, i, array.length - size + i + 1);
    }).concat(f));
  },

  flattenObject: function(object) {
    return _.reduce(object, function(flattened, value, key) {
      if (_.isPlainObject(value)) {
        _.each(_.flattenObject(value), function(nested_value, nested_key) {
          flattened[key + '.' + nested_key] = nested_value;
        });
      } else {
        flattened[key] = value;
      }

      return flattened;
    }, {});
  },
});
