/* eslint-env es6 */

angular.module('genius').component('editUserEmailPreferences', {
  templateUrl: 'edit_user_email_preferences.html',

  bindings: {
    user: '<',
    close: '&',
  },

  controller: ['UserClient', function(UserClient) {
    this.editable_user = angular.copy(this.user);

    this.update_user = () => {
      if (this.saving) return;
      this.saving = true;
      Reflect.deleteProperty(this, 'errors');

      UserClient.update(this.editable_user.id, {
        user: _.pick(this.editable_user, ['preferences']),
      }).then((data) => {
        _.assign(this.user, data.user);
        this.close();
        Reflect.deleteProperty(this, 'errors');
      }).catch((error) => {
        this.errors = error.errors;
      }).finally(() => {
        Reflect.deleteProperty(this, 'saving');
      });
    };
  }],
});
