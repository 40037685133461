/* eslint-env es6 */

angular.module('genius').factory('Youtube', ['$rootScope', '$q', '$timeout', '$window', 'getScript', 'wait_for_global', function($rootScope, $q, $timeout, $window, getScript, wait_for_global) {
  const YOUTUBE_EMBED_SCRIPT_URL = 'https://www.youtube.com/player_api';
  const YOUTUBE_PLAYER_DEFAULTS = {
    controls: 0,
    enablejsapi: 1,
    fs: 0,
    iv_load_policy: 3,
    modestbranding: 1,
    rel: 0,
    showinfo: 0,
  };

  const load_youtube_api = $q.defer();
  const youtube_api = load_youtube_api.promise.
    then(() => getScript(YOUTUBE_EMBED_SCRIPT_URL, {cache: true})).
    then(() => wait_for_global('YT.Player'));

  return class YouTube {
    constructor(element, options) {
      this.state = $rootScope.$new(true);
      this.deferred_load = $q.defer();

      this.player = this.deferred_load.promise.then(() => {
        load_youtube_api.resolve();
        return youtube_api;
      }).then(YTPlayer => $q((resolve) => {
        const player = new YTPlayer(element, {
          height: '100%',
          width: '100%',
          playerVars: _.defaults({
            controls: options.controls ? 2 : 0,
            fs: options.fullscreen ? 1 : 0,
            playsinline: options.playsinline ? 1 : 0,
          }, YOUTUBE_PLAYER_DEFAULTS),
          events: {
            onReady() { resolve(player); },

            onStateChange: () => {
              $rootScope.$apply(this.update_state.bind(this));
            },

            onError: () => {
              $rootScope.$apply(() => {
                this.state.has_errored = true;
              });
            },
          },
        });
      }));
    }

    load() {
      this.deferred_load.resolve();
      return this.player;
    }

    cue_video_by_id(id, start) {
      this.player.then((player) => {
        player.cueVideoById(id, start);
        this.state.placeholder = `https://i.ytimg.com/vi/${id}/sddefault.jpg`;
      });
    }

    update_state() {
      this.player.then((player) => {
        const duration = player.getDuration();
        const position = player.getCurrentTime();
        const remaining = duration > 0 ? duration - position : duration;

        _.assign(this.state, {
          is_playing: player.getPlayerState() === $window.YT.PlayerState.PLAYING,
          percentage: position / duration,
          time_elapsed: position * 1000,
          time_remaining: remaining * 1000,
        });

        if (this.state.is_playing) {
          this.state.is_loaded = true;
          this.state.is_loading = false;
          $timeout(this.update_state.bind(this), 500);
        }
      });
    }

    get api() {
      if (this._api) return this._api;

      this._api = {
        load_media: () => {
          this.state.is_loading = true;
          return this.load().then(() => {
            this.state.is_loading = false;
            this.state.is_loaded = true;
          });
        },

        seek_to: percentage => this.player.then((player) => {
          player.seekTo(percentage * player.getDuration());
        }),

        pause: () => this.player.then((player) => {
          player.pauseVideo();
        }),

        playpause: () => this.player.then((player) => {
          if (player.getPlayerState() !== $window.YT.PlayerState.PLAYING) {
            this.state.has_played = true;
            player.playVideo();
          } else {
            player.pauseVideo();
          }
        }),

        mute: () => this.player.then((player) => {
          player.mute();
        }),
      };

      return this._api;
    }
  };
}]);
