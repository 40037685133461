/* eslint-env es6 */

angular.module('genius').component('artistMetadataQuestions', {
  templateUrl: 'artist_metadata_questions.html',

  bindings: {
    artist: '<',
    artist_metadata_questions: '<artistMetadataQuestions',
  },

  controller: ['Tracking', function(Tracking) {
    this.track_question_link = (clickEvent, metadataQuestion) => {
      const [related_question_slug, related_questionable_type, related_questionable_id] = metadataQuestion.id.split(':');
      Tracking.track_link_click(clickEvent,
        'artist:click_question_page',
        {
          related_question_slug,
          related_questionable_type,
          related_questionable_id,
        });
    };
  }],
});
