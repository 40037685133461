/* eslint-env es6 */

angular.module('genius').directive('watchImagesLoaded', ['$immediate', '$q', function($immediate, $q) {
  return {
    restrict: 'A',

    link(scope, element, attrs) {
      const watch_property = attrs.watchImagesLoaded || 'ngBindHtml';
      scope.$watch(watch_property, (html) => {
        if (!html) {
          scope.$emit('images_loaded');
          return;
        }

        $immediate(() => {
          $q((resolve) => {
            element.waitForImages(resolve);
          }).then(() => {
            scope.$emit('images_loaded');
          });
        }, !'invoke_apply');
      });
    },
  };
}]);
