/* eslint-env es6 */

angular.module('genius').component('profileFollowers', {
  bindings: {
    artist: '<',
    user: '<',
    follows: '<',
  },

  templateUrl: 'profile_followers.html',

  controller: ['PagedDataFeed', 'UserClient', function(PagedDataFeed, UserClient) {
    this.$onChanges = (changes) => {
      if (changes.user) {
        this.scrollable_data = this.get_scrollable_data();
      }
    };

    this.get_scrollable_data = () => {
      if (this.user && this.follows) {
        return new PagedDataFeed(
          _.partial(UserClient.load_followed_users, this.user.id),
          'followed_users'
        );
      }

      if (this.user && !this.follows) {
        return new PagedDataFeed(
          _.partial(UserClient.load_followers_by_user, this.user.id),
          'followers'
        );
      }

      if (!this.user) {
        return new PagedDataFeed(
          _.partial(UserClient.load_followers_by_artist, this.artist.id),
          'followers'
        );
      }
    };
  }],
});
