/* eslint-env es6 */

angular.module('genius').directive('addAnnotation', [function() {
  return {
    restrict: 'E',

    templateUrl: 'add_annotation.html',

    scope: {
      start_new_community_referent: '&startNewCommunityReferent',
      start_new_referent: '&startNewReferent',
      creating_referent: '<creatingReferent',
      can_add_community_annotation: '<canAddCommunityAnnotation',
      can_add_pinned_annotation: '<canAddPinnedAnnotation',
      annotation: '<',
      song: '<',
      enable_waiting_class: '<enableWaitingClass',
    },

    controllerAs: 'ctrl',

    controller: ['Session', function(Session) {
      this.user_signed_in = Session.user_signed_in;
    }],
  };
}]);
