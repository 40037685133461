/* eslint-env es5 */
/* eslint-disable arrow-body-style, arrow-parens, arrow-spacing, constructor-super, generator-star-spacing, no-class-assign, no-confusing-arrow, no-const-assign, no-dupe-class-members, no-duplicate-imports, no-extra-bind, no-new-symbol, no-restricted-imports, no-this-before-super, no-useless-computed-key, no-useless-constructor, no-useless-rename, no-var, object-shorthand, prefer-arrow-callback, prefer-const, prefer-numeric-literals, prefer-reflect, prefer-rest-params, prefer-spread, prefer-template, require-yield, rest-spread-spacing, sort-imports, symbol-description, template-curly-spacing, yield-star-spacing, dot-location, key-spacing, max-len, new-parens, no-lonely-if, no-mixed-operators, no-trailing-spaces, no-whitespace-before-property, padded-blocks, quotes, space-infix-ops */


angular.module('genius').factory('Brightcove', ['$q', 'AppConfig', 'getScript', 'wait_for_global', function($q, AppConfig, getScript, wait_for_global) {
  var PLAYER_ID_CONFIG_REGEX = /brightcove_(.*)_web_player_id/;

  var player_js_url = function(player_id) {
    return '//players.brightcove.net/' +
      AppConfig.brightcove_account_id + '/' +
      player_id +
      '_default/index.min.js';
  };

  var load_brightcove_api = $q.defer();
  var brightcove_api = function(player_id) {
    return load_brightcove_api.promise.then(function() {
      return getScript(player_js_url(player_id), {cache: true});
    }).then(function() {
      return $q.all({videojs: wait_for_global('videojs'), bc: wait_for_global('bc')});
    });
  };

  var brightcove = {
    account_id: AppConfig.brightcove_account_id,
    load_api: function(player_id) {
      load_brightcove_api.resolve();
      return brightcove_api(player_id);
    },
  };

  _(AppConfig).keys().map(function(key) {
    return key.match(PLAYER_ID_CONFIG_REGEX);
  }).filter().each(function(key_match) {
    brightcove[key_match[1] + '_player_id'] = AppConfig[key_match[0]];
  });

  return brightcove;
}]);
