/* eslint-env es6 */

angular.module('genius').component('inboxLineItemDetailDiscussion', {
  bindings: {
    discussion: '<object',
  },

  templateUrl: 'inbox_line_item_detail_discussion.html',

  controller: ['DiscussionClient', 'PagedDataFeed', function(DiscussionClient, PagedDataFeed) {
    this.$onChanges = (changes) => {
      if (changes.discussion) {
        this.paged_data = new PagedDataFeed(
          _.partial(DiscussionClient.forum_posts, this.discussion.id),
          'forum_posts',
          {per_page: 3},
          2
        );

        this.forum_posts = _.clone(this.discussion.latest_posts);

        if (this.discussion.forum_post_count > this.forum_posts.length) {
          this.has_next = this.paged_data.has_next;
        } else {
          this.has_next = () => false;
        }
      }
    };

    this.load_more = () => {
      this.loading = true;
      this.paged_data.next().
        then(forum_posts => this.forum_posts.push(...forum_posts)).
        finally(() => this.loading = false);
    };
  }],
});
