/* eslint-env es6 */

angular.module('genius').factory('AlbumClient', ['ApiClient', function(ApiClient) {
  return {
    autocomplete(q, options = {}) {
      return ApiClient.
        returning('albums').
        get('/albums/autocomplete', Object.assign({q}, options));
    },

    load_leaderboard(album_id, params) {
      return ApiClient.get(`/albums/${album_id}/leaderboard`, params);
    },

    update(album_id, data) {
      return ApiClient.returning('album').put(`/albums/${album_id}`, {album: data, text_format: 'html,markdown'});
    },

    update_tracklist(album_id, tracklist) {
      return ApiClient.returning('album_appearances').put(`/albums/${album_id}/tracklist`, {tracklist});
    },

    delete(album_id) {
      return ApiClient.delete(`/albums/${album_id}`);
    },

    change_lock_state(album_id, lock_state) {
      return ApiClient.returning('album').put(`/albums/${album_id}/change_lock_state`, {state_event: lock_state});
    },
  };
}]);
