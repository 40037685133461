/* eslint-env es6 */

angular.module('genius').directive('composeMessage', [function() {
  return {
    restrict: 'E',

    scope: {
      recipient: '<',
      on_sent: '&onSent',
      yields_is_dirty: '&yieldsIsDirty',
    },

    templateUrl: 'compose_message.html',

    controller: ['$scope', 'MessageClient', 'Session', function($scope, MessageClient, Session) {
      let recipient_id;
      if ($scope.recipient) recipient_id = $scope.recipient.id;

      const reset_message = () => {
        $scope.message = {
          recipient_id,
          body: '',
        };
        if ($scope.ctrl.form) {
          $scope.ctrl.form.$setPristine();
        }
      };

      reset_message();

      this.new_message = !$scope.message.recipient_id;

      this.current_user = Session.current_user;

      this.showing_markdown_preview = false;

      this.markdown_preview_initialized = false;

      this.show_markdown_preview = () => {
        this.showing_markdown_preview = true;
        this.markdown_preview_initialized = true;
      };

      this.hide_markdown_preview = () => {
        this.showing_markdown_preview = false;
      };

      this.send = () => {
        $scope.sending = true;
        $scope.error = null;
        const params = $scope.message;
        MessageClient.create(params).then((message) => {
          reset_message();
          $scope.on_sent({message});
        }).catch((error) => {
          $scope.error = error;
        }).finally(() => {
          $scope.sending = false;
        });
      };
    }],

    controllerAs: 'ctrl',
  };
}]);
