/* eslint-env es6 */

angular.module('genius').component('adminTasksPage', {
  templateUrl: 'admin_tasks_page.html',

  controller: Object.assign(['$scope', 'AdminTaskClient', function($scope, AdminTaskClient) {
    this.no_task = {name: '__default', label: '--Choose a task to run--'};
    this.$routerOnActivate = (next) => {
      this.tasks = next.routeData.page_data.tasks;
      $scope.selected_task = this.no_task;

      this.select_tasks = [this.no_task].concat(_.sortBy(this.tasks.map(t => ({name: t.name, label: t.human_name})), 'label'));
    };

    $scope.$watch('selected_task', (selected_task) => {
      $scope.admin_task = null;

      if (selected_task.name !== this.no_task.name) {
        AdminTaskClient.get(selected_task.name).then((response) => {
          $scope.admin_task = response.admin_task;
        });
      }
    });
  }], {
    $canActivate: ['$nextInstruction', '$prevInstruction', 'PageTransition', function(
      $nextInstruction,
      $prevInstruction,
      PageTransition
    ) {
      return PageTransition.by_router_instruction($nextInstruction, $prevInstruction);
    }],
  }),
});
