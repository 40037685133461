/* eslint-env es6 */

angular.module('genius').component('embedlyYoutube', {
  templateUrl: 'embedly_youtube.html',

  bindings: {
    video_id: '@videoId',
    start: '@',
  },

  controller: ['$scope', 'Tracking', function($scope, Tracking) {
    this.started = false;
    $scope.$on('youtube.player.playing', () => {
      if (!this.started) {
        Tracking.track(
          'Play embedded video',
          {properties: {'Video provider': 'youtube'}}
        );
        this.started = true;
      }
    });
  }],
});
