/* eslint-env es6 */

angular.module('genius').component('miniAnnotatableCard', {
  bindings: {
    object: '<',
    variants: '<',
  },

  templateUrl: 'mini_annotatable_card.html',
});
