/* eslint-env es6 */

angular.module('genius').component('songAttributionVisualization', {
  bindings: {
    id: '<',
  },

  templateUrl: 'song_attribution_visualization.html',

  controller: ['SongClient', function(SongClient) {
    const COLORS = [
      '#FF574B',
      '#87A6FD',
      '#7FFA95',
      '#FFA0E8',
      '#80FDFB',
      '#FEF498',
      '#E5A0AC',
      '#A0CDA8',
    ];

    this.$onInit = () => {
      this.users = {};
      this.user_colors = {};

      SongClient.attribution_visualization(this.id).then((attribution_visualization) => {
        this.words = attribution_visualization.words;
        this.change_times = _.uniq(this.words.map(w => w.changed_at)).sort((b, a) => b - a);
        this.current_time_index = this.change_times.length - 1;

        attribution_visualization.users.forEach((user, index) => {
          this.users[user.id] = user;
          this.user_colors[user.id] = COLORS[index] || '#CCCCCC';
        });
      });

      this.color = (word) => {
        if (word.user_id) return this.user_colors[word.user_id];
      };

      this.words_to_show = () => {
        if (!this.words) return [];

        return this.words.filter(w =>
          w.changed_at <= this.change_times[this.current_time_index]);
      };

      this.adjust_current_time_index = (adjustment) => {
        const index = this.current_time_index + adjustment;
        if (index < 0 || index > this.change_times.length - 1) return;
        this.current_time_index = index;
      };
    };
  }],
});
