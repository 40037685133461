/* eslint-env es6 */

angular.module('genius').factory('StructuredText', [function() {
  const MARKUP_PLACEHOLDER = String.fromCodePoint(parseInt('E000', 16) + 1);
  const MARKDOWN_ANCHOR_OPEN_BRACE_REPLACEMENT = String.fromCodePoint(parseInt('E000', 16) + 2);

  const HTML_TAG_MATCHER = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;
  const TAG_MATCHER = new RegExp(`${MARKDOWN_ANCHOR_OPEN_BRACE_REPLACEMENT}|\\]\\(\\d+\\)|${HTML_TAG_MATCHER.source}`, 'g');
  const CLOSING_TAG_MATCHER = /\]\(\d+\)|<\/[^>]+>/;
  const OPENING_TAG_MATCHER = new RegExp(`${MARKDOWN_ANCHOR_OPEN_BRACE_REPLACEMENT}|<\\w+(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>`);
  const EMPTY_TAG_MATCHER = new RegExp(`${HTML_TAG_MATCHER.source}\\s?<\\/[^>]+>|\\[\\]\\(\\d+\\)`, 'gm');
  const MARKDOWN_ANCHOR_MATCHER =
    /(?:\[((?:\[[^\]]*\]|\[\[[^\]]*\]\]|[^[\]])*)\]\([ \t]*<?(.*?)>?[ \t]*((['"])(.*?)\6[ \t]*)?\))/gm;

  const markup_placeholders = count => _.pad('', count, MARKUP_PLACEHOLDER);

  const replace_markup_with_placeholders = string => string.replace(
    MARKDOWN_ANCHOR_MATCHER,
    (_match, content, id) => `${MARKUP_PLACEHOLDER}${content}${markup_placeholders(id.length + 3)}`
  ).replace(
    HTML_TAG_MATCHER,
    match => markup_placeholders(match.length)
  );

  return class StructuredText {
    constructor(string) {
      this._string = string.replace(
        MARKDOWN_ANCHOR_MATCHER,
        (_match, content, id) => `${MARKDOWN_ANCHOR_OPEN_BRACE_REPLACEMENT}${content}](${id})`
      );

      const characters = replace_markup_with_placeholders(string).split('');
      let plain_to_structured_indices_length = 0;
      for (let i = 0; i < characters.length; i++) {
        if (characters[i] !== MARKUP_PLACEHOLDER) plain_to_structured_indices_length++;
      }

      this.plain_to_structured_indices = new Array(plain_to_structured_indices_length);
      let plain_to_structured_indices_index = 0;
      for (let i = 0; i < characters.length; i++) {
        if (characters[i] !== MARKUP_PLACEHOLDER) {
          this.plain_to_structured_indices[plain_to_structured_indices_index] = i;
          plain_to_structured_indices_index++;
        }
      }
    }

    remove_range(from, to) {
      const actual_from = this.plain_to_structured_indices[from];
      const actual_to = this.plain_to_structured_indices[to] || _.last(this.plain_to_structured_indices) + 1;
      const range = this._string.substring(actual_from, actual_to);
      const tags = (range.match(TAG_MATCHER) || []).join('');

      return new this.constructor(
        this.string.substring(0, actual_from) +
          tags +
          this.string.substring(actual_to)
      );
    }

    insert_at(at, inserted) {
      let actual_at = this.plain_to_structured_indices[at] || _.last(this.plain_to_structured_indices) + 1;

      const preceding = this._string.substring(0, actual_at);
      const following = this._string.substring(actual_at);

      const preceding_opening_tag = preceding.match(new RegExp(`(?:${OPENING_TAG_MATCHER.source})$`));
      const preceding_closing_tag = preceding.match(new RegExp(`(?:${CLOSING_TAG_MATCHER.source})$`));
      const following_opening_tag = following.match(new RegExp(`^(?:${OPENING_TAG_MATCHER.source})`));
      const following_closing_tag = following.match(new RegExp(`^(?:${CLOSING_TAG_MATCHER.source})`));
      const new_line_terminated_insertion = /^\n|\n$/.test(inserted);

      if (preceding_opening_tag && new_line_terminated_insertion) {
        actual_at = actual_at - preceding_opening_tag[0].length;
      } else if (following_closing_tag && new_line_terminated_insertion) {
        actual_at = actual_at + following_closing_tag[0].length;
      } else if (preceding_closing_tag && !following_opening_tag && !new_line_terminated_insertion) {
        actual_at = actual_at - preceding_closing_tag[0].length;
      } else if (following_opening_tag && !preceding_closing_tag && !new_line_terminated_insertion) {
        actual_at = actual_at + following_opening_tag[0].length;
      }

      return new this.constructor(
        this.string.substring(0, actual_at) +
          inserted +
          this.string.substring(actual_at)
      );
    }

    get string() {
      return this._string.replace(new RegExp(`${MARKDOWN_ANCHOR_OPEN_BRACE_REPLACEMENT}`, 'g'), '[');
    }

    get plain() {
      return this.string.
        replace(MARKDOWN_ANCHOR_MATCHER, (_match, content) => content).
        replace(HTML_TAG_MATCHER, '');
    }

    get structured() {
      return this.string.replace(EMPTY_TAG_MATCHER, '');
    }
  };
}]);
