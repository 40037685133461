/* eslint-env es6 */

angular.module('genius').component('profileAllActivity', {
  templateUrl: 'profile_all_activity.html',

  bindings: {
    user: '<',
    artist: '<',
  },

  controller: ['UserClient', 'ArtistClient', 'PagedDataFeed', 'Session', function(UserClient, ArtistClient, PagedDataFeed, Session) {
    this.$onChanges = (_changes) => {
      this.activity_types = [];

      if (Session.has_permission('view_activity_stream', this.user)) {
        this.activity_types.push({
          activity_type: 'user',
          label: `${this.user.name}'s Activity`,
          profile_activity_scrollable_data: new PagedDataFeed(
            _.partial(UserClient.activity_line_items, this.user.id),
            'line_items',
            {text_format: 'html'}
          ),
        });
      }
      if (Session.has_permission('view_activity_stream', this.artist)) {
        this.activity_types.push({
          activity_type: 'artist',
          label: `Activity on ${this.artist.name}'s songs`,
          profile_activity_scrollable_data: new PagedDataFeed(
            _.partial(ArtistClient.activity_line_items, this.artist.id),
            'line_items',
            {text_format: 'html'}
          ),
        });
      }

      this.activity_type = this.activity_types[0];
    };
  }],
});
