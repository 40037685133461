/* eslint-env es6 */

angular.module('genius').component('pageTransitionOverlay', {
  template: `
    <div class="page_transition_overlay" ng-if="$ctrl.transitioning()"></div>
  `,

  controller: ['PageTransition', function(PageTransition) {
    this.transitioning = () => PageTransition.transitioning;
  }],
});
