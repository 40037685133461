/* eslint-env es6 */

angular.module('genius').factory('AdminTaskClient', ['ApiClient', function(ApiClient) {
  return {
    get(task_name) {
      return ApiClient.get(`/admin_tasks/${task_name}`);
    },

    perform_task(task, property_values) {
      return ApiClient.post(`/admin_tasks/${task.name}/perform`, {property_values});
    },
  };
}]);
