/* eslint-env es6 */

angular.module('genius').factory('VideoSourcesClient', ['ApiClient', function(ApiClient) {
  return {
    index(video_id) {
      return ApiClient.returning('video_sources').get('/videos/:video_id/video_sources', {video_id});
    },

    create(video_id, video_source_params) {
      return ApiClient.
        returning('video_source').
        post('/videos/:video_id/video_sources', {video_id, video_source: video_source_params});
    },

    destroy(video_source_id) {
      return ApiClient.delete('/video_sources/:video_source_id', {video_source_id});
    },
  };
}]);
