/* eslint-env es6 */

angular.module('genius').component('emailConfirmationBanner', {
  templateUrl: 'email_confirmation_banner.html',

  bindings: {
    url: '@',
  },

  controller: ['$http', function($http) {
    this.loading = false;
    this.success = false;

    this.request = () => {
      this.loading = true;

      $http.post(this.url).then(() => {
        this.loading = false;
        this.success = true;
      }).catch(() => {
        this.loading = false;
        this.error_message = 'Oops, something went wrong. Please refresh and try again!';
      });
    };
  }],
});
