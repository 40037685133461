/* eslint-env es6 */

angular.module('genius').factory('Bindings', [function() {
  const binding_getter_for = (spec, binding) => {
    let type = spec[0];
    if (spec[1] === '?') {
      type += '?';
    }
    const attr = spec.slice(type.length) || binding;

    const getter = {
      '@'() { return this.$attrs[attr]; },
      '<'() { return this.$scope.$eval(this.$attrs[attr]); },
      '&'() { return locals => this.$scope.$eval(this.$attrs[attr], locals); },
      '&?'() {
        if (_.isUndefined(this.$attrs[attr])) return undefined;
        return locals => this.$scope.$eval(this.$attrs[attr], locals);
      },
    }[type];

    if (!getter) {
      throw new Error(`invalid attribute specification passed to Bindings service: '${spec}'`);
    }

    return getter;
  };

  return {
    resolver(mapping) {
      class Bindings {
        constructor($scope, $attrs) {
          this.$scope = $scope;
          this.$attrs = $attrs;

          this.assign_to(this);
        }

        assign_to(object) {
          _.each(mapping, (attr, binding) => {
            Reflect.defineProperty(object, binding, {
              get: binding_getter_for(attr, binding).bind(this),
            });
          });
        }
      }

      return ($scope, $attrs) => new Bindings($scope, $attrs);
    },
  };
}]);
