/* eslint-env es6 */

angular.module('genius').directive('annotationSidebar', [function() {
  const annotation_permissions_by_type = {
    verified: 'add_pinned_annotation_to',
    community: 'add_community_annotation_to',
  };

  return {
    templateUrl: 'annotation_sidebar.html',

    controllerAs: 'ctrl',

    scope: true,

    link(scope, _, attrs) {
      scope.$watch(attrs.referent, (referent) => {
        scope.referent = referent;
      });
    },

    controller: ['$scope', 'Session', 'ReferentHelper', 'Attempt', function($scope, Session, ReferentHelper, Attempt) {
      this.user_signed_in = Session.user_signed_in;

      this.can_create_referent_from_selection = () => !!$scope.$ctrl.lyrics_selection_range;

      this.start_new_referent_annotation = function(options) {
        $scope.$ctrl.creating_referent = true;
        $scope.$ctrl.lyrics_compiled.promise.then(() => {
          $scope.$ctrl.lyrics_interface.block_edit(true);
          return $scope.$ctrl.lyrics_interface.anchorer.
            create_referent($scope.$ctrl.lyrics_selection_range, options).
            then((referent) => {
              $scope.$emit('referent_created', referent);
              $scope.referent = referent;
            });
        }).then(() => {
          this.referent_creation_failed = true;
        }).finally(() => {
          $scope.$ctrl.creating_referent = false;
          $scope.$ctrl.lyrics_interface.block_edit(false);
        });
      };

      this.add_pinned_annotation = () => {
        const annotation = ReferentHelper.create_verified_annotation_placeholder($scope.referent);
        $scope.referent.annotations.unshift(annotation);
      };

      this.add_community_annotation = () => {
        const annotation = ReferentHelper.create_community_annotation_placeholder($scope.referent);
        $scope.referent.annotations.push(annotation);
      };

      this.can_create_annotation_type_on_referent = (type) => {
        if (!$scope.referent) return false;
        if (_.some($scope.referent.annotations, 'new')) return false;
        const permission = annotation_permissions_by_type[type];
        return Session.has_permission(permission, $scope.referent);
      };

      this.can_create_community_annotation_on_referent = function() {
        return this.can_create_annotation_type_on_referent('community') &&
          !_.some($scope.referent.annotations, 'needs_exegesis');
      };

      $scope.$watch('referent.id', (referent_id_is, referent_id_was) => {
        if (referent_id_is && referent_id_was && referent_id_is.id === referent_id_was.id) return;
        this.referent_creation_failed = false;
      });

      this.close = () => {
        Attempt.close_form(!this.annotation_dirty, () => {
          this.annotation_dirty = false;
          $scope.$ctrl.clear_current_referent();
          $scope.sidebar.signing_up = false;
        });
      };
    }],
  };
}]);
