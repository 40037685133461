/* eslint-env es6 */

angular.module('genius').directive('onLoad', [function() {
  return {
    restrict: 'A',

    link(scope, element, attributes) {
      element.on('load', () => {
        scope.$apply(() => {
          scope.$eval(attributes.onLoad);
        });
      });
    },
  };
}]);
