/* eslint-env es6 */

angular.module('genius').factory('IntersectionObserver', [function() {
  const isServer = typeof window === 'undefined';

  const isClient = !isServer;

  function IntersectionObserverShim(_callback, {shim = {}}) {
    this.observe = shim.observe || _.noop;
    this.unobserve = shim.unobserve || _.noop;
    this.disconnect = shim.disconnect || _.noop;
  }

  return isClient && 'IntersectionObserver' in window ? window.IntersectionObserver : IntersectionObserverShim;
}]);
