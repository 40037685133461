/* eslint-env es6 */

angular.module('genius').factory('Session', ['SessionConfig', function(SessionConfig) {
  return Object.create(Object.prototype, {
    has_permission: {
      value(permission, object) {
        return _.includes(_.get(object, 'current_user_metadata.permissions'), permission);
      },
    },

    has_feature: {
      value(feature) {
        return _.includes(SessionConfig.features, feature);
      },
    },

    user_signed_in: {
      get() {
        return !!this.current_user;
      },
    },

    current_user: {
      get() {
        return SessionConfig.current_user;
      },
      set(value) {
        SessionConfig.current_user = value;
      },
    },
  });
}]);
