/* eslint-env es6 */

angular.module('genius').directive('pyongForm', ['PyongClient', function(PyongClient) {
  return {
    restrict: 'E',

    scope: {
      id: '<',
      type: '<',
      onComplete: '&',
    },

    templateUrl: 'pyong_form.html',

    link(scope) {
      scope.submit = (note) => {
        scope.disabled = true;
        PyongClient.finalizePyong(scope.type, scope.id, note).
          then(scope.onComplete).
          finally(() => scope.disabled = false);
      };
    },
  };
}]);
