/* eslint-env es6 */

angular.module('genius').factory('ConversationClient', ['ApiClient', function(ApiClient) {
  const converstaion_api = ApiClient.set_param('text_format', 'html');

  return {
    latest(options = {}) {
      return converstaion_api.
        returning('conversations').
        get('/conversations', {page: options.page || 1});
    },

    get(conversation_id) {
      return converstaion_api.
        returning('conversation').
        get(
          '/conversations/:conversation_id',
          {conversation_id}
        );
    },

    mark_read(conversation_id) {
      return converstaion_api.
        returning('conversation').
        post(
          '/conversations/:conversation_id/mark_read',
          {conversation_id}
        );
    },

    with_user(user_id) {
      return converstaion_api.
        returning('conversation').
        put(
          '/conversations/with_user',
          {user_id}
        );
    },
  };
}]);
