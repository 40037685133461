/* eslint-env es6 */

angular.module('genius').directive('odometer', ['Odometer', function(Odometer) {
  return {
    restrict: 'E',

    scope: {
      value: '<',
      options: '<',
    },

    link(scope, element) {
      const odometer = new Odometer(_.merge({
        el: element[0],
        value: scope.value,
      }, scope.options));

      scope.$watch('value', (value) => {
        odometer.update(value);
      });
    },
  };
}]);
