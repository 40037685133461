/* eslint-env es5 */
/* eslint-disable arrow-body-style, arrow-parens, arrow-spacing, constructor-super, generator-star-spacing, no-class-assign, no-confusing-arrow, no-const-assign, no-dupe-class-members, no-duplicate-imports, no-extra-bind, no-new-symbol, no-restricted-imports, no-this-before-super, no-useless-computed-key, no-useless-constructor, no-useless-rename, no-var, object-shorthand, prefer-arrow-callback, prefer-const, prefer-numeric-literals, prefer-reflect, prefer-rest-params, prefer-spread, prefer-template, require-yield, rest-spread-spacing, sort-imports, symbol-description, template-curly-spacing, yield-star-spacing, dot-location, key-spacing, max-len, new-parens, no-lonely-if, no-mixed-operators, no-trailing-spaces, no-whitespace-before-property, padded-blocks, quotes, space-infix-ops */

angular.module('genius').directive('autocompleteTagsInput', ['AlbumClient', 'ArtistClient',  'TagClient', 'SongClient', function(AlbumClient, ArtistClient, TagClient, SongClient) {

  var adapters = {
    album: {
      autocomplete: AlbumClient.autocomplete,
      persistence_property: 'name',
      display_property: 'name_with_artist',
    },
    artist: {
      autocomplete: ArtistClient.autocomplete,
      display_property: 'name',
    },
    tag: {
      autocomplete: TagClient.autocomplete,
      display_property: 'name',
    },
    song: {
      autocomplete: SongClient.autocomplete,
      persistence_property: 'id',
      display_property: 'full_title',
    },
  };

  return {
    restrict: 'E',

    scope: {
      bindTo: '=',
      disabled: '<ngDisabled',
      name: '@',
      inputClasses: '@',
      autocompleteModel: '@',
      autocompleteOptions: '<?',
      requireExisting: '<',
      excludeIf: '&',
    },

    templateUrl: 'autocomplete_tags_input.html',

    link: {
      pre: function(scope) {
        var adapter = adapters[scope.autocompleteModel];
        scope.max_results = 10;
        scope.display_property = adapter.display_property;
        scope.autocomplete = function(query) {
          return adapter.autocomplete(query, scope.autocompleteOptions).then(function(results) {
            results = _.reject(results, function(result) {
              return scope.excludeIf({result: result});
            });

            if (scope.requireExisting) return results;

            var results_have_exact_match = _.some(results, function(result) {
              return result[scope.display_property].toLowerCase() ===
                query.toLowerCase();
            });

            if (results_have_exact_match) return results;

            var new_result = {_new: true};
            new_result[adapter.display_property] = query;
            if (adapter.persistence_property) {
              new_result[adapter.persistence_property] = query;
            }
            results = _.take(results, scope.max_results - 1).concat(new_result);

            return results;
          });
        };
      },

      post: function(scope, _elem, attrs) {
        scope.placeholder = function() {
          return scope.bindTo && scope.bindTo.length === 0 ? attrs.placeholder : '';
        };
      },
    },
  };
}]);
