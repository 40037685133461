/* eslint-env es5 */
/* eslint-disable arrow-body-style, arrow-parens, arrow-spacing, constructor-super, generator-star-spacing, no-class-assign, no-confusing-arrow, no-const-assign, no-dupe-class-members, no-duplicate-imports, no-extra-bind, no-new-symbol, no-restricted-imports, no-this-before-super, no-useless-computed-key, no-useless-constructor, no-useless-rename, no-var, object-shorthand, prefer-arrow-callback, prefer-const, prefer-numeric-literals, prefer-reflect, prefer-rest-params, prefer-spread, prefer-template, require-yield, rest-spread-spacing, sort-imports, symbol-description, template-curly-spacing, yield-star-spacing, dot-location, key-spacing, max-len, new-parens, no-lonely-if, no-mixed-operators, no-trailing-spaces, no-whitespace-before-property, padded-blocks, quotes, space-infix-ops */

angular.module('genius').directive('voters', ['VoteClient', function(VoteClient) {
  return {
    restrict: 'E',

    templateUrl: 'voters.html',

    scope: {
      type: '@',
      object: '<',
    },

    link: function(scope) {
      scope.load = function() {
        VoteClient.load_voters(scope.type, scope.object.id).then(function(voters) {
          scope.loaded_voters = true;
          scope.voters = voters;
          scope.object.votes_total = voters.votes_total;
        });
      };

      scope.$on('update_voters', function(_e) {
        scope.loaded_voters = false;
        scope.load();
      });

      scope.load();
    },
  };
}]);
