/* eslint-env es6 */

angular.module('genius').component('videosModal', {
  bindings: {
    initial_video: '<initialVideo',
    build_tracking_data: '&buildTrackingData',
    on_close: '&onClose',
    object: '<',
  },

  templateUrl: 'videos_modal.html',

  controller: ['VideoRegistry', function(VideoRegistry) {
    this.$onInit = () => {
      this.players = {};
      this.carousel_videos = VideoRegistry.videos_for(this.object);

      if (this.initial_video) {
        _.remove(this.carousel_videos, this.initial_video);
        this.carousel_videos.unshift(this.initial_video);
      }
    };

    this.$onChanges = (changes) => {
      if (changes.initial_video) {
        if (this.initial_video) {
          this.active_video = this.initial_video;
        }
      }
    };

    this.select_video = (video) => {
      if (video && video !== this.active_video) {
        const active_player = this.players[this.active_video.id];
        if (active_player) active_player.pause();
        this.play_pct = 0;
        this.active_video = video;
        this.video_can_play = false;
      }
    };

    this.next_video = () => {
      const video_idx = _.findIndex(this.carousel_videos, v => v.id === this.active_video.id);
      const next_video = this.carousel_videos[video_idx + 1];
      if (next_video) this.select_video(next_video);
    };

    this.update_progress = (current, duration) => {
      this.play_pct = Math.ceil(duration ? (current / duration) * 100 : 0);
    };

    this.add_video_api = (id, player) => this.players[id] = player;

    this.tracking_data = video =>
      _.assign(this.build_tracking_data({video}), {'Modal Position': _.indexOf(this.carousel_videos, video) + 1});
  }],
});
