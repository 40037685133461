/* eslint-env es6 */

angular.module('genius').directive('copyToClipboard', ['$timeout', function($timeout) {
  const change_text_after_copy = function(element, text) {
    const text_before_copy = element.text();
    element.text(text);
    $timeout(() => {
      element.text(text_before_copy);
    }, 750);
  };

  return {
    restrict: 'A',

    link(scope, element, attrs) {
      const onClick = () => {
        navigator.clipboard.writeText(scope.$eval(attrs.copyToClipboard)).
          then(() => {
            if (attrs.textToShowAfterCopy) change_text_after_copy(element, attrs.textToShowAfterCopy);
          }).
          catch(element.remove);
      };

      element[0].addEventListener('click', onClick);
      scope.$on('$destroy', () => element[0].removeEventListener('click', onClick));
    },
  };
}]);
