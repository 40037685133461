/* eslint-env es6 */

angular.module('genius').factory('WindowTitle', ['$document', function($document) {
  return {
    update_count(count) {
      const replacement = count > 0 ? `(${count}) ` : '';
      $document[0].title = $document[0].title.replace(/^(\(\d+\) )?/, replacement);
    },
  };
}]);
