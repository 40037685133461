/* eslint-env es6 */

angular.module('genius').factory('getScript', ['$q', function($q) {
  return function(url, options) {
    return $q.when($.ajax(_.assign({
      url,
      type: 'GET',
      dataType: 'script',
    }, options)));
  };
}]);
