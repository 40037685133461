/* eslint-env es6 */

angular.module('genius').filter('i18n', ['I18n', 'AppConfig', function(I18n, AppConfig) {
  return (input, args = {}) => {
    if (args.defaultKey) {
      args = Object.assign(args, {defaultValue: I18n.t(args.defaultKey, args)});
    }

    if (typeof input === 'object') {
      input = _.findKey(input);
    }
    input = input || '__not_found';

    if (!I18n.lookup(input, args)) {
      const error_str = `Missing translation for ${input}!!!`;
      if (AppConfig.env === 'development') {
        alert(error_str);
        throw new Error(error_str);
      }
      return '';
    }
    return I18n.t(input, args);
  };
}]);
