/* eslint-env es6 */

angular.module('genius').directive('classForBrowser', ['Bowser', 'Bindings', function(Bowser, Bindings) {
  const resolve_bindings = Bindings.resolver({
    browsers_and_classes: '<classForBrowser',
  });

  return {
    restrict: 'A',

    link: (scope, element, attributes) => {
      const bindings = resolve_bindings(scope, attributes);

      let has_applied_browser_class = false;

      _.each(bindings.browsers_and_classes, (cssclass, browser) => {
        if (_.has(Bowser, browser)) {
          element.addClass(cssclass);
          has_applied_browser_class = true;
        }
      });

      if (!has_applied_browser_class) element.addClass(bindings.browsers_and_classes['_default']);
    },
  };
}]);

