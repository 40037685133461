/* eslint-env es6 */

angular.module('genius').directive('svg', ['Bowser', 'ShimInnerHtmlProp', '$templateCache', '$immediate', function(Bowser, ShimInnerHtmlProp, $templateCache, $immediate) {
  const update_with_src = ($svg, src) => {
    const $template = $($templateCache.get(src));
    $svg.html($template[0].innerHTML);
    _.each($template[0].attributes, (attr) => { $svg[0].setAttribute(attr.name, attr.value); });

    const viewbox = $svg.get(0).getAttribute('viewBox');
    if ((Bowser.msie || Bowser.msedge) && viewbox) {
      $immediate(() => {
        const bbox = viewbox.split(' ');
        if (bbox.length > 3) $svg.width($svg.height() * (parseInt(bbox[2]) / parseInt(bbox[3])));
      }, !'invoke_apply');
    }
  };

  return {
    link(scope, element, attrs) {
      ShimInnerHtmlProp();

      if ('src' in attrs) {
        update_with_src(element, attrs.src);
      }

      if ('ngSrc' in attrs) {
        scope.$watch(attrs.ngSrc, (src) => {
          if (src) update_with_src(element, src);
        });
      }
    },
  };
}]);
