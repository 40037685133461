/* eslint-env es6 */

angular.module('genius').directive('shareButtons', ['Session', function(Session) {
  return {
    scope: {
      song: '<',
    },

    templateUrl: 'share_buttons.html',

    controllerAs: 'ctrl',

    controller: [function() {
      this.show_report_abuse = Session.has_feature('report_abuse');
    }],
  };
}]);
