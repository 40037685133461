/* eslint-env es6 */

const USER_ACTIVITY_FILTER_OPTIONS = [
  {
    i18n_key: 'contribution_type.all_activity.name',
    filter: null,
    sort: [],
  },
  {
    i18n_key: 'contribution_type.annotations.name',
    filter: 'annotations',
    sort: [
      {
        i18n_key: 'contribution_type.annotations.sort.popularity',
        value: 'popularity',
      },
      {
        i18n_key: 'contribution_type.annotations.sort.chronologically',
        value: 'chronologically',
      },
    ],
  },
  {
    i18n_key: 'contribution_type.pyongs.name',
    filter: 'pyongs',
    sort: [],
  },
  {
    i18n_key: 'contribution_type.transcriptions.name',
    filter: 'transcriptions',
    sort: [
      {
        i18n_key: 'contribution_type.transcriptions.sort.popularity',
        value: 'popularity',
      },
      {
        i18n_key: 'contribution_type.transcriptions.sort.chronologically',
        value: 'chronologically',
      },
    ],
  },
  {
    i18n_key: 'contribution_type.comments.name',
    filter: 'comments',
    sort: [],
  },
  {
    i18n_key: 'contribution_type.questions_and_answers.name',
    filter: 'questions_and_answers',
    sort: [],
  },
  {
    i18n_key: 'contribution_type.articles.name',
    filter: 'articles',
    sort: [
      {
        i18n_key: 'contribution_type.articles.sort.popularity',
        value: 'popularity',
      },
      {
        i18n_key: 'contribution_type.articles.sort.chronologically',
        value: 'chronologically',
      },
    ],
  },
  {
    i18n_key: 'contribution_type.unreviewed_annotations.name',
    filter: 'unreviewed_annotations',
    sort: [
      {
        i18n_key: 'contribution_type.unreviewed_annotations.sort.popularity',
        value: 'popularity',
      },
      {
        i18n_key: 'contribution_type.unreviewed_annotations.sort.chronologically',
        value: 'chronologically',
      },
    ],
  },
];

angular.module('genius').component('profilePage', {
  templateUrl: 'profile_page.html',

  bindings: {
    artist: '<',
    user: '<',
    artist_songs: '<artistSongs',
    artist_albums: '<artistAlbums',
    artist_metadata_questions: '<artistMetadataQuestions',
    top_scholar: '<topScholar',
    top_accomplishment: '<topAccomplishment',
    pinned_annotations: '<pinnedAnnotations',
    artist_has_more_songs: '<artistHasMoreSongs',
    show_artist_pane: '<showArtistPane',
    preloaded_user_activity: '<preloadedUserActivity',
    initial_next_user_activity_timestamp: '<initialNextUserActivityTimestamp',
    $router: '<',
    current_user_metadata: '<currentUserMetadata',
  },

  controller: ['CursoredDataFeed', 'UserClient', 'ArtistClient', '$rootRouter', 'Session', 'ScrollPoint', 'Tracking', 'mobile_device', 'VideoRegistry', 'Attempt', function(CursoredDataFeed, UserClient, ArtistClient, $rootRouter, Session, ScrollPoint, Tracking, mobile_device, VideoRegistry, Attempt) {
    this.$onInit = () => {
      this.attempt_close = Attempt.close_form;

      this.user_activity_sort_options_by_filter = USER_ACTIVITY_FILTER_OPTIONS;

      this.logged_in = Session.user_signed_in;
      this.show_report_abuse = Session.has_feature('report_abuse');

      if (this.user) {
        this.filter_activity_by(this.user_activity_sort_options_by_filter[0]);
      }

      const tracking_properties = {'counts_as_page_load': true};
      if (this.artist) {
        tracking_properties['Artist ID'] = this.artist.id;
        VideoRegistry.register_page(this.artist, mobile_device ? 'mobile_artist_page' : 'desktop_artist_page');
      } else {
        tracking_properties['Profile User ID'] = this.user.id;
      }
      Tracking.delayed_track('Profile Page Load', tracking_properties);
    };

    this.$onChanges = (changes) => {
      if (changes.user || changes.artist) {
        this.community_artist = this.artist && this.user && !this.user.is_verified;
      }

      if (changes.user) {
        this.can_message_user = this.can('send_message', this.user);
        this.can_view_activities_in_real_time = this.logged_in && this.user;
        this.can_edit_artist_profile = this.can('edit_anything', this.artist);
        this.can_edit_user_profile = this.can('edit', this.user);
        this.can_edit_profile = this.can_edit_artist_profile || this.can_edit_user_profile;
        this.can_view_admin_info = this.can('view_admin_info', this.user);
        this.can_view_roles = this.can('view_roles', this.user);
        this.can_verify = this.can('toggle_verification_status', this.user);
        this.can_moderate_user = this.can('toggle_penalty_status', this.user)
          || this.can('mark_spam', this.user)
          || this.can('mark_ham', this.user)
          || this.can('mass_delete_unreviewed_activity', this.user)
          || this.can('mass_delete_votes', this.user);
        this.can_view_tracked_errors = this.can('view_tracked_errors', this.user);
        if (this.user) {
          this.bugsnag_link = 'https://app.bugsnag.com/genius/genius/errors?' +
            `filters[event.since][0]=all&filters[user.id][0][value]=${this.user.id}&filters[user.id][0][type]=eq`;
        }
      }
    };

    this.scroll_to = ScrollPoint.scroll_to;

    this.can = (permission, object) => Session.has_permission(permission, object);

    this.filter_activity_by = (contribution_type, sort) => {
      this.filter_by_contribution_type = contribution_type;
      if (contribution_type.filter) {
        this.filter_by_contribution_type_sort = sort || contribution_type.sort[0];
        this.user_contributions_scrollable_data = new CursoredDataFeed(
          _.partial(UserClient.load_contributions, this.user.id, contribution_type.filter),
          'contribution_groups',
          {
            text_format: 'html,markdown',
            sort: this.filter_by_contribution_type_sort && this.filter_by_contribution_type_sort.value,
            per_page: 8,
          }
        );
      } else {
        this.user_contributions_scrollable_data = new CursoredDataFeed(
          _.partial(UserClient.load_contributions, this.user.id, false),
          'contribution_groups',
          {text_format: 'html,markdown', per_page: 8},
          this.initial_next_user_activity_timestamp
        );
      }
    };

    this.filter_activity_by_type = (filter_type) => {
      this.filter_activity_by(_.find(this.user_activity_sort_options_by_filter, {filter: filter_type}));
      this.scroll_to('user_activity');
    };

    this.display_as_artist = () => !!(this.artist && (!this.user || this.user.is_verified));
    this.display_as_user = () => !this.display_as_artist();
    this.header_url = () =>
      (this.display_as_artist() ? this.artist.header_image_url : this.user.header_image_url);
    this.avatar_url = () =>
      (this.display_as_artist() ? this.artist.image_url : this.user.avatar.medium.url);

    const navigateToSelf = (show_artist_pane) => {
      if (this.display_as_artist()) {
        $rootRouter.navigate([
          'ArtistPage', {},
          show_artist_pane ? 'ProfileBioPage' : 'ProfilePage', {slug: ArtistClient.get_slug(this.artist)},
        ]);
      } else {
        $rootRouter.navigate([
          show_artist_pane ? 'UserBioPage' : 'UserPage', {slug: UserClient.get_slug(this.user)},
        ]);
      }
    };

    this.open_description = () => {
      navigateToSelf(true);
    };
    this.close_description = () => {
      navigateToSelf(false);
    };

    this.change_state = state => this.state = state;

    this.close_conversation_modal = () => this.show_conversation_modal = false;

    this.songs_discography_path = this.display_as_artist() ? ArtistClient.discography_path(this.artist, 'songs') : '';
  }],
});
