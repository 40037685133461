/* eslint-env es6 */

angular.module('genius').factory('FollowClient', ['AppConfig', '$http', function(AppConfig, $http) {
  return {
    set_following(type, id, state) {
      return $http.
        post(`${AppConfig.api_root_url}/${type}s/${id}/${state ? 'follow' : 'unfollow'}`).
        then(response => response.data.response);
    },
  };
}]);
