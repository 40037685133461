import 'mousetrap/mousetrap';
import 'mousetrap/plugins/global-bind/mousetrap-global-bind';
/* eslint-env es5 */
/* eslint-disable arrow-body-style, arrow-parens, arrow-spacing, constructor-super, generator-star-spacing, no-class-assign, no-confusing-arrow, no-const-assign, no-dupe-class-members, no-duplicate-imports, no-extra-bind, no-new-symbol, no-restricted-imports, no-this-before-super, no-useless-computed-key, no-useless-constructor, no-useless-rename, no-var, object-shorthand, prefer-arrow-callback, prefer-const, prefer-numeric-literals, prefer-reflect, prefer-rest-params, prefer-spread, prefer-template, require-yield, rest-spread-spacing, sort-imports, symbol-description, template-curly-spacing, yield-star-spacing, dot-location, key-spacing, max-len, new-parens, no-lonely-if, no-mixed-operators, no-trailing-spaces, no-whitespace-before-property, padded-blocks, quotes, space-infix-ops */


angular.module('genius').factory('Mousetrap', ['$window', '$rootScope', function($window, $rootScope) {
  var create_applied_callback = function(callback, options) {
    options = options || {};
    return function(event) {
      if (options.prevent_default) event.preventDefault();
      if (options.stop_propagation) event.stopPropagation();
      $rootScope.$apply(function() {
        callback.call(this, event);
      }.bind(this));
    };
  };

  var AngularizedMousetrap = function(element, default_options) {
    this.default_options = default_options || {};
    this.mousetrap = new $window.Mousetrap(element);
  };

  AngularizedMousetrap.prototype.bind = function(key, callback) {
    this.mousetrap.bind(key, callback);
  };

  AngularizedMousetrap.prototype.$bind = function(key, callback, options) {
    options = _.assign({}, this.default_options, options);
    this.bind(key, create_applied_callback(callback, options));
  };

  return _.assign(AngularizedMousetrap, {
    $bind: function(key, callback, options) {
      this.bind(key, create_applied_callback(callback, options));
    },

    bind: $window.Mousetrap.bind.bind($window.Mousetrap),
    unbind: $window.Mousetrap.unbind.bind($window.Mousetrap),
    trigger: $window.Mousetrap.trigger.bind($window.Mousetrap),
    bindGlobal: $window.Mousetrap.bindGlobal.bind($window.Mousetrap),
  });
}]);
