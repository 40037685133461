/* eslint-env es6 */

angular.module('genius').directive('showArrowAtOffset', [function() {
  return {
    restrict: 'A',

    link(scope, element) {
      scope.show_arrow_at_offset = offset => offset > element.position().top;
    },
  };
}]);
