/* eslint-env es6 */

angular.module('genius').component('articleAdminBar', {
  bindings: {
    article: '<',
    video: '<',
  },

  templateUrl: 'article_admin_bar.html',

  controller: ['Session', 'Attempt', 'ArticleClient', 'currentFlashMessages', 'SmartRouting', '$window', function(Session, Attempt, ArticleClient, currentFlashMessages, SmartRouting, $window) {
    this.current_user = Session.current_user;
    this.can = (action_name, object) => Session.has_permission(action_name, object);

    this.edit_article = () => {
      SmartRouting.navigate_to_absolute_url(`${$window.location.origin}/articles/${this.article.id}/edit`);
    };

    this.manage_articles = () => {
      SmartRouting.navigate_to_absolute_url(`${$window.location.origin}/articles`);
    };

    this.delete = () => {
      Attempt.admin_action(
        () => {
          ArticleClient.delete(this.article.id).then(() => {
            currentFlashMessages.push({type: 'notice', message: 'Successfully deleted article'});
            $window.location.href = '/';
          });
        },
        'Are you sure you want to delete this article?'
      );
    };
  }],
});
