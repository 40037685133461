/* eslint-env es6 */

angular.module('genius').directive('annotationPlaceholder', [function() {
  return {
    restrict: 'E',

    templateUrl: 'annotation_placeholder.html',

    scope: {
      showCondition: '<',
      noLabel: '<',
      placeholder_title: '<placeholderTitle',
    },
  };
}]);
