/* eslint-env es6 */

angular.module('genius').directive('clickOutside', ['$document', function($document) {
  return {
    restrict: 'A',

    link(scope, element, attrs) {
      const suppress_if_handled = attrs.hasOwnProperty('suppressClickOutsideIfHandled');

      const handler = function(event) {
        if (suppress_if_handled && event.originalEvent.defaultPrevented) return;
        if (!document.body.contains(event.target)) return;
        if (element[0].contains(event.target)) return;
        scope.$apply(() => {
          scope.$eval(attrs.clickOutside, {$event: event});
        });
      };

      const $click_outside_target =
        element.closest('[click-outside-target]').length ?
          element.closest('[click-outside-target]') :
          $document;

      $click_outside_target.on('click', handler);
      scope.$on('$destroy', () => {
        $click_outside_target.off('click', handler);
      });
    },
  };
}]);
