/* eslint-env es5 */
/* eslint-disable arrow-body-style, arrow-parens, arrow-spacing, constructor-super, generator-star-spacing, no-class-assign, no-confusing-arrow, no-const-assign, no-dupe-class-members, no-duplicate-imports, no-extra-bind, no-new-symbol, no-restricted-imports, no-this-before-super, no-useless-computed-key, no-useless-constructor, no-useless-rename, no-var, object-shorthand, prefer-arrow-callback, prefer-const, prefer-numeric-literals, prefer-reflect, prefer-rest-params, prefer-spread, prefer-template, require-yield, rest-spread-spacing, sort-imports, symbol-description, template-curly-spacing, yield-star-spacing, dot-location, key-spacing, max-len, new-parens, no-lonely-if, no-mixed-operators, no-trailing-spaces, no-whitespace-before-property, padded-blocks, quotes, space-infix-ops */

angular.module('genius').directive('editableMarkdown', ['$immediate', 'Filepicker', 'Autosize', function($immediate, Filepicker, Autosize) {
  return {
    restrict: 'E',
    replace: true,

    templateUrl: 'editable_markdown.html',

    scope: {
      html: '=',
      markdown: '=',
      is_required: '<?isRequired',
      placeholder: '@',
    },

    controller: function() {
      var filepicker = new Filepicker();

      this.pick_image = function() {
        filepicker.pick_and_store().then(function(url) {
          this.insert_at_markdown_cursor({before: url});
        }.bind(this));
      };
    },

    controllerAs: 'ctrl',

    link: function(scope, element) {
      scope.editable = scope;

      scope.resize_textarea = function() {
        scope.$evalAsync(function() {
          var $textarea = element.find('textarea');
          Autosize($textarea);
          $immediate($textarea.focus.bind($textarea), !'invoke_apply');
        });
      };
    },
  };
}]);
