/* eslint-env es5 */
/* eslint-disable arrow-body-style, arrow-parens, arrow-spacing, constructor-super, generator-star-spacing, no-class-assign, no-confusing-arrow, no-const-assign, no-dupe-class-members, no-duplicate-imports, no-extra-bind, no-new-symbol, no-restricted-imports, no-this-before-super, no-useless-computed-key, no-useless-constructor, no-useless-rename, no-var, object-shorthand, prefer-arrow-callback, prefer-const, prefer-numeric-literals, prefer-reflect, prefer-rest-params, prefer-spread, prefer-template, require-yield, rest-spread-spacing, sort-imports, symbol-description, template-curly-spacing, yield-star-spacing, dot-location, key-spacing, max-len, new-parens, no-lonely-if, no-mixed-operators, no-trailing-spaces, no-whitespace-before-property, padded-blocks, quotes, space-infix-ops */

angular.module('genius').directive('flashMessages', function() {
  return {
    restrict: 'E',

    replace: true,

    scope: {},

    templateUrl: 'flash_messages.html',

    controllerAs: 'ctrl',

    controller: ['$scope', '$sce', 'Cookies', 'currentFlashMessages', function($scope, $sce, Cookies, currentFlashMessages) {

      var flash = Cookies.getObject('flash') || {};

      _.each(flash, function(message, type) {
        currentFlashMessages.push({
          message: $sce.trustAsHtml(message),
          type: type,
        });
      });

      Cookies.remove('flash', {path: '/'});

      $scope.flash_messages = currentFlashMessages;

      this.close_message = function(message) {
        _.pull($scope.flash_messages, message);
      };
    }],
  };
});
