import 'showdown';
/* eslint-env es5 */
/* eslint-disable arrow-body-style, arrow-parens, arrow-spacing, constructor-super, generator-star-spacing, no-class-assign, no-confusing-arrow, no-const-assign, no-dupe-class-members, no-duplicate-imports, no-extra-bind, no-new-symbol, no-restricted-imports, no-this-before-super, no-useless-computed-key, no-useless-constructor, no-useless-rename, no-var, object-shorthand, prefer-arrow-callback, prefer-const, prefer-numeric-literals, prefer-reflect, prefer-rest-params, prefer-spread, prefer-template, require-yield, rest-spread-spacing, sort-imports, symbol-description, template-curly-spacing, yield-star-spacing, dot-location, key-spacing, max-len, new-parens, no-lonely-if, no-mixed-operators, no-trailing-spaces, no-whitespace-before-property, padded-blocks, quotes, space-infix-ops */


angular.module('genius').factory('Markdown', ['$window', function($window) {
  var $ = angular.element;

  return {
    to_html: function(markdown) {
      if (!markdown) return markdown;

      var converter = new $window.Attacklab.showdown.converter();

      var lone_image_url_regex = /(?:^|\s+)https?:\/\/(?:[a-z0-9\-]+\.)+[a-z]{2,6}(?:\/[^\/#?]+)+\.(?:jpe?g|gif|png)(?=$|\s+)/igm;
      markdown = markdown.replace(lone_image_url_regex, function(match) {
        return '\n\n![](' + $.trim(match) + ')';
      });

      var html = converter.makeHtml(markdown);

      return $('<div>').append(html).find('a').each(function() {
        var $a = $(this);
        if ($a.attr('href').match(/^\d+$/)) {
          $a.attr('href', '/' + $a.attr('href'));
        }
      }).end().html();
    },
  };
}]);
