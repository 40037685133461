/* eslint-env es6 */

angular.module('genius').component('adContainerWithCloseButton', {
  templateUrl: 'ad_container_with_close_button.html',

  transclude: true,

  controller: ['$window', '$element', '$timeout', function($window, $element, $timeout) {
    if ($window.googletag) {
      $window.googletag.cmd.push(() => {
        $window.googletag.pubads().addEventListener('slotRenderEnded', (event) => {
          if (!event.isEmpty && $element.find(`#${event.slot.getSlotElementId()}`).length > 0) {
            $timeout(() => this.show_close_button = true, 10000);
          }
        });
      });
    }
  }],
});
