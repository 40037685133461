/* eslint-env es6 */

angular.module('genius').component('profileContributionsAnswers', {
  templateUrl: 'profile_contributions_answers.html',

  bindings: {
    user: '<',
    contributions_group: '<contributionsGroup',
  },

  controller() {
    this.$onChanges = (changes) => {
      if (changes.contributions_group) {
        this.answers = this.contributions_group.contributions;
      }
    };
  },
});
