/* eslint-env es6 */

angular.module('genius').factory('ScrollFocus', ['$window', 'EventEmitter', function($window, EventEmitter) {
  const JSONStore = $window.JSONStore;
  const debug_enabled = () => JSONStore.get_object('scroll_focus_debug');

  class DebugView {
    constructor() {
      this.elements = [];
    }

    clear() {
      _.invokeMap(this.elements, 'remove');
    }

    draw_area(top, bottom) {
      this.draw({
        position: 'absolute',
        backgroundColor: 'rgba(255, 0, 0, 0.1)',
        border: '1px solid red',
        width: '100%',
        top,
        height: bottom - top,
      });
    }

    draw_line(top) {
      this.draw({
        position: 'absolute',
        border: '1px solid blue',
        width: '100%',
        height: 1,
        top,
      });
    }

    draw(css) {
      const el = $('<div>').css(css).appendTo('body');

      this.elements.push(el);
    }
  }

  class ScrollFocus extends EventEmitter {
    constructor(elements, options) {
      super();

      this.elements = elements;
      this.options = _.defaults({}, options, {
        update_ms: 10,
        topmost_offset: 0.25,
        bottommost_offset: 0.60,
        zone_radius: 0.10,
        container: $($window.document.body),
        scroll_frame: $window,
      });
      this.debug = new DebugView();

      let last_element = null;

      this.scroll_handler = _.throttle(() => {
        this._draw_debug();

        const new_element = this._focused_element();

        if (new_element !== last_element) {
          this.emit('update', new_element);
          last_element = new_element;
        }
      }, this.options.update_ms);

      $(this.options.scroll_frame).on('scroll', this.scroll_handler);
    }

    replace(elements) {
      this.elements = elements;
    }

    destroy() {
      $(this.options.scroll_frame).off('scroll', this.scroll_handler);
    }

    _focused_element() {
      const trigger_top = this._trigger_top();
      const zones = this._element_zones();

      for (let i = 0; i < zones.length; i++) {
        const zone = zones[i];
        if (zone.top < trigger_top && zone.bottom > trigger_top) {
          return this.elements[i];
        }
      }

      return null;
    }

    _trigger_top() {
      const viewport_height = $(this.options.scroll_frame).height();
      const container = $(this.options.container);
      const container_bottom = container.offset().top + container.height();
      const max_scroll = container_bottom - viewport_height;
      const scroll_percentage = Math.min(1, $(this.options.scroll_frame).scrollTop() / max_scroll);

      const offset_range = this.options.bottommost_offset - this.options.topmost_offset;
      const prorated_offset = this.options.topmost_offset + (scroll_percentage * offset_range);

      return prorated_offset * viewport_height;
    }

    _element_zones() {
      const viewport_height = $(this.options.scroll_frame).height();
      const radius = this.options.zone_radius * viewport_height;

      const container_rect = this.options.container.getBoundingClientRect();
      const zones = _.map(this.elements, (element) => {
        const rect = element.getBoundingClientRect();

        return {
          top: Math.max(container_rect.top, rect.top - radius),
          bottom: Math.min(container_rect.bottom, rect.bottom + radius),
        };
      });

      _.eachCons(zones, 2, (upper, lower) => {
        if (upper.bottom > lower.top) {
          const overlap = upper.bottom - lower.top;

          upper.bottom = upper.bottom - (overlap / 2);
          lower.top = lower.top + (overlap / 2);
        }
      });

      return zones;
    }

    _draw_debug() {
      if (!debug_enabled()) return;

      const scroll = $(this.options.scroll_frame).scrollTop();
      this.debug.clear();
      this.debug.draw_line(scroll + this._trigger_top());
      _.each(this._element_zones(), (zone) => {
        this.debug.draw_area(scroll + zone.top, scroll + zone.bottom);
      });
    }
  }

  return ScrollFocus;
}]);
