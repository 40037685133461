/* eslint-env es6 */

angular.module('genius').component('webAnnotatorRolesTable', {
  templateUrl: 'web_annotator_roles_table.html',

  bindings: {
    header: '@',
    web_location_id: '<webLocationId',
    web_location_type: '@webLocationType',
  },

  controller: ['WebAnnotatorClient', function(WebAnnotatorClient) {
    this.$onInit = () => {
      this.errors = [];
      this.load();
    };

    this.load = () =>  WebAnnotatorClient.load_user_roles(
      `${this.web_location_type}s`,
      this.web_location_id
    ).then(user_roles => this.user_roles = user_roles);

    this.create = params => WebAnnotatorClient.create_user_role(
      `${this.web_location_type}s`,
      this.web_location_id,
      params
    ).then(this.load);
  }],
});
