/* eslint-env es5 */
/* eslint-disable arrow-body-style, arrow-parens, arrow-spacing, constructor-super, generator-star-spacing, no-class-assign, no-confusing-arrow, no-const-assign, no-dupe-class-members, no-duplicate-imports, no-extra-bind, no-new-symbol, no-restricted-imports, no-this-before-super, no-useless-computed-key, no-useless-constructor, no-useless-rename, no-var, object-shorthand, prefer-arrow-callback, prefer-const, prefer-numeric-literals, prefer-reflect, prefer-rest-params, prefer-spread, prefer-template, require-yield, rest-spread-spacing, sort-imports, symbol-description, template-curly-spacing, yield-star-spacing, dot-location, key-spacing, max-len, new-parens, no-lonely-if, no-mixed-operators, no-trailing-spaces, no-whitespace-before-property, padded-blocks, quotes, space-infix-ops */

(function(angular) {
  var includeFromCache = ['artist', 'user', 'artist_songs', 'artist_albums', 'artist_metadata_questions', 'top_scholar', 'top_accomplishment', 'pinned_annotations', 'artist_has_more_songs', 'preloaded_user_activity', 'initial_next_user_activity_timestamp', 'current_user_metadata'];

  angular.module('genius').component('routableProfilePage', {
    template: '<ng-outlet></ng-outlet>',
    $routeConfig: [
      {
        path: '/:slug',
        name: 'ProfilePage',
        component: 'routedPage',
        data: {
          component: 'profilePage',
          includeFromCache: includeFromCache,
          include: {show_artist_pane: false},
        },
      },
      {
        path: '/:slug/albums',
        name: 'AlbumsDiscographyPage',
        component: 'ProfilePage',
      },
      {
        path: '/:slug/songs',
        name: 'SongsDiscographyPage',
        component: 'ProfilePage',
      },
      {
        path: '/:slug/bio',
        name: 'ProfileBioPage',
        component: 'routedPage',
        data: {
          component: 'profilePage',
          includeFromCache: includeFromCache,
          include: {show_artist_pane: true},
        },
      },
    ],
  });
})(angular);
