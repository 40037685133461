/* eslint-env es6 */

angular.module('genius').directive('lyricsSyncKeyboard', ['Mousetrap', function(Mousetrap) {
  return {
    scope: {
      showing: '<',
    },

    restrict: 'E',

    require: '^lyricsSyncEditor',

    templateUrl: 'lyrics_sync_keyboard.html',

    link(scope, _elem, _attrs, $ctrl) {
      scope.definitions = [
        {
          key: '?',
          label: '?',
          description: 'Show/Hide Keyboard Shortcuts',
          action() {
            $ctrl.showing_keyboard_shortcuts = !$ctrl.showing_keyboard_shortcuts;
          },
        },

        {
          key: 'space',
          label: 'Space',
          description: 'Advance Line Selection and Set to Current Time',
          action() {
            if (!$ctrl.can('edit') || !$ctrl.can('sync')) return;
            $ctrl.start_or_advance();
          },
        },

        {
          key: 'enter',
          label: '&#x23ce;',
          description: 'Seek to Current Line',
          action() {
            $ctrl.seek_to_index($ctrl.current_line_index);
          },
        },

        {
          key: 'up',
          label: '&darr;, &uarr;',
          description: 'Move Line Selection, Adjust Time While Editing',
          action() {
            if ($ctrl.current_line_index === 0) return;
            do {
              --$ctrl.current_line_index;
            } while ($ctrl.current_line() && $ctrl.current_line().hidden);
          },
        },

        {
          key: 'down',
          action() {
            if ($ctrl.current_line_index === $ctrl.lyrics_synchronization.lines.length - 1) return;
            do {
              ++$ctrl.current_line_index;
            } while ($ctrl.current_line() && $ctrl.current_line().hidden);
          },
        },

        {
          key: 'left',
          label: '&larr;, &rarr;',
          description: 'Seek Forwards and Backwards',
          action() {
            $ctrl.media_player.api.seek_to_position($ctrl.position - 200);
          },
        },

        {
          key: 'right',
          action() {
            $ctrl.media_player.api.seek_to_position($ctrl.position + 150);
          },
        },

        {
          key: 'e',
          label: 'e',
          description: 'Pause And Edit Timestamp',
          action() {
            if (!$ctrl.can('edit')) return;
            $ctrl.media_player.api.pause();
            $ctrl.current_line()['_editing_time'] = true;
          },
        },
      ];

      _.each(scope.definitions, (definition) => {
        Mousetrap.$bind(definition.key, definition.action, {
          prevent_default: true,
          stop_propagation: true,
        });
      });

      scope.$on('$destroy', () => {
        _.each(scope.definitions, (definition) => {
          Mousetrap.unbind(definition.key);
        });
      });
    },
  };
}]);
