/* eslint-env es6 */

angular.module('genius').factory('ReferentRegistry', ['Preloader', 'ReferentClient', function(Preloader, ReferentClient) {
  const preloader = new Preloader(ids => ReferentClient.bulk_load(ids));
  const registry = {referents: {}, referent_ids: []};

  const update = (updated) => {
    const referent = registry.referents[updated.id];
    Reflect.deleteProperty(referent, 'stub');
    Object.assign(referent, updated);
  };

  return {
    get(id) {
      const referent = this.peek(id);
      if (referent && referent.stub) {
        preloader.load(id).then(update);
      }
      return referent;
    },

    peek(id) {
      return registry.referents[id];
    },

    first(fn, {after_referent_id} = {}) {
      const start_index = registry.referent_ids.indexOf(after_referent_id);
      const first_id = _.find(registry.referent_ids, (id, idx) =>
        idx > start_index && fn(registry.referents[id]));
      return registry.referents[first_id];
    },

    register(stub) {
      if (registry.referents[stub.id]) return registry.referents[stub.id];

      registry.referents[stub.id] = stub;
      registry.referent_ids.push(stub.id);
      return stub;
    },

    bulk_preload(ids) {
      preloader.bulk_load(ids);
    },

    preload_insert(referent) {
      preloader.insert(referent.id, referent);
    },

    preload_from_start(count) {
      this.preload_around(registry.referent_ids[0], count);
    },

    preload_around(id, count) {
      const index = registry.referent_ids.indexOf(id);
      const ids = _(registry.referent_ids).
        sortBy(o => Math.abs(_.indexOf(registry.referent_ids, o) - index)).
        value();
      const ids_to_preload = preloader.filter_unloaded(ids).slice(0, count);
      preloader.bulk_load(ids_to_preload);
    },

    update_if_present(referent) {
      const registry_referent = this.peek(referent.id);
      if (registry_referent) {
        if (registry_referent.stub) Object.assign(registry_referent, referent);
        return registry_referent;
      }
      return referent;
    },

    clear() {
      Object.assign(registry, {referents: {}, referent_ids: []});
    },

    reset_fragments() {
      _.each(registry.referents, (referent) => {
        referent.fragments = [];
      });
    },
  };
}]);
