/* eslint-env es6 */

angular.module('genius').component('facebookShareButton', {
  templateUrl: 'facebook_share_button.html',

  bindings: {
    url: '<',
    variants: '<',
  },

  controller: ['Facebook', 'Tracking', function(Facebook, Tracking) {
    this.share_on_facebook = () => {
      Tracking.track('Bagon Facebook Share');
      Facebook.share(this.url);
    };
  }],
});
