/* eslint-env es6 */

angular.module('genius').component('recirculatedContent', {
  templateUrl: 'recirculated_content.html',

  bindings: {
    song: '<',
    stories: '<',
    placement: '@',
  },

  controller: ['VideoPlacement', 'Session', function(VideoPlacement, Session) {
    this.$onChanges = (changes) => {
      if (changes.song && this.song) {
        this.video_placement = new VideoPlacement(this.song, this.placement);
      }
    };

    this.can = (permission, object) => Session.has_permission(permission, object);
  }],
});
