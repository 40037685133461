/* eslint-env es6 */

angular.module('genius').component('paginatedLeaderboard', {
  bindings: {
    source: '<',
    per_page: '<perPage',
    on_empty: '&onEmpty',
    extra_info: '@extraInfo',
  },

  templateUrl: 'paginated_leaderboard.html',

  controller: ['PagedDataFeed', function(PagedDataFeed) {
    this.$onChanges = (changes) => {
      if (changes.source || changes.per_page) {
        this.scrollable_data = new PagedDataFeed(
          this.source, 'leaderboard',
          {per_page: this.per_page}
        );
      }
    };
  }],
});
