/* eslint-env es6 */

angular.module('genius').component('articleContentActions', {
  templateUrl: 'article_content_actions.html',

  bindings: {
    object: '<',
    type: '@',
    variants: '<',
  },

  controller: ['$scope', function($scope) {
    this.toggle_voters = () => this.showing_voters = !this.showing_voters;

    $scope.$on('vote_saved', (_) => {
      $scope.$broadcast('update_voters');
    });
  }],
});
