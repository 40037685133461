/* eslint-env es6 */

angular.module('genius').filter('drop', [function() {
  return (value, count) => {
    if (!value) return;

    return count >= 0
      ? _.drop(value, count)
      : _.dropRight(value, -count);
  };
}]);
