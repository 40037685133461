/* eslint-env es6 */

(() => {
  const benefits = [
    'to identify songs on the go',
    'to get lyrics to the songs you play',
    'to read lyrics offline',
  ];

  angular.module('genius').component('androidBanner', {
    templateUrl: 'android_banner.html',

    bindings: {
      page: '@',
    },

    controller: ['Bowser', 'AppConfig', function(Bowser, AppConfig) {
      this.benefit = _.sample(benefits);
      this.is_android = Bowser.android;
      this.play_store_url = AppConfig.play_store_url;
    }],
  });
})();
