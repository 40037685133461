/* eslint-env es6 */

angular.module('genius').factory('LyricsEditProposal', ['LyricsEditing', 'diff_match_patch', 'patch_structured_text', 'AppConfig', function(LyricsEditing, diff_match_patch, patch_structured_text, AppConfig) {
  const dmp = new diff_match_patch();
  dmp.Match_Threshold = AppConfig.dmp_match_threshold;
  dmp.Patch_DeleteThreshold = AppConfig.dmp_match_threshold;

  return {
    diff(before, after) {
      return dmp.diff_main(before, after);
    },

    apply_patches(patches, target, {edit_format} = {}) {
      const [patched, success] =
        edit_format === 'plain_text' ?
          patch_structured_text(patches, target, {dmp}) :
          dmp.patch_apply(patches, target);
      return {patched, success};
    },

    make_patches: _.memoize(diff => dmp.patch_make(diff)),

    pretty_diff: _.memoize((diffs) => {
      const diffs_clone = _.cloneDeep(diffs);
      dmp.diff_cleanupSemantic(diffs_clone);
      return dmp.diff_prettyHtml(diffs_clone).replace(/&para;/gm, '');
    }),

    lyrics_diffs_for_line_item_detail(detail) {
      if (detail.strategy === 'user_edited_the_lyrics_of_song') {
        return _.map(detail.metadata, metadata =>
          ({
            before: LyricsEditing.prepare_lyrics_for_editing(metadata.before).markdown,
            after: LyricsEditing.prepare_lyrics_for_editing(metadata.after).markdown,
          })
        );
      }

      const after_from_before_and_lyrics_edit = (before, lyrics_edit) => {
        const patches = this.make_patches(lyrics_edit.diff);
        return this.apply_patches(
          patches,
          before,
          {edit_format: lyrics_edit.edit_format}
        ).patched;
      };

      if (detail.targets[0]._type === 'lyrics_edit') {
        return _.map(detail.targets, (lyrics_edit) => {
          let before, state;
          switch (detail.strategy) {
          case 'user_created_lyrics_edit':
            state = lyrics_edit.state;
            switch (lyrics_edit.state) {
            case 'pending':
              before = lyrics_edit.current_lyrics_html;
              break;
            case 'accepted':
              before = lyrics_edit.lyrics_before_accepted.html;
              break;
            case 'rejected':
            case 'inapplicable':
              before = lyrics_edit.lyrics_when_proposed.html;
              break;
            }
            before = LyricsEditing.prepare_lyrics_for_editing(before).markdown;
            break;
          case 'user_accepted_lyrics_edit':
            before = lyrics_edit.lyrics_before_accepted.plain;
            break;
          case 'user_rejected_lyrics_edit':
          case 'user_archived_lyrics_edit':
            before = lyrics_edit.lyrics_when_proposed.plain;
            break;
          }
          const lyrics_diff = {
            before,
            after: after_from_before_and_lyrics_edit(before, lyrics_edit),
          };
          if (state) {
            _.merge(lyrics_diff, {state});
          }
          return lyrics_diff;
        });
      }

      return null;
    },
  };
}]);
