/* eslint-env es6 */

angular.module('genius').component('artistTooltip', {
  bindings: {
    artist: '<',
  },

  templateUrl: 'artist_tooltip.html',

  controller: ['ArtistClient', function(ArtistClient) {
    this.$onInit = () => {
      if (!this.artist.description) {
        const artist_to_load = this.artist;
        ArtistClient.load(artist_to_load.id).then((updated_artist) => {
          _.assign(artist_to_load, updated_artist);
        });
      }
    };
  }],
});
