/* eslint-env es6 */

angular.module('genius').component('commentList', {
  bindings: {
    comments: '<',
    commentable: '<',
    intersperse_ads: '@intersperseAds',
    variants: '<',
  },

  templateUrl: 'comment_list.html',
});
