/* eslint-env es6 */

angular.module('genius').factory('Google', ['getScript', '$window', function(getScript, $window) {
  return {
    setup: _.once(() => getScript('https://apis.google.com/js/platform.js', {cache: true})),

    refresh_youtube_subscribe_in: (elem) => {
      $window.gapi.ytsubscribe.go(elem);
    },
  };
}]);
