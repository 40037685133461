/* eslint-env es6 */

angular.module('genius').factory('LyricsAnchorer', ['$compile', 'ReferentLocator', 'ReferentRegistry', 'ReferentClient', function(
  $compile,
  ReferentLocator,
  ReferentRegistry,
  ReferentClient
) {
  class LyricsAnchorer {
    constructor(options) {
      this.container = options.container;
      this.get_state = options.get_state;
      this.scope = options.scope;
      this.song = options.song;
    }

    construct_referent(range, options = {}, referent_to_create) {
      return ReferentClient.create_referent({
        song: _.pick(this.song, ['id', 'updated_by_human_at']),
        referent: referent_to_create,
        community: !! options.community,
      }).then((referent) => {
        _.assign(referent.annotations[0], {
          new: true,
          _editing: {_form_visible: true},
        });

        ReferentRegistry.preload_insert(referent);

        const anchor = build_referent_anchor(referent.id);
        anchor.html(range.extractContents());
        range.insertNode(anchor[0]);
        $compile(anchor)(this.scope);

        ReferentRegistry.register(referent);
        return referent;
      });
    }

    create_referent(range, options) {
      return this.construct_referent(range, options, this.calculate_referent_location(range));
    }

    create_image_referent(img_element, range, options) {
      const url = img_element.src;
      return this.construct_referent(range, options, {
        image_url: url,
        occurrence: this.get_occurrence_in_container_among(`img[src="${url}"]`, img_element),
      });
    }

    calculate_referent_location(range) {
      return ReferentLocator.calculate_referent_location(range, this.container[0]);
    }

    get_occurrence_in_container_among(selector, element) {
      return this.container.find(selector).index(element) + 1;
    }
  }

  const build_referent_anchor = id => $('<a>').
    attr('href', '#').
    attr('class', 'referent').
    attr('ng-click', 'open()').
    attr('prevent-default-click', '').
    attr('data-id', 'new').
    attr('on-hover-with-no-digest', 'set_current_hover_and_digest(hover ? fragment_id : undefined)').
    attr('annotation-fragment', `'${id}'`).
    attr('classification', 'new');

  return LyricsAnchorer;
}]);
