/* eslint-env es6 */

angular.module('genius').factory('SearchClient', ['ApiClient', function(ApiClient) {
  return {
    multi(query, options) {
      return ApiClient.returning('sections').get('/search/multi', _.assign({q: query}, options));
    },

    section(section, query, options) {
      return ApiClient.
        transform(data => ({hits: data.sections[0].hits, next_page: data.next_page})).
        get('/search/:section', _.assign({q: query, section}, options));
    },
  };
}]);
