/* eslint-env es6 */

angular.module('genius').component('songPage', {
  templateUrl: 'song_page.html',

  controller: Object.assign(['$document', '$window', '$scope', '$location', '$q', 'Tracking', 'Session', 'ReferentElementMap', 'ReferentRegistry', 'querystring', 'performance_mark', 'Bowser', 'mobile_device', '$element', function($document, $window, $scope, $location, $q, Tracking, Session, ReferentElementMap, ReferentRegistry, querystring, performance_mark, Bowser, mobile_device, $element) {
    const PRELOAD_AROUND_REFERENT_COUNT = 10;

    const song_params = params => ({
      slug: params[1],
      query: querystring.parse(params[2] || ''),
    });

    const referent_params = params => ({
      referent_id: parseInt(params[1]),
    });

    const switch_on_instruction = (next, handlers) => {
      switch (next.routeData.data.type) {
      case 'song':
        return handlers.song(song_params(next.params));
      case 'referent':
        return handlers.referent(referent_params(next.params));
      default:
        throw Error(`Unknown song page instruction type: ${next.routeData.data.type}`);
      }
    };

    let song_story_header_cta;
    this.$onInit = () => {
      this.lyrics_compiled = $q.defer();
      this.is_ios = Bowser.ios;
      this.show_report_abuse = Session.has_feature('report_abuse');

      if (mobile_device) {
        $window.addEventListener('scroll', _.throttle(() => {
          if (!song_story_header_cta) {
            song_story_header_cta = $element[0].querySelector('.song_story_header_cta');
          }

          if (song_story_header_cta) {
            const rect = song_story_header_cta.getBoundingClientRect();
            $scope.$apply(() => this.show_song_story_sticky_placement = rect.top + rect.height < 0);
          }
        }, 100));
      }
    };

    this.$routerCanReuse = next =>
      switch_on_instruction(next, {
        song: params => params.slug === current_slug(),
        referent: params => Boolean(ReferentRegistry.peek(params.referent_id)),
      });

    this.$routerOnActivate = (next) => {
      this.page_data = next.routeData.page_data;
      Object.assign($scope, this.page_data);

      switch_on_instruction(next, {
        song: (params) => {
          const referent_id = parseInt(
            params.query.referent_id ||
            _.get($location.hash().match(/^note-(\d+)$/), 1)
          );

          if (_.isFinite(referent_id)) {
            Tracking.set_annotation_opened_from_permalink(referent_id);
            this.scroll_to_referent(referent_id).
              then(() => $location.url(`/${referent_id}`));
          } else {
            $window.scrollTo(0, 0);
          }
        },
        referent: (params) => {
          this.scroll_to_referent(params.referent_id).
            then(() => this.display_referent(params.referent_id));
        },
      });
      performance_mark.create_once('first_router_on_activate');
    };

    this.$routerOnReuse = (next) => {
      switch_on_instruction(next, {
        song: _.noop,
        referent: (params) => {
          this.display_referent(params.referent_id);
        },
      });
    };

    this.$routerOnDeactivate = () => {
      Tracking.reset_counters();
      ReferentRegistry.clear();
    };

    this.scroll_to_referent = (referent_id, animation_duration = 0) =>
      this.lyrics_compiled.promise.then(() => $q((resolve) => {
        const $reference_element = ReferentElementMap.get(referent_id);
        const offset = $reference_element.offset().top +
          ($reference_element.height() / 2) - ($($window).height() / 2);
        $document.find('html, body').
          animate({scrollTop: offset}, animation_duration).promise().then(resolve);
      }));

    this.display_referent = (id) => {
      Tracking.track('Open Annotation', {
        annotation_id: id,
        counts_as_page_load: mobile_device,
      });
      ReferentRegistry.preload_around(id, PRELOAD_AROUND_REFERENT_COUNT);
      $scope.highlighted_fragment_id = id;
      const referent = ReferentRegistry.get(id);

      $q((resolve) => {
        const positioning_target = position_target_for_referent(referent);

        if (positioning_target.bottom < 0 || positioning_target.top > $($window).height()) {
          this.scroll_to_referent(id, 150).then(() =>
            resolve(position_target_for_referent(referent)));
        } else {
          resolve(positioning_target);
        }
      }).then((positioning_target) => {
        this.lyrics_positioning_target = positioning_target;
        $scope.current_referent = referent;
      });
    };

    this.display_description_annotation = ({tracking_name} = {}) => {
      if (tracking_name) Tracking.track(tracking_name);
      this.clear_current_referent();
      $scope.current_referent = this.page_data.song.description_annotation;
    };

    this.clear_current_referent = () => {
      $scope.highlighted_fragment_id = null;
      $scope.current_referent = null;
      $location.url(this.page_data.song.path);
    };

    this.on_lyrics_compiled = () => {
      this.lyrics_compiled.resolve();
    };

    this.update_lyrics_selection_range = (range) => {
      if (range) {
        this.clear_current_referent();
        this.lyrics_selection_range = range;
        this.lyrics_positioning_target = range.getBoundingClientRect();
      } else {
        this.lyrics_selection_range = null;
        if ($scope.current_referent) {
          position_to_current_referent();
        } else {
          this.lyrics_positioning_target = null;
        }
      }
    };

    const position_target_for_referent = (referent) => {
      if (!referent) return;
      return referent.precomputed_client_rect ||
        ReferentElementMap.get(referent.id).get(0).getBoundingClientRect();
    };

    const position_to_current_referent = () => {
      if (_.get($scope.current_referent, 'is_description')) return;
      this.lyrics_positioning_target = position_target_for_referent($scope.current_referent);
    };

    const current_slug = () => {
      const url = _.get(this, 'page_data.song.url');
      if (url) return _.last(url.split('/'));
    };

    this.youtube_media = () => _.find($scope.song.media, {provider: 'youtube'});

    this.track_youtube_play = () => {
      Tracking.track('Song Page Youtube Embed Played');
    };

    $scope.$on('layout_changed', _.throttle(position_to_current_referent, 300));
  }], {
    $canActivate: ['$nextInstruction', '$prevInstruction', 'PageTransition', 'performance_mark', function(
      $nextInstruction,
      $prevInstruction,
      PageTransition,
      performance_mark
    ) {
      performance_mark.create_once('first_router_can_activate');
      return PageTransition.by_router_instruction($nextInstruction, $prevInstruction);
    }],
  }),
});
