/* eslint-env es6 */

angular.module('genius').factory('LyricsEditProposalClient', ['ApiClient', function(ApiClient) {
  return {
    lyrics_for_edit_proposal({song_id}) {
      return ApiClient.
        transform(_.property('lyrics_for_edit_proposal')).
        get('/songs/:song_id/lyrics_for_edit_proposal', {song_id});
    },

    pending({song_id}) {
      return ApiClient.
        transform(_.property('lyrics_edits')).
        get('/songs/:song_id/lyrics_edits', {song_id});
    },

    create({song_id, version, edited_lyrics}) {
      return ApiClient.
        transform(_.property('lyrics_edit')).
        post('/songs/:song_id/lyrics_edits', {
          song_id,
          lyrics_edit: {
            edited_lyrics,
            before_version_number: version,
            edit_format: 'plain_text',
          },
        });
    },

    reject(id) {
      return ApiClient.post('/lyrics_edits/:id/reject', {id});
    },
  };
}]);
