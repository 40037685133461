/* eslint-env es6 */

angular.module('genius').factory('string_has_unmatched_parentheses', [function() {
  return (str, {parentheses = '[]()'} = {}) => {
    const stack = [];
    let i, c;

    for (i = 0; c = str[i++];) {
      const brace_position = parentheses.indexOf(c);

      if (brace_position < 0) continue;

      const brace_is_closed = Boolean(brace_position % 2);

      if (brace_is_closed) {
        if (!stack.length || parentheses.indexOf(stack.pop()) !== brace_position - 1) {
          return true;
        }
      } else {
        stack.push(c);
      }
    }

    return stack.length > 0;
  };
}]);
