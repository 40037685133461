/* eslint-env es6 */

angular.module('genius').component('paginatedAccomplishments', {
  bindings: {
    user: '<',
  },

  templateUrl: 'paginated_accomplishments.html',

  controller: ['CursoredDataFeed', 'AccomplishmentClient', 'UserClient', 'Reversible', 'Session', function(CursoredDataFeed, AccomplishmentClient, UserClient, Reversible, Session) {
    this.$onInit = () => {
      let visibility;
      Reflect.defineProperty(this, 'visibility', {
        get() { return visibility; },
        set(value) {
          visibility = value;
          this.update_src();
        },
      });

      this.visibility = 'visible';
      this.filter_options = [
        {
          i18n_key: 'manage_accomplishments.all',
          value: 'all',
        },
        {
          i18n_key: 'manage_accomplishments.visible',
          value: 'visible',
        },
        {
          i18n_key: 'manage_accomplishments.hidden',
          value: 'hidden',
        },
      ];
    };

    this.can = action_name => Session.has_permission(action_name, this.user);

    this.toggle_managing = () => {
      this.managing = !this.managing;
      if (this.managing) {
        this.visibility = 'all';
      } else {
        this.visibility = 'visible';
      }
    };

    this.update_src = () => {
      this.scrollable_data = new CursoredDataFeed(
        _.partial(UserClient.accomplishments, this.user.id),
        'accomplishments',
        {visibility: this.visibility, per_page: 10}
      );
    };

    this.toggle = (accomplishment) => {
      const revert = Reversible.modify(accomplishment, {'hidden': !accomplishment.hidden});
      AccomplishmentClient.update(accomplishment.id, {
        accomplishment: {hidden: accomplishment.hidden},
      }).then((data) => {
        _.assign(accomplishment, data.accomplishment);
      }).catch(() => {
        revert();
      });
    };
  }],
});
