/* eslint-env es5 */
/* eslint-disable arrow-body-style, arrow-parens, arrow-spacing, constructor-super, generator-star-spacing, no-class-assign, no-confusing-arrow, no-const-assign, no-dupe-class-members, no-duplicate-imports, no-extra-bind, no-new-symbol, no-restricted-imports, no-this-before-super, no-useless-computed-key, no-useless-constructor, no-useless-rename, no-var, object-shorthand, prefer-arrow-callback, prefer-const, prefer-numeric-literals, prefer-reflect, prefer-rest-params, prefer-spread, prefer-template, require-yield, rest-spread-spacing, sort-imports, symbol-description, template-curly-spacing, yield-star-spacing, dot-location, key-spacing, max-len, new-parens, no-lonely-if, no-mixed-operators, no-trailing-spaces, no-whitespace-before-property, padded-blocks, quotes, space-infix-ops */

angular.module('genius').directive('scrollFromBottom', function() {
  return {
    restrict: 'A',

    link: function(scope, element) {
      var mutation_observer = new MutationObserver(function() {
        var current_height = element.prop('scrollHeight');
        element.scrollTop(
          element.scrollTop() +
            current_height -
            last_observed_height
        );
        last_observed_height = current_height;
      });
      var last_observed_height = element.prop('scrollHeight');

      mutation_observer.observe(element[0], {
        childList: true,
        subtree: true,
      });

      scope.$on('$destroy', function() {
        mutation_observer.disconnect();
      });
    },
  };
});
