/* eslint-env es6 */

angular.module('genius').filter('spotify_artist_metadata', ['SpotifyClient', function(SpotifyClient) {
  const cached_artist_metadata = {};

  return (uuid) => {
    if (!uuid) return {};

    const metadata = cached_artist_metadata[uuid];
    if (metadata) return metadata;

    cached_artist_metadata[uuid] = {};
    SpotifyClient.load_artist_metadata(uuid).then((data) => {
      cached_artist_metadata[uuid] = data;
    });
  };
}]);
