/* eslint-env es5 */
/* eslint-disable arrow-body-style, arrow-parens, arrow-spacing, constructor-super, generator-star-spacing, no-class-assign, no-confusing-arrow, no-const-assign, no-dupe-class-members, no-duplicate-imports, no-extra-bind, no-new-symbol, no-restricted-imports, no-this-before-super, no-useless-computed-key, no-useless-constructor, no-useless-rename, no-var, object-shorthand, prefer-arrow-callback, prefer-const, prefer-numeric-literals, prefer-reflect, prefer-rest-params, prefer-spread, prefer-template, require-yield, rest-spread-spacing, sort-imports, symbol-description, template-curly-spacing, yield-star-spacing, dot-location, key-spacing, max-len, new-parens, no-lonely-if, no-mixed-operators, no-trailing-spaces, no-whitespace-before-property, padded-blocks, quotes, space-infix-ops */

angular.module('genius').directive('emptyCopyContent', ['$immediate', '$window', '$document', function($immediate, $window, $document) {
  return {
    link: function(scope, element, attrs) {
      element.on('copy', function() {
        var content = scope.$eval(attrs.emptyCopyContent);
        var selection = $window.getSelection();
        if (selection.toString()) return;

        var $selection_node = $('<div>').
          html(content).
          css({position: 'absolute', height: 0, width: 0}).
          appendTo($document.prop('body'));

        selection.removeAllRanges();
        var range = $document[0].createRange();
        range.selectNodeContents($selection_node[0]);
        selection.addRange(range);

        $immediate(function() { $selection_node.remove(); }, !'invoke_apply');
      });
    },
  };
}]);
