/* eslint-env es6 */

angular.module('genius').component('artistSongsModal', {
  templateUrl: 'artist_songs_modal.html',

  bindings: {
    artist: '<',
    yields_open: '&yieldsOpen',
  },

  controller: [function() {
    this.open = () => this.modal_open = true;

    this.yields_open({open: this.open});
  }],
});
