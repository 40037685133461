/* eslint-env es6 */

angular.module('genius').factory('SpotifySocket', ['$interval', '$rootScope', '$timeout', 'SpotifyPlayerClient', function(
  $interval,
  $rootScope,
  $timeout,
  SpotifyPlayerClient
) {
  const PING_INTERVAL = 10000;
  const PING_WAIT_TIME = 1000;
  const PING = JSON.stringify({type: 'ping'});
  const EVENTS = ['message', 'error', 'close'];

  return class SpotifySocket {
    constructor() {
      _.bindAll(this, ['_on_message', '_on_error', '_on_close']);
      this._connection_id = null;
      this._scope = $rootScope.$new(!!'isolate');
      this._connect();
      $interval(() => this._ping(), PING_INTERVAL);
    }

    on(name, fn) {
      this._scope.$on(name, (_, event) => fn(event));
    }

    _connect() {
      return SpotifyPlayerClient.fresh_token().then((token) => {
        if (this._websocket) this._dispose();
        this._websocket = new WebSocket(`wss://dealer.spotify.com?access_token=${token}`);
        EVENTS.forEach(event => this._websocket.addEventListener(event, this[`_on_${event}`]));
      });
    }

    _ping() {
      if (this.is_closed) {
        this._connect();
      } else if (this.is_open) {
        this._reconnect_timer = $timeout(
          () => this._websocket.close(),
          PING_WAIT_TIME
        );
        this._websocket.send(PING);
      }
    }

    _on_message(message) {
      const data = JSON.parse(message.data);

      if (data.type === 'pong') {
        $timeout.cancel(this._reconnect_timer);
        return;
      }

      const connection_id = _.get(data, ['headers', 'Spotify-Connection-Id']);

      if (connection_id && connection_id !== this._connection_id) {
        SpotifyPlayerClient.subscribe(connection_id).then(() => {
          this._connection_id = connection_id;
        });
      }

      _(data.payloads || []).flatMap('events').each(({type, event}) => {
        this._scope.$emit(type.toLowerCase(), event);
      });
    }

    _on_error(error) {
      this._scope.$emit('error', error);
      this._websocket.close();
    }

    _on_close() {
      this._connection_id = null;
      this._connect();
    }

    _dispose() {
      EVENTS.forEach(event => this._websocket.removeEventListener(event, this[`_on_${event}`]));
      this._websocket.close();
    }

    get is_open() {
      return _.get(this._websocket, 'readyState') === WebSocket.OPEN;
    }

    get is_closed() {
      return _.get(this._websocket, 'readyState') === WebSocket.CLOSED;
    }
  };
}]);
