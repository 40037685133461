import 'setimmediate/setImmediate';
/* eslint-env es6 */


window['Genius.css_loaded'] = window['Genius.css_loaded'] || {push(fn) { fn(); }};

setImmediate(() => {
  const initial_placeholder_content = new Map();
  $('[initial-content-for]').each((_index, element) => {
    const $element = $(element);
    $element.attr('ng-non-bindable', true);
    initial_placeholder_content.set($element.attr('initial-content-for'), $element);
  });
  angular.module('genius').value('initial_placeholder_content', initial_placeholder_content);

  angular.module('genius').value('initial_image_urls', new Set(_([
    _.map($('img'), e => e.currentSrc),
    _.map($('[style]'), e => _.get(e.style.backgroundImage.match(/^url\("(.*)"\)$/), 1)),
  ]).flatten().compact().value()));

  if (window.performance && performance.mark) {
    window.performance.mark('angular_bootstrap_start');
  }

  const bootstrap_start = Date.now();

  window['Genius.css_loaded'].push(() => {
    angular.bootstrap(document.body, ['genius'], {strictDi: true}).
      invoke(['$window', 'application_bootstrap', 'Librato', 'PageDataClient', 'performance_mark', function(
        $window,
        application_bootstrap,
        Librato,
        PageDataClient,
        performance_mark
      ) {
        performance_mark.create('angular_bootstrap_complete');
        if ($window.gtag && $window.ANALYTICS_CONFIG) {
          const bootstrap_time = Date.now() - bootstrap_start;
          Librato.set_page_type(_.get(PageDataClient.preloaded(), 'page_type', 'other'));
          Librato.measure('angular_bootstrap_time', bootstrap_time);
          $window.gtag('event', 'timing_complete', {
            event_category: 'Initial Load',
            name: 'Angular Bootstrap',
            value: bootstrap_time,
            non_interaction: true,
          });
        }

        setImmediate(application_bootstrap.done);
      }]);
  });
});
if (window.performance && performance.mark) {
  window.performance.mark('bundle_load_end');
}
