/* eslint-env es6 */

angular.module('genius').component('songHeaderMediaPlayerControls', {
  templateUrl: 'song_header_media_player_controls.html',

  bindings: {
    media_api: '<mediaApi',
    play_state: '<playState',
  },

  controller() {
    this.$onInit = () => this.play_state = 'paused';
  },
});

