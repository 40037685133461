/* eslint-env es6 */

angular.module('genius').directive('classForDuration', ['$timeout', function($timeout) {
  return {
    restrict: 'A',

    link(scope, element, attrs) {
      _.forIn(scope.$eval(attrs.classForDuration), (duration, class_name) => {
        if (duration) {
          element.addClass(class_name);
          $timeout(() => {
            element.removeClass(class_name);
          }, duration);
        }
      });
    },
  };
}]);
