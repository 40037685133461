/* eslint-env es6 */

angular.module('genius').component('userAttribution', {
  bindings: {
    object: '<',
    rank: '<',
  },

  templateUrl: 'user_attribution.html',
});
