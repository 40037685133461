/* eslint-env es6 */

angular.module('genius').component('videoListVideo', {
  templateUrl: 'video_list_video.html',

  bindings: {
    video: '<',
    video_list: '<videoList',
    position: '<',
    variants: '<',
  },

  controller: ['Session', function(Session) {
    this.$onChanges = (changes) => {
      if (changes.video) {
        this.tracking_data = {
          'Page Type': 'desktop_video_list_page',
          'Placement': 'list',
          'Position': this.position,
          'Video ID': this.video.id,
          'Object Type': 'video_list',
          'Object ID': this.video_list.id,
        };
      }
    };

    this.has_permission = Session.has_permission;
  }],
});
