/* eslint-env es6 */

angular.module('genius').directive('expandableList', [function() {
  return {
    restrict: 'E',

    scope: {
      collection: '<',
      template: '<',
      limit: '<',
      label: '@',
      variants: '<',
    },

    templateUrl(_elem, attr) {
      return `${attr.template}.html`;
    },

    controllerAs: 'ctrl',

    controller: ['$scope', function($scope) {
      this.more_to_show = () => $scope.collection.length > $scope.limit;

      this.show_more = () => {
        Reflect.deleteProperty($scope, 'limit');
      };
    }],
  };
}]);
