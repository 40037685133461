/* eslint-env es5 */
/* eslint-disable arrow-body-style, arrow-parens, arrow-spacing, constructor-super, generator-star-spacing, no-class-assign, no-confusing-arrow, no-const-assign, no-dupe-class-members, no-duplicate-imports, no-extra-bind, no-new-symbol, no-restricted-imports, no-this-before-super, no-useless-computed-key, no-useless-constructor, no-useless-rename, no-var, object-shorthand, prefer-arrow-callback, prefer-const, prefer-numeric-literals, prefer-reflect, prefer-rest-params, prefer-spread, prefer-template, require-yield, rest-spread-spacing, sort-imports, symbol-description, template-curly-spacing, yield-star-spacing, dot-location, key-spacing, max-len, new-parens, no-lonely-if, no-mixed-operators, no-trailing-spaces, no-whitespace-before-property, padded-blocks, quotes, space-infix-ops */

angular.module('genius').factory('MarkdownHelpers', function() {
  var write_anchor_tag = function(_wholeMatch, m1, m2, m3, m4, _m5, _m6, m7) {
    if (m7 === undefined) m7 = "";
    var whole_match = m1;
    var link_text   = m2;
    var link_id   = m3.toLowerCase();
    var url    = m4;
    var title  = m7;

    if (url === "") {
      if (link_id === "") {
        // lower-case and turn embedded newlines into spaces
        link_id = link_text.toLowerCase().replace(/ ?\n/g, " ");
      }
      url = "#"+link_id;

      if (whole_match.search(/\(\s*\)$/m)>-1) {
        // Special case for explicit empty url
        url = "";
      } else {
        return whole_match;
      }
    }

    var result = "<a href=\"" + url + "\"";

    if (title !== "") {
      title = title.replace(/"/g, "&quot;");
      result +=  " title=\"" + title + "\"";
    }

    result += ">" + link_text + "</a>";

    return result;
  };

  return {
    unescape_special_characters_escaped_by_markdown_anchor_converter: function(str) {
      return str.replace(/~E(\d+)E/, function(_match, m1) { return String.fromCharCode(m1); });
    },
    convert_markdown_anchors: function(text) {
      return text.replace(/(\[((?:\[[^\]]*\]|\[\[[^\]]*\]\]|[^\[\]])*)\]\([ \t]*()<?(.*?)>?[ \t]*((['"])(.*?)\6[ \t]*)?\))/g, write_anchor_tag);
    },
  };
});
