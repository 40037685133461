/* eslint-env es6 */

angular.module('genius').factory('IdentityClient', ['$http', 'ApiClient', 'AppConfig', '$q', function($http, ApiClient, AppConfig, $q) {
  const return_error = (response) => {
    const error = new Error(response.data.meta.message);
    _.assign(error, _.pick(response, 'status', 'statusText'));
    return $q.reject(_.assign(error, response.data.response));
  };

  const IdentityClient = {
    destroy(identity_id) {
      const options = {params: {text_format: 'html,markdown'}};
      return $http.delete(`${AppConfig.api_root_url}/identities/${identity_id}`, {}, options).
        then(response => response.data.response).
        catch(response => return_error(response));
    },

    spotify_credentials() {
      return ApiClient.returning('credentials').get('/identities/spotify_credentials');
    },
  };

  return IdentityClient;
}]);
