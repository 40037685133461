/* eslint-env es6 */

angular.module('genius').directive('tetherDrop', ['$immediate', 'TetherDrop', function($immediate, TetherDrop) {
  return {
    restrict: 'E',

    scope: {
      containerClasses: '@',
      position: '@',
      showOn: '@',
      visible: '<?',
      onClose: '&',
      yieldsApi: '&?',
    },

    transclude: 'true',

    link(scope, element, attributes, _controller, transcludeFn) {
      let $transclude;

      const tooltip = new TetherDrop({
        content: $transclude || '',
        target: element.closest('[tether-drop-target]')[0],
        position: scope.position || 'right middle',
        openOn: scope.showOn,
        remove: true,
        loadContentOnDemand: true,
        hoverOpenDelay: 250,
        tetherOptions: {
          constraints: [{
            to: 'window',
            attachment: 'together element',
            pin: true,
          }],
          classes: {
            element: scope.containerClasses,
          },
        },
      });

      tooltip.once('open', () => {
        transcludeFn((clone) => {
          const $tooltip = $(tooltip.content);
          $tooltip.hide();
          $transclude = $('<div>').append(clone)[0];
          $tooltip.append($transclude);

          $immediate(() => {
            $tooltip.show();
            tooltip.position();
          });
        });
      });

      tooltip.on('close', scope.onClose);

      if ('visible' in attributes) {
        scope.$watch('!!visible', (is_visible) => {
          if (is_visible) tooltip.open();
          else tooltip.close();
        });
      }

      scope.$watch(() => tooltip.position);

      scope.$on('$destroy', () => tooltip.destroy());

      const api = {
        hide: tooltip.close.bind(tooltip),
        show: tooltip.open.bind(tooltip),
      };

      if ('yieldsApi' in attributes) {
        scope.yieldsApi({tetherApi: api});
      }
    },
  };
}]);
