/* eslint-env es6 */

angular.module('genius').filter('imprecise_date', ['$filter', function($filter) {
  const DATE_FORMATS = ['yyyy', 'MMMM yyyy', 'MMMM d, yyyy'];

  return (input) => {
    if (!input) return;
    const date_parts = _(input).at('year', 'month', 'day').takeWhile().value();
    const date = new Date(
      date_parts[0],
      (date_parts[1] || 1) - 1,
      date_parts[2] || 1
    );
    return $filter('date')(date, DATE_FORMATS[date_parts.length - 1]);
  };
}]);
