/* eslint-env es6 */

angular.module('genius').factory('ArtistClient', ['$http', 'AppConfig', 'ApiClient', function($http, AppConfig, ApiClient) {
  const ArtistClient = {
    autocomplete_api_endpoint: `${AppConfig.api_root_url}/artists/autocomplete`,

    autocomplete(query) {
      return $http.get(ArtistClient.autocomplete_api_endpoint, {params: {q: query}}).
        then(response => response.data.response.artists);
    },

    autocomplete_options: {
      minChars: 2,
      parse(response) {
        return _.map(response.response.artists, artist => ({
          data: [artist.name, artist],
          value: artist,
          result: artist,
        }));
      },
    },

    load(artist_id) {
      return $http.get(`${AppConfig.api_root_url}/artists/${artist_id}`).then(response => response.data.response.artist);
    },

    update(id, params) {
      const keys = [
        'name', 'alternate_names', 'image_url', 'header_image_url',
        'instagram_name', 'twitter_name', 'facebook_name', 'translation_artist',
      ];
      return $http.put(`${AppConfig.api_root_url}/artists/${id}`, {
        artist: _.pick(params, keys),
        text_format: 'html,markdown',
      }).then(response => response.data.response.artist);
    },

    load_albums(artist_id, params) {
      return $http.get(`${AppConfig.api_root_url}/artists/${artist_id}/albums`, {params}).
        then(response => response.data.response);
    },

    load_leaderboard(artist_id, params) {
      return $http.get(`${AppConfig.api_root_url}/artists/${artist_id}/leaderboard`, {params}).
        then(response => response.data.response);
    },

    merge(artist_id, target_artist_name) {
      return $http.put(`${AppConfig.api_root_url}/artists/${artist_id}/merge`, {
        target: {
          name: target_artist_name,
        },
      }).then(response => response.data.response);
    },

    split(artist_id, single_names) {
      return $http.put(`${AppConfig.api_root_url}/artists/${artist_id}/split`, {
        single_names,
      }).then(response => response.data.response);
    },

    get_slug(artist) {
      return _(this.get_path(artist).split('/')).last();
    },

    get_path(artist) {
      return new URL(artist.url).pathname;
    },

    activity_line_items(artist_id, params) {
      return $http.get(`${AppConfig.api_root_url}/artists/${artist_id}/activity_stream/line_items`, {params}).
        then(response => response.data.response);
    },

    contribution_opportunities(artist_id, params) {
      return ApiClient.get(
        '/artists/:id/contribution_opportunities',
        _.merge(params, {id: artist_id, text_format: 'html,markdown'})
      );
    },

    discography_path(artist, discography_type) {
      return `${this.get_path(artist)}/${discography_type}`;
    },
  };

  return ArtistClient;
}]);
