/* eslint-env es6 */

angular.module('genius').component('marqueeAd', {
  templateUrl: 'marquee_ad.html',

  bindings: {
    name: '@',
  },

  controller: ['Cookies', '$scope', '$element', 'AppConfig', '$window', 'IntersectionObserver', function(Cookies, $scope, $element, AppConfig, $window, IntersectionObserver) {
    const full_height_vw = 25;
    const collapsed_height_vw = 10;
    const percentage_to_detect_as_collapsed = (collapsed_height_vw / full_height_vw) + 0.01;

    const seen_cookie_name = key => `marquee_seen_${key}`;

    const post_to_marquee = (message) => {
      $element.find('.celtra-ad-inline-host iframe')[0].contentWindow.postMessage(message, '*');
    };

    this.$onInit = () => {
      this.permanently_collapsed = false;
      this.marquee_ready = false;
    };

    this.$onDestroy = () => this.observer && this.observer.disconnect();

    this.on_filled = (render_event) => {
      this.creative_id = render_event.creativeId;

      const handler = (message) => {
        if (message.origin === `https://${AppConfig.canonical_domain}`) {
          if (message.data === 'marqueeLoaded') {
            this.on_marquee_loaded();
          } else if (message.data === 'marqueeReady') {
            this.on_marquee_ready();
          }
        }
      };

      $window.addEventListener('message', handler);

      return () => $window.removeEventListener('message', handler);
    };

    this.on_marquee_loaded = () => {
      const seen = Boolean(Cookies.get(seen_cookie_name(this.creative_id)));
      post_to_marquee({type: 'userData', seen});

      if (seen) {
        this.permanently_collapsed = true;
      } else {
        on_initial_impression();
      }
    };

    const on_initial_impression = () => {
      Cookies.put(seen_cookie_name(this.creative_id), 1, {expires: 1});

      const collapse = _.debounce(() => {
        post_to_marquee({type: 'permanentlyCollapse'});
        $scope.$applyAsync(() => this.permanently_collapsed = true);
      }, 1000);

      this.observer = new IntersectionObserver(([entry]) => {
        if (!entry.isIntersecting) {
          collapse();
        } else {
          collapse.cancel();
        }
      }, {
        threshold: percentage_to_detect_as_collapsed,
      });

      const el = document.querySelector('.marquee_ad');
      this.observer.observe(el);
    };

    this.on_marquee_ready = () => {
      this.marquee_ready = true;
      $scope.$apply();
    };
  }],
});
