/* eslint-env es6 */

angular.module('genius').factory('VideoRegistry', ['VideoClient', 'PagedDataFeed', '$q', 'PromiseQueue', 'AppConfig', 'SessionConfig', (VideoClient, PagedDataFeed, $q, PromiseQueue, AppConfig, SessionConfig) => {
  const RELEVANCE_ORDER = ['high', 'medium', 'low'];
  const page_by_model_link = {};
  const model_link_for = object => `${object._type}:${object.id}`;

  const VideoWithMetadata = class {
    constructor(video) {
      this.video = video;
      this.claimed = false;
    }
  };

  const PageWithVideo = class {
    constructor(object, name) {
      this.name = name;
      this.placements = _.map(AppConfig.video_placements[name], 'name');
      if (object._type === 'song' && object.song_story) {
        _.remove(this.placements, placement => placement === 'sidebar' || placement === 'sidebar_thumb');
      }
      this.placement_deferreds = {};
      _.forEach(this.placements, (placement) => {
        this.placement_deferreds[placement] = $q.defer();
      });

      this.videos_with_metadata = [];
      const params = {};
      const id_param_name = `${object._type}_id`;
      params[id_param_name] = object.id;
      this.paged_data_feed = new PagedDataFeed(VideoClient.index, 'videos', params, 1, (videos) => {
        this.videos_with_metadata = this.videos_with_metadata.concat(_.map(videos, video => new VideoWithMetadata(video)));
      });

      this.series_videos_with_metadata = [];
      this.series_paged_data_feed = new PagedDataFeed(
        VideoClient.index,
        'videos',
        _.merge({series: true}, params),
        1,
        (videos) => {
          this.series_videos_with_metadata =
            this.series_videos_with_metadata.concat(_.map(videos, video => new VideoWithMetadata(video)));
        }
      );

      this.resolve_placements();
    }

    next_unclaimed_video(min_relevance, series) {
      const paged_data_feed = series ? this.series_paged_data_feed : this.paged_data_feed;
      const deferred = $q.defer();
      const unclaimed_videos_with_metadata =
        _.reject(series ? this.series_videos_with_metadata : this.videos_with_metadata, 'claimed');
      if (_.some(unclaimed_videos_with_metadata)) {
        const video_with_metadata = _.head(unclaimed_videos_with_metadata);
        if (RELEVANCE_ORDER.indexOf(video_with_metadata.video.relevance) <= RELEVANCE_ORDER.indexOf(min_relevance)) {
          deferred.resolve(video_with_metadata);
        } else {
          deferred.reject();
        }
      } else if (paged_data_feed.has_next()) {
        paged_data_feed.next().then(() => deferred.resolve(this.next_unclaimed_video(min_relevance, series)));
      } else {
        deferred.reject();
      }
      return deferred.promise;
    }

    resolve_placements() {
      const promise_queue = new PromiseQueue();
      _.forEach(this.placements, placement => promise_queue.add(() => this.resolve_placement(placement)));
    }

    resolve_placement(placement) {
      const config = _.find(AppConfig.video_placements[this.name], {name: placement});
      const min_relevance = SessionConfig.fringe_enabled && !_.isUndefined(config.fringe_min_relevance) ?
        config.fringe_min_relevance :
        config.min_relevance;
      const claimed = [];
      const promise_queue = new PromiseQueue({fail_fast: true});
      const promises = _.times(
        config.max_videos,
        () => promise_queue.add(() => this.next_unclaimed_video(min_relevance, config.series).then((video_with_metadata) => {
          video_with_metadata.claimed = true;
          claimed.push(video_with_metadata);
        }))
      );
      $q.all(promises).finally(() => this.placement_deferreds[placement].resolve(_.map(claimed, 'video')));
      return this.placement_deferreds[placement].promise;
    }

    videos_for_placement(placement) {
      return this.placement_deferreds[placement].promise;
    }

    videos() {
      return _.map(this.videos_with_metadata, 'video');
    }
  };

  return {
    current_page_type(object) {
      return page_by_model_link[model_link_for(object)].name;
    },

    register_page(object, name) {
      page_by_model_link[model_link_for(object)] = new PageWithVideo(object, name);
    },

    videos_for_placement(object, placement) {
      return page_by_model_link[model_link_for(object)].videos_for_placement(placement);
    },

    videos_for(object) {
      return page_by_model_link[model_link_for(object)].videos();
    },

    paged_data_feed_for(object) {
      return page_by_model_link[model_link_for(object)].paged_data_feed;
    },
  };
}]);
