/* eslint-env es6 */

angular.module('genius').factory('ChartClient', ['ApiClient', function(ApiClient) {
  return {
    get(model, params = {}) {
      return ApiClient.get('/:model/chart', _.assign({model}, params));
    },

    state(model, id) {
      return ApiClient.get('/:model/:id/charts/state', {model, id});
    },

    exclude(model, id) {
      return ApiClient.put('/:model/:id/charts/exclude', {model, id});
    },

    unexclude(model, id) {
      return ApiClient.put('/:model/:id/charts/unexclude', {model, id});
    },
  };
}]);
