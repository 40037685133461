/* eslint-env es6 */

angular.module('genius').factory('VideoListClient', ['ApiClient', ApiClient => ({
  index(params) {
    return ApiClient.get('/video_lists', params);
  },

  update(id, video_list_params) {
    return ApiClient.returning('video_list').put('/video_lists/:id', {id, video_list: video_list_params});
  },

  videos(slug, params) {
    return ApiClient.get('/video_lists/by_slug/:slug/videos', {slug, ...params});
  },
})]);
