/* eslint-env es6 */

angular.module('genius').factory('ArticleClient', ['$http', 'AppConfig', 'ApiClient', function($http, AppConfig, ApiClient) {
  return {
    index(params) {
      return $http.get(`${AppConfig.api_root_url}/articles`, {params}).
        then(response => response.data.response);
    },

    delete(article_id) {
      return ApiClient.delete(`/articles/${article_id}`);
    },

    update_featured_slots(featured_articles) {
      return ApiClient.put('/articles/featured_slots', {featured_articles});
    },
  };
}]);
