/* eslint-env es6 */

angular.module('genius').component('comments', {
  bindings: {
    type: '@',
    commentable: '<',
    allow_reasons: '<allowReasons',
    prevent_compose: '<preventCompose',
    are_suggestions: '<areSuggestions',
    context_updated_at: '<contextUpdatedAt',
    intersperse_ads: '@intersperseAds',
    on_dirty: '&onDirty',
    on_clean: '&onClean',
    variants: '<',
  },

  templateUrl: 'comments.html',

  controller: ['CommentClient', 'Session', 'PagedDataFeed', function(CommentClient, Session, PagedDataFeed) {
    let preloaded_comments = [];

    const add_comments = (comments) => {
      const existing_comments_by_id = _.groupBy(this.comments, 'id');
      comments = _.reject(comments, comment => existing_comments_by_id[comment.id]);
      this.comments.push(...comments);
    };

    const min_top_comments = 1;
    const max_top_comments = 5;
    const min_votes_total = 10;
    const extract_top_comments = comments =>
      _.remove(
        comments,
        (comment, i) => i < max_top_comments && (i < min_top_comments || comment.votes_total >= min_votes_total)
      );

    const reset = () => {
      this.scrollable_data.reset();
      this.comments = [];
      if (this.commentable.top_comment) {
        add_comments([this.commentable.top_comment]);
      } else {
        this._loading = true;
        this.scrollable_data.next().then((comments) => {
          add_comments(extract_top_comments(comments));
          preloaded_comments = comments;
        }).finally(() => this._loading = false);
      }
      if (this.commentable.comment_count - this.comments.length > 0) {
        this.has_next = () => !_.isEmpty(preloaded_comments) || this.scrollable_data.has_next();
      } else {
        this.has_next = () => false;
      }
    };

    this.$onChanges = (changes) => {
      if (changes.context_updated_at && this.context_updated_at) reset();

      if (changes.type || changes.commentable) {
        this.scrollable_data = new PagedDataFeed(
          _.partial(CommentClient.load, this.type, this.commentable.id),
          'comments',
          {text_format: 'html,markdown'}
        );
        if (this.commentable.comment_count > 0) {
          reset();
        } else {
          this.comments = [];
        }
      }
    };

    this.load_more = () => {
      if (!_.isEmpty(preloaded_comments)) {
        add_comments(preloaded_comments);
        preloaded_comments = [];
        return;
      }
      this._loading = true;
      this.scrollable_data.next().
        then(add_comments).
        finally(() => this._loading = false);
    };

    this.showing_comment_form = () => (
      Session.has_permission('create_comment', this.commentable) ||
      !Session.user_signed_in
    ) && !this.prevent_compose;

    this.visible_comments = () => _.reject(this.comments, '_deleted');

    this.save_comment = (new_comment) => {
      this.comments.unshift(new_comment);
      return CommentClient.create(this.type, this.commentable.id, new_comment).then(() => {
        this.commentable.comment_count += 1;
      });
    };
  }],
});
