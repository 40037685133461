/* eslint-env es6 */

angular.module('genius').directive('inboxLineItemDetail', ['Bindings', '$compile', '$templateCache', 'LyricsEditProposal', function(Bindings, $compile, $templateCache, LyricsEditProposal) {
  const resolve_controller_bindings = Bindings.resolver({detail: '<lineItemDetail'});
  const resolve_link_bindings = Bindings.resolver({formation: '<'});

  return {
    restrict: 'E',

    template: '',

    link(scope, element, attrs) {
      const formation = resolve_link_bindings(scope, attrs).formation;
      let template = `
        <div
          class="inbox_line_item_detail"
          ng-class="{\'inbox_line_item_detail--dark_background\': $ctrl.use_dark_background}"
        >
      `;
      for (const component of formation) {
        template += '<div class="inbox_line_item_detail-section">';
        template += $templateCache.get(`inbox_line_item_detail/${component}.html`);
        template += '</div>';
      }
      template += '</div>';
      element.append($compile(template)(scope));
    },

    controller: ['$scope', '$attrs', 'ReferentRegistry', function($scope, $attrs, ReferentRegistry) {
      resolve_controller_bindings($scope, $attrs).assign_to(this);
      $scope.$watch('$ctrl.detail', () => {
        this.detail.targets = _.map(this.detail.targets, (target) => {
          if (target._type === 'referent') {
            target.rejection_comments = _.map(target.annotations, 'rejection_comment');
            return ReferentRegistry.update_if_present(target);
          }
          return target;
        });

        this.use_dark_background =
          _.includes(['forum_post', 'discussion'], _.get(this.detail, 'context._type')) ||
          _.includes(['forum_post', 'discussion'], _.get(this.detail, 'target._type'));

        this.lyrics_diffs = LyricsEditProposal.lyrics_diffs_for_line_item_detail(this.detail);
      });
    }],

    controllerAs: '$ctrl',
  };
}]);
