/* eslint-env es6 */

angular.module('genius').factory('TextSelection', ['$window', '$document', '$rootScope', '$immediate', 'XpathRange', function($window, $document, $rootScope, $immediate, XpathRange) {
  const scope = $rootScope.$new();

  const get_populated_selection = () => {
    const selection = ($window.getSelection || $document[0].getSelection || $.noop)();
    if (selection === undefined || !selection.rangeCount || selection.isCollapsed) return;

    return selection;
  };

  const is_equal_range_list = (ranges, other_ranges) => {
    if (!ranges || !other_ranges) return false;
    if (ranges.length !== other_ranges.length) return false;
    return _.every(_.zip(ranges, other_ranges), (range_pair) => {
      const r0 = range_pair[0];
      const r1 = range_pair[1];
      return r0.startOffset === r1.startOffset &&
             r0.endOffset === r1.endOffset &&
             r0.startContainer.isEqualNode(r1.startContainer) &&
             r0.endContainer.isEqualNode(r1.endContainer);
    });
  };

  const extract_valid_ranges = (selection) => {
    const ranges = [];
    for (let i = 0; i < selection.rangeCount; i++) {
      const range = selection.getRangeAt(i);
      if (range.getBoundingClientRect().width > 0.1) {
        ranges.push(range);
      }
    }
    return ranges;
  };

  const get_current_valid_ranges = () => {
    const selection = get_populated_selection();
    if (!selection) return [];
    return extract_valid_ranges(selection);
  };

  let last_ranges = [];
  const notify_selection_change = () => {
    const ranges = get_current_valid_ranges();
    if (!is_equal_range_list(ranges, last_ranges)) {
      last_ranges = ranges;
      scope.$emit('selection', {ranges});
    }
  };

  scope.restore_selection_if_clobbered = (element, callback) => {
    const ranges = get_current_valid_ranges();
    let serialized_range;
    if (ranges.length) {
      serialized_range = XpathRange.fromRange(ranges[0], element);
    }
    const result = callback();
    if (serialized_range && !get_current_valid_ranges().length) {
      const restored_range = XpathRange.toRange(
        serialized_range.start,
        serialized_range.startOffset,
        serialized_range.end,
        serialized_range.endOffset,
        element
      );
      const selection = getSelection();
      selection.removeAllRanges();
      selection.addRange(restored_range);
      notify_selection_change();
    }
    return result;
  };

  $document.on('mouseup keyup', (event) => {
    if (event.keyCode && !(event.keyCode >= 37 && event.keyCode <= 40)) return;
    $immediate(notify_selection_change);
  });

  return scope;
}]);
