/* eslint-env es6 */

angular.module('genius').factory('PageVisibility', ['$document', '$rootScope', function($document, $rootScope) {
  let hidden, visibility_change;
  if (!_.isUndefined($document[0].hidden)) {
    hidden = 'hidden';
    visibility_change = 'visibilitychange';
  } else if (!_.isUndefined($document[0].mozHidden)) {
    hidden = 'mozHidden';
    visibility_change = 'mozvisibilitychange';
  } else if (!_.isUndefined($document[0].msHidden)) {
    hidden = 'msHidden';
    visibility_change = 'msvisibilitychange';
  } else if (!_.isUndefined($document[0].webkitHidden)) {
    hidden = 'webkitHidden';
    visibility_change = 'webkitvisibilitychange';
  }
  const supported = !_.isUndefined(hidden);
  const when_visible_queue = [];

  if (supported) {
    $document[0].addEventListener(visibility_change, () => {
      if (_.isEmpty(when_visible_queue)) return;
      while (_.some(when_visible_queue)) {
        when_visible_queue.shift()();
      }
      $rootScope.$apply();
    });
  }

  return {
    is_visible() {
      return !$document[0][hidden];
    },

    is_hidden() {
      return $document[0][hidden];
    },

    when_visible(fn, context) {
      fn = context ? fn.bind(context) : fn;
      if (supported && $document[0][hidden]) {
        when_visible_queue.push(fn);
      } else {
        fn();
      }
    },
  };
}]);
