/* eslint-env es6 */

angular.module('genius').component('imageUploadButton', {
  templateUrl: 'image_upload_button.html',

  bindings: {
    default_image: '<defaultImage',
    on_file_picked: '&onFilePicked',
    allow_clear: '<allowClear',
    clear_confirm: '@clearConfirm',
    on_clear: '&onClear',
    allow_crop_to_square: '<allowCropToSquare',
    variants: '<',
  },

  controller: ['Filepicker', function(Filepicker) {
    const filepicker = new Filepicker();

    this.pick_image_for_artist = (open_to) => {
      const filepicker_options = {
        container: 'modal',
        services: ['COMPUTER', 'URL', 'FACEBOOK', 'INSTAGRAM', 'IMAGE_SEARCH'],
        openTo: open_to,
      };

      if (this.allow_crop_to_square) {
        filepicker_options.services.unshift('CONVERT');
        filepicker_options.conversions = ['crop'];
        filepicker_options.cropRatio = 1;
      }

      const store_options = {path: Math.random().toString(36).slice(2)};

      filepicker.pick_and_store(filepicker_options, store_options).then((url) => {
        this.on_file_picked({url});
      });
    };
  }],
});
