/* eslint-env es6 */

angular.module('genius').component('questionList', {
  bindings: {
    pinned_questions: '<pinnedQuestions',
    default_questions: '<defaultQuestions',
    featured_question: '<featuredQuestion',
    pending_question_count: '<pendingQuestionCount',
    variants: '<',
    questionable: '<',
  },

  templateUrl: 'question_list.html',

  controller: ['QuestionClient', 'PagedDataFeed', 'Session', function(QuestionClient, PagedDataFeed, Session) {
    const scrollable_data_params = {state: 'pending'};

    this.$onChanges = (changes) => {
      if (changes.default_questions || changes.pinned_questions) {
        this.questions = _([]).concat(this.default_questions, this.pinned_questions).compact().value();
        this.monetized_question_ids = this.pinned_questions.
          map((question, index) => ({id: question.id, index})).
          filter(({index}) => index % 2 === 0 && index < this.pinned_questions.length - 1).
          map(({id}) => id);
      }

      if (changes.featured_question) {
        if (this.featured_question && this.featured_question.state !== 'pinned') {
          this.questions.push(this.featured_question);
        }
      }

      if (changes.variants) {
        if (this.variants && this.variants.answered) {
          scrollable_data_params.answered = this.variants.answered;
        }
        this.scrollable_data = new PagedDataFeed(
          _.partial(QuestionClient.index, this.questionable._type, this.questionable.id),
          'questions', scrollable_data_params);
      }

      if (changes.pending_question_count) {
        if (this.pending_question_count) {
          this.has_next = this.scrollable_data.has_next;
        } else {
          this.has_next = () => false;
        }
      }

      this.can_create_question = Session.has_permission('create_question', this.questionable);
    };

    this.sorting_predicates = [
      q => ['default', 'pinned', 'pending', 'archived'].indexOf(q.state),
      'pin_order',
    ];

    this.move_question = (direction, question) => {
      let others;

      if (direction === 'up') {
        others = _(this.questions).
          filter(q => q.pin_order && q.pin_order < question.pin_order).
          sortBy('pin_order').
          reverse().
          take(2).
          thru(o => [o[1], o[0]]);
      } else if (direction === 'down') {
        others = _(this.questions).
          filter(q => q.pin_order && q.pin_order > question.pin_order).
          sortBy('pin_order').
          take(2).
          thru(o => [o[0], o[1]]);
      }

      const on_error = this.move_question_between(question, others.get(0), others.get(1));
      QuestionClient.move(question, direction).catch(on_error);
    };

    this.move_question_between = (question, question_above, question_below) => {
      let midpoint;
      if (question_above && question_below) {
        midpoint = (question_above.pin_order + question_below.pin_order) / 2;
      } else if (question_below) {
        midpoint = question_below.pin_order / 2;
      } else if (question_above) {
        midpoint = question_above.pin_order + 1;
      }

      const old_pin_order = question.pin_order;
      question.pin_order = midpoint;
      return () => {
        question.pin_order = old_pin_order;
      };
    };

    this.load_more = () => {
      this._loading = true;
      this.scrollable_data.next().then((questions) => {
        questions = _.reject(questions, this.is_featured_question, this);
        this.pending_question_count -= questions.length;
        this.questions.push(...questions);
      }).finally(() => {
        this._loading = false;
      });
    };

    this.is_featured_question = question =>
      this.featured_question && this.featured_question.id === question.id;

    this.is_monetized = question => this.monetized_question_ids.includes(question.id);

    this.is_visible = question =>
      !question._deleted && (question.answer || !(question.default_question && question.default_question.answer_prefix));
  }],
});
