/* eslint-env es6 */

angular.module('genius').component('standaloneInbox', {
  templateUrl: 'standalone_inbox.html',

  transclude: true,

  bindings: {
    embed: '<',
  },

  controller: ['$window', function($window) {
    this.$onChanges = (changes) => {
      if (changes.embed) {
        if (this.embed) {
          const parent = $window.frameElement.parentElement;
          parent.style.height = `${$window.parent.innerHeight}px`;
          this.max_height = parent.offsetHeight;
          parent.style.height = 'auto';
        } else {
          this.max_height = 0;
        }
      }
    };
  }],
});
