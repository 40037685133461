/* eslint-env es6 */

angular.module('genius').component('deferCompile', {
  bindings: {
    timing_label: '@timingLabel',
    initial_content_key: '@useInitialContentWithKey',
    within_visibility_range: '@withinVisibilityRange',
    delay: '<',
  },

  transclude: true,

  controller: ['$element', '$q', '$timeout', '$transclude', '$window', 'application_bootstrap', 'initial_placeholder_content', 'on_within_visibility_range', 'performance_mark', function($element, $q, $timeout, $transclude, $window, application_bootstrap, initial_placeholder_content, on_within_visibility_range, performance_mark) {
    this.$onInit = () => {
      application_bootstrap.before(() => {
        const initial_element = initial_placeholder_content.get(this.initial_content_key);
        if (initial_element) $element.empty().append(initial_element);
      });

      const delay = _.isFinite(this.delay) ? $timeout(this.delay) : $q.when();
      const timing_label = this.timing_label || this.initial_content_key;

      application_bootstrap.after(() => delay.then(() => {
        const within_visibility_range = this.within_visibility_range
          ? on_within_visibility_range($element, parse_visibility_range(this.within_visibility_range))
          : $q.when();
        within_visibility_range.then(() => {
          if (timing_label) {
            performance_mark.create(`defer_compile_start:${timing_label}`);
          }
          $element.empty().append($transclude());
          if (timing_label) {
            performance_mark.create(`defer_compile_end:${timing_label}`);
          }
        });
      }));
    };

    const parse_visibility_range = (distance_string) => {
      const [, number_string, units] = distance_string.match(/^(\d+(?:\.\d+)?)(vh|px)$/)  || [];
      switch (units) {
      case 'px':
        return parseFloat(number_string);
      case 'vh':
        return (parseFloat(number_string) / 100) * $($window).height();
      default:
        throw new Error('within-visibility-range attribute must have units (either px or vh');
      }
    };
  }],
});
