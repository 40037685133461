/* eslint-env es6 */

angular.module('genius').component('lyricsEditProposal', {
  bindings: {
    edit_proposal: '<editProposal',
    editable: '<',
    accept_with: '&acceptWith',
  },

  templateUrl: 'lyrics_edit_proposal.html',

  controller: ['$scope', 'LyricsEditProposal', 'LyricsEditProposalClient', 'Session', '$immediate', '$q', 'Tracking', function($scope, LyricsEditProposal, LyricsEditProposalClient, Session, $immediate, $q, Tracking) {
    this.$onChanges = (changes) => {
      if (changes.editable) {
        apply_patches();
      }
    };

    this.can = action_name => Session.has_permission(action_name, this.edit_proposal);

    this.accept = () => {
      const editable = _.extend({}, this.editable, {markdown: this.patched});
      const params = {editable, lyrics_edit_id: this.edit_proposal.id};
      perform_moderation('accepted', this.accept_with({params}), 'accept_proposal');
    };

    this.reject = () => {
      perform_moderation('rejected', LyricsEditProposalClient.reject(this.edit_proposal.id), 'reject_proposal');
    };

    const set_moderated_state = (state) => {
      this.error = null;
      this.moderated_state = state;
      return $immediate(() => this.edit_proposal._moderated = true);
    };

    const perform_moderation = (state, action, analytics_label) => {
      const state_promise = set_moderated_state(state);
      return $q.all([action, state_promise]).
        then(() => {
          Tracking.track(`lyrics_edit_proposals:${analytics_label}`, {lyrics_edit_id: this.edit_proposal.id});
        }).
        catch((error) => {
          this.error = error.message;
          this.edit_proposal._moderated = false;
          this.moderated_state = null;
        });
    };

    const apply_patches = () => {
      const patches = LyricsEditProposal.make_patches(this.edit_proposal.diff);
      const {patched, success} = LyricsEditProposal.apply_patches(
        patches,
        this.editable.markdown,
        {edit_format: this.edit_proposal.edit_format}
      );
      if (_.some(success)) {
        this.patched = patched;
        const patched_diff = LyricsEditProposal.diff(this.editable.markdown, this.patched);
        this.diff_html = LyricsEditProposal.pretty_diff(patched_diff);
      } else {
        set_moderated_state('inapplicable');
      }
    };

    $scope.$watch('$ctrl.editable.markdown', (markdown) => {
      if (markdown) apply_patches();
    });
  }],
});
