/* eslint-env es6 */

angular.module('genius').directive('slideOverPane', [function() {
  return {
    restrict: 'E',

    templateUrl: 'slide_over_pane.html',

    transclude: true,

    scope: {
      condition: '<',
      closeOn: '&',
      scrollToTopTrigger: '<',
    },

    controller: ['$scope', 'Bowser', function($scope, Bowser) {
      if (Bowser.ios) $scope.is_ios = true;
    }],
  };
}]);
