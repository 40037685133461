/* eslint-env es5 */
/* eslint-disable arrow-body-style, arrow-parens, arrow-spacing, constructor-super, generator-star-spacing, no-class-assign, no-confusing-arrow, no-const-assign, no-dupe-class-members, no-duplicate-imports, no-extra-bind, no-new-symbol, no-restricted-imports, no-this-before-super, no-useless-computed-key, no-useless-constructor, no-useless-rename, no-var, object-shorthand, prefer-arrow-callback, prefer-const, prefer-numeric-literals, prefer-reflect, prefer-rest-params, prefer-spread, prefer-template, require-yield, rest-spread-spacing, sort-imports, symbol-description, template-curly-spacing, yield-star-spacing, dot-location, key-spacing, max-len, new-parens, no-lonely-if, no-mixed-operators, no-trailing-spaces, no-whitespace-before-property, padded-blocks, quotes, space-infix-ops */

angular.module('genius').component('userInfoWithActions', {
  bindings: {
    user: '<object',
    variants: '<',
  },

  templateUrl: 'user_info_with_actions.html',

  controller: ['$scope', 'Session', 'UserClient', 'Attempt', function($scope, Session, UserClient, Attempt) {
    this.attempt_close = Attempt.close_form;

    this.user_signed_in = Session.user_signed_in;
    this.current_user = Session.current_user;

    $scope.$watch('$ctrl.user', function(user) {
      if (!user.current_user_metadata) {
        var user_to_load = this.user;
        UserClient.load(user_to_load.id).then(function(updated_user) {
          _.assign(user_to_load, updated_user);
        });
      }
    }.bind(this));

    this.close_conversation_modal = () => this.sending_message = false;
  }],
});
