/* eslint-env es5 */
/* eslint-disable arrow-body-style, arrow-parens, arrow-spacing, constructor-super, generator-star-spacing, no-class-assign, no-confusing-arrow, no-const-assign, no-dupe-class-members, no-duplicate-imports, no-extra-bind, no-new-symbol, no-restricted-imports, no-this-before-super, no-useless-computed-key, no-useless-constructor, no-useless-rename, no-var, object-shorthand, prefer-arrow-callback, prefer-const, prefer-numeric-literals, prefer-reflect, prefer-rest-params, prefer-spread, prefer-template, require-yield, rest-spread-spacing, sort-imports, symbol-description, template-curly-spacing, yield-star-spacing, dot-location, key-spacing, max-len, new-parens, no-lonely-if, no-mixed-operators, no-trailing-spaces, no-whitespace-before-property, padded-blocks, quotes, space-infix-ops */

angular.module('genius').factory('signed_out_created_content', ['$window', 'Session', function($window, Session) {
  var session_storage = $window.sessionStorage;
  var memory_storage = {};

  var update = function(data) {
    if (!session_storage) return;
    try {
      session_storage.setItem('signed_out_created_content', JSON.stringify(data));
    } catch (e) {
      if (e.name !== 'QuotaExceededError') throw e;
    }
  };

  return {
    add: function(type, id) {
      if (Session.user_signed_in) return;
      var data = this.get();
      data[type] = data[type] || [];
      data[type].push(id);
      update(data);
    },

    get: function() {
      if (!session_storage) return memory_storage;
      return JSON.parse(session_storage.getItem('signed_out_created_content') || "{}");
    },

    clear: function() {
      memory_storage = {};
      session_storage.removeItem('signed_out_created_content');
    },
  };
}]);
