/* eslint-env es6 */

angular.module('genius').factory('AnnotationClient', ['$q', 'ApiClient', 'Session', 'Reversible', 'Markdown', function($q, ApiClient, Session, Reversible, Markdown) {
  const with_text_format = (req) => {
    const text_format = Session.user_signed_in ? 'html,markdown' : 'html';
    return req.extend({params: {text_format}});
  };

  const safe_remove = (annotation, with_action) => {
    annotation._deleted = true;
    return with_action.catch(() => {
      Reflect.deleteProperty(annotation, '_deleted');
    });
  };

  const act_on_proposed_edit = (action, edit_id) => ApiClient.
    use(with_text_format).
    returning('annotation_edit').
    post('/annotation_edits/:edit_id/:action', {edit_id, action});

  const update = (annotation_id, data) => ApiClient.
    use(with_text_format).
    put('/annotations/:annotation_id', _.merge(data, {annotation_id}));

  const create = (referent_id, data) => ApiClient.
    use(with_text_format).
    post('/referents/:referent_id/annotations', _.merge(data, {referent_id}));

  const rejection_implies_deletion = annotation => annotation.comment_count === 0;

  return {
    save(annotation, params) {
      const savable_annotation = {
        body: {markdown: annotation._editing.markdown},
        edit_note: annotation._editing.edit_note,
      };

      if (annotation._editing._has_source) {
        savable_annotation.source = annotation._editing.source;
      }

      if (annotation._editing.custom_preview !== annotation.custom_preview) {
        savable_annotation.custom_preview = annotation._editing.custom_preview;
      }

      let action;
      if (annotation.id) {
        action = update(annotation.id, {annotation: savable_annotation});
      } else {
        action = create(params.referent.id, {annotation: savable_annotation});
      }

      const revert = Reversible.modify(
        annotation,
        {
          'body.html': Markdown.to_html(annotation._editing.markdown),
          new: false,
          source: savable_annotation.source,
        }
      );
      return action.then((payload) => {
        _.assign(params.referent, payload.referent);
        return _.assign(annotation, payload.annotation);
      }).catch(() => {
        revert();
        return $q.reject();
      });
    },

    safe_delete(annotation) {
      return safe_remove(annotation, this.delete(annotation.id));
    },

    delete(annotation_id) {
      return ApiClient.delete('/annotations/:annotation_id', {annotation_id});
    },

    accept(annotation, params) {
      const referent = params.referent;
      const reverse_referent_modifications = Reversible.modify(referent, {
        classification: referent.classification === 'unreviewed' ? 'accepted' : referent.classification,
      });

      const reverse_annotation_modifications = Reversible.modify(annotation, {
        state: 'accepted',
        accepted_by: Session.current_user,
        'current_user_metadata.permissions': ['edit'],
      });

      return ApiClient.
        use(with_text_format).
        returning('annotation').
        post('/annotations/:annotation_id/accept', {annotation_id: annotation.id}).
        then(updated_annotation => _.assign(annotation, updated_annotation)).
        catch(() => {
          reverse_referent_modifications();
          reverse_annotation_modifications();
          return $q.reject();
        });
    },

    safe_reject(annotation, params) {
      let rollback;
      const comment = params.comment;
      const save_comment = _.assign({}, comment, {
        body: comment.body.markdown,
        reason: comment.reason ? comment.reason.raw_name : undefined,
      });
      if (rejection_implies_deletion(annotation)) {
        rollback = Reversible.modify(annotation, {_deleted: true});
      } else {
        rollback = Reversible.modify(annotation, {needs_exegesis: true});
      }
      return this.reject(annotation.id, {comment: save_comment}).
        then((response) => {
          const payload = response || {};
          _.assign(annotation, payload.annotation || {_deleted: true});
          _.assign(params.referent, payload.referent || {_deleted: true});
        }).catch(() => {
          rollback();
          return $q.reject();
        });
    },

    reject(annotation_id, data) {
      return ApiClient.
        use(with_text_format).
        post('/annotations/:annotation_id/reject', _.merge(data, {annotation_id}));
    },

    pin_to_profile(annotation_id) {
      return ApiClient.
        use(with_text_format).
        put('/annotations/:annotation_id/pin_to_profile', {annotation_id});
    },

    unpin_from_profile(annotation_id) {
      return ApiClient.
        use(with_text_format).
        put('/annotations/:annotation_id/unpin_from_profile', {annotation_id});
    },

    toggle_cosign(annotation_id, cosign) {
      return ApiClient.
        use(with_text_format).
        put(`/annotations/:annotation_id/${cosign ? '' : 'un'}cosign`, {annotation_id});
    },

    clear_votes(annotation_id) {
      return ApiClient.delete('/votes', {annotation_id});
    },

    report_as_abusive(annotation_id) {
      return ApiClient.
        post('/annotations/:annotation_id/report_as_abusive', {annotation_id});
    },

    load_versions(annotation_id) {
      return ApiClient.
        use(with_text_format).
        returning('versions').
        get('/annotations/:annotation_id/versions', {annotation_id});
    },

    revert_to_version(annotation_id, version_id) {
      return ApiClient.
        use(with_text_format).
        returning('annotation').
        put('/annotations/:annotation_id/version', {annotation_id, version_id});
    },

    propose_edit(annotation) {
      const annotation_params = {
        body: {markdown: annotation._editing.markdown},
        edit_note: annotation._editing.edit_note,
      };

      return ApiClient.
        use(with_text_format).
        returning('annotation_edit').
        post('/annotations/:annotation_id/edits', {annotation_id: annotation.id, annotation: annotation_params});
    },

    proposed_edits(annotation_id) {
      return ApiClient.
        use(with_text_format).
        returning('annotation_edits').
        get('/annotations/:annotation_id/edits', {annotation_id});
    },

    merge_edit(edit_id) { return act_on_proposed_edit('merge', edit_id); },

    reject_edit(edit_id) { return act_on_proposed_edit('reject', edit_id); },
  };
}]);
