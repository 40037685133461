/* eslint-env es6 */

angular.module('genius').component('relevantVideos', {
  bindings: {
    object: '<',
    placement: '@',
    container_classes: '@containerClasses',
    on_filled: '&onFilled',
    on_no_video: '&onNoVideo',
    variants: '<',
  },

  templateUrl: 'relevant_videos.html',

  controller: ['VideoPlacement', 'Session', function(VideoPlacement, Session) {
    this.$onChanges = (changes) => {
      if (changes.object) {
        this.video_placement = new VideoPlacement(
          this.object,
          this.placement,
          videos => videos.length ? this.on_filled({videos}) : this.on_no_video()
        );
      }
    };

    this.can = (permission, object) => Session.has_permission(permission, object);
  }],
});
