/* eslint-env es6 */

angular.module('genius').component('contributionOpportunity', {
  bindings: {
    assignment: '<?',
    contribution_opportunity: '<contributionOpportunity',
    on_dismiss: '&onDismiss',
    source: '@',
  },

  templateUrl: 'contribution_opportunity.html',

  controller: ['ContributionOpportunityAssignmentClient', 'Tracking', 'Session', '$timeout', function(ContributionOpportunityAssignmentClient, Tracking, Session, $timeout) {
    this.can = permission => Session.has_permission(permission, this.contribution_opportunity.target);

    this.track = (action, event) => {
      const name = 'Contribution Opportunity Action';
      const params = {action, contribution_opportunity_id: this.contribution_opportunity.id, source: this.source};
      if (event) {
        Tracking.track_link_click(event, name, params);
      } else {
        Tracking.track(name, params);
      }
    };

    this.track_open = (event) => {
      this.track('open', event);
    };

    this.on_pending_event = (event, result) => {
      if (_.includes(this.contribution_opportunity.complete_on_events, event)) {
        this.submitted = true;
        const optimistic_remove = $timeout(() => {
          this.optimistically_removed = true;
        }, 200);
        result.then(() => {
          this.track('complete');
        }).catch(() => {
          this.submitted = false;
          this.optimistically_removed = false;
          $timeout.cancel(optimistic_remove);
        });
      }
    };

    this.dismiss = () => {
      ContributionOpportunityAssignmentClient.update(this.assignment.id, {dismissed: true}).
        then(() => {
          this.track('dismiss');
          this.on_dismiss({assignment: this.assignment});
        });
    };
  }],
});
