/* eslint-env es6 */

angular.module('genius').factory('LookupClient', ['$http', 'AppConfig', function($http, AppConfig) {
  return {
    lookup(url) {
      return $http.get(`${AppConfig.api_root_url}/lookup`, {params: {url}}).
        then(response => _(response.data.response).values().first());
    },
  };
}]);
