/* eslint-env es6 */

angular.module('genius').factory('CursoredDataFeed', [function() {
  return class {
    constructor(get_data, model_key, params = {}, starting_cursor_value = undefined, cursor_name = 'next_cursor') {
      this.cursor_name = cursor_name;
      this.next_cursor = starting_cursor_value;
      this.params = _.clone(params);

      this.next = () => {
        const params_to_send = {};
        params_to_send[this.cursor_name] = this.next_cursor;

        return get_data(_.assign(params_to_send, this.params)).then((data) => {
          this.next_cursor = data[this.cursor_name];
          return data[model_key];
        });
      };

      this.has_next = () =>
        this.next_cursor || typeof this.next_cursor === 'undefined';

      this.reset = () => {
        this.next_cursor = starting_cursor_value;
      };
    }
  };
}]);
