/* eslint-env es6 */

angular.module('genius').component('miniSongCard', {
  bindings: {
    song: '<object',
    search_highlights: '<searchHighlights',
    artist: '<',
    number: '<',
    variants: '<',
  },

  templateUrl: 'mini_song_card.html',

  controller: ['string_to_symbol_array', function(string_to_symbol_array) {
    const extract_range_from_string =
      (string, range_start, range_end) => string_to_symbol_array(string).slice(range_start, range_end).join('');

    const wrap_excerpt_ranges_in_spans = (excerpt_and_ranges) => {
      const excerpt = excerpt_and_ranges.value;
      const ranges = excerpt_and_ranges.ranges;

      const excerpt_in_chunks = [];
      let last_index = 0;
      ranges.forEach((range) => {
        const pre_highlight_text = extract_range_from_string(excerpt, last_index, range.start);
        const highlight_text = extract_range_from_string(excerpt, range.start, range.end);
        const wrapped_highlight_text = `<span>${highlight_text}</span>`;

        excerpt_in_chunks.push(pre_highlight_text);
        excerpt_in_chunks.push(wrapped_highlight_text);

        last_index = range.end;
      });

      const last_chunk = excerpt.split('').slice(last_index).join('');
      excerpt_in_chunks.push(last_chunk);

      return excerpt_in_chunks.join('');
    };

    this.$onChanges = (changes) => {
      if (changes.artist || changes.song) {
        this.non_primary_artist_present = this.artist && this.song.primary_artists.
          every(({id}) => this.artist.id !== id);
      }

      if (changes.search_highlights) {
        const lyrics_match = _.keyBy(this.search_highlights, 'property').lyrics;

        if (lyrics_match) {
          this.excerpt_with_markup = wrap_excerpt_ranges_in_spans(lyrics_match);
        }
      }
    };
  }],
});
