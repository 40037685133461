/* eslint-env es6 */

angular.module('genius').factory('MarkdownPlaintextFinderRegex', [function() {
  return function(plaintext) {
    return new RegExp(
      plaintext.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&'),
      'gm'
    );
  };
}]);
