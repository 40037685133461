/* eslint-env es6 */

angular.module('genius').component('editArtistProfile', {
  templateUrl: 'edit_artist_profile.html',

  bindings: {
    artist: '<',
    close: '&',
  },

  controller: ['ArtistClient', '$location', 'Session', 'only_changes', function(ArtistClient, $location, Session, only_changes) {
    this.$onChanges = (changes) => {
      if (changes.artist) {
        this.editable_artist = angular.copy(this.artist);
      }
    };

    this.can = action_name => Session.has_permission(action_name, this.artist);

    this.update_artist = () => {
      this.errors = null;
      if (this.saving) return;
      this.saving = true;

      ArtistClient.
        update(this.artist.id, only_changes(this.editable_artist, this.artist)).
        then((artist) => {
          _.assign(this.artist, artist);
          this.close();
          this.errors = null;
          $location.path(ArtistClient.get_path(artist));
          $location.replace();
        }).catch((error) => {
          this.errors = error.data.response.errors;
        }).finally(() => {
          this.saving = false;
        });
    };
  }],
});
