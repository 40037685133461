/* eslint-env es6 */

angular.module('genius').component('songStoryLeadStoryPlacement', {
  bindings: {
    story: '<',
    show_story: '&showStory',
  },

  transclude: true,

  templateUrl: 'song_story_lead_story_placement.html',
});
