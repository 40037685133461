/* eslint-env es6 */

angular.module('genius').directive('roleIcon', [function() {
  return {
    scope: {
      role: '<',
      hasChecky: '<',
    },

    templateUrl: 'role_icon.html',
  };
}]);
