/* eslint-env es6 */

angular.module('genius').factory('on_within_visibility_range', ['$q', '$window', function($q, $window) {
  let max_scroll = 0;
  let window_height = $($window).height();
  let deferreds = [];

  const process_deferreds = () => {
    max_scroll = Math.max(max_scroll, $($window).scrollTop());
    deferreds = _.tap([], (unresolved) => {
      deferreds.forEach((deferred) => {
        if (deferred.element.offset().top - deferred.range < max_scroll + window_height) {
          deferred.resolve();
        } else {
          unresolved.push(deferred);
        }
      });
    });
  };

  $($window).on('scroll', _.throttle(process_deferreds, 100));
  $($window).on('resize', () => {
    window_height = $($window).height();
  });

  return (element, range = 0) => _.tap($q(resolve =>
    deferreds.push({resolve, range, element: $(element)})), process_deferreds);
}]);
