/* eslint-env es6 */

angular.module('genius').component('answer', {
  bindings: {
    answer: '<',
    question: '<',
    yieldsApi: '&',
    on_pending_event: '&onPendingEvent',
    yields_iq: '&yieldsIq',
    variants: '<',
  },

  templateUrl: 'answer.html',

  controller: ['$scope', 'Reversible', 'AnswerClient', 'Session', function($scope, Reversible, AnswerClient, Session) {
    this.user_signed_in = Session.user_signed_in;

    this.source_form_open = this.answer && this.answer.answer_source;

    this.answer_has_prefix = this.question.default_question && this.question.default_question.answer_prefix;

    this.can = (permission, object) => Session.has_permission(permission, object);

    this.show_contributors = () => {
      if (_.get(this, 'answer.answer_source.source_name')) {
        if (this.can('update_answer_source', this.question)) return this.question.contributors_count;
      } else {
        return this.question.contributors_count;
      }
    };

    this.answer_is_unwritten = () => !_.get(this, 'answer.body.html');

    this.question_is_archived = () => this.question.state === 'archived';

    this.question_is_pinned = () => this.question.state === 'pinned';

    this.start_editing = () => {
      if (!this.answer) this.answer = {body: {}};

      this.answer._editing = _.defaults(this.answer._editing || {}, {
        _form_active: true,
        html: this.answer.body.html,
        markdown: this.answer.body.markdown,
      });
    };

    this.cancel_editing = () => {
      Reflect.deleteProperty(this.answer, '_editing');
    };

    this.finish_editing = () => {
      if (this.disable_saving_with) return;
      this.disable_saving_with = 'Saving';
      this.errors = null;

      const restart_editing = Reversible.modify(this.answer, {'_editing._form_active': false});
      if (!this.source_form_open && this.answer.answer_source) {
        this.answer.answer_source = null;
      }
      const promise = AnswerClient.save(this.answer, {question: this.question}).then(this.cancel_editing);
      this.on_pending_event({event: 'UserCreateAnswer', result: promise});
      promise.catch((error) => {
        this.errors = error.errors;
        restart_editing();
      }).finally(() => {
        this.disable_saving_with = false;
      });
    };

    this.destroy = () => {
      if (!confirm('Are you sure?')) return false;

      const answer_was = this.answer;
      this.answer = {body: {}};

      AnswerClient.destroy(answer_was, {question: this.question}).catch(() => {
        this.answer = answer_was;
      });
    };

    this.showing_contributors = false;
    this.toggle_contributors = () => {
      this.showing_contributors = !this.showing_contributors;
    };

    this.show_answer_form_by_default = () =>
      this.can('add_answer', this.question) && this.answer_is_unwritten() && !this.question_is_archived();

    this.show_answer_edit_form = () =>
      _.get(this, 'answer._editing._form_active') || this.show_answer_form_by_default();

    this.show_answer = () =>
      !this.answer_is_unwritten() && !_.get(this, 'answer._editing._form_active');

    this.show_blank_answer_message = () =>
      this.answer_is_unwritten() && !this.show_answer_form_by_default() && !_.get(this, 'answer._editing._form_active');

    let api;
    {
      const $ctrl = this;

      api = {
        start_editing() {
          $ctrl.start_editing();
        },

        can_edit() {
          return !$ctrl.answer_has_prefix && $ctrl.can('edit', $ctrl.answer);
        },

        clear_answer() {
          $ctrl.destroy();
        },

        can_clear() {
          return !$ctrl.answer_has_prefix && $ctrl.can('destroy', $ctrl.answer);
        },
      };
    }
    this.yieldsApi({answerApi: api});

    if (Session.user_signed_in) {
      this.iq_api = _.cloneDeep(this.question.current_user_metadata.iq_by_action);
      $scope.$watch('$ctrl.question.votes_total', (votes_total) => {
        this.iq_api.answer.primary.multiplier = _.max([votes_total, 1]);
      });
      $scope.$watch('$ctrl.answer._editing.pin_question', (pin_question) => {
        this.iq_api.answer.pin.applicable = pin_question || this.question.state === 'pinned';
      });
      $scope.$watch('$ctrl.question.state', (state) => {
        this.iq_api.answer.pin.applicable = state === 'pinned' || _.get(this.answer, '_editing.pin_question');
      });
      this.yields_iq({iq_api: this.iq_api});
    }
  }],
});
