/* eslint-env es6 */

angular.module('genius').component('appleMatchEvaluationsPage', {
  templateUrl: 'apple_match_evaluations_page.html',

  controller: Object.assign(['$sce', 'AppleMatchEvaluationsClient', '$timeout', '$document',
    function($sce, AppleMatchEvaluationsClient, $timeout, $document) {
      this.$routerOnActivate = (next) => {
        this.evaluation = next.routeData.page_data;
        this.model = {notes: this.evaluation.notes};
        this.song = this.evaluation.song;
        this.apple_embed_url = $sce.trustAsResourceUrl(this.evaluation.apple_embed_url);
        this.apple_albums = this.evaluation.apple_albums;
      };

      this.submit = (state) => {
        this.saving = state;

        const evaluation = _.merge({state}, this.model);
        AppleMatchEvaluationsClient.submit(this.evaluation.id, evaluation).then((_response) => {
          $timeout(() => $document[0].location = 'apple_match_evaluations/new', 200);
        });
      };

      this.skip = () => {
        this.saving = 'skip';

        AppleMatchEvaluationsClient.skip(this.evaluation.id).then((_response) => {
          $timeout(() => $document[0].location = 'apple_match_evaluations/new', 200);
        });
      };
    }], {
    $canActivate: ['$nextInstruction', '$prevInstruction', 'PageTransition', function(
      $nextInstruction,
      $prevInstruction,
      PageTransition
    ) {
      return PageTransition.by_router_instruction($nextInstruction, $prevInstruction);
    }],
  }),
});
