import 'mark_bundle_start';
import 'es7-shim/dist/es7-shim.js';
import 'jquery.cookie';
import 'angular/angular';
import 'angular-sanitize/angular-sanitize';
import 'angular-animate/angular-animate';
import 'angular-svg-round-progressbar/build/roundProgress';
import 'angular_1_router';
import 'angular-inview/angular-inview.js';
import 'angular-loading-bar/build/loading-bar';
import 'angular-youtube-embed/dist/angular-youtube-embed.min.js';
import 'angularjs-google-recaptcha-v3/release/angularjs-google-recaptcha-v3.min.js';
import 'ng-tags-input/build/ng-tags-input';
import 'at_who';
import 'jquery.caret/dist/jquery.caret.js';
import 'jquery.waitforimages';
import 'lodash';
import 'rxjs';
import 'shared/onsite/i18n_translations';
import 'i18n';
import 'filepicker-js/dist/filepicker.js';
import 'moment-2.10.6';
import 'angular-moment.1.0.0.b3.js';
import 'iframeResizer-b585f2e';
import 'angular-rich-text-diff';
import 'diff_match_patch';
import 'bowser/src/bowser';
import 'genius_innerhtml';
import 'angular-sticky-top/angular-sticky';



$('html').toggleClass('windows', !!bowser.windows);

angular.module('genius', ['ngSanitize', 'ngAnimate', 'ngTagsInput', 'angularMoment', 'angular-rich-text-diff', 'angular-inview', 'angular-svg-round-progressbar', 'ngComponentRouter', 'youtube-embed', 'ngSticky', 'cfp.loadingBar', 'fayzaan.gRecaptcha.v3']).
  config(['$locationProvider', '$httpProvider', '$animateProvider', '$compileProvider', 'tagsInputConfigProvider', 'AppConfig', '$anchorScrollProvider', 'cfpLoadingBarProvider', '$qProvider', function($locationProvider, $httpProvider, $animateProvider, $compileProvider, tagsInputConfigProvider, AppConfig, $anchorScrollProvider, cfpLoadingBarProvider, $qProvider) {
    $locationProvider.html5Mode({enabled: true, rewriteLinks: false});
    $locationProvider.hashPrefix('!');
    $httpProvider.interceptors.push('OnsiteInterceptor');
    $httpProvider.interceptors.push('RetryFailureInterceptor');
    $httpProvider.interceptors.push('GlobalErrorInterceptor');
    $animateProvider.classNameFilter(/nganimate-/);
    $compileProvider.debugInfoEnabled(AppConfig.enable_angular_debug);
    $compileProvider.commentDirectivesEnabled(false);
    $compileProvider.cssClassDirectivesEnabled(false);
    $anchorScrollProvider.disableAutoScrolling();
    tagsInputConfigProvider.setDefaults('tagsInput', {
      placeholder: '',
    }).setActiveInterpolation('tagsInput', {
      placeholder: true,
    });
    cfpLoadingBarProvider.includeSpinner = false;
    $compileProvider.preAssignBindingsEnabled(true);
    $qProvider.errorOnUnhandledRejections(false);
  }]).value('$routerRootComponent', 'routablePage').run(['AppConfig', 'Tracking', function(AppConfig, Tracking) {
    $.embedly.defaults.key = AppConfig.embedly_key;
    Tracking.start_tracking();
  }]);

(() => {
  const context = require.context('./application/', true, /.js$/);
  context.keys().forEach(context);
})();
import 'shared/universal/commonjs_shim';
import 'bagon_bootstrap';