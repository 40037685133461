/* eslint-env es6 */

angular.module('genius').directive('ampIframeResizable', ['Bindings', '$window', 'AppConfig', function(Bindings, $window, AppConfig) {
  const resolve_bindings = Bindings.resolver({
    on_amp_iframe_resize: '&onAmpIframeResize',
  });

  return {
    restrict: 'A',

    link(scope, element, attrs) {
      const bindings = resolve_bindings(scope, attrs);

      $window.addEventListener('message', (message) => {
        if (
          message.origin === `https://${AppConfig.canonical_domain}` &&
            message.source === element[0].contentWindow &&
            message.data.sentinel === 'amp' &&
            message.data.type === 'embed-size'
        ) {
          scope.$applyAsync(() => bindings.on_amp_iframe_resize({
            width: message.data.width,
            height: message.data.height,
          }));

          if (message.data.height) {
            element.css('height', `${message.data.height}px`);
          } else {
            element.css('height', '');
          }
          if (message.data.width) {
            element.css('width', `${message.data.width}px`);
          } else {
            element.css('width', '');
          }
        }
      });
    },
  };
}]);
