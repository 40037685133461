/* eslint-env es6 */

angular.module('genius').component('statisticsPanelItem', {
  bindings: {
    label: '@',
    value: '<',
    icon: '@',
  },

  templateUrl: 'statistics_panel_item.html',
});
