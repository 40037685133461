/* eslint-env es6 */

angular.module('genius').component('messagesInbox', {
  bindings: {
    opened: '<',
    decrement_unread_count: '&decrementUnreadCount',
    embed: '<',
  },

  templateUrl: 'messages_inbox.html',

  controller: ['Attempt', 'InboxClient', 'Tracking', '$window', function(Attempt, InboxClient, Tracking, $window) {
    this.attempt_close = (override_confirm) => {
      Attempt.close_form(override_confirm, () => {
        this.composing = false;
        this.on_modal_close();
      });
    };

    const refresh = () => {
      this.refresh_at = Date.now();
    };

    this.$onInit = () => {
      InboxClient.on_update('messages_inbox', refresh);
    };

    this.$onChanges = (changes) => {
      if (changes.opened) {
        const is_open = changes.opened.currentValue;
        const was_open = changes.opened.previousValue;
        if (is_open && (!was_open || changes.opened.isFirstChange())) {
          Tracking.track('Inbox Open', {inbox_name: 'messages_inbox'});
        }
      }
    };

    this.on_conversation_read = (unread_messages_count) => {
      this.decrement_unread_count({decrement: unread_messages_count});
      if (this.embed) {
        $window.parent.postMessage({
          type: 'decrement_unread_messages_count',
          decrement: unread_messages_count,
        }, '*');
      }
    };

    this.on_modal_open = () => {
      this.modal_open = true;
      if (this.embed) {
        const embed_container_element = $window.frameElement.parentElement;
        embed_container_element.style.height = `${$window.parent.innerHeight}px`;
        const max_height = embed_container_element.offsetHeight;
        embed_container_element.style.height = 'auto';
        this.modal_dimensions = {
          top: embed_container_element.scrollTop,
          height: Math.min(Math.max(embed_container_element.offsetHeight, 800), max_height) - 32,
          width: embed_container_element.offsetWidth - 32,
        };
      }
    };

    this.on_modal_close = () => this.modal_open = false;
  }],
});
