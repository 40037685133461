/* eslint-env es6 */

angular.module('genius').factory('AsyncAnalytics', ['$document', '$q', '$immediate', 'AnalyticsConfig', 'Librato', 'Tracking', 'wait_for_global', function($document, $q, $immediate, AnalyticsConfig, Librato, Tracking, wait_for_global) {
  const update_and_trigger_chartbeat = page_data =>
    wait_for_global('_sf_async_config').then((_sf_async_config) => {
      Object.assign(_sf_async_config, page_data.chartbeat);
      return wait_for_global('pSUPERFLY');
    }).then((pSUPERFLY) => {
      pSUPERFLY.virtualPage(page_data.path, page_data.title);
    });

  const trigger_comscore = () =>
    wait_for_global('COMSCORE').then((COMSCORE) => {
      COMSCORE.beacon({c1: '2', c2: AnalyticsConfig.comscore_client_id});
    });

  const update_and_trigger_google_analytics = page_data =>
    wait_for_global('gtag').then((gtag) => {
      gtag('set', 'page_location', page_data.path);
      gtag('set', 'controller_and_action', page_data.controller_and_action);
      gtag('set', 'primary_tag', page_data.tracking_data['Primary Tag']);
      gtag('set', 'music', String(page_data.tracking_data['Music?']));
      gtag('set', 'client_routed', 'true');
      gtag('event', 'page_view');
    });

  const update_librato = (page_data) => {
    Librato.set_page_type(page_data.page_type);
  };

  const update_mixpanel = (page_data) => {
    Tracking.reset_counters();
    Tracking.register({fast_navigating: true});
    Tracking.set_tacking_data(page_data.
      tracking_data.reduce((properties, {key, value}) =>
        Object.assign(properties, {[key]: value}), {}));
  };

  const trigger_quantcast = () =>
    $q.all({
      __qc: wait_for_global('__qc'),
      _qevents: wait_for_global('_qevents'),
      quantserve: wait_for_global('quantserve'),
    }).then(({__qc, _qevents, quantserve}) => {
      __qc.qpixelsent = [];
      quantserve();
      $immediate(() =>
        _qevents.push({qacct: AnalyticsConfig.quantcast_account}));
    });

  return {
    on_navigation(page_data) {
      this.remove_social_metadata();

      if (!AnalyticsConfig.enabled) return $q.when();

      const updates_and_triggers = [
        update_and_trigger_chartbeat(page_data),
        trigger_comscore(),
        update_and_trigger_google_analytics(page_data),
        update_librato(page_data),
        trigger_quantcast(),
      ];

      if (AnalyticsConfig.mixpanel_enabled) {
        updates_and_triggers.push(update_mixpanel(page_data));
      }

      return $q.all(updates_and_triggers);
    },

    remove_social_metadata: _.once(() =>
      _.filter($document.prop('head').querySelectorAll('meta'), tag =>
        (tag.getAttribute('property') || '').match(/^(og|fb|twitter):/)).
        forEach(tag => tag.remove())
    ),
  };
}]);
