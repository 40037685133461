/* eslint-env es6 */

angular.module('genius').factory('BeforeUnloadCheck', ['$window', 'I18n', function($window, I18n) {
  const checks = [];

  $window.onbeforeunload = () => {
    const has_dirty = _.some(checks, f => f());

    if (has_dirty) {
      return I18n.t('editing.dirty_unload');
    }
  };

  return {
    add(check) {
      checks.push(check);
    },

    remove(check) {
      _.remove(checks, c => c === check);
    },
  };
}]);
