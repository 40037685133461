/* eslint-env es6 */

angular.module('genius').directive('globalMessage', ['$compile', '$templateCache', 'GlobalRefs', function($compile, $templateCache, GlobalRefs) {
  const template = $templateCache.get('global_message.html');

  const target = GlobalRefs.get_element('global_message');

  return {
    scope: true,

    restrict: 'E',

    transclude: true,

    link(scope, _element, _attributes, _controller, transcludeFn) {
      const link = $compile(template);

      link(scope, (cloned_element) => {
        target.append(cloned_element);

        scope.$on('$destroy', () => {
          cloned_element.remove();
        });
      }, {
        parentBoundTranscludeFn: transcludeFn,
        futureParentElement: target,
      });
    },
  };
}]);
