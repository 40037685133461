/* eslint-env es6 */

angular.module('genius').component('headerActions', {
  templateUrl: 'header_actions.html',

  controller: ['Session', function(Session) {
    this.$onInit = () => {
      this.user_signed_in = Session.user_signed_in;
      this.can = permission => Session.has_permission(permission, Session.current_user);
    };
  }],
});
