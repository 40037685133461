/* eslint-env es6 */

angular.module('genius').directive('commentsPlaceholder', [function() {
  return {
    restrict: 'E',

    templateUrl: 'comments_placeholder.html',

    scope: {},
  };
}]);
