/* eslint-env es6 */

angular.module('genius').factory('InboxClient', ['Session', 'Pusher', 'ApiClient', function(Session, Pusher, ApiClient) {
  return {
    get_line_item(inbox_name, id) {
      const text_format = Session.user_signed_in ? 'html,markdown' : 'html';
      const path = inbox_name ? `/inboxes/${inbox_name}/line_items/${id}` : `/line_items/${id}`;
      return ApiClient.get(path, {text_format}).
        then(response => response.line_item);
    },

    line_items(inbox_name, next_timestamp) {
      return ApiClient.get(
        '/inboxes/:inbox_name/line_items',
        {text_format: 'html', timestamp_for_next_page: next_timestamp, inbox_name}
      ).then((response) => {
        response.line_items.timestamp_for_next_page = response.timestamp_for_next_page;
        return response.line_items;
      });
    },

    mark_read(inbox_name, grouped_event_ids) {
      let event_ids;
      if (grouped_event_ids) {
        event_ids = {};
        for (let i = 0; i < grouped_event_ids.length; i++) {
          event_ids[i + 1] = grouped_event_ids[i];
        }
      }
      return ApiClient.post('/inboxes/:inbox_name/line_items/mark_read', {event_ids, inbox_name});
    },

    on_update(inbox_name, callback) {
      Pusher.subscribe(
        Session.current_user.pusher_channel,
        `new-${inbox_name.replace(/_/g, '-')}-event`,
        callback
      );

      Pusher.subscribe(
        Session.current_user.pusher_channel,
        'inbox-marked-read',
        (data) => {
          if (data.inbox === inbox_name) callback();
        }
      );
    },
  };
}]);
