/* eslint-env es6 */

angular.module('genius').component('searchResults', {
  bindings: {
    sections: '<',
    search_text: '<searchText',
    scroll_container_selector: '@scrollContainerSelector',
    on_filter: '&onFilter',
    on_sections_change: '&onSectionsChange',
    on_load_next: '&onLoadNext',
    on_animation_end: '&onAnimationEnd',
  },

  templateUrl: 'search_results.html',

  controller: ['$element', '$immediate', function($element, $immediate) {
    this.$onChanges = (changes) => {
      if (changes.sections) {
        this.on_sections_change();
      }
    };

    this.has_results = () => _.flatMap(this.sections, 'hits').length > 0;

    let $scroll_container = $element.find(this.scroll_container_selector);
    if (!$scroll_container.length) {
      $scroll_container = $($element).closest(this.scroll_container_selector);
    }
    const $results = $element.find('.global_search-search_results');
    const $sections = $element.find('.global_search-search_results-sections');
    let scroll_pos, scroll_height;
    this.save_scroll_and_scroll_top = () => {
      $immediate(() => {
        const $show_more = $element.find('.global_search-search_results-show_more');
        scroll_pos = $scroll_container.scrollTop();
        scroll_height = $results.height();
        $results.css({minHeight: scroll_height});
        $sections.css({position: 'absolute', top: -scroll_pos});
        $show_more.css({position: 'relative', top: 0});
        $scroll_container.scrollTop(0);
      });
    };

    this.restore_scroll = () => {
      const $show_more = $element.find('.global_search-search_results-show_more');
      const new_scroll_pos = $scroll_container.scrollTop();
      $show_more.css({position: 'absolute', top: scroll_pos - new_scroll_pos});
      $sections.css({position: 'relative', top: 0});
      $scroll_container.scrollTop(scroll_pos);
    };

    $sections.on('animationend', () => {
      this.on_animation_end();
    });
  }],
});
