/* eslint-env es5 */
/* eslint-disable arrow-body-style, arrow-parens, arrow-spacing, constructor-super, generator-star-spacing, no-class-assign, no-confusing-arrow, no-const-assign, no-dupe-class-members, no-duplicate-imports, no-extra-bind, no-new-symbol, no-restricted-imports, no-this-before-super, no-useless-computed-key, no-useless-constructor, no-useless-rename, no-var, object-shorthand, prefer-arrow-callback, prefer-const, prefer-numeric-literals, prefer-reflect, prefer-rest-params, prefer-spread, prefer-template, require-yield, rest-spread-spacing, sort-imports, symbol-description, template-curly-spacing, yield-star-spacing, dot-location, key-spacing, max-len, new-parens, no-lonely-if, no-mixed-operators, no-trailing-spaces, no-whitespace-before-property, padded-blocks, quotes, space-infix-ops */

angular.module('genius').directive('textarea', ['Autosize', '$immediate', function(Autosize, $immediate) {
  return {
    restrict: 'E',

    link: function(scope, elem, attrs) {
      if ('autosizeOff' in attrs) return;

      var resize = function() {
        Autosize.update(elem);
      };

      var initialize = function() {
        Autosize(elem);
      };

      if ('autosizeInitOnFocus' in attrs) {
        elem.on('focus', initialize);
      } else {
        $immediate(initialize, !'invoke_apply');
      }

      elem.on('change', resize);

      scope.$watch(attrs.ngModel, function(val) {
        if (val !== elem.val()) {
          $immediate(resize, !'invoke_apply');
        }
      });

      if (attrs.ngShow) {
        scope.$watch(attrs.ngShow, function(new_value) {
          if (new_value) $immediate(resize, !'invoke_apply');
        });
      }
    },
  };
}]);
