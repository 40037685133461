import 'jquery.autocomplete-1.1-genius-modifications';
/* eslint-env es6 */


$.browser = {};
angular.module('genius').directive('quickSearch', ['Tracking', function(Tracking) {
  return {
    restrict: 'A',

    link(_scope, element) {
      element.autocomplete('/search/quick.js', {
        delay: 250,
        scrollHeight: 500,
        matchSubset: false,
        max: 20,
        minChars: 4,
        selectFirst: false,
        resultsClass: 'ac_results quick_search-results',
        formatResult: item => item[1] ? 'Loading...' : ' ',
      }).result((_event, data) => {
        if (data && data[1]) {
          $('form.global_search :submit').attr('disabled', 'disabled');

          Tracking.track_with_timeout('Search Result Tap', () => {
            window.location.href = data[1];
          });
        }
      });
    },
  };
}]);
