/* eslint-env es6 */

angular.module('genius').directive('annotationEdits', ['AnnotationClient', 'ReferentClient', 'ReferentRegistry', function(AnnotationClient, ReferentClient, ReferentRegistry) {
  return {
    scope: {
      annotation: '<object',
      context_updated_at: '<contextUpdatedAt',
      on_resolution: '&onResolution',
    },

    restrict: 'E',

    templateUrl: 'annotation_edits.html',

    controllerAs: 'ctrl',

    controller: ['$scope', function($scope) {
      this.on_merge = (edit) => {
        _.assign($scope.annotation, edit.annotation_after);
        load_edits();
      };

      const load_edits = () => {
        if (this.loading_edits) return;
        this.loading_edits = true;
        AnnotationClient.proposed_edits($scope.annotation.id).then((edits) => {
          $scope.annotation.edits = edits;
        }).finally(() => {
          Reflect.deleteProperty(this, 'loading_edits');
        });
      };

      $scope.$watch('annotation.edits.length', (edits_count_is, edits_count_was) => {
        if (!edits_count_is && !edits_count_was) return;
        const referent = ReferentRegistry.peek($scope.annotation.referent_id);
        ReferentClient.refresh(referent);
      });

      $scope.$watch('context_updated_at', (value) => {
        if (value) load_edits();
      });

      load_edits();
    }],
  };
}]);
