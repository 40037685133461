/* eslint-env es6 */

angular.module('genius').component('standaloneSearch', {
  templateUrl: 'standalone_search.html',

  transclude: true,

  controller: ['$window', 'SearchClient', 'AppConfig', function($window, SearchClient, AppConfig) {
    this.$onInit = () => {
      const parent = $window.frameElement.parentElement;
      parent.style.height = `${$window.parent.innerHeight}px`;
      this.max_height = parent.offsetHeight;
      parent.style.height = 'auto';

      const handler = (message) => {
        if (
          message.origin === `https://${AppConfig.canonical_domain}` &&
            _.get(message.data, 'type') === 'standalone-search-query'
        ) {
          this.search_text = message.data.query;
          SearchClient.multi(this.search_text).then((sections) => {
            this.sections = sections;
          });
        }
      };
      $window.addEventListener('message', handler);
    };
  }],
});
