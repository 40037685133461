/* eslint-env es6 */

angular.module('genius').directive('conversationMessages', [function() {
  return {
    templateUrl: 'conversation_messages.html',

    scope: {
      conversation: '<',
      yields_is_dirty: '&yieldsIsDirty',
    },

    controller: ['$scope', 'MessageClient', 'ConversationClient', function($scope, MessageClient, ConversationClient) {
      let next_page = 1;
      let currently_loading_page;
      $scope.finished_paginating = false;
      $scope.messages = [];

      this.message_sent = (message) => {
        $scope.messages.push(message);
        $scope.conversation.latest_message = message;
        $scope.conversation.latest_message_preview = message.body_preview;
      };

      this.should_paginate = () => !currently_loading_page &&
        !$scope.finished_paginating;

      this.load_next_page = () => {
        if (currently_loading_page) return;

        currently_loading_page = MessageClient.for_conversation(
          $scope.conversation.id,
          {page: next_page}
        ).then((messages) => {
          $scope.messages.unshift(...messages.reverse());
          currently_loading_page = null;
          if (next_page === 1) {
            ConversationClient.mark_read($scope.conversation.id);
          }
          ++next_page;
          $scope.finished_paginating = messages.length === 0;
        });
      };

      this.load_next_page();
    }],

    controllerAs: 'ctrl',
  };
}]);
