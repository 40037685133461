/* eslint-env es6 */

angular.module('genius').component('lyricsSyncEditor', {
  bindings: {
    id: '<modelId',
    instructions_url: '@instructionsUrl',
    go_to_home: '&goToHome',
  },

  templateUrl: 'lyrics_sync_editor.html',

  controller: ['$scope', '$q', '$immediate', 'BeforeUnloadCheck', 'LyricsSynchronizationClient', 'AppleAudioPlayback', 'Session', 'MusicKit', function($scope, $q, $immediate, BeforeUnloadCheck, LyricsSynchronizationClient, AppleAudioPlayback, Session, MusicKit) {
    this.position = 0;
    this.current_line_index = -1;
    this.is_editing_timestamp = false;

    this.$onInit = () => {
      LyricsSynchronizationClient.get(this.id).then((lyrics_synchronization) => {
        this.lyrics_synchronization = lyrics_synchronization;
        this.auto_advance = !this.can('edit');
        this.sync_and_staff_approve = this.can('sync_and_staff_approve');
      });
    };
    let clear_media_player_listener = _.noop;
    $scope.$watch('$ctrl.lyrics_synchronization.external_song.id', (track_id) => {
      clear_media_player_listener();
      if (!track_id) return;

      const external_song = this.lyrics_synchronization.external_song;
      if (external_song.provider === 'apple') {
        MusicKit.load().then(({getInstance}) => {
          this.media_player = new AppleAudioPlayback({
            instance: getInstance(),
            external_song,
          });
          clear_media_player_listener = this.media_player.$on('state', on_media_state);
        });
      }
    });

    this.start_or_advance = () => {
      if (!this.media_player.state.is_playing) {
        return this.media_player.api.play();
      }

      if (this.current_line_index >= this.lyrics_synchronization.lines.length - 1) return;

      const line_index_was = this.current_line_index;
      this.current_line_index = this.lyrics_synchronization.
        next_visible_line_index_after_index(this.current_line_index);

      if (!this.can('edit')) return;

      try {
        this.lyrics_synchronization.apply_timestamp_to_line_at_index(
          this.position, this.current_line_index
        );
      } catch (e) {
        this.error = e;
        this.current_line_index = line_index_was;
      }
    };

    this.seek_to_index = (index) => {
      const position = this.lyrics_synchronization.timestamp_for_line_at_index(index);
      if (position || position === 0) this.media_player.api.seek_to_position(position);
      if (!this.auto_advance) this.current_line_index = index;
    };

    this.seek_to_index_and_play = (index) => {
      this.seek_to_index(index);
    };

    this.can = permission => this.lyrics_synchronization && Session.has_permission(permission, this.lyrics_synchronization);

    this.in_order_validator_for_index = index => (model_value) => {
      if (!model_value && model_value !== 0) return true;
      return model_value > this.lyrics_synchronization.max_timestamp_before_index(index) &&
        model_value < this.lyrics_synchronization.min_timestamp_after_index(index);
    };

    this.move_to_index_for_position = function(position) {
      this.current_line_index = this.lyrics_synchronization.line_index_for_position(position);
    };

    this.current_line = () => this.lyrics_synchronization.lines[this.current_line_index];

    this.edit_line_lyrics_at_index = (index) => {
      this.media_player.api.pause();
      const line = this.lyrics_synchronization.lines[index];
      line._editing_lyrics = this.can('edit');
    };

    this.after_edit_line_lyrics_at_index = (index) => {
      this.lyrics_synchronization.lines[index]._editing_lyrics = false;
      this.lyrics_synchronization.mark_dirty();
    };

    this.edit_line_timestamp_at_index = (index) => {
      this.media_player.api.pause();
      this.lyrics_synchronization.lines[index]._editing_time = this.can('edit');
      this.is_editing_timestamp = true;
    };

    this.after_edit_line_timestamp_at_index = (index) => {
      this.lyrics_synchronization.lines[index]._editing_time = false;
      this.lyrics_synchronization.mark_dirty();
      this.seek_to_index_and_play(index);
      this.is_editing_timestamp = false;
    };

    const on_media_state = (_e, state) => {
      this.position = state.position;
      if (this.auto_advance) {
        this.move_to_index_for_position(this.position);
      }
    };

    this.last_action = $q.when();

    const define_transaction = (action, options) => {
      options = options || {};
      return (...args) => {
        this.saving = true;
        this.last_action = this.last_action.finally(() => Reflect.apply(action, this, args).
          then((lyrics_synchronization) => {
            if (!options.retain_error) this.error = null;
            this.lyrics_synchronization._dirty = null;
            _.assign(this.lyrics_synchronization, _.omit(lyrics_synchronization, 'lines'));
            if (lyrics_synchronization.lines && this.lyrics_synchronization.lines) {
              lyrics_synchronization.lines.forEach((line, index) => {
                _.assign(this.lyrics_synchronization.lines[index], line);
              }, this);
            } else {
              this.lyrics_synchronization.lines = lyrics_synchronization.lines;
            }
          }).catch((error) => {
            this.error = error;
            $immediate(this.load.bind(this));
            return $q.reject(error);
          }).finally(() => {
            this.saving = false;
          }));
        return this.last_action;
      };
    };

    this.load = define_transaction(() =>
      LyricsSynchronizationClient.get(this.id), {retain_error: true});

    this.submit = define_transaction((action_name, data) =>
      LyricsSynchronizationClient.submit(action_name, this.lyrics_synchronization, data));

    this.submit_sync = define_transaction(() =>
      LyricsSynchronizationClient.submit_sync(this.lyrics_synchronization, {
        sync_and_staff_approve: this.sync_and_staff_approve,
      }));

    this.update_state = define_transaction(state =>
      LyricsSynchronizationClient.update_state(state, this.lyrics_synchronization));

    this.save = define_transaction(() =>
      LyricsSynchronizationClient.save(this.lyrics_synchronization));

    this.autosave = _.debounce(this.save, 1000);

    this.revert_action = define_transaction(action =>
      LyricsSynchronizationClient.revert_action(action, this.lyrics_synchronization));

    this.skip = define_transaction(data =>
      LyricsSynchronizationClient.skip(data, this.lyrics_synchronization));

    this.change_song = define_transaction(song => LyricsSynchronizationClient.change_song({
      song_id: song.id, lyrics_synchronization_id: this.id,
    }).then((result) => {
      this.reassociating = null;
      return result;
    }));

    this.preview_with_song = song => LyricsSynchronizationClient.preview({
      song_id: song.id,
      external_song_id: _.get(this.lyrics_synchronization, 'external_song.id'),
    }).then((lyrics_synchronization) => {
      this.error = null;
      this.lyrics_synchronization = lyrics_synchronization;
    }).catch((error) => {
      this.error = error;
      return this.error;
    });

    this.cancel_reassociation = () => {
      this.reassociating = false;
      this.load();
    };

    const dirty_check = () => this.lyrics_synchronization._dirty;

    BeforeUnloadCheck.add(dirty_check);

    $scope.$on('$destroy', () => {
      BeforeUnloadCheck.remove(dirty_check);
      this.media_player.api.pause();
    });

    LyricsSynchronizationClient.$on('edit', () => {
      this.error = null;
      if (!this.can('edit')) return;
      if (this.can('approve') || this.can('staff_approve')) {
        this.autosave();
      }
    });
  }],
});
