/* eslint-env es6 */

angular.module('genius').component('artistOpportunities', {
  bindings: {
    artist: '<',
  },

  templateUrl: 'artist_opportunities.html',

  controller: ['PagedDataFeed', 'ArtistClient', function(PagedDataFeed, ArtistClient) {
    this.scrollable_data = new PagedDataFeed(
      _.partial(ArtistClient.contribution_opportunities, this.artist.id),
      'contribution_opportunities',
      {per_page: 10}
    );
  }],
});
