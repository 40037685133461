/* eslint-env es6 */

angular.module('genius').factory('Soundcloud', ['$sce', '$http', 'querystring', function($sce, $http, querystring) {
  const DEFAULT_WIDGET_OPTIONS = {
    auto_play: false,
    buying: false,
    liking: false,
    download: false,
    sharing: false,
    show_artwork: false,
    show_comments: false,
    show_playcount: false,
    show_user: false,
    hide_related: true,
    visual: false,
  };

  const fetch_oembed_params_for_src = src =>
    $http.get('https://soundcloud.com/oembed', {
      params: {url: src, format: 'json'},
    }).then((response) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(response.data.html, 'text/html');
      const qs = doc.querySelector('iframe').src.split('?')[1];
      return querystring.parse(qs);
    });

  return {
    oembed_url_for_src(src) {
      return fetch_oembed_params_for_src(src).then(params => params.url);
    },

    player_url_for_src(src) {
      return fetch_oembed_params_for_src(src).then((params) => {
        const qs = querystring.stringify(_.assign(params, DEFAULT_WIDGET_OPTIONS));
        return $sce.trustAsResourceUrl(`https://w.soundcloud.com/player/?${qs}`);
      });
    },
  };
}]);
