/* eslint-env es6 */

angular.module('genius').component('articleIndexItem', {
  bindings: {
    article: '<',
    unfeature: '&?',
    disableGoToArticle: '=',
    feature: '&?',
    featureVideo: '&?',
  },

  templateUrl: 'article_index_item.html',

  controller: ['Session', '$window', function(Session, $window) {
    this.can = permission => Session.has_permission(permission, this.article);

    this.goToArticle = () => {
      if (!this.disableGoToArticle) {
        $window.location.href = this.article.path;
      }
    };

    this.isVideoArticle = () => this.article.article_type === 'video';

    this.displayFeatureButton = () => this.can('change_featured_slot') && this.feature;

    this.displayFeatureVideoButton = () => this.can('change_featured_slot') && this.isVideoArticle() && this.featureVideo;

    this.displayUnfeatureButton = () => this.can('change_featured_slot') && this.article.featured_slot && this.unfeature;
  }],
});
