/* eslint-env es6 */

const ACCEPTABLE_CONTRAST_VALUE = 4.5;
const BLACK_HEX = '#000000';
const WHITE_HEX = '#ffffff';

angular.module('genius').component('songColorGradientPicker', {
  bindings: {
    song_art_primary_color: '=songArtPrimaryColor',
    song_art_secondary_color: '=songArtSecondaryColor',
    song_art_text_color: '=songArtTextColor',
    valid_song_art_contrast: '=validSongArtContrast',
    song_art_image_url: '<songArtImageUrl',
    errors: '<',
  },

  templateUrl: 'song_color_gradient_picker.html',

  controller: ['$scope', function($scope) {
    const hexToRgb = (hex) => {
      const m = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return m === null ? null : [m[1], m[2], m[3]].map(s => parseInt(s, 16));
    };

    const luminance = (rgb_array) => {
      const a = rgb_array.map((v) => {
        v /= 255;
        return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
      });
      return (a[0] * 0.2126) + (a[1] * 0.7152) + (a[2] * 0.0722);
    };

    const contrast_value = (color1, color2) => {
      if (!color1 || !color2) return 1;

      const color1_rgb = hexToRgb(color1);
      const color2_rgb = hexToRgb(color2);

      const color1_luminance = luminance(color1_rgb);
      const color2_luminance = luminance(color2_rgb);

      return color1_luminance > color2_luminance
        ? _.round((color1_luminance + 0.05) / (color2_luminance + 0.05), 2)
        : _.round((color2_luminance + 0.05) / (color1_luminance + 0.05), 2);
    };

    const is_acceptable_contrast = contrast => contrast >= ACCEPTABLE_CONTRAST_VALUE;

    this.$onInit = () => {
      this.text_on_primary_contrast = contrast_value(this.song_art_primary_color, this.song_art_text_color);
      this.text_on_secondary_contrast = contrast_value(this.song_art_secondary_color, this.song_art_text_color);
      this.valid_song_art_contrast = is_acceptable_contrast(this.text_on_primary_contrast) &&
                                     is_acceptable_contrast(this.text_on_secondary_contrast);
    };

    $scope.$watch('$ctrl.song_art_primary_color', (new_value, old_value) => {
      if (new_value === old_value) return;

      const black_on_primary_contrast = contrast_value(new_value, BLACK_HEX);
      const new_song_art_text_color = is_acceptable_contrast(black_on_primary_contrast) ? BLACK_HEX : WHITE_HEX;

      this.text_on_primary_contrast = contrast_value(new_value, new_song_art_text_color);

      if (this.song_art_text_color !== new_song_art_text_color) {
        this.song_art_text_color = new_song_art_text_color;
        this.text_on_secondary_contrast = contrast_value(this.song_art_secondary_color, new_song_art_text_color);
        this.valid_song_art_contrast = is_acceptable_contrast(this.text_on_primary_contrast) &&
                                       is_acceptable_contrast(this.text_on_secondary_contrast);
      }
    });

    $scope.$watch('$ctrl.song_art_secondary_color', (new_value) => {
      this.text_on_secondary_contrast = contrast_value(new_value, this.song_art_text_color);
      this.valid_song_art_contrast = is_acceptable_contrast(this.text_on_primary_contrast) &&
                                     is_acceptable_contrast(this.text_on_secondary_contrast);
    });
  }],
});
