/* eslint-env es6 */

((angular) => {
  const includeFromCache = [
    'artist',
    'user',
    'artist_songs',
    'artist_albums',
    'top_scholar',
    'top_accomplishment',
    'pinned_annotations',
    'artist_has_more_songs',
    'preloaded_user_activity',
    'initial_next_user_activity_timestamp',
    'current_user_metadata',
  ];

  const empty = /(?:)/;
  const wildcard = /.*/;
  const required = _.curry((regex, leader) => new RegExp(`${leader.source}${regex.source}`));
  const optional = _.curry((regex, leader) => new RegExp(`(?:${leader.source}${regex.source})?`));
  const capture = inner => new RegExp(`(${inner.source})`);

  const default_segment = optional(wildcard);
  const url_regex = ({path = default_segment, query = default_segment, hash = default_segment}) =>
    new RegExp([
      '^',
      path(empty).source,
      query(/\?/).source,
      hash(/#/).source,
      '$',
    ].join(''));

  angular.module('genius').component('routablePage', {
    template: '<ng-outlet></ng-outlet>',

    $routeConfig: [
      {
        path: '/song-stories',
        name: 'SongStoryGalleryPage',
        component: 'songStoryGalleryPage',
        data: {
          type: 'song_story_gallery',
        },
      },
      {
        path: '/albums/:artist_slug/:album_slug',
        name: 'AlbumPage',
        component: 'albumPage',
        data: {
          type: 'album',
          clientRoutableOn: ['desktop'],
        },
      },
      {
        path: '/album_cover_arts/:cover_art_id',
        name: 'AlbumPageCoverArtFocus',
        component: 'albumPage',
        data: {
          type: 'album_cover_art',
          clientRoutableOn: ['desktop'],
        },
      },
      {
        path: '/a/:article_slug',
        name: 'ArticlePage',
        component: 'articlePage',
        data: {
          type: 'article',
          clientRoutableOn: ['desktop'],
        },
      },
      {
        path: '/a/preview/:preview_key',
        name: 'ArticleExternalPreviewPage',
        component: 'articlePage',
        data: {
          type: 'article',
          clientRoutableOn: ['desktop'],
        },
      },
      {
        path: '/articles/:article_id/preview',
        name: 'ArticlePreviewPage',
        component: 'articlePage',
        data: {
          type: 'article',
          clientRoutableOn: ['desktop'],
        },
      },
      {
        path: '/shows/:slug',
        name: 'VideoListPage',
        component: 'videoListPage',
        data: {
          type: 'video_list',
          clientRoutableOn: ['desktop'],
        },
      },
      {
        path: '/shows/:slug/:page',
        name: 'VideoListPage',
        component: 'videoListPage',
        data: {
          type: 'video_list',
          clientRoutableOn: ['desktop'],
        },
      },
      {
        path: '/videos/:slug',
        name: 'VideoPage',
        component: 'videoPage',
        data: {
          type: 'video',
          clientRoutableOn: ['desktop'],
        },
      },
      {
        path: '/search',
        name: 'SearchResultsPage',
        component: 'searchResultsPage',
      },
      {
        path: '/artists/...',
        name: 'ArtistPage',
        component: 'routableProfilePage',
      },
      {
        regex: url_regex({
          path: required(capture(/[^/?]*-(?:annotated|lyrics)/)),
          query: optional(capture(wildcard)),
        }),
        serializer(params) {
          return {urlPath: params.slug};
        },
        name: 'SongPage',
        component: 'songPage',
        data: {
          type: 'song',
        },
      },
      {
        path: '/admin_tasks',
        name: 'AdminTasksPage',
        component: 'adminTasksPage',
      },
      {
        path: '/apple_match_evaluations/:evaluation_id',
        name: 'AppleMatchEvaluationsPage',
        component: 'appleMatchEvaluationsPage',
      },
      {
        regex: url_regex({
          path: required(capture(/\d+/)),
        }),
        serializer(params) {
          return {urlPath: params.referent_id};
        },
        name: 'SongReferentFocus',
        component: 'songPage',
        data: {
          type: 'referent',
        },
      },
      {
        path: '/:slug',
        name: 'UserPage',
        component: 'routedPage',
        data: {
          includeFromCache,
          component: 'profilePage',
          include: {show_artist_pane: false},
        },
      },
      {
        path: '/:slug/bio',
        name: 'UserBioPage',
        component: 'routedPage',
        data: {
          includeFromCache,
          component: 'profilePage',
          include: {show_artist_pane: true},
        },
      },
      {
        path: '/users/...',
        name: 'UserRouter',
        component: 'routableUserPage',
      },
    ],
  });
})(window.angular);
