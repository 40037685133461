/* eslint-env es6 */

angular.module('genius').directive('newConversation', [function() {
  return {
    scope: {
      on_sent: '&onSent',
      yields_is_dirty: '&yieldsIsDirty',
    },

    templateUrl: 'new_conversation.html',

    controller: ['$scope', 'ConversationClient', function($scope, ConversationClient) {
      this.did_send = (message) => {
        ConversationClient.get(message.conversation_id).then((conversation) => {
          $scope.conversation = conversation;
        });
      };
    }],

    controllerAs: 'ctrl',
  };
}]);
