/* eslint-env es6 */

angular.module('genius').component('songMissingMetadata', {
  templateUrl: 'song_missing_metadata.html',

  bindings: {
    song: '<',
    possible_missing_info: '<possibleMissingInfo',
  },

  controller() {
    this.has_missing_info = () => _.some(this.possible_missing_info, this.missing_info);
    this.missing_info = info => _.isEmpty(this.song[info]) && _.includes(this.possible_missing_info, info);
  },
});
