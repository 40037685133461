/* eslint-env es6 */

const REMOVED_STATES = ['accepted', 'integrated', 'rejected', 'marked_spam', 'archived'];

angular.module('genius').directive('comment', ['Session', 'CommentClient', 'Reversible', function(Session, CommentClient, Reversible) {
  return {
    restrict: 'E',
    templateUrl: 'comment.html',

    scope: {
      comment: '<object',
      variants: '<',
    },

    require: '?^^annotation',
    controllerAs: 'ctrl',
    controller() {},

    link(scope, _element, _attrs, annotation_ctrl) {
      const perform_delete_with = (promise, changes = {}) => {
        const reverse_count_change = annotation_ctrl ? annotation_ctrl.comment_removed() : undefined;
        const reverse = Reversible.modify(scope.comment, Object.assign(changes, {_deleted: true}));
        promise.catch(() => {
          if (reverse_count_change) reverse_count_change();
          reverse();
        });
      };

      scope.destroy = () => {
        perform_delete_with(CommentClient.destroy(scope.comment.id), {deleted_at: new Date().getTime()});
      };

      scope.integrate = () => {
        annotation_ctrl.integrate_comment(scope.comment);
        scope.perform_action('integrate', 'integrated');
      };

      scope.perform_action = (action_name, state_name) => {
        perform_delete_with(CommentClient.change_state(scope.comment.id, action_name), {state: state_name});
      };

      scope.can_perform = action_name => Session.has_permission(action_name, scope.comment);

      scope.toggle_voters = () => {
        scope.showing_voters = !scope.showing_voters;
      };

      scope.$on('vote_saved', (_e) => {
        scope.$broadcast('update_voters');
      });

      scope.is_removed = () =>
        scope.comment.deleted_at ||
          _.includes(REMOVED_STATES, scope.comment.state);
    },
  };
}]);
