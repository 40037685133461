/* eslint-env es6 */

angular.module('genius').component('profileArtistPane', {
  bindings: {
    artist: '<',
  },

  templateUrl: 'profile_artist_pane.html',

  controller: ['Session', 'ArtistClient', function(Session, ArtistClient) {
    this.$onInit = () => {
      this.user_signed_in = Session.user_signed_in;
      this.show_report_abuse = Session.has_feature('report_abuse');
    };

    this.$onChanges = (changes) => {
      if (changes.artist) {
        this.twitter_url = `https://twitter.com/${this.artist.twitter_name}`;
        this.facebook_url = `https://www.facebook.com/${this.artist.facebook_name}`;
        this.instagram_url = `https://instagram.com/${this.artist.instagram_name}`;
      }
    };

    this.get_leaderboard_page = params =>
      ArtistClient.load_leaderboard(this.artist.id, params);

    this.can = (permission, object) =>
      Session.has_permission(permission, object);

    this.change_state = state => this.state = state;
  }],
});
