/* eslint-env es6 */

angular.module('genius').directive('annotation', ['Session', 'Reversible', 'AnnotationClient', 'ReferentRegistry', 'ReferentClient', '$immediate', '$q', 'Bowser', 'I18n', 'AppConfig', function(Session, Reversible, AnnotationClient, ReferentRegistry, ReferentClient, $immediate, $q, Bowser, I18n, AppConfig) {
  return {
    restrict: 'E',
    templateUrl: 'annotation.html',

    scope: {
      annotation: '=object',
      referent: '<referent',
      variants: '<',
      annotatable_title: '<annotatableTitle',
      on_seek_to_next_card: '&onSeekToNextCard',
      yields_api: '&yieldsApi',
      on_pending_event: '&onPendingEvent',
      yields_iq: '&yieldsIq',
      on_dirty: '&onDirty',
      on_clean: '&onClean',
      focused_comments: '<focusedComments',
    },

    controllerAs: 'ctrl',

    controller: ['$scope', '$filter', function($scope, $filter) {
      this.hiding_comments = $scope.variants && $scope.variants.hideComments;
      this.user_signed_in = Session.user_signed_in;
      this.show_report_abuse = Session.has_feature('report_abuse');
      this.is_android = Bowser.android;
      this.truncation_length = 600;
      this.content_is_truncated = _.get($scope, 'variants.truncate_content') &&
        $scope.annotation.body.html.length > this.truncation_length;

      this.unreviewed_annotation_tooltip_info_url = AppConfig.unreviewed_annotation_tooltip_info_url;

      this.integrate_comment = (comment) => {
        $scope.start_editing({integration_body: comment.body});
      };

      this.update_editorial_action_count = () => {
        $scope.annotation.proposed_edit_count = this.edits_to_show_count();
        $scope.$emit('editorial_action_count_changed', {commentable: $scope.annotation});
      };

      $scope.$watch('annotation.comment_count', this.update_editorial_action_count);

      this.comment_removed = () =>
        Reversible.modify($scope.annotation, {comment_count: $scope.annotation.comment_count - 1});

      this.edits_to_show_count = () => {
        if (!$scope.annotation.edits) {
          return $scope.annotation.proposed_edit_count;
        }
        return $scope.annotation.edits.filter(edit => !edit._deleted && !edit._merged).length;
      };

      $scope.next_referent = (user) => {
        const condition = r => r.classification === 'verified' && _.includes(r.verified_annotator_ids, user.id);
        const options = {after_referent_id: $scope.annotation.referent_id};
        ReferentRegistry.first(condition, options);
      };

      this.seek_to_next_card = (user) => {
        $scope.on_seek_to_next_card({referent_id: $scope.next_referent(user).id});
      };

      this.has_more_by_user = user => !!$scope.next_referent(user);

      $scope.finish_editing = () => {
        if ($scope.disable_saving_with) return;
        $scope.disable_saving_with = 'Saving';

        let action;
        if (_.get($scope, 'annotation._editing.proposed_edit')) {
          action = AnnotationClient.propose_edit($scope.annotation).
            then((annotation_edit) => {
              $scope.annotation.edits = $scope.annotation.edits || [];
              $scope.annotation.edits.unshift(annotation_edit);
              this.showing_edits = true;
            });
        } else {
          action = AnnotationClient.save($scope.annotation, {referent: $scope.referent}).then(() => {
            ReferentClient.refresh($scope.referent);
          });
        }

        const restart_editing = Reversible.modify($scope.annotation, {'_editing._form_visible': !$scope.annotation.referent_id});
        action.then($scope.dismiss_editing).catch(restart_editing).finally(() => {
          $scope.disable_saving_with = null;
        });
      };

      $scope.cancel_editing = () => {
        $scope.dismiss_editing();

        Reflect.deleteProperty($scope.referent, 'temporary_classification');

        if ($scope.annotation.new) {
          _.remove($scope.referent.annotations, $scope.annotation);
        }

        if (!$scope.referent.annotations.length) {
          const deletion_promise = AnnotationClient.safe_delete($scope.annotation);
          $scope.$emit('annotation_deleted', $scope.annotation, deletion_promise);
        }
      };

      $scope.dismiss_editing = () => {
        Reflect.deleteProperty($scope.annotation, '_editing');
        $scope.$emit('layout_changed');
      };

      this.initialize_annotation_custom_preview = () => {
        $scope.annotation._editing._creating_custom_preview = true;
      };

      this.clear_annotation_custom_preview = () => {
        $scope.annotation._editing.custom_preview = null;
        $scope.annotation._editing._creating_custom_preview = false;
      };

      this.show_annotation_custom_preview_form = () => $scope.annotation._editing.custom_preview
        || $scope.annotation._editing._creating_custom_preview;

      this.report_as_abusive = () => {
        this.reported_abuse = true;
        return AnnotationClient.report_as_abusive($scope.annotation.id).
          catch(() => { this.reported_abuse = false; });
      };

      this.should_show_comments = () =>
        !$scope.rejecting &&
          !this.hiding_comments &&
          !$scope.annotation.new &&
          !$scope.annotation._editing &&
          ($scope.annotation.body.html || $scope.annotation.needs_exegesis);

      this.annotation_is_unwritten = () =>
        $scope.annotation.new || $scope.annotation.needs_exegesis || !_.get($scope, 'annotation.body.html');

      this.show_annotation_label = () => {
        if (!Session.user_signed_in && _.get($scope, 'referent.classification') === 'needs_exegesis') return false;

        const editing_form_visible = _.get($scope, 'annotation._editing._form_visible');

        const editing_or_loading = $scope.annotation.being_created || editing_form_visible;

        return $scope.annotation.community &&
          !editing_or_loading &&
          !this.annotation_is_being_created_by_someone_else();
      };

      this.show_placeholder_annotation_label = () => {
        const editing_form_visible = _.get($scope, 'annotation._editing._form_visible');

        return $scope.annotation.being_created &&
          !editing_form_visible &&
          !this.annotation_is_being_created_by_someone_else();
      };

      this.annotation_is_being_created_by_someone_else = () =>
        $scope.annotation.being_created &&
          (!Session.user_signed_in || Session.current_user.id !== $scope.annotation.created_by.id);

      this.annotation_title = () => {
        const is_description = $scope.variants && $scope.variants.isDescription && !$scope.variants.force_genius_annotation_title;

        const prefix = $scope.annotation.state === 'pending' ? 'Unreviewed ' : '';
        const brand = $scope.annotation.state === 'accepted' && !is_description ? 'Genius ' : '';
        const type = I18n.t(
          is_description ? 'sle.prependable_to_name' : 'annotation.singular_noun_capitalized',
          {type_name: $filter('modelHumanName')($scope.referent.annotatable._type)}
        );
        const truncated_title = $filter('truncateToBoundary')($scope.annotatable_title, 100);
        const song_title = is_description ? ` “${truncated_title}”` : '';

        return prefix + brand + type + song_title;
      };

      this.handle_toggle_comments = () => {
        this.hiding_comments = !this.hiding_comments;
        if (!this.user_signed_in) {
          this.prompt_auth = !this.prompt_auth;
        }
      };

      this.is_authored_by_current_user =
        () => _($scope.annotation.authors).some(author => author.user.id === Session.current_user.id);

      const api = {
        reject() {
          $scope.begin_rejection();
        },
        accept() {
          $scope.accept();
        },
      };
      $scope.yields_api({api});

      if (_.has($scope.annotation, 'current_user_metadata.iq_by_action')) {
        $scope.yields_iq({iq_api: $scope.annotation.current_user_metadata.iq_by_action});
      }
    }],

    link(scope) {
      scope.annotation_directive = scope;

      scope.can = action_name => Session.has_permission(action_name, scope.annotation);

      const add_cosign = () => {
        scope.annotation.cosigned_by.push(Session.current_user);
      };

      const remove_cosign = () => {
        _.remove(scope.annotation.cosigned_by, user => user.id === Session.current_user.id);
      };

      scope.toggle_cosign = (cosign) => {
        cosign ? add_cosign() : remove_cosign();
        scope.saving_cosign = true;
        AnnotationClient.toggle_cosign(scope.annotation.id, cosign).then((response) => {
          _.assign(scope.annotation, response.annotation);
          _.assign(scope.referent, response.referent);
        }).catch(cosign ? remove_cosign : add_cosign).finally(() => {
          scope.saving_cosign = false;
        });
      };

      scope.showing_contributors = false;

      scope.clear_votes = () => {
        scope.showing_voters = false;
        const revert = Reversible.modify(scope, {
          'annotation.votes_total': 0,
          'annotation.current_user_metadata.interactions.vote': undefined,
        });
        AnnotationClient.clear_votes(scope.annotation.id).catch(revert);
      };

      scope.toggle_voters = () => {
        scope.showing_voters = !scope.showing_voters;
      };

      scope.$on('images_loaded', () => {
        scope.loaded = true;
        scope.$emit('layout_changed');
      });

      scope.toggle_contributors = () => {
        scope.showing_contributors = !scope.showing_contributors;
      };

      scope.toggle_ellipsis_menu = () => {
        scope.showing_ellipsis_menu = !scope.showing_ellipsis_menu;
      };

      scope.start_editing = (options) => {
        options = options || {};
        scope.annotation._editing = _.defaults(scope.annotation._editing || {}, {
          _form_visible: true,
          _has_source: !!scope.annotation.source,
          source: angular.copy(scope.annotation.source || {}),
          proposed_edit: options.proposed_edit,
          html: scope.annotation.body.html,
          markdown: scope.annotation.body.markdown,
          _creating_custom_preview: false,
          custom_preview: scope.annotation.custom_preview,
        });

        if (options.integration_body) {
          scope.annotation._editing.html = _.compact([
            scope.annotation._editing.html,
            `PROPOSED SUGGESTION: ${options.integration_body.html}`,
          ]).join('<br>');

          scope.annotation._editing.markdown = _.compact([
            scope.annotation._editing.markdown,
            `PROPOSED SUGGESTION: ${options.integration_body.markdown}`,
          ]).join('\n\n');
        }

        if (scope.annotation.verified) scope.referent.temporary_classification = 'verified';
      };

      scope.accept = () => {
        const promise = AnnotationClient.accept(scope.annotation, {referent: scope.referent});
        scope.on_pending_event({event: 'UserAcceptAnnotation', result: promise});
        promise.then(() => {
          scope.context_updated_at = Date.now();
        });
      };

      scope.focus_comment_textarea = () => {
        scope.$broadcast('focus_comment_textarea', scope.annotation);
      };

      scope.begin_rejection = () => {
        scope.rejecting = true;
        $immediate(scope.focus_comment_textarea);
      };

      scope.reject_with_comment = (comment) => {
        scope.rejecting = false;
        scope.disable_saving_with = 'Saving';
        const promise = AnnotationClient.safe_reject(scope.annotation, {
          comment,
          referent: scope.referent,
        });
        scope.on_pending_event({event: 'UserRejectAnnotation', result: promise});
        promise.then(() => {
          scope.context_updated_at = Date.now();
        }).catch(() => {
          scope.rejecting = true;
          return $q.reject();
        }).finally(() => scope.disable_saving_with = null);
        scope.$emit('annotation_deleted', scope.annotation, promise);
        return promise;
      };

      scope.delete = () => {
        const promise = AnnotationClient.safe_delete(scope.annotation);
        scope.$emit('annotation_deleted', scope.annotation, promise);
      };

      scope.toggle_pin_status = () => {
        if (scope.toggling_pin_status) return;

        scope.toggling_pin_status = true;
        const method = scope.can('pin_to_profile') ? 'pin_to_profile' : 'unpin_from_profile';
        AnnotationClient[method](scope.annotation.id).then((result) => {
          _.assign(scope.annotation, result.annotation);
        }).finally(() => {
          scope.toggling_pin_status = false;
        });
      };

      scope.$on('vote_submitted', (_e, type, vote) => {
        if (vote === 'down' && type === 'annotation') scope.focus_comment_textarea();
      });

      scope.$on('vote_saved', (_e) => {
        scope.$broadcast('update_voters');
      });
    },
  };
}]);
