/* eslint-env es6 */

angular.module('genius').component('songAlbum', {
  templateUrl: 'song_album.html',

  bindings: {
    album: '<',
    tracks: '<',
    current_song: '<currentSong',
  },

  controller: ['Session', function(Session) {
    this.can = (permission, object) => Session.has_permission(permission, object);
    this.is_current_song = song => song.id === this.current_song.id;
    this.should_link_to_track = track => this.can('view_song', track) && !this.is_current_song(track.song);
  }],
});
