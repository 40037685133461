/* eslint-env es5 */
/* eslint-disable prefer-reflect */

angular.module('genius').component('splitArtistForm', {
  templateUrl: 'split_artist_form.html',

  bindings: {
    artist: '<',
  },

  controller: ['Session', 'ArtistClient', function(Session, ArtistClient) {
    this.singles = [{name: ''}, {name: ''}];

    this.can = (permission, object) => Session.has_permission(permission, object);

    this.remove_single = () => {
      this.singles.pop();
    };

    this.add_single = () => {
      this.singles.push({name: ''});
    };

    this.show_warning = true;
    this.split_artist = () => {
      if (!this.show_warning) {
        this.show_warning = true;
      } else {
        delete this.errors;
        delete this.artist_split_complete;
        if (this.saving) return;
        this.saving = true;

        const single_names = this.singles.map(s => s.name);
        ArtistClient.split(this.artist.id, single_names).then(() => {
          this.artist_split_complete = true;
        }).catch((error) => {
          this.errors = [error.data.meta.message];
        }).finally(() => {
          delete this.saving;
        });
      }
    };
  }],
});
