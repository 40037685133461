/* eslint-env es6 */

angular.module('genius').directive('slideDown', ['$immediate', 'Bindings', ($immediate, Bindings) => {
  const resolve_bindings = Bindings.resolver({
    no_slide_when: '<noSlideWhen',
    slide_down_callback: '&slideDownCallback',
  });

  return {
    restrict: 'A',

    link(scope, element, attributes) {
      const bindings = resolve_bindings(scope, attributes);
      if (!bindings.no_slide_when) {
        element.hide();

        const slide_down_with_callback = () => {
          element.slideDown(200, () => {
            bindings.slide_down_callback();
          });
        };

        $immediate(slide_down_with_callback, !'invoke_apply');
      }
    },
  };
}]);
