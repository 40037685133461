/* eslint-env es6 */

angular.module('genius').filter('timestamp_for_display', [function() {
  const left_zero_pad = (str, n) => '0000'.substring(0, n - str.toString().length) + str;

  return (ts, {skip_millis, always_millis} = {}) => {
    if (!ts && ts !== 0) { return ''; }
    const seconds = ts / 1000;
    let str = `${Math.floor(seconds / 60)}:${left_zero_pad(Math.floor(seconds % 60), 2)}`;
    const milli = Math.floor(ts % 1000);
    if (always_millis || (milli && !skip_millis)) {
      str += `.${left_zero_pad(milli, 3)}`;
    }
    return str;
  };
}]);
