/* eslint-env es6 */
angular.module('genius').factory('ReactSongPageService', [function() {
  return {
    reactSongPageRedirect:
      _.tap(new URL(window.location), (url) => {
        url.search = _.tap(new URLSearchParams(url.search),
          (params) => {
            params.delete('bagon');
            params.append('react', 1);
          }
        ).toString();
      }).toString(),
  };
}]);
