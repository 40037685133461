/* eslint-env es6 */

angular.module('genius').component('songMetadataSongRelationshipsInput', {
  templateUrl: 'song_metadata_song_relationships_input.html',

  bindings: {
    song_relationships: '<songRelationships',
    isDisabled: '<',
  },

  controller() {
    this.$onChanges = (changes) => {
      if (changes.song_relationships) {
        this.visible_relationships = _.filter(this.song_relationships, song_relationship => song_relationship.songs.length);
      }
    };

    this.available_relationships = () => _.difference(this.song_relationships, this.visible_relationships);

    this.change_relationship_to = (from_relationship, to_relationship) => {
      to_relationship.songs = from_relationship.songs;
      from_relationship.songs = [];
      this.visible_relationships[this.visible_relationships.indexOf(from_relationship)] = to_relationship;
    };

    this.remove_song_relationship = (song_relationship) => {
      song_relationship.songs = [];
      this.visible_relationships = _.without(this.visible_relationships, song_relationship);
    };

    this.add_new_song_relationship = () => {
      const available = this.available_relationships();
      if (available.length) {
        this.visible_relationships.push(available[0]);
      }
    };
  },
});
