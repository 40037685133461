/* eslint-env es6 */

angular.module('genius').component('lyricsExportPreview', {
  bindings: {
    song: '<',
  },

  templateUrl: 'lyrics_export_preview.html',

  controller: ['SongClient', 'Session', function(SongClient, Session) {
    this.can_perform = action_name =>
      Session.has_permission(action_name, this.song);

    this.should_show_modal = () => this._show_modal;

    this.show_modal = () => {
      this._show_modal = true;
      SongClient.preview_lyrics_for_export(this.song.id).
        then(preview_lyrics_for_export =>
          this.preview_lyrics_for_export = preview_lyrics_for_export);
    };

    this.hide_modal = () => {
      this._show_modal = false;
      this.preview_lyrics_for_export = null;
    };
  }],
});
