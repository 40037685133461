/* eslint-env es5 */
/* eslint-disable arrow-body-style, arrow-parens, arrow-spacing, constructor-super, generator-star-spacing, no-class-assign, no-confusing-arrow, no-const-assign, no-dupe-class-members, no-duplicate-imports, no-extra-bind, no-new-symbol, no-restricted-imports, no-this-before-super, no-useless-computed-key, no-useless-constructor, no-useless-rename, no-var, object-shorthand, prefer-arrow-callback, prefer-const, prefer-numeric-literals, prefer-reflect, prefer-rest-params, prefer-spread, prefer-template, require-yield, rest-spread-spacing, sort-imports, symbol-description, template-curly-spacing, yield-star-spacing, dot-location, key-spacing, max-len, new-parens, no-lonely-if, no-mixed-operators, no-trailing-spaces, no-whitespace-before-property, padded-blocks, quotes, space-infix-ops */

angular.module('genius').factory('parse_time_string', function() {
  return function(timestring) {
    if (!timestring) return null;
    const components = timestring.split(/\D/);

    if (components.length === 1) {
      return parseInt(components[0]);
    } else {
      const minutes = components[0] ? parseInt(components[0]) : 0;
      const seconds = components[1] ? parseInt(components[1]) : 0;
      const ms = components[2] ? parseInt((components[2] + '000').slice(0, 3)) : 0;

      return (minutes * 60 * 1000) + (seconds * 1000) + ms;
    }
  };
});
