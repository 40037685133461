/* eslint-env es6 */

angular.module('genius').directive('dirtyable', ['BeforeUnloadCheck', function(BeforeUnloadCheck) {
  return {
    restrict: 'A',

    require: 'form',

    link(scope, _element, attrs, controller) {
      const check = () => {
        scope.$apply(() => controller.$commitViewValue());
        return controller.$dirty;
      };

      const handlerForApplyCycle = () => {
        controller.$commitViewValue();
        return controller.$dirty;
      };

      scope.$eval(attrs.yieldsIsDirty, {is_dirty: handlerForApplyCycle});

      BeforeUnloadCheck.add(check);

      scope.$on('$destroy', () => BeforeUnloadCheck.remove(check));
    },
  };
}]);
