/* eslint-env es6 */

angular.module('genius').directive('removeClassOnAngularLoad', [function() {
  return {
    restrict: 'A',

    link(_scope, element, attrs) {
      element.removeClass(attrs.removeClassOnAngularLoad);
    },
  };
}]);

