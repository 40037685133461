/* eslint-env es6 */

angular.module('genius').component('coverArtModal', {
  templateUrl: 'cover_art_modal.html',

  bindings: {
    cover_artable: '<coverArtable',
    tiny_cover_arts: '<tinyCoverArts',
    focused_cover_art_id: '<focusedCoverArtId',
    type: '@',
    close: '&',
  },

  controller: ['CoverArtClient', 'Tracking', 'Session', 'SmartRouting', function(CoverArtClient, Tracking, Session, SmartRouting) {
    this.$onChanges = (changes) => {
      if (changes.type || changes.cover_artable) {
        Tracking.track('Cover Art View', {
          'Cover Artable Type': this.type,
          'Cover Artable ID': this.cover_artable.id,
          'Cover Art Count': this.tiny_cover_arts.length,
        });

        this.full_cover_arts = null;
        CoverArtClient.
          index(this.type, this.cover_artable.id).
          then(cover_arts => this.full_cover_arts = cover_arts);
      }
    };

    this.cover_arts = () => this.full_cover_arts || this.tiny_cover_arts;

    this.focused_cover_art = () =>
      _.find(this.cover_arts(), ca => ca.id === this.focused_cover_art_id);

    this.focused_cover_art_index = () =>
      _.findIndex(this.cover_arts(), ca => ca.id === this.focused_cover_art_id);

    this.move_cover_art_focus = (change) => {
      const next_index = this.focused_cover_art_index() + change;
      const next_url = _.get(this.cover_arts(), [next_index, 'url']);
      SmartRouting.navigate_to_absolute_url(next_url);
    };

    this.user_signed_in = Session.user_signed_in;
  }],
});
