/* eslint-env es5 */
/* eslint-disable arrow-body-style, arrow-parens, arrow-spacing, constructor-super, generator-star-spacing, no-class-assign, no-confusing-arrow, no-const-assign, no-dupe-class-members, no-duplicate-imports, no-extra-bind, no-new-symbol, no-restricted-imports, no-this-before-super, no-useless-computed-key, no-useless-constructor, no-useless-rename, no-var, object-shorthand, prefer-arrow-callback, prefer-const, prefer-numeric-literals, prefer-reflect, prefer-rest-params, prefer-spread, prefer-template, require-yield, rest-spread-spacing, sort-imports, symbol-description, template-curly-spacing, yield-star-spacing, dot-location, key-spacing, max-len, new-parens, no-lonely-if, no-mixed-operators, no-trailing-spaces, no-whitespace-before-property, padded-blocks, quotes, space-infix-ops */

angular.module('genius').factory('ScrollPoint', ['$window', function($window) {
  var ScrollPoint = {};

  var points = {};

  ScrollPoint.register = function(key, element) {
    if (ScrollPoint.has_point(key)) {
      points[key].push(element);
    }
    points[key] = [element];
  };

  ScrollPoint.unregister = function(key, element) {
    if (ScrollPoint.has_point(key)) {
      points[key] = _.without(points[key], element);
      if (!points[key].length) {
        delete points[key];
      }
    }
  };

  ScrollPoint.scroll_to = function(key) {
    $window.requestAnimationFrame(function() {
      if (ScrollPoint.has_point(key)) {
        $('html, body').animate({scrollTop: points[key][0].offset().top});
      }
    });
  };

  ScrollPoint.has_point = function(key) {
    return !!points[key];
  };

  return ScrollPoint;
}]);
