/* eslint-env es6 */

angular.module('genius').directive('inboxLineItemActionIcon', [function() {
  return {
    scope: {
      action_name: '<actionName',
    },

    templateUrl: 'inbox_line_item_action_icon.html',
  };
}]);
