import 'mixpanel';
/* eslint-env es6 */


angular.module('genius').factory('Tracking', ['$window', 'AbTest', '$timeout', function($window, AbTest, $timeout) {
  const tracking = $window.app.tracking;
  const ab_test_promise = AbTest.get_ab_tests().then((ab_tests) => {
    const properties = {};
    _.forIn(ab_tests, (variant, ab_test) => {
      properties[`AB Test - ${ab_test}`] = variant;
    });
    tracking.register(properties);
  });

  const SECONDS_TO_WAIT_FOR_ATTACHMENTS = 3;
  const delayed_events = [];
  _.assign(tracking, {
    delayed_track(event, properties = {}) {
      delayed_events.push({event, properties});
      ab_test_promise.finally(() => $timeout(() => {
        tracking.track(event, properties);
        _.remove(delayed_events, {event});
      }, SECONDS_TO_WAIT_FOR_ATTACHMENTS * 1000));
    },

    attach_to_delayed_track(attachment, properties) {
      _.forEach(delayed_events, (delayed_event) => {
        _.forEach(properties, (value, property) => {
          delayed_event.properties[`${attachment} - ${property}`] = value;
        });
      });
    },
  });
  return tracking;
}]);
