/* eslint-env es6 */

angular.module('genius').directive('sizedSrc', ['ImageTransform', '$window', function(ImageTransform, $window) {
  return {
    restrict: 'A',

    scope: {
      sizedSrc: '<',
    },

    link: (scope, element) => {
      scope.$watch('sizedSrc', (original) => {
        element.css('width', '100%');
        const width = element.width() * ($window.devicePixelRatio || 1);
        element.attr('src', ImageTransform.resize(original, {width}));
      });
    },
  };
}]);
