/* eslint-env es6 */

angular.module('genius').component('editableTracklistNumber', {
  bindings: {
    track_number: '<trackNumber',
    on_update: '&onUpdate',
  },

  templateUrl: 'editable_tracklist_number.html',

  controller() {
    this.$onChanges = (changes) => {
      if (changes.track_number) {
        this.reset();
      }
    };

    this.submit = () => {
      this.on_update({input: this.editable_track_number});
      this.reset();
    };

    this.reset = () => {
      this.editing = false;
      this.editable_track_number = this.track_number;
    };
  },
});
