/* eslint-env es6 */

angular.module('genius').component('collapsibleContent', {
  templateUrl: 'collapsible_content.html',

  transclude: true,

  bindings: {
    maxCollapsedHeight: '<',
    minExpansionHeight: '<',
    text: '@',
    auto_expand: '<autoExpand',
  },

  controller: [function() {
    this.expand = function() {
      Reflect.deleteProperty(this, 'max_height');
    };

    this.content_height_changed = (content_height) => {
      if (content_height > this.maxCollapsedHeight + this.minExpansionHeight) {
        this.max_height = this.maxCollapsedHeight;
      }
      if (this.auto_expand) this.expand();
    };
  }],
});
