/* eslint-env es5 */
/* eslint-disable arrow-body-style, arrow-parens, arrow-spacing, constructor-super, generator-star-spacing, no-class-assign, no-confusing-arrow, no-const-assign, no-dupe-class-members, no-duplicate-imports, no-extra-bind, no-new-symbol, no-restricted-imports, no-this-before-super, no-useless-computed-key, no-useless-constructor, no-useless-rename, no-var, object-shorthand, prefer-arrow-callback, prefer-const, prefer-numeric-literals, prefer-reflect, prefer-rest-params, prefer-spread, prefer-template, require-yield, rest-spread-spacing, sort-imports, symbol-description, template-curly-spacing, yield-star-spacing, dot-location, key-spacing, max-len, new-parens, no-lonely-if, no-mixed-operators, no-trailing-spaces, no-whitespace-before-property, padded-blocks, quotes, space-infix-ops */

angular.module('genius').factory('SpotifyClient', ['$http', function($http) {
  var find_token = function() {
    return $('[data-spotify-access-token]').attr('data-spotify-access-token');
  };

  return {
    load_artist_metadata: function(uuid) {
      var url = 'https://api.spotify.com/v1/artists/' + uuid;
      var token = find_token();
      if (token) url += '?access_token=' + token;

      return $http.get(url).then(function(response) {
        return response.data;
      });
    },
  };
}]);
