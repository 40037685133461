/* eslint-env es5 */
/* eslint-disable arrow-body-style, arrow-parens, arrow-spacing, constructor-super, generator-star-spacing, no-class-assign, no-confusing-arrow, no-const-assign, no-dupe-class-members, no-duplicate-imports, no-extra-bind, no-new-symbol, no-restricted-imports, no-this-before-super, no-useless-computed-key, no-useless-constructor, no-useless-rename, no-var, object-shorthand, prefer-arrow-callback, prefer-const, prefer-numeric-literals, prefer-reflect, prefer-rest-params, prefer-spread, prefer-template, require-yield, rest-spread-spacing, sort-imports, symbol-description, template-curly-spacing, yield-star-spacing, dot-location, key-spacing, max-len, new-parens, no-lonely-if, no-mixed-operators, no-trailing-spaces, no-whitespace-before-property, padded-blocks, quotes, space-infix-ops */

angular.module('genius').directive('proxiedImage', function() {
  return {
    restrict :'A',
    require:'ngModel',
    link : function(_scope, _element, _attrs, modelCtrl) {
      modelCtrl.$formatters.push(function formatter(modelValue) {
        if (!modelValue) return '';

        var obj = {};
        try {
          var url = new URL(modelValue);

          if (url.hostname.match(/\.embed\.ly$/)) {
            _.each(url.search.substr(1).split('&'), function(pair) {
              var split = pair.split('=');
              obj[decodeURIComponent(split[0])] = decodeURIComponent(split[1]);
            });
          }
          return obj.url || modelValue;
        } catch (_e) {
          return modelValue;
        }
      });
    },
  };
});
