/* eslint-env es5 */
/* eslint-disable arrow-body-style, arrow-parens, arrow-spacing, constructor-super, generator-star-spacing, no-class-assign, no-confusing-arrow, no-const-assign, no-dupe-class-members, no-duplicate-imports, no-extra-bind, no-new-symbol, no-restricted-imports, no-this-before-super, no-useless-computed-key, no-useless-constructor, no-useless-rename, no-var, object-shorthand, prefer-arrow-callback, prefer-const, prefer-numeric-literals, prefer-reflect, prefer-rest-params, prefer-spread, prefer-template, require-yield, rest-spread-spacing, sort-imports, symbol-description, template-curly-spacing, yield-star-spacing, dot-location, key-spacing, max-len, new-parens, no-lonely-if, no-mixed-operators, no-trailing-spaces, no-whitespace-before-property, padded-blocks, quotes, space-infix-ops */

angular.module('genius').component('recentSignupsPage', {
  templateUrl: 'recent_signups_page.html',

  controller: ['UserClient', 'Attempt', 'TimestampedDataFeed', 'I18n', function(UserClient, Attempt, TimestampedDataFeed, I18n) {
    var mark_spam_state = function(state, user_spam_evaluation) {
      user_spam_evaluation.marking_spam_state = true;
      UserClient.update_spam_state(user_spam_evaluation.user.id, {state: state}).then(function() {
        user_spam_evaluation.marked_spam_state = true;
      }.bind(this)).finally(function() {
        delete user_spam_evaluation.marking_spam_state;
      });
    }.bind(this);

    this.mark = function(state, user_spam_evaluation) {
      Attempt.admin_action(function() {
        mark_spam_state(state, user_spam_evaluation);
      }, I18n.t('mark_' + state + '.confirm'));
    };

    this.filter_by_spam_state = function(spam_state) {
      if (this.filtering_spam_state !== spam_state) {
        this.filtering_spam_state = spam_state;

        this.recent_signups_scrollable_data = new TimestampedDataFeed(
          _.partial(UserClient.load_recent_signups, spam_state === 'unclassified_spam_state' ? '' : spam_state),
          'user_spam_evaluations'
        );
      }
    };

    this.filter_by_spam_state('unclassified_spam_state');
  }],
});
