/* eslint-env es6 */

angular.module('genius').component('spamStatus', {
  templateUrl: 'spam_status.html',

  bindings: {
    user: '<',
  },

  controller: ['UserClient', function(UserClient) {
    this.$onChanges = (changes) => {
      if (changes.user) {
        UserClient.spam_status(this.user.id).then((response) => {
          this.user_spam_evaluation = response.user_spam_evaluation;
        });
      }
    };
  }],
});
