/* eslint-env es6 */

angular.module('genius').component('annotationShareMenu', {
  templateUrl: 'annotation_share_menu.html',

  bindings: {
    annotation: '<',
    variants: '<',
  },
});
