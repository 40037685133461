/* eslint-env es6 */

angular.module('genius').factory('MarkdownPreviewClient', ['$http', 'AppConfig', function($http, AppConfig) {
  return {
    create_preview(markdown) {
      return $http.post(
        `${AppConfig.api_root_url}/markdown_preview`,
        {markdown}
      ).then(response => response.data.response.html);
    },
  };
}]);
