/* eslint-env es5 */
/* eslint-disable arrow-body-style, arrow-parens, arrow-spacing, constructor-super, generator-star-spacing, no-class-assign, no-confusing-arrow, no-const-assign, no-dupe-class-members, no-duplicate-imports, no-extra-bind, no-new-symbol, no-restricted-imports, no-this-before-super, no-useless-computed-key, no-useless-constructor, no-useless-rename, no-var, object-shorthand, prefer-arrow-callback, prefer-const, prefer-numeric-literals, prefer-reflect, prefer-rest-params, prefer-spread, prefer-template, require-yield, rest-spread-spacing, sort-imports, symbol-description, template-curly-spacing, yield-star-spacing, dot-location, key-spacing, max-len, new-parens, no-lonely-if, no-mixed-operators, no-trailing-spaces, no-whitespace-before-property, padded-blocks, quotes, space-infix-ops */

angular.module('genius').factory('ReferentHelper', ['Session', function(Session) {
  var create_annotation_placeholder = function(referent) {
    return {
      _editing: {_form_visible: true},
      new: true,
      referent_id: referent.id,
      body: {markdown: '', html: ''},
      authors: [{
        user: Session.current_user,
        pinned_role: referent.current_user_metadata.relationships.pinned_role,
      }],
      created_at: parseInt(Date.now()/1000),
      needs_exegesis: referent.annotations.length === 0,
    };
  };

  return {
    display_mode_by_classification: function(classification) {
      if (_.includes(['verified', 'cosigned'], classification)) return 'green';
      if (classification === 'unreviewed' && Session.has_permission('see_unreviewed_referents', Session.current_user)) return 'red';
      return 'yellow';
    },

    create_verified_annotation_placeholder: function(referent) {
      return _.assign(create_annotation_placeholder(referent), {
        verified: true,
      });
    },

    create_community_annotation_placeholder: function(referent) {
      return _.assign(create_annotation_placeholder(referent), {
        id: referent.id,
      });
    },

    range_or_contents_intersects_links_or_contains_images: function(range) {
      var contents = range.cloneContents();
      if (contents.querySelectorAll('img').length) return true;
      return !!(contents.querySelectorAll('a').length ||
        $(range.startContainer).closest('a').length ||
        $(range.endContainer).closest('a').length);
    },
  };
}]);
