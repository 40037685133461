/* eslint-env es6 */

angular.module('genius').factory('RetryFailureInterceptor', ['$injector', '$q', function($injector, $q) {
  return {
    responseError(response) {
      const config = response.config;

      if (config.retries && config.retries > 0) {
        config.retries -= 1;
        return $injector.get('$http')(config);
      }

      return $q.reject(response);
    },
  };
}]);
