/* eslint-env es6 */

angular.module('genius').component('solidarityBanner', {
  templateUrl: 'solidarity_banner.html',

  controller: ['Cookies', 'AppConfig', function(Cookies, AppConfig) {
    const cookie_name = `solidarity_flag_${AppConfig.solidarity_text}_${AppConfig.solidarity_url}`;
    this.solidarity_text = AppConfig.solidarity_text;
    this.solidarity_url = AppConfig.solidarity_url;
    this.is_link_external = (new URL(AppConfig.solidarity_url)).host !== AppConfig.canonical_domain;

    this.$onInit = () => {
      const seen = Cookies.get(cookie_name);

      if (AppConfig.solidarity_text && !seen) {
        this.visible = true;
      }
    };

    this.hide = () => {
      this.visible = false;
      Cookies.put(cookie_name, Date.now(), {path: '/'});
    };
  }],
});
