/* eslint-env es6 */

angular.module('genius').component('adminTask', {
  bindings: {
    task: '<',
  },

  templateUrl: 'admin_task.html',

  controller: ['AdminTaskClient', function(AdminTaskClient) {
    this.$onChanges = (changes) => {
      this.property_values = _.reduce(changes.task.currentValue.task_properties, (values, property) => {
        if (property.type === 'date') {
          values[property.name] = new Date(property.default);
        } else {
          values[property.name] = property.default;
        }
        return values;
      }, {});
    };

    this.is_url = value => /^https?:\/\/\S+$/.test(value);

    this.perform_task = () => {
      if (this.task.confirmation && !confirm(this.task.confirmation)) return;

      this.validation_errors = null;
      this.success_message = null;
      this.tabular_data = null;
      this.saving = true;

      AdminTaskClient.perform_task(this.task, this.property_values).then((response) => {
        this.success_message = response.admin_task_result.success_message;
        this.tabular_data = response.admin_task_result.tabular_data;
        this.record_data = response.admin_task_result.record_data;
      }).catch((api_error) => {
        if (api_error.response.validation_errors) {
          this.validation_errors = api_error.response.validation_errors;
        } else {
          this.validation_errors = {base: ['Something unexpected went wrong']};
        }
      }).finally(() => this.saving = false);
    };
  }],
});
