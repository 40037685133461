/* eslint-env es6 */

angular.module('genius').filter('truncateToBoundary', [function() {
  const ellipsis = '…';
  const separator = ' ';

  return (input, num, {with_ellipsis} = {}) => {
    if (!input) return;

    num = with_ellipsis ? num - ellipsis.length : num;

    let trimmed = input.substr(0, num);

    if (input[num] && input[num] !== separator) {
      const last_space_idx = trimmed.lastIndexOf(separator);
      if (last_space_idx !== -1) {
        trimmed = trimmed.substr(0, last_space_idx);
      }
    }

    if (with_ellipsis && trimmed.length < input.length) trimmed += ellipsis;

    return trimmed;
  };
}]);
