/* eslint-env es6 */

angular.module('genius').directive('linksNavigableByArrowKeys', ['$immediate', 'Mousetrap', 'Bindings', function($immediate, Mousetrap, Bindings) {
  const resolve_bindings = Bindings.resolver({
    yields_refresh: '&linksNavigableByArrowKeys',
  });

  return {
    restrict: 'A',

    link(scope, element, attrs) {
      const bindings = resolve_bindings(scope, attrs);

      let links;
      let current_link_index = null;
      const refresh = (reset_index = true) => {
        $immediate(() => {
          links = element.find('a:visible');
          if (reset_index) {
            current_link_index = null;
          }
        }, false);
      };
      refresh();
      bindings.yields_refresh({refresh});

      const down_handler = () => {
        if (!links.length || current_link_index >= links.length - 1) return;
        if (_.isNull(current_link_index)) {
          current_link_index = 0;
        } else {
          current_link_index++;
        }
        links[current_link_index].focus();
        return false;
      };
      Mousetrap.bindGlobal('down', down_handler);
      scope.$on('$destroy', () => Mousetrap.unbind('down'));

      const up_handler = () => {
        if (!links.length || _.isNull(current_link_index) || current_link_index === 0) return;
        current_link_index--;
        links[current_link_index].focus();
        return false;
      };
      Mousetrap.bindGlobal('up', up_handler);
      scope.$on('$destroy', () => Mousetrap.unbind('up'));
    },
  };
}]);
