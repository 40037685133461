/* eslint-env es6 */

angular.module('genius').component('lyricsEditProposalForm', {
  bindings: {
    lyrics: '<',
    song: '<',
  },

  templateUrl: 'lyrics_edit_proposal_form.html',

  controller: ['LyricsEditProposal', 'LyricsEditProposalClient', 'Tracking', '$window', function(LyricsEditProposal, LyricsEditProposalClient, Tracking, $window) {
    this.$onInit = () => {
      Tracking.track('lyrics_edit_proposals:start_proposal', {properties: {edit_format: 'markdown'}});
    };

    this.$onChanges = (changes) => {
      if (changes.lyrics) {
        this.editable = {
          markdown: this.lyrics.body.plain,
          before: this.lyrics.body.plain,
        };
      }
    };

    this.propose = () => {
      if (this.saving) return;

      this.errors = null;

      if (this.editable.markdown === this.editable.before) {
        this.proposed = true;
        return;
      }

      this.saving = true;
      LyricsEditProposalClient.create({
        song_id: this.song.id,
        version: this.lyrics.version,
        edited_lyrics: this.editable.markdown,
      }).then(() => {
        this.proposed = true;
        Tracking.track('lyrics_edit_proposals:save_proposal', {properties: {edit_format: 'plain_text'}});
      }).catch((error) => {
        this.errors = error.response.errors || [error.message];
      }).finally(() => {
        $window.scrollTo(0, 0);
        this.saving = false;
      });
    };

    this.back = () => {
      if (!this.proposed) {
        Tracking.track('lyrics_edit_proposals:cancel_proposal', {properties: {edit_format: 'plain_text'}});
      }
      $window.history.back();
    };

    this.toggle_preview = () => {
      this.previewing = !this.previewing;
      if (this.previewing) {
        const diff = LyricsEditProposal.diff(this.editable.before, this.editable.markdown);
        this.diff_html = LyricsEditProposal.pretty_diff(diff);
      }
    };
  }],
});
