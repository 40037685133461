/* eslint-env es6 */

angular.module('genius').directive('localReferentRouting', () => ({
  controller: ['$scope', '$location', 'Tracking', function($scope, $location, Tracking) {
    $scope.$on('$locationChangeSuccess', () => {
      let permalinked_referent_id = Number($location.search().referent_id);

      if (!permalinked_referent_id) {
        const matched = $location.hash().match(/^note-(\d+)$/);
        if (matched) {
          permalinked_referent_id = matched[1];
        }
      }

      const path = $location.path();

      if (permalinked_referent_id) {
        Tracking.set_annotation_opened_from_permalink(permalinked_referent_id);
        $scope.song_ctrl.scroll_to_referent(permalinked_referent_id).then(() => {
          $scope.song_ctrl.update_url_for_open_referent(permalinked_referent_id);
          $location.replace();
        });
      } else if (path === $scope.song.path) {
        $scope.current_referent = null;
      } else {
        const fragment_id = Number(path.slice(1));
        $scope.song_ctrl.display_referent(fragment_id);
      }
    });
  }],
}));
