/* eslint-env es6 */

angular.module('genius').directive('followButton', ['FollowClient', 'Session', 'Tracking', 'Reversible', function(FollowClient, Session, Tracking, Reversible) {
  const following_property_path = 'object.current_user_metadata.interactions.following';

  return {
    restrict: 'E',

    scope: {
      onUnauthorized: '&',
      object: '<',
      type: '@',
      variants: '<',
      disabled: '<',
    },

    templateUrl: 'follow_button.html',

    link(scope) {
      let saving;

      scope.user_signed_in = Session.user_signed_in;

      scope.$watch(following_property_path, (value) => {
        scope.following = !!value;
      });

      scope.label = () => scope.hoverLabel || (scope.following ? 'Following' : 'Follow');

      scope.toggle_following = () => {
        if (!Session.user_signed_in) return scope.onUnauthorized();
        if (saving) return;
        saving = true;

        const modifications = {};
        modifications[following_property_path] = !scope.following;
        const revert = Reversible.modify(scope, modifications);

        Tracking.track(scope.following ? 'Follow Button' : 'Unfollow Button');
        FollowClient.set_following(scope.type, scope.object.id, !scope.following).catch(revert).finally(() => {
          scope.hoverLabel = null;
          saving = false;
        });
      };

      scope.can_follow = () => Session.has_permission('follow', scope.object);
    },
  };
}]);
