/* eslint-env es6 */

angular.module('genius').directive('scrollOnlyThisElement', [function() {
  return {
    restrict: 'A',

    link(_scope, element) {
      element.on('mousewheel', (event) => {
        event.preventDefault();
        const direction = (event.detail < 0 || event.wheelDelta > 0) ? 1 : -1;
        element.scrollTop(element.scrollTop() + (direction * event.deltaFactor * event.deltaY));
      });
    },
  };
}]);
