/* eslint-env es6 */

angular.module('genius').component('youtubeSubscribeButton', {
  templateUrl: 'youtube_subscribe_button.html',

  controller: ['Google', '$element', function(Google, $element) {
    this.$postLink = () => {
      Google.setup().then(() => {
        Google.refresh_youtube_subscribe_in($element[0]);
      });
    };
  }],
});
