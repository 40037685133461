/* eslint-env es6 */

angular.module('genius').directive('songMetadata', [function() {
  return {
    templateUrl: 'song_metadata.html',

    restrict: 'E',

    scope: {
      song: '<',
    },

    controllerAs: 'ctrl',

    controller: ['$scope', 'Session', function($scope, Session) {
      this.has_permission = permission => Session.has_permission(permission, $scope.song);
    }],
  };
}]);
