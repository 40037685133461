/* eslint-env es6 */

angular.module('genius').factory('MentionClient', ['$http', 'AppConfig', function($http, AppConfig) {
  return {
    autocomplete(query) {
      return $http.get(
        `${AppConfig.api_root_url}/mentions`,
        {params: {q: query}}
      ).then(response => response.data.response.mentions);
    },
  };
}]);
