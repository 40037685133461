/* eslint-env es6 */

angular.module('genius').component('songStoryFeaturedStoryPlacement', {
  bindings: {
    story: '<',
    label: '@',
    variants: '<',
  },

  templateUrl: 'song_story_featured_story_placement.html',
});
