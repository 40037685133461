/* eslint-env es6 */

angular.module('genius').factory('ReferentLocator', ['$document', 'AppConfig', 'string_to_symbol_array', 'LyricsEditing', 'MarkdownPlaintextFinderRegex', function($document, AppConfig, string_to_symbol_array, LyricsEditing, MarkdownPlaintextFinderRegex) {
  const CONTEXT_LENGTH = AppConfig.annotator_context_length;

  const valid_text_chars = text => text.replace(/\s+/g, '');

  const range_selecting_element = (container) => {
    const range = $document[0].createRange();
    range.selectNodeContents(container);
    return range;
  };

  const remove_unselectable = (range) => {
    const cloned_range_contents = range.cloneContents();
    $(cloned_range_contents).find('[avoid-selection]').remove();
    return range_selecting_element(cloned_range_contents);
  };

  const get_previous_context = (range, container) => {
    const previous_range = range_selecting_element(container);
    previous_range.setEnd(range.startContainer, range.startOffset);

    return valid_text_chars(remove_unselectable(previous_range).toString());
  };

  const get_next_context = (range, container) => {
    const next_range = range_selecting_element(container);
    next_range.setStart(range.endContainer, range.endOffset);

    return valid_text_chars(next_range.toString());
  };

  const get_occurrence_in_markdown = (range, container) => {
    const preceding_range = range_selecting_element(container);
    preceding_range.setEnd(range.startContainer, range.startOffset);

    const preceding_range_html = $(preceding_range.cloneContents().children).html();
    const editable = LyricsEditing.prepare_lyrics_for_editing(preceding_range_html);
    const occurrence_matcher = MarkdownPlaintextFinderRegex(range.toString());

    return (editable.markdown.match(occurrence_matcher) || []).length;
  };

  const calculate_referent_location = (range, container) => {
    const all_previous_context = string_to_symbol_array(get_previous_context(range, container));
    const previous_context = all_previous_context.slice(-CONTEXT_LENGTH);

    const all_next_context = string_to_symbol_array(get_next_context(range, container));
    const next_context = all_next_context.slice(0, CONTEXT_LENGTH);

    return {
      previous_context: previous_context.join(''),
      text: range.toString(),
      next_context: next_context.join(''),
      total_offset: all_previous_context.length,
      occurrence_in_markdown: get_occurrence_in_markdown(range, container),
    };
  };

  const calculate_image_location = (container, image) => ({
    image_url: image.src,
    occurrence: $(container).find(`img[src="${image.src}"]`).index(image) + 1,
  });

  return {
    calculate_referent_location,
    calculate_image_location,
  };
}]);
