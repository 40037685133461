/* eslint-env es6 */

angular.module('genius').directive('preload', ['PreloadCache', function(PreloadCache) {
  return {
    restrict: 'E',

    priority: 10000,

    link(scope, element) {
      const data = JSON.parse(element.find('preload-content').attr('data-preload_data'));
      angular.forEach(data, (value, key) => {
        PreloadCache[key] = scope[key] = value;
      });

      element.remove();
    },
  };
}]);
