/* eslint-env es6 */

angular.module('genius').component('twitterShareButton', {
  templateUrl: 'twitter_share_button.html',

  bindings: {
    url: '<',
    message: '<',
    variants: '<',
  },

  controller: ['Twitter', 'Tracking', function(Twitter, Tracking) {
    this.share_on_twitter = () => {
      Tracking.track('Bagon Twitter Share');
      Twitter.share({url: this.url, message: this.message});
    };
  }],
});
