import 'pusher-js/dist/web/pusher';
/* eslint-env es5 */
/* eslint-disable arrow-body-style, arrow-parens, arrow-spacing, constructor-super, generator-star-spacing, no-class-assign, no-confusing-arrow, no-const-assign, no-dupe-class-members, no-duplicate-imports, no-extra-bind, no-new-symbol, no-restricted-imports, no-this-before-super, no-useless-computed-key, no-useless-constructor, no-useless-rename, no-var, object-shorthand, prefer-arrow-callback, prefer-const, prefer-numeric-literals, prefer-reflect, prefer-rest-params, prefer-spread, prefer-template, require-yield, rest-spread-spacing, sort-imports, symbol-description, template-curly-spacing, yield-star-spacing, dot-location, key-spacing, max-len, new-parens, no-lonely-if, no-mixed-operators, no-trailing-spaces, no-whitespace-before-property, padded-blocks, quotes, space-infix-ops */


angular.module('genius').factory('Pusher', ['$window', '$rootScope', 'AppConfig', function($window, $rootScope, AppConfig) {
  var channels = {};

  var _pusher;
  var pusher = function() {
    if (!_pusher) _pusher = new $window.Pusher(AppConfig.pusher_app_key);
    return _pusher;
  };

  return {
    pusher: pusher,
    subscribe: function(channel, event_name, callback) {
      if (!channels[channel]) {
        channels[channel] = {};
        pusher().subscribe(channel).bind_all(function(event, data) {
          var listeners = channels[channel][event];
          if (listeners) {
            $rootScope.$applyAsync(function() {
              listeners.forEach(function(l) { l(data); });
            });
          }
        });
      }

      channels[channel][event_name] = channels[channel][event_name] || [];
      channels[channel][event_name].push(callback);
    },
  };
}]);
