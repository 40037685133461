/* eslint-env es6 */

angular.module('genius').directive('sticky', ['Waypoint', '$immediate', 'Bindings', function(Waypoint, $immediate, Bindings) {
  const resolve_bindings = Bindings.resolver({
    'sticky': '<',
  });

  return {
    restrict: 'A',

    link(scope, element, attrs) {
      const bindings = resolve_bindings(scope, attrs);
      const stuck_class = attrs.stickyClass;
      let wrapper;

      if ('stickyUseParentAsWrapper' in attrs) {
        wrapper = false;
      } else {
        const wrapper_class = attrs.stickyWrapperClass || 'sticky_wrapper';
        wrapper = `<div class="${wrapper_class}">`;
      }

      let stuck = false;
      $immediate(() => {
        const conditional_sticky = typeof bindings.sticky !== 'undefined';

        const sticky_waypoint = new Waypoint.Sticky({
          element,
          wrapper,
          stuckClass: stuck_class,
          offset: () => attrs.stickyOffset || '0',
          handler: () => stuck = !stuck,
          enabled: !conditional_sticky,
        });

        if (conditional_sticky) {
          scope.$watch(() => bindings.sticky, () => {
            bindings.sticky ? sticky_waypoint.waypoint.enable() : sticky_waypoint.waypoint.disable();
          });
        }
      }, !'invoke_apply');
    },
  };
}]);
