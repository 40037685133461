/* eslint-env es5 */
/* eslint-disable arrow-body-style, arrow-parens, arrow-spacing, constructor-super, generator-star-spacing, no-class-assign, no-confusing-arrow, no-const-assign, no-dupe-class-members, no-duplicate-imports, no-extra-bind, no-new-symbol, no-restricted-imports, no-this-before-super, no-useless-computed-key, no-useless-constructor, no-useless-rename, no-var, object-shorthand, prefer-arrow-callback, prefer-const, prefer-numeric-literals, prefer-reflect, prefer-rest-params, prefer-spread, prefer-template, require-yield, rest-spread-spacing, sort-imports, symbol-description, template-curly-spacing, yield-star-spacing, dot-location, key-spacing, max-len, new-parens, no-lonely-if, no-mixed-operators, no-trailing-spaces, no-whitespace-before-property, padded-blocks, quotes, space-infix-ops */

angular.module('genius').directive('simpleDatetimeInput', ['$filter', function($filter) {
  return {
    restrict: 'E',

    scope: {},

    require: 'ngModel',

    templateUrl: 'simple_datetime_input.html',

    controller: ['$scope', function($scope) {
      $scope.datetime = {};

      var current_year = new Date().getFullYear();
      _.assign($scope, {
        years: _.range(1900, current_year + 100).reverse(),
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].map(function(month, index) {
          return {name: month, number: index + 1};
        }),
        days: _.range(1, 32),
        hours: _.range(0, 24),
        minutes: _.range(0, 60),
      });
    }],

    link: function(scope, _a, _b, ngModel) {
      var cast_to_date = $filter('cast_to_date');

      ngModel.$render = function() {
        var t = cast_to_date(ngModel.$modelValue) || (new Date());
        scope.datetime = {year: t.getFullYear(), month: t.getMonth() + 1, day: t.getDate(), hour: t.getHours(), minute: t.getMinutes()};
      };

      ngModel.$render();

      scope.$watch(function() {
        if (!scope.datetime) return;
        if (_.some(scope.datetime, function(val) { return val === undefined; })) return;
        return scope.datetime.year + '/' + scope.datetime.month + '/' + scope.datetime.day + ' ' + scope.datetime.hour + ':' + scope.datetime.minute;
      }, function(value) {
        if (value) ngModel.$setViewValue(value);
      });
    },
  };
}]);
