/* eslint-env es6 */

angular.module('genius').factory('QuestionClient', ['$http', '$q', 'AppConfig', 'Reversible', 'ApiClient', 'Session', function($http, $q, AppConfig, Reversible, ApiClient, Session) {
  return {
    index(questionable_type, questionable_id, params) {
      params['text_format'] = Session.user_signed_in ? 'html,markdown' : 'html';
      params[`${questionable_type}_id`] = questionable_id;
      return ApiClient.get('/questions', params);
    },

    create_if_not_exists(question) {
      if (!question.id) {
        return this.create(question.questionable_type, question.questionable_id, question);
      } else {
        return $q.resolve(question);
      }
    },

    create(questionable_type, questionable_id, new_question) {
      const save_question = _.assign({}, new_question, {
        body: new_question.body,
      });
      const params = {question: save_question, text_format: 'plain'};
      params[`${questionable_type}_id`] = questionable_id;

      return $http.post(`${AppConfig.api_root_url}/questions`, params).
        then((response) => {
          _.assign(new_question, response.data.response.question);
          return new_question;
        }).catch(response =>
          $q.reject(_.set(response.data.response, 'message', response.data.meta.message))
        );
    },

    update(question, data) {
      const revert = Reversible.modify(question, data.question);

      return $http.put(`${AppConfig.api_root_url}/questions/${question.id}`, data, {params: {text_format: 'html,markdown'}}).
        then((response) => {
          _.assign(question, response.data.response.question);
        }).catch((response) => {
          revert();
          return $q.reject(new Error(response.data.meta.message));
        });
    },

    move(question, direction) {
      const data = {direction};

      return $http.put(`${AppConfig.api_root_url}/questions/${question.id}/move`, data, {params: {text_format: 'html,markdown'}}).
        then((response) => {
          _.assign(question, response.data.response.question);
        }).catch(response =>
          $q.reject(new Error(response.data.meta.message))
        );
    },

    change_state(question, state) {
      const data = {state_event: state};
      const path = `${AppConfig.api_root_url}/questions/${question.id}/change_state`;

      return $http.put(path, data, {params: {text_format: 'html,markdown'}}).
        then((response) => {
          _.assign(question, response.data.response.question);
        }).catch(response =>
          $q.reject(new Error(response.data.meta.message))
        );
    },
  };
}]);
