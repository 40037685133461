/* eslint-env es6 */

angular.module('genius').directive('mentionable', ['MentionClient', function(MentionClient) {
  return {
    restrict: 'A',

    link(_scope, elem) {
      elem.atwho({
        at: '@',
        displayTpl: '<li>${name}</li>',
        insertTpl: '@${value}',
        searchKey: 'name',
        callbacks: {
          remoteFilter(query, callback) {
            MentionClient.autocomplete(query).then((mentions) => {
              callback(mentions);
            });
          },

          sorter(_query, items) {
            return items;
          },
        },
      });
    },
  };
}]);
