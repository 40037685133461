/* eslint-env es6 */

angular.module('genius').component('songVerifiedArtistsSection', {
  bindings: {
    contributors: '<',
    featured: '<',
    label: '@',
    lyrics_only: '<lyricsOnly',
  },

  templateUrl: 'song_verified_artists_section.html',
});
