/* eslint-env es5 */
/* eslint-disable arrow-body-style, arrow-parens, arrow-spacing, constructor-super, generator-star-spacing, no-class-assign, no-confusing-arrow, no-const-assign, no-dupe-class-members, no-duplicate-imports, no-extra-bind, no-new-symbol, no-restricted-imports, no-this-before-super, no-useless-computed-key, no-useless-constructor, no-useless-rename, no-var, object-shorthand, prefer-arrow-callback, prefer-const, prefer-numeric-literals, prefer-reflect, prefer-rest-params, prefer-spread, prefer-template, require-yield, rest-spread-spacing, sort-imports, symbol-description, template-curly-spacing, yield-star-spacing, dot-location, key-spacing, max-len, new-parens, no-lonely-if, no-mixed-operators, no-trailing-spaces, no-whitespace-before-property, padded-blocks, quotes, space-infix-ops */

angular.module('genius').directive('submitOnCmdEnter', function() {
  return {
    restrict: 'A',

    require: 'form',

    link: function(scope, element, _attrs, controller) {
      element.on('keydown', function(e) {
        if (e.keyCode === 13 && (e.metaKey || e.shiftKey)) {
          scope.$apply(function() {
            controller.$commitViewValue();
          });
          element.triggerHandler('submit');
          e.preventDefault();
        }
      });
    },
  };
});
