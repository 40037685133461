/* eslint-env es6 */

angular.module('genius').directive('soundcloud', ['$q', 'Tracking', 'getScript', 'Soundcloud', ($q, Tracking, getScript, Soundcloud) => {
  const load = () => getScript('https://w.soundcloud.com/player/api.js', {cache: true});

  return {
    restrict: 'E',

    templateUrl: 'soundcloud.html',

    replace: true,

    scope: {
      yieldsApi: '&',
      yieldsState: '&',
      soundcloud_src: '<soundcloudSrc',
    },

    link(scope, element) {
      scope.$watch('soundcloud_src', (soundcloud_src) => {
        if (!soundcloud_src) return;
        const defer = $q.defer();
        const widget_promise = defer.promise;

        const set_iframe_src_without_digest = (iframe, src) => {
          iframe.attr('src', src);
        };

        $q.all([Soundcloud.player_url_for_src(scope.soundcloud_src), load()]).then((data) => {
          const url = data[0];

          set_iframe_src_without_digest(element, url.$$unwrapTrustedValue());

          const widget = SC.Widget(element[0]);
          widget.bind(SC.Widget.Events.READY, () => {
            widget.getDuration((duration) => {
              if (duration) {
                defer.resolve(widget);
                scope.duration = duration;
              } else {
                state.has_errored = true;
              }

              scope.$apply();
            });
          });
        });

        const state = {};
        scope.yieldsState({state});

        const bind_to_widget = (widget, name, fn) => {
          widget.bind(name, () => {
            scope.$apply(fn);
          });
        };

        const track_play = _.once(() => { Tracking.track('Soundcloud play'); });

        widget_promise.then((widget) => {
          state.is_loaded = true;

          bind_to_widget(widget, SC.Widget.Events.ERROR, () => {
            state.has_errored = true;
          });

          bind_to_widget(widget, SC.Widget.Events.PAUSE, () => {
            state.is_loaded = true;
            state.is_playing = false;
          });

          bind_to_widget(widget, SC.Widget.Events.PLAY, () => {
            track_play();
            state.is_loaded = false;
            state.is_playing = true;
          });

          widget.bind(SC.Widget.Events.PLAY_PROGRESS, _.throttle((data) => {
            scope.$apply(() => {
              state.is_loaded = true;
              state.percentage = data.relativePosition;
              state.time_remaining = scope.duration - data.currentPosition;
            });
          }, 100));
        });

        scope.yieldsApi({
          api: {
            load_media() {},

            playpause() {
              widget_promise.then((widget) => {
                state.is_playing ? widget.pause() : widget.play();
              });
            },

            pause() {
              widget_promise.then((widget) => {
                widget.pause();
              });
            },

            seek_to(percentage) {
              state.percentage = percentage;
              widget_promise.then((widget) => {
                widget.seekTo(scope.duration * percentage);
              });
            },
          },
        });

        widget_promise.then((resolved_widget) => {
          Soundcloud.oembed_url_for_src(soundcloud_src).
            then(resolved_widget.load.bind(resolved_widget));
        });
      });
    },
  };
}]);
