/* eslint-env es6 */

angular.module('genius').component('songStoryModal', {
  templateUrl: 'song_story_modal.html',

  bindings: {
    story: '<story',
    song: '<',
    on_close: '&onClose',
  },

  controller: ['$window', '$scope', '$sce', 'Session', 'AppConfig', function($window, $scope, $sce, Session, AppConfig) {
    this.$onChanges = (changes) => {
      if (changes.story || changes.song) {
        const url = this.song ? `${this.story.url}#context=song:${this.song.id}` : this.story.url;
        this.src = $sce.trustAsUrl(url);
        this.title = this.story.title;
      }
    };

    this.$onInit = () => {
      this.can = (action_name, object) => Session.has_permission(action_name, object);

      $window.addEventListener('message', (event) => {
        if (event.origin === `https://${AppConfig.canonical_domain}`) {
          $scope.$apply(() => {
            this.title = event.data.title;
            this.title_url = event.data.url;
          });
        }
      });
    };
  }],
});
