/* eslint-env es5 */
/* eslint-disable arrow-body-style, arrow-parens, arrow-spacing, constructor-super, generator-star-spacing, no-class-assign, no-confusing-arrow, no-const-assign, no-dupe-class-members, no-duplicate-imports, no-extra-bind, no-new-symbol, no-restricted-imports, no-this-before-super, no-useless-computed-key, no-useless-constructor, no-useless-rename, no-var, object-shorthand, prefer-arrow-callback, prefer-const, prefer-numeric-literals, prefer-reflect, prefer-rest-params, prefer-spread, prefer-template, require-yield, rest-spread-spacing, sort-imports, symbol-description, template-curly-spacing, yield-star-spacing, dot-location, key-spacing, max-len, new-parens, no-lonely-if, no-mixed-operators, no-trailing-spaces, no-whitespace-before-property, padded-blocks, quotes, space-infix-ops */

angular.module('genius').directive('displayUnlessCookie', ['Cookies', 'DateTools', function(Cookies, DateTools) {
  var set_cookie = function(name, expiresInDays) {
    Cookies.put(name, 1, {expires: DateTools.days_to_ms(expiresInDays), path: '/'});
  };

  return {
    transclude: true,
    scope: {
      name: '@',
      expiresInDays: '<',
      setCookieOnDisplay: '<',
    },
    template: '<ng-transclude ng-if="!hidden"></ng-transclude>',
    link: function(scope) {
      scope.hidden = Cookies.get(scope.name);

      if (scope.setCookieOnDisplay && !Cookies.get(scope.name)) set_cookie(scope.name, scope.expiresInDays);
    },
    controller: ['$scope', function($scope) {
      this.set_cookie_and_close = function() {
        set_cookie($scope.name, $scope.expiresInDays);
        $scope.hidden = true;
      };
    }],
  };
}]);
