/* eslint-env es6 */

angular.module('genius').component('profileArtistDescriptionPreview', {
  templateUrl: 'profile_artist_description_preview.html',

  bindings: {
    artist: '<',
    top_scholar: '<topScholar',
  },
});
