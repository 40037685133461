/* eslint-env es5 */
/* eslint-disable arrow-body-style, arrow-parens, arrow-spacing, constructor-super, generator-star-spacing, no-class-assign, no-confusing-arrow, no-const-assign, no-dupe-class-members, no-duplicate-imports, no-extra-bind, no-new-symbol, no-restricted-imports, no-this-before-super, no-useless-computed-key, no-useless-constructor, no-useless-rename, no-var, object-shorthand, prefer-arrow-callback, prefer-const, prefer-numeric-literals, prefer-reflect, prefer-rest-params, prefer-spread, prefer-template, require-yield, rest-spread-spacing, sort-imports, symbol-description, template-curly-spacing, yield-star-spacing, dot-location, key-spacing, max-len, new-parens, no-lonely-if, no-mixed-operators, no-trailing-spaces, no-whitespace-before-property, padded-blocks, quotes, space-infix-ops */

angular.module('genius').directive('timestampInput', ['timestamp_for_displayFilter', function(timestamp_for_display) {
  var string_to_timestamp = function(str) {
    if (!str) return null;
    var minutes_and_seconds = str.split(':');
    return Math.floor((parseInt(minutes_and_seconds[0] || '0', 10) * 60 + parseFloat(minutes_and_seconds[1] || '0')) * 1000);
  };

  return {
    scope: {
      timestamp: '=ngModel',
      ngDisabled: '<',
    },

    template: '<input ng-model="string" ng-disabled="ngDisabled" type="text"/>',

    link: function(scope, element) {
      scope.$watch('timestamp', function(timestamp) {
        scope.string = timestamp_for_display(timestamp);
      });

      element.find('input').on('blur', function() {
        scope.timestamp = string_to_timestamp(scope.string);
        scope.$apply();
      });
    },
  };
}]);
