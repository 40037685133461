/* eslint-env es6 */

angular.module('genius').factory('LyricsSynchronizationClient', ['$rootScope', '$q', '$http', 'AppConfig', function($rootScope, $q, $http, AppConfig) {
  const scope = $rootScope.$new();

  const is_valid_timestamp = number => _.isNumber(number) && (number || number === 0);

  const resource_url = (id, action_name) =>
    _.compact([AppConfig.api_root_url, 'lyrics_synchronizations', id, action_name]).join('/');

  const return_model = response => new LyricsSynchronizationClient(response.data.response.lyrics_synchronization);

  const return_error = (response) => {
    const error = new Error(response.data.meta.message);
    _.assign(error, _.pick(response, 'status', 'statusText'));
    return $q.reject(_.assign(error, response.data.response));
  };

  const define_edit_action = action => function(...args) {
    this._dirty = true;
    return _.tap(Reflect.apply(action, this, args), () => {
      scope.$emit('edit');
    });
  };

  class LyricsSynchronizationClient {
    constructor(data) {
      _.assign(this, data);
    }

    get mark_dirty() {
      return define_edit_action(_.noop);
    }

    get apply_timestamp_to_line_at_index() {
      return define_edit_action(function(position, index) {
        if (position < this.max_timestamp_before_index(index)) {
          throw new Error("Can't set time to earlier than a previous line");
        }

        this.lines[index].timestamp = position;

        this.lines.slice(index).forEach((line) => {
          if (line.timestamp < position) line.timestamp = null;
        });

        return $q.when(this.lines[index]);
      });
    }

    get set_line_at_index_hidden() {
      return define_edit_action(function(index, hidden) {
        _.assign(this.lines[index], {hidden, timestamp: null});
      });
    }

    get set_line_at_index_marked() {
      return define_edit_action(function(index, marked) {
        this.lines[index].fixed = false;
        this.lines[index].marked = marked;
      });
    }

    get set_line_at_index_fixed() {
      return define_edit_action(function(index) {
        this.lines[index].marked = false;
        this.lines[index].fixed = true;
      });
    }

    get add_line_at_index() {
      return define_edit_action(function(index, line) {
        this.lines.splice(index, 0, _.defaults(line || {}, {
          added: true,
          lyrics: '[Instrumental Break]',
          timestamp: null,
        }));
      });
    }

    get remove_line_at_index() {
      return define_edit_action(function(index) {
        this.lines.splice(index, 1);
      });
    }

    max_timestamp_before_index(index) {
      return _(this.lines.slice(0, index)).map('timestamp').max() || 0;
    }

    min_timestamp_after_index(index) {
      return _(this.lines.slice(index + 1)).map('timestamp').compact().min() || this.duration;
    }

    timestamp_for_line_at_index(index) {
      return index === -1 ? 0 : this.lines[index].timestamp;
    }

    line_index_for_position(position) {
      return _.findLastIndex(this.lines, line => is_valid_timestamp(line.timestamp) && line.timestamp < position);
    }

    next_visible_line_index_after_index(start_index) {
      return _.findIndex(this.lines, (line, index) => index > start_index && !line.hidden);
    }

    get all_lines_timestamped() {
      return _.every(this.lines, line => line.hidden || is_valid_timestamp(line.timestamp));
    }

    get all_timestamps_in_order() {
      return _(this.lines).map('timestamp').compact().
        every((ts, i, list) => i === 0 || ts > list[i - 1]);
    }

    get preview() {
      return this.state === 'preview';
    }
  }

  return {
    $on: scope.$on.bind(scope),

    get(id) {
      return $http.get(resource_url(id)).then(return_model);
    },

    preview(params) {
      return $http.get(resource_url('preview'), {params}).
        then(return_model).catch(return_error);
    },

    home() {
      return $http.get(resource_url('home')).then((response) => {
        const result = response.data.response.lyrics_synchronization_home;
        result.checked_out = result.checked_out.map(ls => new LyricsSynchronizationClient(ls));
        return result;
      });
    },

    submit(action_name, lyrics_synchronization, data) {
      const save = lyrics_synchronization._dirty
        ? this.save(lyrics_synchronization)
        : $q.when(lyrics_synchronization);

      return save.then(
        sync => $http.post(
          resource_url(sync.id, action_name),
          _.assign(data || {}, _.pick(sync, 'lock_version'))
        ).then(return_model, return_error)
      );
    },

    submit_sync(lyrics_synchronization, {sync_and_staff_approve}) {
      if (_.some(lyrics_synchronization.lines, 'marked')) {
        return $q.reject(new Error("Can't submit lyrics with lines still marked as incorrect"));
      }

      return this.submit('sync', lyrics_synchronization, {sync_and_staff_approve});
    },

    update_state(state, lyrics_synchronization) {
      return $http.put(
        resource_url(lyrics_synchronization.id, 'state'), {state}
      ).then(return_model, return_error);
    },

    save(lyrics_synchronization) {
      if (!lyrics_synchronization.all_timestamps_in_order) {
        return $q.reject(new Error("Can't save lyrics with timestamps out of order"));
      }

      const data = {lyrics_synchronization};
      return $http.put(resource_url(lyrics_synchronization.id), data).
        then(return_model, return_error);
    },

    revert_action(action, lyrics_synchronization) {
      return $http.post(resource_url(lyrics_synchronization.id, 'revert_action'), {action_id: action.id}).
        then(return_model, return_error);
    },

    skip(data, lyrics_synchronization) {
      return $http.post(resource_url(lyrics_synchronization.id, 'skip'), data).
        then(return_model, return_error);
    },

    change_song(data) {
      return $http.put(resource_url(data.lyrics_synchronization_id, 'song'), {song_id: data.song_id}).
        then(return_model, return_error);
    },

    start(verb) {
      return $http.post(resource_url('start'), {verb}).
        then(return_model, return_error);
    },

    set_finalized(id, bool) {
      const method = bool ? 'put' : 'delete';
      return $http[method](`${resource_url(id)}/finalized`).
        then(return_model, return_error);
    },
  };
}]);
