/* eslint-env es6 */

angular.module('genius').factory('VoteClient', ['AppConfig', 'querystring', '$http', function(AppConfig, querystring, $http) {
  return {
    load_voters(type, id) {
      const query = _.set({}, `${type}_id`, id);
      return $http.get(`${AppConfig.api_root_url}/voters?${querystring.stringify(query)}`).
        then(response => response.data.response.voters);
    },

    submit_vote(type, id, vote) {
      const data = _.set({direction: vote || 'middle'}, `${type}_id`, id);
      return $http.post(`${AppConfig.api_root_url}/votes`, data);
    },
  };
}]);
