/* eslint-env es5 */
/* eslint-disable arrow-body-style, arrow-parens, arrow-spacing, constructor-super, generator-star-spacing, no-class-assign, no-confusing-arrow, no-const-assign, no-dupe-class-members, no-duplicate-imports, no-extra-bind, no-new-symbol, no-restricted-imports, no-this-before-super, no-useless-computed-key, no-useless-constructor, no-useless-rename, no-var, object-shorthand, prefer-arrow-callback, prefer-const, prefer-numeric-literals, prefer-reflect, prefer-rest-params, prefer-spread, prefer-template, require-yield, rest-spread-spacing, sort-imports, symbol-description, template-curly-spacing, yield-star-spacing, dot-location, key-spacing, max-len, new-parens, no-lonely-if, no-mixed-operators, no-trailing-spaces, no-whitespace-before-property, padded-blocks, quotes, space-infix-ops */

angular.module('genius').component('routedPage', {
  template: ['$rootRouter', function($rootRouter) {
    var dasherize = function(string) {
      return string.replace(/([a-zA-Z])(?=[A-Z])/g, '$1-').toLowerCase().replace(/_/g, '-');
    };

    var component = $rootRouter.currentInstruction;
    while (component.child != null) {
      component = component.child;
    }

    var routeData = component.component.routeData.data;
    var templateTag = dasherize(routeData.component);
    var urlParams = $rootRouter.currentInstruction.component.params;

    return '<'+ templateTag + ' ' +
      _(routeData.includeFromCache).map(function(key) {
        return dasherize(key) + '="$ctrl.preloadedData.' + key + '"';
      }).join(' ') + ' ' +
      _(routeData.includeFromUrlParams).map(function(key) {
        return dasherize(key) + '="' + urlParams[key] + '"';
      }).join(' ') + ' ' +
      _(routeData.include || {}).map(function(value, key) {
        return dasherize(key) + '="' + value + '"';
      }).join(' ')
      + ' $router="$ctrl.$router"></'+templateTag+'>';
  }],

  bindings: {
    $router: '<',
  },

  controller: ['PreloadCache', function(PreloadCache) {
    this.preloadedData = PreloadCache;
    this.$routerCanReuse = function() { return false; };
  }],
});
