/* eslint-env es6 */

angular.module('genius').factory('WebAudioPlayer', ['$document', '$interval', function($document, $interval) {
  const CALLBACK_INTERVAL = 50;

  return class WebAudioPlayer {
    constructor(callback) {
      this._callback = callback;
      this._audio = $document[0].createElement('audio');

      $interval(() => this._callback(this.state), CALLBACK_INTERVAL);
    }

    pause() {
      this._audio.pause();
      this._callback(this.state);
    }

    play(track_url) {
      if (track_url && this._audio.src !== track_url) {
        this._audio.src = track_url;
        this._audio.currentTime = 0;
      }
      this._audio.play();
      this._callback(this.state);
    }

    seek(position, {play} = {play: false}) {
      this._audio.currentTime = position / 1000;
      if (play) this._audio.play();
      this._callback(this.state);
    }

    print_log_entries() { }

    download_log_entries() { }

    get state() {
      return {
        is_playing: !this._audio.paused,
        position: this._audio.currentTime * 1000,
        duration: this._audio.duration * 1000,
        track_uri: this._audio.src,
      };
    }

    get playback_type() {
      return 'web_audio';
    }

    get playback_label() {
      return 'Web Audio';
    }
  };
}]);
