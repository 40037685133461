import 'json_store';


var SESSION_END_TRACKING_DATA_KEY = 'session_end_tracking_events';

var Tracking = function() {
  this.enabled = false;
  this.reset_counters();
};

Tracking.arrange_arguments = function(f) {
  return function(event_name, options, callback) {
    if (typeof options === 'function') {
      callback = options;
      options = {};
    }

    return f.call(this, event_name, options, callback);
  };
};

Tracking.if_enabled = function(f) {
  return function() {
    var callback = arguments[arguments.length - 1];

    if (!this.enabled) {
      if (typeof(callback) === 'function') {
        setTimeout(callback);
      }
      return false;
    }

    return f.apply(this, arguments);
  };
};

Tracking.prototype = {
  start_tracking: function() {
    if (typeof(mixpanel) === 'undefined' || typeof(mixpanel.register) === 'undefined') return false;

    this.enabled = true;
    this.tracking_data = window.TRACKING_DATA;

    var current_tag_name;
    if (typeof(CURRENT_TAG) !== 'undefined' && CURRENT_TAG) {
      current_tag_name = CURRENT_TAG.tag.slug;
    }

    mixpanel.register({
      'Logged In': !!CURRENT_USER,
      'Is Editor': CURRENT_USER && CURRENT_USER.is_editor,
      'Is Moderator': CURRENT_USER && CURRENT_USER.is_moderator,
      'Mobile Site': MOBILE_DEVICE,
      'AMP': false,
      'Tag': current_tag_name,
      genius_platform: 'web',
      user_agent: window.navigator.userAgent,
      assembly_uid: window.getAnaUid && window.getAnaUid(),
      ana_sdk_version: window.getAnaVersion && window.getAnaVersion(),
    });

    [
      'AB Test - apple_desktop_static_cta',
      'AB Test - apple_player_position',
      'AB Test - apple_player_position_mobile',
      'Annotatable ID',
      'Annotatable Type',
      'Comment Count',
      'Has Apple Match',
      'Has Description',
      'Has Recirculated Articles',
      'Has Translation Q&A',
      'Has Youtube URL',
      'Lyrics Language',
      'Music?',
      'NRM Target Date',
      'NRM Tier',
      'Primary Album',
      'Primary Album ID',
      'Primary Artist',
      'Primary Artist ID',
      'Primary Tag',
      'Primary Tag ID',
      'Release Date',
      'Song ID',
      'Title',
      'amp_cache',
      'cohort_ids',
      'containing_frame_is_fullbleed',
      'created_at',
      'created_month',
      'created_year',
      'featured_video',
      'has_featured_annotation',
      'has_verified_callout',
      'hot',
      'platform_variant',
      'provider',
      'provider_id',
      'react',
      'song',
      'song_id',
      'song_tier',
    ].forEach(function(property) {
      mixpanel.unregister(property);
    });

    if (CURRENT_USER) {
      mixpanel.identify(CURRENT_USER.id);
    } else {
      window.gtag && window.gtag('get', APP_CONFIG.ga4_measurement_ids[0], 'client_id', (client_id) => {
        mixpanel.identify(client_id);
      });
    }

    if (app.is_on_song_page && app.is_on_song_page()) this.track('Song Page Load');

    if ($('body.act-new.cont-users').length > 0) {
      var from_question_email = $.deparam(location.search.substring(1)).from_question_email;
      if (from_question_email) {
        this.track('Question email signup clicked', {question_id: from_question_email});
      }
    }

    var track_links_properties = {'from url': document.location.href};

    mixpanel.track_links('.android_interstitial-download', 'clicked_download_from_android_interstitial', track_links_properties);

    window['Genius.cmp'] = window['Genius.cmp'] || [];
    window['Genius.cmp'].push(function(gcmp) {
      gcmp.resolve_with_tracking(this);
    }.bind(this));
  },

  set_tacking_data: function(tracking_data) {
    this.tracking_data = tracking_data;
  },

  reset_counters: function() {
    this.annotations_opened = 0;
    this.line_item_opens = {};
    this.song_page_loaded_from_direct_link_to_un_annotated_line = false;
    this.annotation_id_opened_from_permalink = null;
  },

  registered_properties: {},

  register: function(properties) {
    _.extend(this.registered_properties, properties);
  },

  build_event_data: function(event_name, options) {
    options = options || {};
    var properties;
    if (options.properties) {
      properties = options.properties;
    } else if (this.is_old_preprocessed_event(event_name)) {
      properties = this.preprocess_options(event_name, options);
    } else {
      properties = options;
    }

    return $.extend(
      {},
      this.registered_properties,
      this.tracking_data,
      _.flattenObject(properties),
      {dfp_enabled: 'googletag' in window},
      {assembly_uid: window.getAnaUid && window.getAnaUid()},
    );
  },

  is_old_preprocessed_event: function(event_name) {
    return _.includes([
      'Song Page Load',
      'Wrapped Web Page Load',
      'Annotated Web Page Load',
      'Open Annotation',
      'Engaged Annotation View',
      'Offsite Filter Selection',
      'Inbox Open',
      'Line Item Open',
    ], event_name);
  },

  preprocess_options: function(event_name, options) {
    if (event_name === 'Song Page Load') {
      return _.merge(options, {
        'Un-annotated direct link': !!this.song_page_loaded_from_direct_link_to_un_annotated_line,
        'Un-annotated line': this.song_page_loaded_from_direct_link_to_un_annotated_line,
      });
    } else if (event_name === 'Wrapped Web Page Load' || event_name === 'Annotated Web Page Load') {
      return {
        'Source location': options.source_location,
        script_source: options.script_source,
        script_source_version: options.script_source_version,
        'Host Page Referrer': options.referrer,
      };
    } else if (event_name === 'Open Annotation' || event_name === 'Engaged Annotation View') {
      this.annotations_opened++;

      var properties = {
        'Annotation ID': options.annotation_id,
        'Annotator Login': options.annotator_login,
        'Annotatable Type': options.annotatable_type,
        script_source: options.script_source,
        script_source_version: options.script_source_version,
      };

      if (!options.skip_counters) {
        $.extend(properties, {
          'Cumulative Annos Opened': this.annotations_opened,
          'Anno Opened from Permalink': options.annotation_id === this.annotation_id_opened_from_permalink,
          'First Anno was Permalink': !!this.annotation_id_opened_from_permalink,
        });
      }
      if (_.has(options, 'counts_as_page_load')) {
        $.extend(properties, {
          counts_as_page_load: options.counts_as_page_load,
        });
      }
      return properties;
    } else if (event_name === 'Offsite Filter Selection') {
      return {
        'Filter Type': options.filter_type,
        'Filter Arguments': options.filter_args,
        'Filter Reason': options.reason,
        script_source: options.script_source,
        script_source_version: options.script_source_version,
      };
    } else if (event_name === 'Inbox Open') {
      return {
        'Inbox Name': options.inbox_name,
        'Unread Count When Opened': options.unread_count,
      };
    } else if (event_name === 'Line Item Open') {
      if (typeof(this.line_item_opens[options.inbox_name]) == 'undefined')
      {this.line_item_opens[options.inbox_name] = 0;}

      return {
        'Inbox Name': options.inbox_name,
        'Line Item Index': options.index,
        'Action Name': options.action_name,
        'Target Type': options.target_type,
        'Viewing Context': options.viewing_context,
        'Cumulative Line Items Opened': this.line_item_opens[options.inbox_name]++,
        'Line Item Strategy': options.strategy_name,
        'Bagon': !!options.bagon,
        iframe: !!options.iframe,
      };
    }
  },

  track: Tracking.arrange_arguments(Tracking.if_enabled(function(event_name, options, callback) {
    var properties = this.build_event_data(event_name, options);
    mixpanel.track(event_name, properties, callback);
    if (window.FS) {
      window.FS.from_mixpanel(event_name, properties);
    }
  })),

  track_with_delayed_handling: Tracking.arrange_arguments(Tracking.if_enabled(function(event_name, options, callback) {
    if (bowser.mobile || bowser.tablet) {
      JSONStore.modify_store(SESSION_END_TRACKING_DATA_KEY, function(events) {
        events = events || [];
        return events.concat(
          {
            event_name: event_name,
            event_options: this.build_event_data(event_name, options),
            event_occurred_at: Date.now(),
          }
        );
      }.bind(this));
    } else {
      this.track(event_name, options, callback);
    }
  })),

  track_with_timeout: Tracking.arrange_arguments(Tracking.if_enabled(function(event_name, options, callback) {
    var timeout = this.fetch_mixpanel_config('track_links_timeout', 0);
    callback = _.once(callback);

    setTimeout(callback, timeout);
    this.track(event_name, options, callback);
  })),

  track_link_click: function(click_event) {
    var RIGHT_CLICK_EVENT = 2;
    var $anchor = $(click_event.target).closest('a');

    var href = $anchor.attr('href');
    var new_tab =
      click_event.which === RIGHT_CLICK_EVENT ||
      click_event.metaKey ||
      click_event.ctrlKey ||
      $anchor.attr('target') === '_blank';

    var args = Array.prototype.slice.call(arguments, 1);

    if (new_tab) {
      return this.track.apply(this, args);
    } else {
      click_event.preventDefault();

      var last_argument = args[args.length - 1];
      var user_callback = typeof(last_argument) === 'function' ? args.pop() : null;

      return this.track_with_timeout.apply(this, args.concat(function() {
        if (user_callback) user_callback();
        window.location.href = href;
      }));
    }
  },

  set_annotation_opened_from_permalink: function(id) {
    this.annotation_id_opened_from_permalink = id;
  },

  set_song_page_loaded_from_direct_link_to_un_annotated_line: function(referent) {
    this.song_page_loaded_from_direct_link_to_un_annotated_line = referent;
  },

  fetch_mixpanel_config: function(key, default_value) {
    if (this.enabled && typeof(mixpanel.get_config) == 'function') {
      return mixpanel.get_config(key);
    } else {
      return default_value;
    }
  },
};

$.extend(window.app, {tracking: new Tracking()});

$(function() {
  $(document.body).on('annotation:open', 'a[data-id]', function() {
    app.tracking.track('Open Annotation', {
      annotation_id: $(this).attr('data-id'),
      counts_as_page_load: MOBILE_DEVICE,
    });
  });

  $(document).on('click', '.show_help:not(.pressed)', function() {
    app.tracking.track('opened_what_is_genius_dropdown', {
      'standalone_annotation': $(this).closest('.stand_alone_annotation_container').length > 0,
    });
  });

  JSONStore.modify_store(SESSION_END_TRACKING_DATA_KEY, function(stored_session_end_events_to_track) {
    _.each(stored_session_end_events_to_track, function(session_end_event_options) {
      if (Date.now() - session_end_event_options.event_occurred_at < APP_CONFIG.mixpanel_delayed_events_timeout * 1000) {
        mixpanel.track(
          session_end_event_options.event_name,
          session_end_event_options.event_options
        );
      }
    });
    return [];
  });
});
