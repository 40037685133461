/* eslint-env es6 */

angular.module('genius').factory('Facebook', ['AppConfig', '$window', '$q', 'getScript', (AppConfig, $window, $q, getScript) => {
  const setup_facebook = _.once(() => {
    const deferred = $q.defer();

    $window.fbAsyncInit = () => {
      $window.FB.init({
        appId: AppConfig.facebook_app_id,
        version: `v${AppConfig.facebook_opengraph_api_version}`,
      });
      deferred.resolve($window.FB);
    };

    getScript('//connect.facebook.net/en_US/sdk.js', {cache: true});

    return deferred.promise;
  });

  $(setup_facebook);

  return {
    share(url) {
      setup_facebook().then((FB) => {
        FB.ui({method: 'share', href: url});
      });
    },

    process_embeds(element) {
      setup_facebook().then((FB) => {
        FB.XFBML.parse(element);
      });
    },
  };
}]);
