/* eslint-env es6 */

angular.module('genius').factory('PageTransition', ['$document', '$exceptionHandler', '$location', '$rootScope', 'Ads', 'AsyncAnalytics', 'cfpLoadingBar', 'PageDataClient', function(
  $document,
  $exceptionHandler,
  $location,
  $rootScope,
  Ads,
  AsyncAnalytics,
  cfpLoadingBar,
  PageDataClient
) {
  let transitioning = false;
  const scope = $rootScope.$new(true);

  return {
    by_router_instruction(instruction, previous_instruction) {
      if (_.isUndefined(previous_instruction)) {
        $exceptionHandler(new Error('must pass $prevInstruction as second argument to by_router_instruction'));
      }

      const is_initial_pageload = _.isNull(previous_instruction);

      if (is_initial_pageload && PageDataClient.preloaded()) {
        Object.assign(instruction.routeData, {page_data: PageDataClient.preloaded()});
        return true;
      } else {
        transitioning = true;
        cfpLoadingBar.start();
        return PageDataClient.load(
          instruction.routeData.data.type,
          $location.path()
        ).then((page_data) => {
          transitioning = false;
          cfpLoadingBar.complete();
          Object.assign(instruction.routeData, {page_data});
          if (page_data.dfp_kv) {
            const targeting = {};
            for (const {name, values} of page_data.dfp_kv) {
              targeting[name] = values;
            }
            Ads.reset_for_new_page_view({targeting});
          }
          $document.prop('title', page_data.title);
          $document.scrollTop(0);
          AsyncAnalytics.on_navigation(page_data);
          scope.$broadcast('client_transition_complete');
          return true;
        }).catch((error) => {
          $document.prop('location').reload();
          return Promise.reject(error);
        });
      }
    },

    on_client_transition_complete(fn) {
      scope.$on('client_transition_complete', fn);
    },

    get transitioning() { return transitioning; },
  };
},
]);
