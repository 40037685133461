/* eslint-env es6 */

angular.module('genius').directive('lyrics', ['$q', '$compile', 'SongClient', 'LyricsEditing', 'LyricsAnchorer', 'Lyrics', 'LyricsEditProposalClient', 'ReferentHelper', 'ReferentElementMap', 'ReferentRegistry', 'TextSelection', 'Tracking', 'Bindings', 'ScrollFocus', 'mobile_device', 'Session', 'StructuredText', 'LyricsEditProposal', 'ReactSongPageService', function(
  $q,
  $compile,
  SongClient,
  LyricsEditing,
  LyricsAnchorer,
  Lyrics,
  LyricsEditProposalClient,
  ReferentHelper,
  ReferentElementMap,
  ReferentRegistry,
  TextSelection,
  Tracking,
  Bindings,
  ScrollFocus,
  mobile_device,
  Session,
  StructuredText,
  LyricsEditProposal,
  ReactSongPageService
) {
  const resolve_bindings = Bindings.resolver({
    yields_api: '&yieldsApi',
    on_selection_range_updated: '&onSelectionRangeUpdated',
    canonical_lyrics_html: '<canonicalLyricsHtml',
    on_focused_referent_anchor_changed: '&onFocusedReferentAnchorChanged',
  });

  return {
    restrict: 'E',

    templateUrl: 'lyrics.html',

    controller: ['$scope', 'AppConfig', function($scope, AppConfig) {
      this.user_signed_in = Session.user_signed_in;

      this.react_song_page_link = ReactSongPageService.reactSongPageRedirect;

      this.transcriber_guide_url = AppConfig.transcriber_guide_url;

      this.set_editable = (lyrics) => {
        $scope.editable = lyrics;
        this.editable_lyrics_stale = false;
      };

      this.build_correction = () => {
        let anchor, referent_params;
        const state = this.lyrics_anchorer.get_state();

        if (state.referent) {
          const occurrence = this.lyrics_anchorer.get_occurrence_in_container_among(
            `[annotation-fragment=${state.referent.id}]`,
            state.reference_element
          );

          anchor = state.reference_element;
          referent_params = {
            id: state.referent.id,
            occurrence,
          };
        } else {
          anchor = $('<span>').attr('class', 'line-correction');
          referent_params = this.lyrics_anchorer.calculate_referent_location(state.range);
          anchor.html(state.range.extractContents());
          state.range.insertNode(anchor[0]);
        }

        const original_html = anchor.html();
        const replacement = LyricsEditing.
          prepare_lyrics_for_editing(original_html);
        const original_markdown = replacement.markdown;
        if (this.proposing_edit) {
          replacement.markdown = new StructuredText(original_markdown).plain;
        }

        return {
          anchor,
          rect: anchor.get(0).getBoundingClientRect(),
          referent: referent_params,
          replacement,
          original: {
            html: original_html,
            markdown: original_markdown,
          },
        };
      };

      this.should_show_full_lyrics_save_cancel_buttons = () =>
        (this.proposing_edit || this.editing) && !this.correcting;

      this.should_show_full_lyrics_start_propose_button = () => {
        if (this.correcting && this.proposing_edit) return true;

        return $scope.can_perform('propose_lyrics_edit') && !this.editing &&
          !this.proposing_edit;
      };

      this.should_show_full_lyrics_start_edit_button = () => {
        if (this.correcting && this.editing) return true;
        return $scope.can_perform('edit_lyrics') && !this.editing;
      };

      this.should_show_edit_annotation_brackets_button = () =>
        $scope.can_perform('edit_annotation_brackets') &&
          !this.proposing_edit &&
          !this.editing &&
          !this.saving;

      this.should_show_lyrics_edit_form = () => this.editing && !this.correcting;

      this.should_show_lyrics_edit_proposal_form = () => this.proposing_edit && !this.correcting;

      this.should_show_lyrics_form_cancel_button = () =>
        this.editing && !this.saving && !this.correcting;

      this.should_show_lyrics_sync_warning = () =>
        $scope.can_perform('view_lyrics_synchronization') &&
          this.should_show_lyrics_edit_form() &&
          $scope.song.lyrics_marked_complete_by;

      const prepare_lyrics_for_editing = () => {
        this.set_editable(
          LyricsEditing.prepare_lyrics_for_editing(
            $scope.canonical_lyrics_html
          )
        );
      };

      this.start_editing = () => {
        prepare_lyrics_for_editing();
        this.editing = true;

        if (this.correcting) {
          Tracking.track('lyrics_edits:start_by_line_edit');
        } else {
          Tracking.track('lyrics_edits:start_full_edit');
        }
      };

      this.start_proposing_edit = () => {
        this.edit_blocked = true;

        if (this.correcting) {
          Tracking.track(
            'lyrics_edit_proposals:start_by_line_proposal',
            {properties: {edit_format: 'plain_text'}}
          );
        } else {
          Tracking.track(
            'lyrics_edit_proposals:start_proposal',
            {properties: {edit_format: 'plain_text'}}
          );
        }

        LyricsEditProposalClient.
          lyrics_for_edit_proposal({song_id: $scope.song.id}).
          then(({body, version}) => {
            this.proposing_edit = true;
            this.lyrics_for_edit_proposal = {
              version,
              before: body.plain,
              markdown: body.plain,
              attr_hash: {},
            };
          }).finally(() => {
            this.edit_blocked = false;
          });
      };

      this.start_correcting = () => {
        this.correcting = true;
        if ($scope.can_perform('propose_lyrics_edit')) {
          this.proposing_edit = true;
          this.editing = false;
          this.correction = this.build_correction();
          this.start_proposing_edit();
        } else {
          this.proposing_edit = false;
          this.editing = true;
          this.correction = this.build_correction();
          this.start_editing();
        }
      };

      this.cancel_edit_or_proposal = () => {
        if (this.editing && this.correcting) {
          Tracking.track('lyrics_edits:cancel_by_line_edit');
        } else if (this.editing && !this.correcting) {
          Tracking.track('lyrics_edits:cancel_full_edit');
        } else if (this.proposing_edit && this.correcting) {
          Tracking.track(
            'lyrics_edit_proposals:cancel_by_line_proposal',
            {properties: {edit_format: 'markdown'}}
          );
        } else if (this.proposing_edit && !this.correcting) {
          Tracking.track(
            'lyrics_edit_proposals:cancel_proposal',
            {properties: {edit_format: 'markdown'}}
          );
        }

        this.errors = null;
        this.editing = null;
        this.correcting = null;
        this.correction = null;
        this.proposing_edit = null;
        this.edit_blocked = false;
        this.reset_lyrics_form();
      };

      this.reset_lyrics_form = () => {
        this.errors = null;
        this.lyrics_form.$rollbackViewValue();
        this.lyrics_form.$setPristine();
      };

      this.finish_editing = () => {
        if (this.saving) return;

        const validation_errors = LyricsEditing.validate_pseudo_markdown_lyrics(
          $scope.canonical_lyrics_html,
          (this.proposing_edit ? this.lyrics_for_edit_proposal : $scope.editable).markdown,
          {is_music: $scope.song.is_music}
        );

        if (validation_errors.length) {
          this.errors = validation_errors;
          return;
        }

        if (this.editing) {
          this.saving = 'saving_edit';
        } else if (this.proposing_edit) {
          this.saving = 'saving_proposal';
        }

        this.edit_blocked = true;
        this.errors = null;

        return (this.proposing_edit ? this.save_edit_proposal() : this.save_edit()).
          finally(() => { this.saving = false; });
      };

      this.save_edit_proposal = () => {
        this.proposing_edit = false;

        if (this.lyrics_for_edit_proposal.markdown === this.lyrics_for_edit_proposal.before) {
          this.cancel_edit_or_proposal();
          return $q.when();
        }

        return LyricsEditProposalClient.create({
          song_id: $scope.song.id,
          version: this.lyrics_for_edit_proposal.version,
          edited_lyrics: this.lyrics_for_edit_proposal.markdown,
        }).then(() => {
          if (this.correcting) {
            Tracking.track(
              'lyrics_edit_proposals:save_by_line_proposal',
              {properties: {edit_format: 'markdown'}}
            );
          } else {
            Tracking.track(
              'lyrics_edit_proposals:save_proposal',
              {properties: {edit_format: 'markdown'}}
            );
          }

          this.lyrics_form.$setPristine();
          this.edit_proposal_submitted = true;
          this.correction = null;
          this.correcting = null;
        }).catch((error) => {
          this.errors = error.response.errors || [error.message];
          this.proposing_edit = true;
        }).finally(() => {
          this.edit_blocked = false;
        });
      };

      this.save_edit = () => {
        this.editing = false;
        const original_html = $scope.lyrics_section_html();
        $scope.lyrics_section_html(get_lyrics_for_preview());

        return SongClient.save_lyrics($scope.song, get_lyrics_for_saving()).then((result) => {
          const tracking_event = this.correction ? 'lyrics_edits:save_by_line_edit' : 'lyrics_edits:save_full_edit';
          Tracking.track(tracking_event);
          this.apply_lyrics_update(result);
          this.editing = false;
          this.edit_blocked = false;
          this.correction = null;
          this.correcting = null;
          this.lyrics_form.$setPristine();
        }).catch((data) => {
          if (!this.correction) this.editing = true;
          $scope.lyrics_section_html(original_html);
          if (data.meta.status === 409) {
            this.refresh_lyrics();
          } else {
            this.errors = data.response.errors;
            if (!this.errors || !this.errors.length) this.errors = [data.meta.message || 'Unexpected error'];
            this.edit_blocked = false;
          }
        });
      };

      this.refresh_lyrics = () => {
        this.edit_blocked = true;

        return SongClient.load_lyrics($scope.song.id).then((lyrics) => {
          this.edit_blocked = false;
          this.apply_lyrics_update(lyrics);
        });
      };

      this.remove_referent = (id) => {
        $scope.canonical_lyrics_html = LyricsEditing.lyrics_html_without_referent($scope.canonical_lyrics_html, id);
      };

      this.on_image_referent_created = (selection_range, referent) => {
        this.edit_blocked = false;
        referent.precomputed_client_rect = selection_range.precomputed_client_rect;
        $scope.current_referent = referent;
        $scope.$emit('referent_created', referent);
      };

      this.on_image_referent_creation_failed = () => {
        this.edit_blocked = false;
      };

      this.on_image_referent_creation_started = () => {
        this.edit_blocked = true;
      };

      this.can_correct_line = () => {
        if (this.has_block_level_markup()) return false;

        return ($scope.current_referent && !$scope.current_referent.is_image) ||
          this.selection_range ||
          this.correction;
      };

      this.submit_line_correction = (corrected_markdown) => {
        this.edit_blocked = true;
        this.errors = null;
        if (this.proposing_edit) {
          const original = new StructuredText(this.correction.original.markdown).plain;
          const diff = LyricsEditProposal.diff(original, corrected_markdown);
          const patches = LyricsEditProposal.make_patches(diff);
          corrected_markdown = LyricsEditProposal.apply_patches(
            patches,
            this.correction.original.markdown,
            {edit_format: 'plain_text'}
          ).patched;
        }
        this.correction.replacement = {
          markdown: corrected_markdown,
          html: LyricsEditing.convert_editable_for_saving({
            markdown: corrected_markdown,
            attr_hash: {},
          }),
        };

        this.set_editable(
          LyricsEditing.prepare_lyrics_with_correction($scope.canonical_lyrics_html, this.correction)
        );
        if (this.proposing_edit) {
          this.lyrics_for_edit_proposal.markdown = new StructuredText($scope.editable.markdown).plain;
        }
        return this.finish_editing();
      };

      this.has_block_level_markup = () => LyricsEditing.html_has_block_level_markup($scope.canonical_lyrics_html);

      this.apply_lyrics_update = (song_lyrics) => {
        _.assign($scope.song, song_lyrics.client_timestamps);
        this.update_lyrics_selection_safe(song_lyrics.body.html);
        if (normalized_html($scope.canonical_lyrics_html) !== normalized_html(song_lyrics.body.html)) {
          $scope.canonical_lyrics_html = song_lyrics.body.html;
          this.editable_lyrics_stale = true;
        }
        return song_lyrics;
      };

      const normalized_html = lyrics => $('<div>').append($(lyrics)).html();

      const get_lyrics_for_saving = () => LyricsEditing.convert_editable_for_saving($scope.editable);

      const get_lyrics_for_preview = () => LyricsEditing.backfill_angular_link_properties(
        LyricsEditing.lyrics_pseudo_markdown_to_html__deprecated(
          $scope.editable.markdown,
          $scope.editable.attr_hash
        )
      );

      $scope.$watch('lyrics_ctrl.correction', (_correction_is, correction_was) => {
        if (correction_was && correction_was.anchor.hasClass('line-correction')) {
          correction_was.anchor.replaceWith(correction_was.original.html);
        }
      });

      $scope.$watch('current_referent', (current_referent_is) => {
        if (current_referent_is) {
          this.correcting = null;
          this.correction = null;
        }
      });

      $scope.$watch('lyrics_data', (lyrics_data) => {
        if (lyrics_data) this.apply_lyrics_update(lyrics_data);
      });
    }],

    controllerAs: 'lyrics_ctrl',

    link(scope, element, attrs, ctrl) {
      const bindings = resolve_bindings(scope, attrs);
      const $section = element.find('section');

      ctrl.lyrics_anchorer = new LyricsAnchorer({
        container: $section,
        get_state() {
          const reference_element = scope.current_referent ? ReferentElementMap.get(scope.current_referent.id) : null;

          return {
            range: ctrl.selection_range,
            referent: scope.current_referent,
            reference_element,
          };
        },
        scope,
        song: scope.song,
      });

      ctrl.update_selection_range = function(range) {
        if (range === this.selection_range) return;
        if (range && !ReferentHelper.range_or_contents_intersects_links_or_contains_images(range)) {
          this.selection_range = range;
        } else {
          this.selection_range = null;
        }
        bindings.on_selection_range_updated({range: this.selection_range});
      };

      bindings.yields_api({
        api: {
          anchorer: ctrl.lyrics_anchorer,
          remove_referent: ctrl.remove_referent,
          refresh_lyrics: ctrl.refresh_lyrics.bind(ctrl),

          block_edit(edit_blocked) {
            ctrl.edit_blocked = edit_blocked;
          },
        },
      });

      ctrl.update_lyrics_selection_safe = (new_html) => {
        TextSelection.restore_selection_if_clobbered($section[0], () => {
          scope.lyrics_section_html(new_html);
        });
      };

      let referent_scroll_focus = {replace() {}};
      if (mobile_device) {
        referent_scroll_focus = new ScrollFocus([], {
          container: $section.get(0),
        }).on('update', (referent_anchor) => {
          bindings.on_focused_referent_anchor_changed({referent_anchor});
        });
      }

      const attach_lyrics = (dom) => {
        $section.empty();
        $section.append(dom);

        const referent_anchors = $section.find('a[annotation-fragment]').toArray();
        const previewable_anchors =
          _.chain(referent_anchors).
            uniqBy(anchor => $(anchor).data('id')).
            filter((anchor) => {
              const referent_id = Number($(anchor).data('id'));
              return scope.song_ctrl.referent_has_preview(referent_id);
            }).
            value();

        referent_scroll_focus.replace(previewable_anchors);
        scope.$emit('content_change');
      };

      let lyrics_body_compilation_scope;
      scope.lyrics_section_html = (new_html) => {
        if (lyrics_body_compilation_scope) {
          lyrics_body_compilation_scope.$destroy();
        }
        if (new_html) {
          ReferentElementMap.clear();
          ReferentRegistry.reset_fragments();
          lyrics_body_compilation_scope = scope.$new();
          attach_lyrics($compile(Lyrics.prepare_for_display(new_html))(lyrics_body_compilation_scope));
          scope.$ctrl.on_lyrics_compiled();
        } else {
          return $section.html();
        }
      };

      scope.canonical_lyrics_html = bindings.canonical_lyrics_html;
    },
  };
}]);
