/* eslint-env es6 */

angular.module('genius').component('headerWithCoverArt', {
  templateUrl: 'header_with_cover_art.html',

  bindings: {
    type: '@',
    object: '<',
    header_image_url: '<headerImageUrl',
    cover_art_url: '<coverArtUrl',
    titleLabel: '<',
    artists: '<',
    can_edit: '<canEdit',
    on_edit: '&onEdit',
    verified_contributors: '<verifiedContributors',
    has_instagram_reels: '<hasInstagramReels',
    variants: '<',
    cover_arts: '<coverArts',
    focused_cover_art_id: '<focusedCoverArtId',
    close_cover_art_modal: '&onCloseCoverArtModal',
  },

  transclude: {
    metadata: '?metadata',
    secondary: '?secondary',
  },

  controller() {
    this.cover_art_cta_text = () => {
      const cover_art_count = this.cover_arts.length;
      const plural = cover_art_count > 1;
      const annotated_cover_art_count = _.filter(this.cover_arts, 'annotated').length;

      if (!annotated_cover_art_count) {
        return `${cover_art_count} Cover${plural ? 's' : ''}`;
      } else if (cover_art_count === annotated_cover_art_count) {
        return `${cover_art_count} Annotated Cover${plural ? 's' : ''}`;
      } else {
        return `${cover_art_count} Cover${plural ? 's' : ''}, ${annotated_cover_art_count} Annotated`;
      }
    };
  },
});
