/* eslint-env es6 */

angular.module('genius').factory('Ads', ['$location', '$q', '$window', 'ApiClient', 'application_bootstrap', function($location, $q, $window, ApiClient, application_bootstrap) {
  $window['Genius.ads'] = $window['Genius.ads'] || [];
  const ready = $q(resolve => $window['Genius.ads'].push(resolve));

  const log_render_event = (name, instance, event) => {
    ApiClient.post(`/client_logs/gpt_slot_rendered/${$location.search().log_ads}`, Object.assign(
      {
        name,
        instance,
        url: $location.url(),
        hb_bidder: _.get(event.slot.getTargetingMap(), ['hb_bidder', 0]),
        hb_adid: _.get(event.slot.getTargetingMap(), ['hb_adid', 0]),
      },
      event.slot.getResponseInformation()
    ));
  };

  ready.then((ads) => {
    application_bootstrap.after(ads.trigger_user_syncs.bind(ads));
  });

  return {
    ensure_targeting_for_placements(placements) {
      return ready.then(ads => ads.ensure_targeting_for_placements(placements));
    },

    id_for(name, instance) {
      return ready.then(ads => ads.id_for(name, instance));
    },

    reset_for_new_page_view({targeting}) {
      return ready.then((ads) => {
        ads.reset_for_new_page_view({targeting});
      });
    },

    render(name, instance, kv) {
      return ready.then(ads => $q((resolve) => {
        ads.render(name, instance, kv).then((event) => {
          if ($location.search().log_ads) log_render_event(name, instance, event);
          resolve(event);
        });
      }));
    },

    dispose(name, instance) {
      return ready.then(ads => $q((resolve) => {
        ads.dispose(name, instance).then(resolve);
      }));
    },

    get_base_targeting(name) {
      return ready.then(ads => $q((resolve) => {
        resolve(ads.get_base_targeting(name));
      }));
    },

    get_async_targeting(name, instance) {
      return ready.then(ads => $q((resolve) => {
        ads.get_async_targeting(name, instance).then(resolve);
      }));
    },
  };
}]);
