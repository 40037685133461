/* eslint-env es6 */

angular.module('genius').directive('markdownPreview', [function() {
  return {
    restrict: 'E',

    scope: {
      markdown_content: '<markdownContent',
    },

    templateUrl: 'markdown_preview.html',

    controller: ['$scope', 'MarkdownPreviewClient', function($scope, MarkdownPreviewClient) {
      $scope.$watch('markdown_content', _.throttle(() => {
        MarkdownPreviewClient.create_preview($scope.markdown_content).then((html) => {
          $scope.html_content = html;
        });
      }, 500, {leading: true, trailing: true}));
    }],
  };
}]);
