/* eslint-env es6 */

angular.module('genius').component('otherUsersWithIp', {
  templateUrl: 'other_users_with_ip.html',

  bindings: {
    user: '<',
  },

  controller: ['PagedDataFeed', 'UserClient', function(PagedDataFeed, UserClient) {
    this.$onChanges = (changes) => {
      if (changes.user) {
        this.infinite_scroll_data = new PagedDataFeed(
          _.partial(UserClient.other_users_with_ip, this.user.id),
          'other_users_with_ip'
        );
      }
    };
  }],
});
