/* eslint-env es6 */

angular.module('genius').directive('showIfBrowser', ['Bowser', function(Bowser) {
  return {
    restrict: 'A',

    link(_scope, element, attrs) {
      if (!Bowser[attrs.showIfBrowser]) {
        element.hide();
      }
    },
  };
}]);
