angular.module('genius').component('albumGrid', {
  templateUrl: 'album_grid.html',

  bindings: {
    albums: '<',
    artist: '<',
    count: '<',
    variants: '<',
  },

  controller: ['PagedDataFeed', 'ArtistClient', 'Session', function(PagedDataFeed, ArtistClient, Session) {
    this.$onChanges = (changes) => {
      if (changes.artist) {
        this.artist_album_scrollable_data = new PagedDataFeed(
          _.partial(ArtistClient.load_albums, this.artist.id),
          'albums'
        );
      }
    };

    this.albums_discography_path = Session.has_permission('view_discography', this.artist) ?
      ArtistClient.discography_path(this.artist, 'albums') :
      '';
  }],
});
