/* eslint-env es6 */

angular.module('genius').factory('Attempt', ['$window', 'I18n', function($window, I18n) {
  return {
    close_form(override_confirm, close) {
      if (override_confirm) {
        close();
      } else if ($window.confirm(I18n.t('editing.dirty_unload_confirm_box'))) {
        close();
      }
    },

    admin_action(perform_admin_action, message) {
      if ($window.confirm(message)) {
        perform_admin_action();
      }
    },
  };
}]);
