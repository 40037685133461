/* eslint-env es6 */

angular.module('genius').component('manageRoles', {
  templateUrl: 'manage_roles.html',

  bindings: {
    user: '<',
  },

  controller: ['Session', 'UserClient', 'Reversible', 'AppConfig', function(Session, UserClient, Reversible, AppConfig) {
    this.user_authority_roles = AppConfig.user_authority_roles;

    this.can_toggle = role => Session.has_permission(`toggle_${role}_status`, this.user);

    this.has_role = role => _.includes(this.user.roles_for_display, role);

    this.role_grant = role => _.find(this.user.role_grants, role_grant => role_grant.role === role);

    this.errors = {};
    this.grant = (role, status) => {
      const message_key = status ? 'role.actions.confirm_grant' : 'role.actions.confirm_remove';
      const confirm = window.confirm(I18n.t(message_key, {role, login: this.user.login}));
      if (!confirm) return;

      this.errors = {};
      let updated_roles;
      let action;
      if (status) {
        updated_roles = this.user.roles_for_display.concat(role);
        action = UserClient.add_role(this.user.id, role);
      } else {
        updated_roles = _.without(this.user.roles_for_display, role);
        action = UserClient.remove_role(this.user.id, role);
      }
      const revert = Reversible.modify(this.user, {roles_for_display: updated_roles});
      action.then((data) => {
        _.assign(this.user, data.user);
      }).catch((data) => {
        revert();
        this.errors[role] = data.errors;
      });
    };
  }],
});
