/* eslint-env es6 */

angular.module('genius').component('miniUserCard', {
  bindings: {
    user: '<object',
    variants: '<',
  },

  templateUrl: 'mini_user_card.html',
});
