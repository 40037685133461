/* eslint-env es6 */

angular.module('genius').factory('AppleAudioPlayback', ['$interval', '$rootScope', function($interval, $rootScope) {
  return class AppleAudioPlayback {
    constructor({instance, external_song: {provider_id, duration_ms}}) {
      this.instance = instance;
      this.apple_id = provider_id;
      this.duration = duration_ms;
      this.type = 'apple_audio';
      this.label = 'Apple Music';
      this.userHasRequestedPlayback = false;
      this.emitter = $rootScope.$new();

      $interval(() => this.emitter.$emit('state', this.state), 50);

      this.instance.addEventListener('playbackTimeDidChange', async () => {
        if (this.instance.currentPlaybackTime === this.instance.currentPlaybackDuration) {
          await this.instance.pause();
          await this.instance.seekToTime(0);
        }
      });

      this.instance.setQueue({song: this.apple_id}).then(() => this.preloadAudio());
    }

    $on(name, callback) {
      return this.emitter.$on(name, callback);
    }

    get playing() {
      return this.instance.isPlaying;
    }

    get position() {
      return this._currentPlayer.audio ? this._currentPlayer.audio.currentTime * 1000 : 0;
    }

    get loading() {
      return !(this.instance.nowPlayingItem && this._currentPlayer.audio);
    }

    get state() {
      return {
        position: this.position,
        percentage: this.position / this.duration,
        time_elapsed: this.position,
        time_remaining: this.duration - this.position,
        is_loading: this.loading,
        is_playing: this.playing,
      };
    }

    get api() {
      return {
        play: () => {
          this.userHasRequestedPlayback = true;
          if (this.instance.isAuthorized) {
            return this.instance.play();
          } else {
            return this.instance.authorize().then(this.instance.play);
          }
        },
        pause: () => this.instance.pause(),
        playpause: () => this.playing ? this.api.pause() : this.api.play(),
        seek_to: percentage => this.api.seek_to_position(this.duration * percentage),
        seek_to_position: (position) => {
          if (this.playing) {
            return this.instance.seekToTime(position / 1000);
          } else {
            return this.api.play().then(() => this.instance.seekToTime(position / 1000));
          }
        },
      };
    }

    get _currentPlayer() {
      return this.instance._playbackController._mediaItemPlayback._currentPlayer;
    }

    preloadAudio() {
      if (this.instance.isAuthorized) {
        const callback = () => {
          if (!this.userHasRequestedPlayback) {
            this.instance.pause();
          }
        };
        this.instance.addEventListener('nowPlayingItemDidChange', callback, {once: true});

        return this.instance.play().finally(() => this.cleanUpBuggyAutoplayPromise());
      }
    }

    cleanUpBuggyAutoplayPromise() {
      if (this._currentPlayer._deferredPlay) {
        this._currentPlayer._deferredPlay.resolve();
      }
    }
  };
}]);
