/* eslint-env es6 */

angular.module('genius').component('profileContributionsArticles', {
  templateUrl: 'profile_contributions_articles.html',

  bindings: {
    user: '<',
    contributions_group: '<contributionsGroup',
  },

  controller() {
    this.$onChanges = (changes) => {
      if (changes.contributions_group) {
        this.article = this.contributions_group.contributions[0];
      }
    };
  },
});
