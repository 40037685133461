/* eslint-env es6 */

angular.module('genius').component('albumTracklistRow', {
  bindings: {
    album: '<album',
    album_appearance: '<albumAppearance',
  },

  templateUrl: 'album_tracklist_row.html',

  controller: ['Session', function(Session) {
    this.has_credits = () =>
      this.album_appearance.song.featured_artists.length ||
      this.album_appearance.song.producer_artists.length ||
      this.album_appearance.song.writer_artists.length;

    this.can = permission => Session.has_permission(permission, this.album_appearance.song);

    const album_primary_artist_ids = this.album.primary_artists.
      map(artist => artist.id).
      sort();
    const song_primary_artist_ids = this.album_appearance.song.primary_artists.
      map(artist => artist.id).
      sort();
    this.show_artist =
      album_primary_artist_ids.length !== song_primary_artist_ids.length ||
      album_primary_artist_ids.some((id, index) => song_primary_artist_ids[index] !== id);
  }],
});
