/* eslint-env es6 */

angular.module('genius').factory('patch_structured_text', ['StructuredText', 'diff_match_patch', function(StructuredText, diff_match_patch) {
  const {DIFF_DELETE, DIFF_INSERT, DIFF_EQUAL} = diff_match_patch;

  return function(patches, string, {dmp} = {}) {
    if (patches.length === 0) return [string, []];

    dmp = dmp || new diff_match_patch();

    patches = dmp.patch_deepCopy(patches);
    dmp.patch_splitMax(patches);

    const nullPadding = dmp.patch_addPadding(patches);
    let text = new StructuredText(nullPadding + string + nullPadding);

    let delta = 0;
    const results = [];
    for (let x = 0; x < patches.length; x++) {
      const expected_loc = patches[x].start2 + delta;
      const text1 = dmp.diff_text1(patches[x].diffs);
      let start_loc;
      let end_loc = -1;
      if (text1.length > dmp.Match_MaxBits) {
        start_loc = dmp.match_main(
          text.plain,
          text1.substring(0, dmp.Match_MaxBits),
          expected_loc
        );

        if (start_loc !== -1) {
          end_loc = dmp.match_main(
            text.plain,
            text1.substring(text1.length - dmp.Match_MaxBits),
            expected_loc + text1.length - dmp.Match_MaxBits
          );

          if (end_loc === -1 || start_loc >= end_loc) {
            start_loc = -1;
          }
        }
      } else {
        start_loc = dmp.match_main(text.plain, text1, expected_loc);
      }

      if (start_loc === -1) {
        results[x] = false;
        delta -= patches[x].length2 - patches[x].length1;
      } else {
        results[x] = true;
        delta = start_loc - expected_loc;

        let text2;
        if (end_loc === -1) {
          text2 = text.plain.substring(start_loc, start_loc + text1.length);
        } else {
          text2 = text.plain.substring(start_loc, end_loc + dmp.Match_MaxBits);
        }

        const diffs = dmp.diff_main(text1, text2, false);
        if (text1.length > dmp.Match_MaxBits &&
            dmp.diff_levenshtein(diffs) / text1.length >
            dmp.Patch_DeleteThreshold) {
          results[x] = false;
        } else {
          dmp.diff_cleanupSemanticLossless(diffs);
          let index1 = 0;
          let index2;
          for (const [diff_type, content] of patches[x].diffs) {
            if (diff_type !== DIFF_EQUAL) {
              index2 = dmp.diff_xIndex(diffs, index1);
            }
            if (diff_type === DIFF_INSERT) {
              text = text.insert_at(start_loc + index2, content);
            } else if (diff_type === DIFF_DELETE) {
              text = text.remove_range(
                start_loc + index2,
                start_loc + dmp.diff_xIndex(diffs, index1 + content.length)
              );
            }
            if (diff_type !== DIFF_DELETE) {
              index1 += content.length;
            }
          }
        }
      }
    }

    return [
      text.structured.substring(nullPadding.length, text.structured.length - nullPadding.length),
      results,
    ];
  };
}]);
