/* eslint-env es6 */

angular.module('genius').factory('PyongClient', ['AppConfig', '$http', function(AppConfig, $http) {
  const post = (path, type, id, params = {}) => {
    params[`${type}_id`] = id;
    return $http.post(AppConfig.api_root_url + path, params).
      then(response => response.data.response[type]);
  };

  return {
    pyong(type, id) {
      return post('/pyongs', type, id, {note_may_follow: true});
    },
    finalizePyong(type, id, pyong_note) {
      return post('/pyongs/finalize', type, id, {pyong_note});
    },
  };
}]);
