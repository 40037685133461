/* eslint-env es6 */

angular.module('genius').factory('VideoPlacement', ['Tracking', 'VideoRegistry', '$immediate', (Tracking, VideoRegistry, $immediate) => class {
  constructor(object, name, on_ready = _.noop) {
    this.object = object;
    this.name = name;

    $immediate(() => {
      VideoRegistry.videos_for_placement(this.object, this.name).then((videos) => {
        this.videos = videos;
        this.track_loaded();
        on_ready(this.videos);
      });
      this.page_type = VideoRegistry.current_page_type(this.object);
    });
  }

  tracking_data(video) {
    return {
      'Page Type': this.page_type,
      'Placement': this.name,
      'Position': _.indexOf(this.videos, video) + 1,
      'Video ID': video.id,
      'Relevance': video.relevance,
      'Object Type': this.object._type,
      'Object ID': this.object.id,
    };
  }

  track_loaded() {
    const buckets = {};
    _.forEach(this.videos, (video) => {
      buckets[video.relevance] = buckets[video.relevance] || [];
      buckets[video.relevance].push(video);
    });
    _.forEach(buckets, (videos, bucket) => {
      Tracking.attach_to_delayed_track(
        `Videos Loaded (placement: ${this.name}, relevance: ${bucket})`,
        {'IDs': _.map(videos, 'id')}
      );
    });
  }
}]);
