/* eslint-env es6 */

angular.module('genius').directive('placeholderUserBadge', [function() {
  return {
    restrict: 'E',

    scope: {
      size: '@',
    },

    templateUrl: 'user_badge.html',

    link(scope) {
      scope.user = {
        name: 'username',
        iq: '1738',
        avatar: {
          thumb: {
            url: '',
          },
        },
      };
    },
  };
}]);
