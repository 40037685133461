/* eslint-env es6 */

angular.module('genius').component('artistSongs', {
  templateUrl: 'artist_songs.html',

  bindings: {
    artist: '<',
  },

  controller: ['PagedDataFeed', 'SongClient', 'Session', 'ArtistClient', '$debounce', function(PagedDataFeed, SongClient, Session, ArtistClient, $debounce) {
    this.search_text = '';
    this.can = (permission, object) => Session.has_permission(permission, object);

    const sort_all_songs_by = (method) => {
      this.artist_song_infinite_scrollable_data =
        new PagedDataFeed(_.partial(SongClient.for_artist, this.artist.id), 'songs', {sort: method});
    };

    this.sort_songs_by = (method) => {
      if (this.sort_method !== method) {
        if (!this.search_text) {
          sort_all_songs_by(method);
        }
        this.sort_method = method;
      }
    };

    this.search = function() {
      this.searching = true;
      this.search_debounced();
    };

    this.search_debounced = $debounce(
      () => {
        if (this.search_text) {
          this.artist_song_infinite_scrollable_data =
            new PagedDataFeed(
              _.partial(SongClient.search_with_artist, this.artist.id),
              'songs',
              {sort: this.sort_method, q: this.search_text}
            );
          const next = this.artist_song_infinite_scrollable_data.next;
          this.artist_song_infinite_scrollable_data.next = () => next().then((data) => {
            this.searching = false;
            return data;
          });
        } else {
          sort_all_songs_by(this.sort_method);
        }
      },
      500
    );

    this.sort_songs_by('popularity');

    this.songs_discography_path = this.can('view_discography', this.artist) ?
      ArtistClient.discography_path(this.artist, 'songs') :
      '';
  }],
});
