/* eslint-env es5 */
/* eslint-disable arrow-body-style, arrow-parens, arrow-spacing, constructor-super, generator-star-spacing, no-class-assign, no-confusing-arrow, no-const-assign, no-dupe-class-members, no-duplicate-imports, no-extra-bind, no-new-symbol, no-restricted-imports, no-this-before-super, no-useless-computed-key, no-useless-constructor, no-useless-rename, no-var, object-shorthand, prefer-arrow-callback, prefer-const, prefer-numeric-literals, prefer-reflect, prefer-rest-params, prefer-spread, prefer-template, require-yield, rest-spread-spacing, sort-imports, symbol-description, template-curly-spacing, yield-star-spacing, dot-location, key-spacing, max-len, new-parens, no-lonely-if, no-mixed-operators, no-trailing-spaces, no-whitespace-before-property, padded-blocks, quotes, space-infix-ops */

angular.module('genius').component('songAlbums', {
  bindings: {
    song: '<',
    primary_album_tracks: '<primaryAlbumTracks',
  },

  templateUrl: 'song_albums.html',

  controller: function() {
    var all_shown = false;

    this.primary_album = function() {
      return this.song.album;
    };

    this.other_albums = function() {
      return this.primary_album()
        ? _.reject(this.song.albums, {id: this.primary_album().id})
        : this.song.albums;
    };

    this.hidden_album_count = function() {
      return all_shown ? 0 : Math.max(0, this.other_albums().length - 2);
    };

    this.show_all = function() {
      all_shown = true;
    };
  },
});
