/* eslint-env es6 */

angular.module('genius').directive('songAdminMenu', [function() {
  return {
    restrict: 'E',

    templateUrl: 'song_admin_menu.html',

    transclude: {
      'additional_buttons': '?additionalButtons',
    },

    controller: ['$scope', function($scope) {
      $scope.actions = [
        'publish', 'unpublish',
        'hide', 'unhide',
        'award_transcription_iq', 'remove_transcription_iq',
        'reindex',
        'mark_as_hot', 'unmark_as_hot',
        'view_annotation_engagement_data', 'edit_spotify_annotations_for',
        'view_lyrics_synchronization', 'destroy',
        'verify_lyrics', 'unverify_lyrics',
        'disable_media', 'enable_media',
        'hide_apple_player', 'unhide_apple_player', 'trigger_apple_match',
        'mark_lyrics_evaluation_as_complete', 'mark_lyrics_evaluation_as_staff_approved',
        'unmark_lyrics_evaluation_as_complete', 'mark_lyrics_evaluation_as_un_staff_approved',
      ];
    }],

    link(scope) {
      scope.$watch('song.current_user_metadata', () => {
        scope.has_admin_items = _(scope.actions).some(action => scope.can_perform(action));
      });
    },
  };
}]);
