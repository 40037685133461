/* eslint-env es6 */

angular.module('genius').controller('HeaderCtrl', ['$scope', 'GlobalRefs', 'Tracking', 'LibratoBeacon', function($scope, GlobalRefs, Tracking, LibratoBeacon) {
  this.$onInit = function() {
    this.cloudflare_error = Boolean(
      GlobalRefs.get_element('cloudflare_unavailable_alert').length
    );

    if (this.cloudflare_error) {
      Tracking.track('cloudflare_error.always_online_banner', {source: 'bagon'});
      LibratoBeacon.count('cloudflare_error.always_online_banner', {source: 'bagon'});
    }
  };

  $scope.toggle_mobile_subnav_menu = () => {
    $scope.mobile_subnav_menu_open = !$scope.mobile_subnav_menu_open;
  };

  $scope.close_mobile_subnav_menu = () => {
    $scope.mobile_subnav_menu_open = false;
  };
}]);
