/* eslint-env es6 */

angular.module('genius').component('songVerifiedArtistsSectionSingle', {
  bindings: {
    contributor: '<',
    featured: '<',
    label: '@',
    variants: '<',
  },

  templateUrl: 'song_verified_artists_section_single.html',

  controller() {
    this.$onChanges = (changes) => {
      if (changes.contributor) {
        this.url = this.contributor.artist.url;
        this.image_url = this.contributor.artist.image_url;
        this.is_meme_verified = this.contributor.artist.is_meme_verified;
        this.name = this.contributor.artist.name;
      }
    };
  },
});
