/* eslint-env es6 */

angular.module('genius').directive('scrubber', ['$document', '$timeout', function($document, $timeout) {
  const is_touch = ('ontouchstart' in window) || navigator.MaxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

  return {
    scope: {
      scrubPercentage: '=',
      scrubbed: '&',
      scale: '@',
    },

    link(scope, element) {
      const scale = parseFloat(scope.scale || '1');

      const seek_to_position = (pageX) => {
        const width = element.width() * scale;
        const offset_to_timeline = Math.max(0, Math.min(width, pageX - element.offset().left));
        scope.scrubbed({percentage: offset_to_timeline / width});
      };

      element.on('click', (e) => {
        seek_to_position(e.pageX);
        return false;
      });

      const input = is_touch ?
        {start: 'touchstart', end: 'touchend', move: 'touchmove'} :
        {start: 'mousedown', end: 'mouseup', move: 'mousemove'};

      const pageX = (e) => {
        if (e.pageX) return e.pageX;
        const event = e.originalEvent;
        return event.type === 'touchend' ? event.changedTouches[0].pageX : event.touches[0].pageX;
      };

      element.on(input.start, () => {
        const width = element.width() * scale;

        const move_handler = function(move_event) {
          const offset_to_timeline = Math.max(0, Math.min(width, pageX(move_event) - element.offset().left));
          scope.$apply(() => {
            scope.scrubPercentage = offset_to_timeline / width;
          });
        };

        $document.on(input.move, move_handler);

        $document.one(input.end, (end_event) => {
          if (end_event.originalEvent.defaultPrevented) { return; }
          seek_to_position(pageX(end_event));
          $document.off(input.move, move_handler);
          $timeout(() =>  {
            Reflect.deleteProperty(scope, 'scrubPercentage');
          }, 500, !'invoke_apply');
        });
      });
    },
  };
}]);
