/* eslint-env es6 */

angular.module('genius').component('webAnnotatorManagerErrors', {
  bindings: {
    yields_on_error: '&yieldsOnError',
  },

  templateUrl: 'web_annotator_manager_errors.html',

  controller: ['$timeout', function($timeout) {
    this.$onInit = () => {
      this.errors = [];
      this.yields_on_error({on_error: this.on_error});
    };

    this.on_error = (error) => {
      this.errors.unshift(error);
      $timeout(() => _.remove(this.errors, error), 5000);
    };
  }],
});
