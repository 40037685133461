import 'jquery.autocomplete-1.1-genius-modifications';
/* eslint-env es6 */


angular.module('genius').directive('autocompleteWith', ['ArtistClient', 'UserClient', 'CustomPerformanceRoleClient', 'SongClient', 'Bindings', function(ArtistClient, UserClient, CustomPerformanceRoleClient, SongClient, Bindings) {
  const autocomplete_default_options = {
    delay: 250,
    scroll: false,
    matchSubset: false,
    max: 20,
    minChars: 4,
    dataType: 'json',
    selectFirst: false,
    resultsClass: 'autocomplete-results',
  };

  const adapters = {
    user: UserClient,
    customPerformanceRole: CustomPerformanceRoleClient,
    artist: ArtistClient,
    song: SongClient,
  };

  const resolve_bindings = Bindings.resolver({
    on_select: '&?onSelect',
    create_result: '&?createResult',
    display_field: '@displayField',
    autocomplete_with: '@autocompleteWith',
    autocomplete_options: '<autocompleteOptions',
  });

  return {
    restrict: 'A',

    require: '?ngModel',

    link(scope, element, attrs, ngModel) {
      const bindings = resolve_bindings(scope, attrs);

      const display_field = bindings.display_field;
      const adapter = adapters[bindings.autocomplete_with];
      const field_value_to_model_value_map = {};

      if (display_field) {
        ngModel.$formatters.push((obj) => {
          if (!obj) return '';
          return _.get(obj[0], display_field, '');
        });
        ngModel.$parsers.push((selected) => {
          field_value_to_model_value_map[selected] =
            field_value_to_model_value_map[selected] || _.set({}, display_field, selected);
          return field_value_to_model_value_map[selected];
        });
      }

      const autocomplete_options = _.assign({},
        autocomplete_default_options,
        _.merge({}, adapter.autocomplete_options, bindings.autocomplete_options)
      );
      const parser = autocomplete_options.parse;
      autocomplete_options.parse = (data, term) => {
        const parsed = parser(data);
        _.each(parsed, (parsed_value) => {
          field_value_to_model_value_map[parsed_value.data[0]] = parsed_value.value;
        });
        if (bindings.create_result) {
          const new_result = bindings.create_result({term});
          parsed.unshift({
            data: [term, new_result],
            result: new_result,
            value: new_result,
          });
        }
        return parsed;
      };

      element.autocomplete(adapter.autocomplete_api_endpoint, autocomplete_options).result((_event, data) => {
        if (_.isUndefined(bindings.on_select)) {
          ngModel.$setViewValue(data[0]);
          ngModel.$render();
        } else {
          scope.$apply(() => bindings.on_select({data: data[1]}));
          element.val('');
        }
      });
    },
  };
}]);
