angular.module('genius').component('albumMetadataForm', {
  templateUrl: 'album_metadata_form.html',

  bindings: {
    album_was: '<album',
    on_save: '&onSave',
    close: '&',
    yields_is_dirty: '&yieldsIsDirty',
  },

  controller: [function() {
    this.$onInit = () => {
      this.album = angular.copy(this.album_was);
    };

    this.save = () => {
      this.on_save({album: this.album}).catch((error) => {
        this.error = error;
      });
    };
  }],
});
