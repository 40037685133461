/* eslint-env es6 */

angular.module('genius').factory('performance_mark', ['$window', function($window) {
  const create = (name) => {
    if (!_.get($window, 'performance.mark')) return;
    $window.performance.mark(name);
  };

  return {create, create_once: _.memoize(create)};
}]);
