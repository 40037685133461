/* eslint-env es6 */

angular.module('genius').factory('MessageClient', ['ApiClient', function(ApiClient) {
  return {
    for_conversation(conversation_id, params = {}) {
      return ApiClient.
        returning('messages').
        get(
          '/conversations/:conversation_id/messages',
          {conversation_id, text_format: 'html', page: params.page || 1}
        );
    },

    create(message) {
      return ApiClient.
        returning('message').
        post(
          '/messages',
          {message, text_format: 'html'}
        );
    },
  };
}]);
