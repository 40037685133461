/* eslint-env es6 */

angular.module('genius').factory('LibratoBeacon', ['$window', '$document', function($window, $document) {
  const METRICS_URL = 'https://librato-collector.genius.com/v1/metrics';

  const queue = [];
  const isClient = typeof $window !== 'undefined';

  const flush_queue = _.debounce(() => {
    const body = _.mergeWith(
      ...queue.splice(0),
      (operations1, operations2) => (operations1 || []).concat(operations2)
    );
    if (navigator.sendBeacon) {
      navigator.sendBeacon(
        METRICS_URL,
        new Blob([JSON.stringify(body)], {type: 'application/json'})
      );
    } else {
      fetch(
        METRICS_URL,
        {
          method: 'POST',
          body: JSON.stringify(body),
          headers: {'Content-Type': 'application/json'},
        }
      );
    }
  }, 2000, {leading: false, trailing: true});

  isClient && $document[0].addEventListener('visibilitychange', () => {
    if ($document[0].visibilityState === 'hidden') {
      flush_queue.flush();
    }
  });

  return {
    measure(name, value, {source}) {
      queue.push({gauges: [{name, value, source}]});
      flush_queue();
    },
    count(name, {source}) {
      queue.push({counters: [{name, value: 1, source}]});
      flush_queue();
    },
  };
}]);
