/* eslint-env es6 */

angular.module('genius').directive('selectAllOnClick', [function() {
  return {
    restrict: 'A',

    link(_scope, element) {
      element.on('click', (e) => {
        e.target.select();
      });
    },
  };
}]);
