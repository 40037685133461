/* eslint-env es6 */

angular.module('genius').factory('AuthenticationClient', ['ApiClient', 'signed_out_created_content', function(ApiClient, signed_out_created_content) {
  return {
    sign_in(user) {
      return ApiClient.post('/sessions', {user});
    },

    sign_up(user) {
      return ApiClient.post('/users', {
        user,
        signed_out_created_content: signed_out_created_content.get(),
      }).then((response) => {
        signed_out_created_content.clear();
        return response;
      });
    },
  };
}]);
