/* eslint-env es5 */
/* eslint-disable arrow-body-style, arrow-parens, arrow-spacing, constructor-super, generator-star-spacing, no-class-assign, no-confusing-arrow, no-const-assign, no-dupe-class-members, no-duplicate-imports, no-extra-bind, no-new-symbol, no-restricted-imports, no-this-before-super, no-useless-computed-key, no-useless-constructor, no-useless-rename, no-var, object-shorthand, prefer-arrow-callback, prefer-const, prefer-numeric-literals, prefer-reflect, prefer-rest-params, prefer-spread, prefer-template, require-yield, rest-spread-spacing, sort-imports, symbol-description, template-curly-spacing, yield-star-spacing, dot-location, key-spacing, max-len, new-parens, no-lonely-if, no-mixed-operators, no-trailing-spaces, no-whitespace-before-property, padded-blocks, quotes, space-infix-ops */

angular.module('genius').component('iqNotificationList', {
  templateUrl: 'iq_notification_list.html',

  controller: ['LocalStorage', 'PageVisibility', 'Session', 'Pusher', '$timeout', function(LocalStorage, PageVisibility, Session, Pusher, $timeout) {
    this.user = Session.current_user;
    this.notifications = [];
    this.line_item_count = 0;

    var add_notification = function(line_item) {
      if (_.isEmpty(this.notifications)) {
        this.notifications.unshift(this.user);
      }
      this.line_item_count++;

      $timeout(function() {
        this.notifications.unshift(line_item);
        this.user.iq += line_item.iq_gained;

        $timeout(function() {
          _.pull(this.notifications, line_item);
          this.line_item_count--;
          if (this.line_item_count === 0) {
            this.notifications.pop();
          }
        }.bind(this), 5000);
      }.bind(this), 200);
    }.bind(this);

    Pusher.subscribe(Session.current_user.pusher_channel, 'iq_increment', function(line_item) {
      PageVisibility.when_visible(function() {
        if (LocalStorage.is_available()) {
          var newest_seen_event_id = LocalStorage.get_item('newest_event_id', 0);
          var newest_event_id = _.max(line_item.event_ids);
          if (newest_event_id > newest_seen_event_id) {
            add_notification(line_item);
            LocalStorage.set_item('newest_event_id', newest_event_id);
          }
        } else {
          add_notification(line_item);
        }
      });
    }.bind(this));
  }],
});
