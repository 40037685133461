/* eslint-env es6 */

angular.module('genius').factory('MetaTagData', ['$document', function($document) {
  const cache = {};

  return {
    get(itemprop) {
      if (_.has(cache, itemprop)) return cache[itemprop];
      const elem = $document[0].head.querySelector(`[itemprop=${itemprop}]`);
      cache[itemprop] = elem ? JSON.parse(elem.content) : undefined;
      return cache[itemprop];
    },
  };
}]);
