/* eslint-env es5 */
/* eslint-disable arrow-body-style, arrow-parens, arrow-spacing, constructor-super, generator-star-spacing, no-class-assign, no-confusing-arrow, no-const-assign, no-dupe-class-members, no-duplicate-imports, no-extra-bind, no-new-symbol, no-restricted-imports, no-this-before-super, no-useless-computed-key, no-useless-constructor, no-useless-rename, no-var, object-shorthand, prefer-arrow-callback, prefer-const, prefer-numeric-literals, prefer-reflect, prefer-rest-params, prefer-spread, prefer-template, require-yield, rest-spread-spacing, sort-imports, symbol-description, template-curly-spacing, yield-star-spacing, dot-location, key-spacing, max-len, new-parens, no-lonely-if, no-mixed-operators, no-trailing-spaces, no-whitespace-before-property, padded-blocks, quotes, space-infix-ops */

angular.module('genius').directive('detectImageDiffs', ['$immediate', function($immediate) {
  return {
    link: function(scope, elem, attrs) {
      $immediate(function() {
        elem.find('img').each(function() {
          var before = scope.$eval(attrs.left).indexOf(this.src) !== -1;
          var after =  scope.$eval(attrs.right).indexOf(this.src) !== -1;
          if (before && !after) this.classList.add('del');
          if (!before && after) this.classList.add('ins');
        });
      }, !'invoke_apply');
    },
  };
}]);
