/* eslint-env es6 */

angular.module('genius').component('editUserProfile', {
  templateUrl: 'edit_user_profile.html',

  bindings: {
    user: '<',
    disable_image_edit: '<disableImageEdit',
    disable_user_bio_edit: '<disableUserBioEdit',
    close: '&',
  },

  controller: ['UserClient', 'Session', '$location', 'only_changes', function(UserClient, Session, $location, only_changes) {
    this.$onChanges = (changes) => {
      if (changes.user) {
        this.editable_user = angular.copy(this.user);
      }
    };
    this.can = action_name => Session.has_permission(action_name, this.user);

    this.update_user = () => {
      if (this.editable_user.password !== this.editable_user.confirm_password) {
        this.errors = ['Passwords do not match'];
        return;
      }
      if (this.saving) return;
      this.saving = true;
      this.errors = null;

      const user_data = _.pick(
        this.editable_user,
        ['login', 'about_me', 'email', 'password', 'photo_url', 'custom_header_image_url']
      );
      user_data.about_me = user_data.about_me.markdown;
      UserClient.update(this.editable_user.id, {
        user: only_changes(user_data, this.user),
      }).then((data) => {
        _.assign(this.user, data.user);
        this.close();
        this.errors = null;
        $location.path(UserClient.get_path(data.user));
        $location.replace();
      }).catch((error) => {
        this.errors = error.errors;
      }).finally(() => {
        this.saving = false;
      });
    };
  }],
});
